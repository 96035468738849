import React from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert'

import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

const Popup = ({ handleDelete, id }) => {

    return (
        <>
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>

                        <MoreVertIcon {...bindTrigger(popupState)} sx={{ cursor: "pointer" }} />

                        <Popover

                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography px={1.5} py={0.6} onClick={(event) => { handleDelete(event, id) }}>Delete</Typography>
                        </Popover>
                    </div>
                )}
            </PopupState>
        </>
    );
}

export default Popup;
