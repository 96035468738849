import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Card, CardContent, Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  startGetAllAppointments,
  setSelectedAppointments,
  resetVisitorDetails,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'
import CustomTabContainer from '../../UI/MeetingRooms/TabsContainer/CustomTabContainer'
import BookedAppointments from './BookedAppointments'
import RequestedAppointments from './RequestedAppointments'

const AllAppointments = () => {
  const appointments = useSelector(
    (state) => state.visitData?.allAppointments?.appointments,
  )
  const appointmentsIsLoaded = useSelector(
    (state) => state.visitData?.allAppointments?.appointmentsIsLoaded,
  )
  
  const userId=useSelector(state=>state?.user?.data?.id)
  const dispatch = useDispatch()

  const handleNavigate = () => {
    // dispatch(setSelectedAppointments(appointment))
    dispatch(setVisitorComp('VisitorLocation'))
  }

  useEffect(() => {
    dispatch(startGetAllAppointments({userId}))
    return () => {
      dispatch(resetVisitorDetails())
    }
  }, [])


  return (
    <Box
      sx={{
        paddingTop: '5%',
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
        height: '100vh',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: '#FF6520',
                textTransform: 'none',
                marginBottom: 2,
                width: '100%',
                height: '60px',
                fontWeight: 'bold',
              }}
              onClick={handleNavigate}
            >
              Book an appointment
            </Button>
          </Grid>
          <Box sx={{ width: '100%' }}>
           <CustomTabContainer
              tabList={['Appointment requests', 'Booked appointments']}
              tabsPanel={[RequestedAppointments, BookedAppointments]}
              
            />
          </Box>
          {/* <Grid
            item
            spacing={2}
            xs={12}
          >
            {appointmentsIsLoaded ? (
              appointments.map((appointment) => (
                <Box
                  key={appointment._id}
                  sx={{
                    display: 'flex',
                    marginBottom: '5%',
                    
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width:'100vw',
                      padding: "3%",
                       background: '#ffffff',
                    }}
                    onClick={() => {
                      handleNavigate(appointment)
                    }}
                  >
                    <Typography component="div" variant="h6">
                      Purpose: {appointment?.purpose}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      Visitor Name: {appointment?.visitors?.[0]?.visitor?.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      Location: {appointment?.location?.name}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      Start Time:
                      {`${moment(appointment?.startTime).format(
                        'DD MMM YYYY',
                      )} ${moment(appointment?.startTime?.gmtTime).format(
                        'hh:mm A',
                      )}`}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="text.secondary"
                      component="div"
                    >
                      Status: {appointment?.status}
                    </Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Grid> */}
        </Grid>
      </Box>
    </Box>
  )
}

export default AllAppointments
