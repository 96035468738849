import swal from 'sweetalert'

export const errorModal = ({ errorText, toggleLoader }) => {
  swal({
    title: 'Oops!',
    text: errorText||'Something went wrong',
    icon: 'error',
    button: 'Ok',
  }).then((willDelete) => {
    if (willDelete) {
      toggleLoader && toggleLoader()
      // handleLoader&&handleLoader(false)
      // failRedirect&&failRedirect()
    } else {
      window.location.reload()
    }
  })
}
