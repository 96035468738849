import axios from 'axios'
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'
export const SET_FLOOR = 'SET_FLOOR'
export const RESET_ISLOADED = 'RESET_ISLOADED'
export const RESET_FLOORS='RESET_FLOORS'

const initialState={
    floors:[],
    isLoaded:false
}


export const startGetFloors=createAsyncThunk('frFloors/startGetFloors',async({id})=>{
    try{
        const response=await GET(`fr/v1/floors?buildingId=${id}`)
        if(response.status===200){
            return response.data
        }
        else {
            errorModal()
        }
    }
    catch(err){
        errorModal()
    }
})



const frFloorSlice=createSlice({
    name:'frFloorSlice',
    initialState,
    reducers:{
        resetFloorIsLoaded:(state,action)=>{
            state.isLoaded=false
        },
        resetFloor:(state,action)=>{
            state.floors=[]
            state.isLoaded=false
        }
    },
    extraReducers(builder){

        builder.addCase(startGetFloors.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.floors=action.payload
                state.isLoaded=true
            }
        })
    }
})

export const {resetFloorIsLoaded,resetFloor}=frFloorSlice.actions

export default frFloorSlice.reducer