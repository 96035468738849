import React from 'react'
import { Card } from '@mui/material'

const CardComp = (props) => {
    const { height, width, border,minHeight,backgroundColor,padding } = props
    return (
        <Card sx={{ width: width + 'vh' || '100%', height: height + 'vh' || '100%', minHeight:minHeight, border:'solid 1px #E0E0E0', padding:padding||0, borderRadius: border + 'px' || '20px', display: 'flex',  justifyContent: 'center', boxShadow: 0, /* border: noBorder ? 'solid grey 0.5px' : 'none' */ }}
        style={{backgroundColor: backgroundColor||"#FFFFFF"}}>
            {props.children}
        </Card >
    )
}

export default CardComp