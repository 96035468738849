import { Box, CssBaseline, } from '@mui/material'
import React  from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Main from '../../components/FaultReporting/Main.js'
import Nav from '../../UI/FaultReporting/Widgets/Header.js'

function FaultReporting() {
    const comp=useSelector(state=>state.faultReportingComp).title
    return (
        <Box sx={{ width: "100%", minHeight: "100%", }}>
           <CssBaseline />
           {comp==='Fault Reporting'&&
           <Nav title='Fault Reporting' route='/' />
                
    }
            <Box height={"100%"}>
                <Main />
            
        </Box>
        </Box>
    )
}

export default FaultReporting