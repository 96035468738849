import React from 'react'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton, Typography, Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import qr from '../../../Assets/Images/qr.png'
import back from '../../../Assets/Images/left-arrow.png'
import { useDispatch, useSelector } from 'react-redux';
import { resetMeetingLocalState } from '../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice';
import { assetSetComp } from '../../../Redux/ReduxSlice/AssetManagement/assetCompSlice';
import { clearAssetsToMove, setFeature } from '../../../Redux/ReduxSlice/AssetManagement/assetSlice';

const Header = (props) => {
    const comp=useSelector(state=>state.assetComp?.title)
    const history = useHistory()
    const dispatch=useDispatch()
    const { title, link, title2, title2Logo, trashLogo, handleDeleteClick, eventId } = props
    const feature=useSelector(state=>state?.assetData?.feature)
    const viewAll=useSelector(state=>state.redirection?.viewAll)
    const handleClick = () => {
       if(comp==="assetQrCodeReader"||comp==='AssetInfo'){
         if(comp==='assetQrCodeReader'&&feature==='transfer'){
            dispatch(clearAssetsToMove())
         }   
         
         else if(comp==="assetQrCodeReader"&&feature==='checkin'&&viewAll){
            history.push('/notifications')
         }
        dispatch(assetSetComp('AssetHome'))
       }
       else if(comp==='AssetTransfer'){
            dispatch(assetSetComp('assetQrCodeReader'))
       }
       else {
        history.push('/')
       }
    }
    const handleHomeClick = () => {
        history.push('/')
    }

    const handleQrClick=()=>{
        dispatch(setFeature('transfer'))
        dispatch(assetSetComp('assetQrCodeReader'))
    }
    return (
        <Grid container spacing={1} display='flex' alignItems={'center'} height={70} backgroundColor='#FF6520' p={2} >
            {!title2 && <Grid item xs={1.2} >
                <IconButton size='small' float='left' onClick={handleClick}>
                    <img src={back} width={'24px'} />
                </IconButton>

            </Grid>}
            <Grid item xs={!trashLogo ? 9.8 : 8.8} >
                <Typography fontSize={20} align='left' color='#FFFFFF'>{title}</Typography>
            </Grid>
            
                {comp==='AssetHome'&&<Grid item xs={1} onClick={handleQrClick} display='flex' justifyContent='flex-end'>
                    <img src={qr} style={{ height: '20px' }} />
                </Grid>}
            {title2Logo &&
                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton>
                        <img src={title2Logo} />
                        <Typography align='center' fontSize={15} >{title2}</Typography>
                    </IconButton>
                </Grid>}
            
        </Grid>
    )
}

export default Header