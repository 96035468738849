import { Box } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import FeedbackHome from '../../components/FeedBack/FeedbackHome'
import NewFeedBack from '../../components/FeedBack/NewFeedBack'
import QrCodeFeedBack from '../../components/FeedBack/QrCodeFeedBack'
import SelectedFeedBack from '../../components/FeedBack/SelectedFeedBack'

const Main = () => {
  const comp = useSelector((state) => state.feedbackComp?.title)

  return (
    <Box>
      {comp === 'Feed Back Home' ? (
        <FeedbackHome />
      ) : comp == 'QrCode' ? (
        <QrCodeFeedBack />
      ) : 
      comp==='Feedback_form'?<NewFeedBack />:
      comp==='SelectedRating'?<SelectedFeedBack />:
      (

        <></>
      )}
    </Box>
  )
}

export default Main
