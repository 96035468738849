import React, { useState } from 'react'
import { Box, Stack, Grid, Typography } from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import moment from 'moment'
import MeetingViewDeskBooking from '../../../UI/DeskBooking/DeskBooking/MeetingViewDeskBooking'

const MyMeetings = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format('DD-MM-YYYY'),
  )
  const now = moment()
  const arr = []
  for (let i = 0; i <= 2; i++) {
    const momentDate = moment().subtract(i, 'days').format('ddd DD-MM-YYYY')

    arr.push({
      day: momentDate.slice(0, 3),
      date: momentDate.slice(4, 6),
      fullDate: momentDate.slice(4),
    })
    if (i === 2) arr.reverse()
  }
  for (let i = 0; i <= 15; i++) {
    const momentDate = moment()
      .add(i + 1, 'days')
      .format('ddd DD-MM-YYYY')

    arr.push({
      day: momentDate.slice(0, 3),
      date: momentDate.slice(4, 6),
      fullDate: momentDate.slice(4),
    })
  }

  return (
    <>
      <Box>
        <Typography variant="h5">
          November 2022 <KeyboardArrowDownOutlinedIcon />{' '}
        </Typography>
        <Stack p={2} spacing={2}>
          <div>
            <Grid
              item
              xs={0.5}
              display="flex"
              direction="row"
              backgroundColor="black"
              container
              wrap="nowrap"
              align="center"
              justifyContent="space-between"
              sx={{
                overflow: 'auto',
                minWidth: '100%',
                minHeight: '20px',
                borderRadius: '10px',
              }}
            >
              {arr?.map((item, index) => {
                return (
                  <Grid
                    item
                    display="flex"
                    direction="row"
                    mx={1}
                    height={'100%'}
                    container
                    align="center"
                    key={index}
                  >
                    <Box
                      sx={{
                        color: '#FFFFFF',
                        backgroundColor:
                          selectedDate === item.fullDate ? 'red' : 'black',
                        p: 1,
                        borderRadius: '6px',
                        width: '40px',
                      }}
                      onClick={() => {
                        setSelectedDate(item.fullDate)
                      }}
                    >
                      <Stack m={0} p={0}>
                        <Grid item xs={12}>
                          <Typography variant="h6" fontWeight={550}>
                            {item.date}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="body1">{item.day}</Typography>
                        </Grid>
                      </Stack>
                    </Box>
                  </Grid>
                )
              })}
            </Grid>
          </div>
        </Stack>

        <Box>
          <Stack p={2} spacing={2}>
            <Box>
              <span class="material-icons blue-color"></span>
              <MeetingViewDeskBooking
                backgroundColor="#02B9A4"
                timeRange={'4.00am to 4.30am'}
                floor={'First floor'}
                meetingName={'Project X- Scrum Call'}
                room={'small meeting room'}
                guests={'04'}
                isPopup={true}
              />
            </Box>
            <MeetingViewDeskBooking
              backgroundColor="#AAAAAA"
              timeRange={'4.00am to 4.30am'}
              floor={'First floor'}
              meetingName={'Project X- Scrum Call'}
              room={'small meeting room'}
              guests={'04'}
              isPopup={true}
            />
            <MeetingViewDeskBooking
              backgroundColor="#AAAAAA"
              timeRange={'5.00pm to 5.30pm'}
              floor={'First floor'}
              meetingName={'Daily Updates'}
              room={'Medium meeting room'}
              guests={'-06'}
              isPopup={true}
            />
          </Stack>
        </Box>
      </Box>
    </>
  )
}
export default MyMeetings
