import axios from "axios"

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { GET } from "../../../Helpers/http"

export const RESOURCES_RESET = "RESOURCES_RESET"
export const RESOURCES_UPDATE_ERROR = "RESOURCES_UPDATE_ERROR"
export const RESOURCES_UPDATE_DATA = "RESOURCES_UPDATE_DATA"

const initialState = {
    isLoading: false,
    error: {
        message: "",
    },
    buildings: [],
    amenities: [],
    seatingCapacities: []
}



export const asyncFetchResources = createAsyncThunk('mr/asyncFetchResources', async (_, thunkAPI) => {
    try {
        const response = await GET('mr/v1/resources')
        if (response.status === 200) {
            return response.data
        }
    } catch (error) {
        if(error.response?.status === 303){
            window.location.href = error.response?.data?.authUrl
            thunkAPI.dispatch(updateError("Something Went Wrong"))
        } else {
            thunkAPI.dispatch(updateError("Something Went Wrong"))
        }
        throw error
    }
})


const mrResourceSlice = createSlice({
    name: 'mrResourceSlice',
    initialState,
    reducers: {
        updateError: (state, action) => {
            state.error.message = action.payload
        }

    },
    extraReducers(builder) {
        builder.addCase(asyncFetchResources.fulfilled, (state, action) => {
            if(action.payload){
                state.buildings = action.payload?.buildings
                state.amenities = action.payload?.amenities
                state.seatingCapacities = action.payload?.seatingCapacities
            }
            
        })
    }
})

export const { updateError } = mrResourceSlice.actions

export default mrResourceSlice.reducer


