import axios from 'axios'
import { setComp } from './compSlice'
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'
export const SET_AREAS = 'SET_AREAS'
export const RESET_ISLOADED = 'RESET_ISLOADED'
export const SET_SUB_AREA = 'SET_SUB_AREA'
export const CLEAR_SUB_AREA = 'CLEAR_SUB_AREA'
export const RESET_AREA = 'RESET_AREA'

const initialState={
    areas:[],
    isLoaded:false,
    subArea:{
        data:{},
        isLoaded:false
    }
}


export const startGetAreas=createAsyncThunk('frAreas/startGetAreas',async({buildingId,floorId})=>{
    try{
        const response=await GET(`fr/v1/areas?buildingId=${buildingId}${floorId && `&floorId=${floorId}`}`)
        if(response.status===200){
            return response.data
        }
    }
    catch{
        errorModal()
    }
})



export const startSubArea=createAsyncThunk('frAreas/startSubArea',async({area,bid,handleModal},thunkAPI)=>{
    try{
        const response=await GET(`fr/v1/qrcode?code=${area.scannedString}&buildingId=${bid}`)
        if(response.status===200){
            thunkAPI.dispatch(setComp('QrCodeTicket'))
            return response.data
        }
        else {
            handleModal()
        }
    }
    catch(err){
        errorModal()
    }
})



export const frAreaSlice=createSlice({
    name:'frAreaSlice',
    initialState,
    reducers:{
        resetAreaIsLoaded:(state,action)=>{
            state.isLoaded=false
        },
        clearSubArea:(state,action)=>{
            state.subArea.data={}
            state.subArea.isLoaded=false
        },
        resetArea:(state,action)=>{
            state.areas=[]
            state.isLoaded=false
        }
    },
    extraReducers(builder){
        builder.addCase(startGetAreas.fulfilled,(state,action)=>{
            if(action.payload){

                state.areas=action.payload
                state.isLoaded=true
            }
        })
        builder.addCase(startSubArea.fulfilled,(state,action)=>{
            if(action.payload){
                state.subArea={data:action.payload,isLoaded:true}
            }
        })
    }
})

export const {clearSubArea,resetArea}=frAreaSlice.actions

export default frAreaSlice.reducer