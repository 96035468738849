import {
  Box,
  Card,
  Stack,
  Grid,
  Autocomplete,
  Checkbox,
  Button,
  Typography,
  FormControlLabel,
  Modal,
  CircularProgress,
  IconButton,
  Backdrop,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import CardTitle from '../../../../UI/MeetingRooms/Widgets/MeetingRoom/CardTitle'
import TextField from '@mui/material/TextField'
import ClearIcon from '@mui/icons-material/Clear'
import GuestsButton from '../../../../UI/MeetingRooms/Button/GuestsButton'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import isEmail from 'validator/lib/isEmail'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import meetingRoom from '../../../../Assets/MeetingRooms/Images/meetingRoom.svg'
import { useHistory, useLocation } from 'react-router-dom'
import ConfirmButton from '../../../../UI/MeetingRooms/Button/ConfirmButton'
import AddGuestInput from '../../AddGuestInput/AddGuestInput'
import GuestDescription from '../../GuestDescription/GuestDescription'
import DescriptionIcon from '../../../../Assets/MeetingRooms/Images/DescriptionIcon.svg'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  startFetchMeetingRooms,
  saveEventData,
  resetEvent,
} from '../../../../Redux/ReduxSlice/MeetingRooms/eventsSlice'
import {
  setMeetingLocalState,
} from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'

import text from '../../../../Assets/MeetingRooms/Images/text.png'
import stairs from '../../../../Assets/MeetingRooms/Images/stairs.png'
import squareLayouts from '../../../../Assets/MeetingRooms/Images/squareLayouts.png'
import meetingRoomSize from '../../../../Assets/MeetingRooms/Images/meetingRoomSize.png'
import calender from '../../../../Assets/MeetingRooms/Images/calendar.png'
import addUserIcon from '../../../../Assets/MeetingRooms/Images/addUserIcon.png'
import swal from 'sweetalert';
import { asyncFetchResources } from '../../../../Redux/ReduxSlice/MeetingRooms/resourcesSlice'

const MeetingRoomPanel = (props) => {
  const location = useLocation()
  const currentLocation = location.pathname
  const history = useHistory()

  const seatingCapacities = useSelector((state) => {
    return state?.resource?.seatingCapacities
  })
  const buildings = useSelector((state) => {
    return state?.resource?.buildings
  })

  const amenitiesList = useSelector((state) => {
    return state?.resource?.amenities
  })
  const isLoaded = useSelector((state) => {
    return state?.event?.isLoaded
  })
  const [seatingCapacity, setSeatingCapacity] = useState('')
  const [date, setDate] = useState(new Date())
  const [startTime, setStartTime] = useState(new Date())
  const [meetingDuration, setMeetingDuration] = useState('Select Time')
  const [building, setBuilding] = useState('All Buildings')
  const [selectedAmenities, setSelectedAmenities] = useState([])
  const [meetingTitle, setMeetingTitle] = useState('')
  const [checked, setChecked] = useState(false)
  const [attendees, setAttendees] = useState([])
  const [description, setDescription] = useState('')
  const [isError, setIsError] = useState(false)
  const [guestEmail, setGuestEmail] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const meetingTime = [
    '15mins',
    '30mins',
    '1hr',
    '1.5hrs',
    '2hrs',
    '3hrs',
    '4hrs',
    '5hrs',
  ]
  const [load, setLoad] = useState(true)
  const [open, setOpen] = useState(false)
  const [available, setAvailable] = useState(true)
  const [finalObj, setFinalObj] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [loader, setLoader] = useState(false)
  const meetingLocalState = useSelector(
    (state) => state?.meetings?.selectedEvent?.localState,
  )
  const handleLoader = (val) => {
    setLoader(val)
  }
  const selectedEvent = useSelector((state) => {
    return state?.meetings?.selectedEvent.data
  })
  useEffect(() => {
    const length = Object.keys(selectedEvent).length > 0
    if (!length && location.pathname === '/meetingrooms/update') {
      history.push('/meetingrooms/meetings')
    } else if (length) {
      setMeetingTitle(selectedEvent.summary)
      setSeatingCapacity(selectedEvent.seatingCapacity)
      setDate(moment(selectedEvent.startTime))
      setTime(moment(selectedEvent.startTime).format('HH:mm'))
      setMeetingDuration(selectedEvent.duration)
      setBuilding(selectedEvent.building)
      setSelectedAmenities(selectedEvent.selectedAmenities)
      setDescription(selectedEvent.description)
      setAttendees(selectedEvent.guests?.map((email) => email.email))
      setIsUpdate(true)
    }
  }, [])
  useEffect(() => {
    if (Object.keys(meetingLocalState).length > 0) {
      setMeetingTitle(meetingLocalState?.meetingTitle)
      setMeetingDuration(meetingLocalState?.meetingDuration)
      setBuilding(meetingLocalState?.building)
      setSelectedAmenities(meetingLocalState?.selectedAmenities)
      setTime(meetingLocalState?.time)
      setDate(meetingLocalState?.date)
      setSeatingCapacity(meetingLocalState?.seatingCapacity)
      setChecked(meetingLocalState?.checked)
    } else if (location.pathname === '/meetingrooms/booking') {
      dispatch(resetEvent())
      setMeetingTitle('')
      setSeatingCapacity([])
      setDate(new Date())
      setTime(moment().format('HH:mm'))
      setMeetingDuration('Select Time')
      setBuilding('All Buildings')
      setSelectedAmenities([])
      setDescription('')
      setAttendees([])
      setIsUpdate(false)
    }
  }, [location.pathname])

  const handleChange = (event) => {
    if (event.target.name === 'meetingDuration') {
      setMeetingDuration(event.target.value)
    }
    if (event.target.name === 'building') {
      setBuilding(event.target.value)
    }
    if (event.target.name === 'title') {
      setMeetingTitle(event.target.value)
    }
    if (event.target.name === 'All day') {
      setChecked(event.target.checked)
    }
    if (event.target.name === 'description') {
      setDescription(event.target.value)
    }
    if (event.target.name === 'email') {
      setIsError(false)
      const value = event.target.value
      setGuestEmail(event.target.value)
      if (value[value.length - 1] === ',') {
        let finalVal
        finalVal = value.slice(0, -1)
        if (
          isEmail(finalVal.trim()) &&
          !attendees.includes(finalVal.toLowerCase().trim())
        ) {
          setAttendees([...attendees, finalVal.toLowerCase().trim()])
          setGuestEmail('')
        } else {
          setIsError(true)
        }
      }
    }
  }

  const handleDelete = (email) => {
    setAttendees(attendees?.filter((e) => e !== email))
  }

  const handleGuestInputBlur = () => {
    if (guestEmail.length > 0) {
      if (
        isEmail(guestEmail.trim()) &&
        !attendees.includes(guestEmail.toLowerCase().trim())
      ) {
        setAttendees([...attendees, guestEmail.toLowerCase().trim()])
        setGuestEmail('')
      } else {
        setIsError(true)
      }
    }
  }

  const [time, setTime] = React.useState(moment().format('hh:mm'))
  const changeTime = (e) => {
    setTime(e.target.value)
  }
  const handleSubmit = () => {
    const meetingDurationTime = meetingDuration;

    const minute =
      meetingDurationTime === '15mins'
        ? 15
        : meetingDurationTime === '30mins'
          ? 30
          : meetingDurationTime === '1hr'
            ? 60
            : meetingDurationTime === '1.5hrs'
              ? 90
              : meetingDurationTime === '2hrs'
                ? 120
                : meetingDurationTime === '3hrs'
                  ? 180
                  : meetingDurationTime === '4hrs'
                    ? 240
                    : 300;

    const date1 = moment(date).format('YYYY-MM-DD');
    const newStartTime = new Date(new Date(`${date1} ${time}`));
    const newEndTime = new Date(moment(newStartTime).add(minute, 'minutes').format());
    const eventObj = {
      summary: meetingTitle,
      seatingCapacity,
    };
    const obj = {};
    if (checked) {
      obj.selectedDate = new Date(date).toISOString().slice(0, 10);
      eventObj.selectedDate = new Date(date).toISOString().slice(0, 10);
    } else {
      obj.startTime = new Date(newStartTime).toISOString();
      obj.endTime = new Date(newEndTime).toISOString();
      eventObj.startTime = new Date(newStartTime).toISOString();
      eventObj.endTime = new Date(newEndTime).toISOString();
      eventObj.duration = meetingDuration;
    }
    obj.seatingCapacity = seatingCapacity;
    eventObj.isUpdate = isUpdate;
    eventObj.eventId = selectedEvent.id;
    obj.eventId = selectedEvent.id;
    if (building !== 'All Buildings') obj.buildingName = building;
    if (selectedAmenities.length > 0) {
      obj.amenities = selectedAmenities;
      eventObj.selectedAmenities = selectedAmenities;
    }
    location.pathname === '/meetingrooms/update' && (eventObj.attendees = attendees);

    if (description) {
      eventObj.description = description;
    }
    if (guestEmail !== '' && !attendees.includes(guestEmail)) {
      setIsError(true);
    } else {
      // Check if the selected time is in the future
      const currentTime = new Date();
      const futureTime = new Date(currentTime.getTime() - 10 * 60000); 

      if (newStartTime < futureTime) {
        swal({
          icon: 'error',
          title: 'Selected time is in the past',
          text: 'Please choose a future time.',
        });
        return;
      }

      handleLoader(true);
      setIsError(false);
      setFinalObj(eventObj);

      if (location.pathname === '/meetingrooms/booking') {
        const localState = {
          meetingTitle,
          meetingDuration,
          date,
          building,
          selectedAmenities,
          time,
          seatingCapacity,
          checked,
        };
        dispatch(setMeetingLocalState(localState));
      }

      dispatch(startFetchMeetingRooms({data:obj, handleLoader}));
      setButtonDisabled(true);
    }
  };

  const setGuests = (guest) => {
    setSeatingCapacity(guest)
  }
  const handleDateChange = (date) => {
    setDate(date.$d)
  }
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(asyncFetchResources())
  }, [])
  useEffect(() => {
    if (isLoaded) {
      dispatch(saveEventData(finalObj))
      history.push('/meetingrooms/available')
    }
  }, [isLoaded])

  return (
    <Box height="100%">
      <Card sx={{ borderRadius: '20px' }}>
        {open && (
          <Box>
            <Modal
              disablePortal
              disableEnforceFocus
              disableAutoFocus
              open={open}
              sx={{
                display: 'flex',
                p: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: 400,
                  height: 200,
                  bgcolor: 'background.paper',
                  borderRadius: 8,
                  p: 4,
                }}
              >
                <Stack alignItems={'center'}>
                  <img src={meetingRoom} height="30px" />
                  <Typography variant="h6" fontWeight={'bold'}>
                    Small meeting room
                  </Typography>
                  <Typography>Floor</Typography>
                  <Box>
                    {load ? (
                      <Stack alignItems={'center'}>
                        <Typography variant="h6" align="center">
                          Confirming the availability of the meeting room for
                          your schedule{' '}
                        </Typography>
                        <CircularProgress color="inherit" />
                      </Stack>
                    ) : available ? (
                      <Typography variant="h6" align="center">
                        Available{' '}
                      </Typography>
                    ) : (
                      <Typography variant="h6" align="center">
                        Sorry! it looks like the above meeting room is not
                        available for your updated information
                      </Typography>
                    )}
                  </Box>
                </Stack>

                {!available && (
                  <Stack direction="row" spacing={2}>
                    <ConfirmButton
                      title="Cancel"
                      color="#212121"
                      onClick={() => {
                        setOpen(false)
                      }}
                    />
                    <ConfirmButton
                      title="Find another meeting room"
                      color="#FF6520"
                      link="/meetingrooms/available"
                    />
                  </Stack>
                )}
              </Box>
            </Modal>
          </Box>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack spacing={2} p={2}>
          <Box>
            <Box>
              <CardTitle icon={text} title="Add meeting title" />
            </Box>
            <TextField
              type="text"
              sx={{ height: 40, mb: 2, borderRadius: '50%' }}
              fullWidth
              name="title"
              placeholder="Enter meeting name here"
              value={meetingTitle}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <CardTitle
              icon={meetingRoomSize}
              title="Select meeting room size"
            />
            <Stack direction="row" spacing={1}>
              {seatingCapacities.map((guest) => (
                <GuestsButton
                  guest={guest}
                  key={guest}
                  setGuests={setGuests}
                  seatingCapacity={seatingCapacity}
                />
              ))}
            </Stack>
          </Box>
          <Box>
            <CardTitle
              icon={calender}
              title={checked ? 'Select date' : 'Select date, time & duration'}
            />
            {checked ? (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label=""
                        inputFormat="MM/DD/YYYY"
                        disablePast
                        name="date"
                        value={date}
                        onChange={(newDate) => setDate(newDate)}
                        renderInput={(params) => (
                          <TextField {...params} sx={{ width: '100%' }} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            ) : (
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label=""
                        inputFormat="MM/DD/YYYY"
                        disablePast
                        name="date"
                        value={date}
                        onChange={(newDate) => handleDateChange(newDate)}
                        renderInput={(params) => <TextField {...params} />}
                        height="20px"
                        InputProps={{ height: '20px' }}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      value={time}
                      type="time"
                      InputProps={{ step: 900 }}
                      onChange={changeTime}
                      sx={{ width: '100%' }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      name="meetingDuration"
                      value={meetingDuration}
                      onChange={handleChange}
                      fullWidth
                      height="10px"
                    >
                      <MenuItem value="Select Time">Select Time</MenuItem>
                      {meetingTime.map((time) => (
                        <MenuItem value={time}>{time}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
            )}
            <Box sx={{ pt: 1.5, pb: 0 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="All day"
                    checked={checked}
                    onChange={handleChange}
                    sx={{
                      pl: 1,
                      pb: 0,
                      pt: 0,
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  />
                }
                label="All day"
              />
            </Box>
          </Box>
          <Box>
            <CardTitle
              icon={stairs}
              title="Select Preferred Building (Optional)"
            />
            <Select
              value={building}
              name="building"
              onChange={handleChange}
              fullWidth
            >
              <MenuItem value="All Buildings">All Buildings</MenuItem>
              {buildings.map((building) => (
                <MenuItem value={building?.buildingName}>
                  {building?.buildingName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box>
            <CardTitle
              icon={squareLayouts}
              title="Choose required Amenities (Optional)"
            />

            <Autocomplete
              multiple
              options={amenitiesList}
              onChange={(event, value) => setSelectedAmenities(value)}
              disableCloseOnSelect
              value={selectedAmenities ? selectedAmenities : []}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              sx={{ borderRadius: '30px' }}
              fullWidth
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          {currentLocation === '/meetingrooms/update' && (
            <>
              <Box p={0} width={'100%'}>
                <CardTitle icon={addUserIcon} title="Add Guests" />
                <AddGuestInput
                  name="email"
                  onChange={handleChange}
                  onblur={handleGuestInputBlur}
                  value={guestEmail}
                  isError={isError}
                />
                <Box p={1}>
                  {attendees.map((email) => {
                    return (
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        key={email}
                      >
                        <Typography key={email}>{email}</Typography>
                        <IconButton
                          sx={{ m: 0, p: 0 }}
                          onClick={() => {
                            handleDelete(email)
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Box>
                    )
                  })}
                </Box>
              </Box>
              <Box>
                <CardTitle
                  icon={DescriptionIcon}
                  title="Description (Optional)"
                />
                <GuestDescription
                  name={'description'}
                  value={description}
                  onChange={handleChange}
                />
              </Box>
            </>
          )}
          <Box width={'100%'} display="flex" justifyContent={'center'} mb={2}>
            <Button
              onClick={handleSubmit}
              disabled={
                !seatingCapacity ||
                (!checked && meetingDuration === 'Select Time') ||
                meetingTitle === '' ||
                !date ||
                !startTime ||
                buttonDisabled
              }
              sx={{
                backgroundColor: '#FF6520',
                color: '#FFFFFF',
                textTransform: 'none',
                width: '100%',
                height: '8vh',
              }}
            >
              {currentLocation === '/meetingrooms/update'
                ? 'Update Booking'
                : 'Search for meeting rooms'}
            </Button>
          </Box>
        </Stack>
      </Card>
    </Box>
  )
}

export default MeetingRoomPanel
