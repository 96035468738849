import { createTheme } from '@mui/material/styles';

const baseTheme = createTheme({
    typography: {
        fontFamily: "roboto",
        fontSize: 12,
        fontFamily: "'roboto','Open sans','Helvetica'"
    }
})

const lightTheme = createTheme({
    ...baseTheme,
    palette: {
        background: {
            default: '#F6F6F6'
          },
        "nhance": {
            dim: "#808080",
            background: '#F7F7F7'
        },
        "neutral": {
            main: "#64748B",
            contrastText: "#fff"
        },
        "white": {
            main: "#ffffff",
            contrastText: "#000000"
        },
        "orange": {
            orange: "#FF6520",
            contrastText: "#fff"
        },
    }
})

export { lightTheme }
