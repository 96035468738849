import {
    Typography,
    Grid,
    IconButton,
    Box,
    Drawer,
    Stack,
    Divider,
    Rating,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import backButton from '../../Assets/Images/left-arrow.png'
import Star from '../../Assets/FeedBack/Star.png'
import brokenImg from '../../Assets/FeedBack/brokenImg.png'
import { Global } from '@emotion/react'
import StarIcon from '@mui/icons-material/Star'
import { useSelector, useDispatch } from 'react-redux'
import { setCompFb } from '../../Redux/ReduxSlice/Feedback/CompSliceFb'
import { clearSelectedRating } from '../../Redux/ReduxSlice/Feedback/RatingsSlice'
import locationFeedBack from '../../Assets/FeedBack/locationFeedBack.png'
import FeedbackProgressBar from '../../UI/FeedBack/FeedbackProgressBar'

const SelectedFeedBack = (props) => {
    const imageLink = useSelector(state => state?.config?.imageLink)
    const dispatch = useDispatch()
    const [ratings, setRatings] = useState(0)
    const selectedRating = useSelector(
        (state) => state?.ratings?.selectedRating?.rating,
    )
    const selectedRatingIsLoaded = useSelector(state => state?.ratings?.selectedRating?.isLoaded)

    const handleBackClick = () => {
        dispatch(setCompFb('Feed Back Home'))
    }

    const ratingDistribution = selectedRatingIsLoaded ? selectedRating?.overAllRating?.ratingDistribution : {}

    const ratingValues = selectedRatingIsLoaded ? Object.values(ratingDistribution) : []
    const maxRatingCount = selectedRatingIsLoaded ? Math.max(...ratingValues) : 0
    const progressArr = [
        { label: '5', color: '#a0c15a' },
        { label: '4', color: '#add633' },
        { label: '3', color: '#ffd934' },
        { label: '2', color: '#ffb234' },
        { label: '1', color: '#ff8c5a' },
    ]

    const progressBars = progressArr.map((ele, i) => {
        return { ...ele, progress: ((ratingDistribution[ele.label] / maxRatingCount) * 100), count: ratingDistribution[ele.label] }
    })


    useEffect(() => {
        return () => {
            dispatch(clearSelectedRating())
        }
    }, [])

    return (
        <Box>
            {selectedRatingIsLoaded && <Box>
                <Grid
                    container
                    spacing={0}
                    display="flex"
                    alignItems={'center'}
                    height={50}
                    backgroundColor="#F6F6F6"
                    mb={2}
                    p={2}
                >
                    <Grid item xs={1.2}>
                        <IconButton size="small" float="left" onClick={handleBackClick}>
                            <img src={backButton} width="24px" style={{
                                backgroundColor: 'rgba(0, 0, 0, 0.1)',

                                width: '30px',
                                borderRadius: '50%',
                                padding: '0.5em '
                            }} />
                        </IconButton>
                    </Grid>
                    <Grid item xs={10.8} display="flex" >
                        <Box ml={1}>
                            <Typography fontSize={20} align="left">
                                {'FeedBack'}
                            </Typography>
                        </Box>

                    </Grid>
                </Grid>
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `calc(65%)`,
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                        },
                    }}
                />
                <Box
                    display={'flex'}
                    justifyContent="center"
                    alignItems={'center'}
                    height={'20vh'}
                >
                    {selectedRating?.area?.imageLinks?.length > 0 ? (
                        <img
                            src={`${imageLink}${selectedRating?.area?.imageLinks[0]}`}
                            width="500px"
                        />
                    ) : (
                        <img src={brokenImg} width="36px" />
                    )}

                </Box>
                <Drawer
                    anchor="bottom"
                    open={true}
                    variant="permanent"
                    ModalProps={{
                        keepMounted: false,
                    }}
                >
                    <Box p={2}>
                        <Box>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography
                                        sx={{
                                            fontSize: '20px',
                                            fontWeight: '400',
                                            color: '#212121',
                                        }}
                                    >
                                        {selectedRating?.userRating?.area?.name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={0.5}
                                    display={'flex'}
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <img src={locationFeedBack} height="16px" />
                                </Grid>
                                <Grid item xs={11.5}>
                                    <Typography
                                        sx={{
                                            fontSize: '16px',
                                            fontWeight: '400',
                                            color: '#212121',
                                        }}
                                    >
                                        {selectedRating?.userRating?.floor?.name} |{' '}
                                        {selectedRating?.userRating?.building?.name}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box pb={3} pt={3}>
                            <Grid container>
                                <Grid
                                    item
                                    xs={2}
                                    display="flex"
                                    alignItems={'center'}
                                    justifyContent="flex-start"
                                >
                                    <img src={Star} height="50px" />
                                </Grid>
                                <Grid item xs={4} display='flex' alignItems={'center'}>
                                    <Stack>
                                        <Typography
                                            sx={{
                                                fontSize: '20px',
                                                fontWeight: '700',
                                                color: '#212121',
                                            }}
                                        >
                                            {selectedRating?.overAllRating?.averageRating}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '14px',
                                                fontWeight: '400',
                                                color: '#212121',
                                            }}
                                        >
                                            Overall Rating
                                        </Typography>
                                    </Stack>
                                </Grid>
                                {true && <Grid item xs={6}>

                                    <FeedbackProgressBar progressBars={progressBars} />

                                </Grid>}
                            </Grid>
                        </Box>
                        <Divider />
                        <Box p={3}>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: '400',
                                        color: '#000000',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    You Rated
                                </Typography>
                                <Box display="flex" justifyContent="center" pt={4}>
                                    <Rating
                                        name="size-large"
                                        sx={{ fontSize: '50px' }}
                                        value={selectedRating?.userRating?.rating}
                                        onChange={(event, newValue) => {
                                            setRatings(newValue)
                                        }}
                                        precision="0.5"
                                        disabled={true}
                                        emptyIcon={
                                            <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                                        }
                                    />
                                </Box>
                            </Stack>
                        </Box>
                    </Box>
                </Drawer>
            </Box>}
        </Box>
    )
}
export default SelectedFeedBack
