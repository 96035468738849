import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Drawer,
  Paper,
  Grid,
  Card,
  Stack,
  IconButton,
  TextField,
  Modal,
} from '@mui/material'
import {
  ModalStyle,
  imageStyle,
  BackButtonStyle,
} from '../../UI/FaultReporting/Styles/AssetDetailsStyles'
import back from '../../Assets/Images/left-arrow.png'

// import TextField from '@mui/joy/TextField';
import { Global } from '@emotion/react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FaultDescription from '../../components/FaultReporting/Panel/FaultDescription'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { useDispatch, useSelector } from 'react-redux'
import { startCreateTicket } from '../../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import {
  AlignedBox,
  inputStyle,
} from '../../UI/FaultReporting/Styles/ReportStyles'
import { startSetImage } from '../../Redux/ReduxSlice/FaultReporting/FaultImageSlice'
import { clearSubArea } from '../../Redux/ReduxSlice/FaultReporting/AreaSlice'
import defaultImage from '../../Assets/FaultReporting/Images/defaultImage.png'
import cameraIcon from '../../Assets/FaultReporting/Images/camera.svg'
import folderIcon from '../..//Assets/FaultReporting/Images/folder.svg'
import correct from '../../Assets/Images/correct.svg'

function QrCodeTicket(props) {
    const imageLink=useSelector(state=>state?.config?.imageLink)  
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const subArea = useSelector((state) => state?.frAreas?.subArea?.data)
  const subAreaIsLoaded = useSelector(
    (state) => state?.frAreas?.subArea?.isLoaded,
  )
  const [buildingsButton, setBuildingsButton] = useState(
    subArea.buildingName ? subArea.buildingName : '',
  )

  const [FloorButton, setFloorButton] = useState(
    subArea.floor ? subArea.floor : '',
  )
  const [areaButton, setAreaButton] = useState(subAreaIsLoaded?subArea.name:'')
  const [description, setDescription] = useState('')
  const [radioButton, setRadioButton] = useState('')
  const [load, setLoad] = useState(false)
  const [assetTextBox, setAssetTextBox] = useState('')
  const [other, setOther] = useState('')
  const [source, setSource] = useState('')
  const [modal, setModal] = useState(false)
  const [imageBase64, setImageBase64] = useState('')
  const [file, setFile] = useState('')

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        let formData = new FormData()
        formData.append('file', target.files[0])
        setFile(formData)
        setModal(false)
      }
    }
  }

  const imageBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '85vh',
    display: 'inline-block',
    position: 'relative',
  }

  const handleChange = (e, category) => {
    if (category === 'building') setBuildingsButton(e)
    if (category === 'floor') setFloorButton(e)
    if (category === 'area') setAreaButton(e)
    if (e.target?.name === 'description') setDescription(e.target.value)
    if (e.target?.name === 'radio') setRadioButton(e.target.value)
    if (e.target?.name === 'assetTextBox') setAssetTextBox(e.target.value)
    if (e.target?.name === 'other') setOther(e.target.value)
  }

  const handleLoad = () => {
    setLoad(true)
  }

  const handleSetImage = (val) => {
    setSource(val)
  }
  const handleClikedModal = (e) => {
    e.preventDefault()
    const ticket = {}
    ticket.reportedOn = Number(new Date())
    ticket.buildingId = subArea?.building?.id
    ticket.floorId = subArea?.floor?.id
    if (source) {
      ticket.imageUrl = source
    }
    ticket.areaId = subArea.id
    ticket.description = description
    ticket.issue = radioButton == 'other' ? other : radioButton
    ticket.title = assetTextBox

    dispatch(startCreateTicket({ticket, handleLoad}))
  }

  useEffect(() => {
    if (load) {
      setTimeout(() => {
        dispatch(setComp('Fault Reporting'))
        setLoad(false)
        dispatch(clearSubArea())
        setBuildingsButton('')
        setFloorButton('')
        setAreaButton('')
        setDescription('')
        setRadioButton('')
      }, 2000)
    }
  }, [load])

  useEffect(() => {
    
    return () => {
      dispatch(clearSubArea())
    }
  }, [])

  const handleBackClick = () => {
    dispatch(setComp('QrCode'))
  }

  useEffect(() => {
    if (file) {
      dispatch(startSetImage(file, handleSetImage))
    }
  }, [file])

  useEffect(() => {
    if (areaButton.length == 0) {
      dispatch(setComp('QrCode'))
    }
    return () => {
      dispatch(clearSubArea())
    }
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '20px',
  }
  return (
    <Box>
        {subAreaIsLoaded&&<Box>
      <Box style={imageBoxStyle}>
        {source ? (
          <>
            <img
              src={`${imageLink}${source}`}
              alt={'snap'}
              style={imageStyle}
            ></img>
          </>
        ) : (
          <Box
            height="41%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            onClick={() => setModal(true)}
          >
            <Box>
              <Box display="flex" justifyContent="center" p={1}>
                <img src={defaultImage} height="20px" />
              </Box>
              <Typography>Tap to add an image</Typography>
            </Box>
          </Box>
        )}

        <Box style={BackButtonStyle} onClick={handleBackClick}>
          <img src={back} height="24px" />
        </Box>
      </Box>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(65%)`,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
        }}
      />
      <Box></Box>
      <Drawer
        anchor="bottom"
        open={true}
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box p={1} m={1}>
          <Grid container item spacing={1} pb={0}>
            <Grid item xs={12}>
              <Typography variant="h5" fontSize={'20px'}>
                Please add asset details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontSize={'16px'} color="#4B4B4B">
                Select the building of the asset being reported
              </Typography>
            </Grid>
          </Grid>
          <Grid pt={0.5}>
            {Object.keys(subArea).length > 0 && (
              <Grid>
                <Stack>
                  <Typography
                    key={subArea.building?.id}
                    fontSize="1.1rem"
                    gutterBottom
                    color="#212121"
                  >
                    {subArea.building?.name}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>

          <Grid mt={1.5}>
            {Object.keys(subArea).length > 0 && (
              <Grid>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    fontSize={'16px'}
                    color="#4B4B4B"
                  >
                    Select the floor of the asset
                  </Typography>
                  <Typography
                    key={subArea.floorId}
                    fontSize="18px"
                    gutterBottom
                    color="#212121"
                  >
                    {subArea.floor?.name}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
          <Grid mt={1.5}>
            {Object.keys(subArea).length > 0 && (
              <Grid item xs={12}>
                <Stack>
                  <Typography
                    variant="subtitle2"
                    fontSize={'16px'}
                    color="#4B4B4B"
                  >
                    Select the area of the asset
                  </Typography>
                  <Typography
                    key={subArea.id}
                    fontSize="18px"
                    gutterBottom
                    color="#212121"
                  >
                    {subArea.name}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>

          <Grid mt={1.5}>
            {Object.keys(areaButton).length > 0 && (
              <Grid container item spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle2"
                    fontSize={'16px'}
                    color="#4B4B4B"
                  >
                    Name of the asset
                  </Typography>
                  <TextField
                    sx={{ width: '100%' }}
                    variant="outlined"
                    value={assetTextBox}
                    name="assetTextBox"
                    autoComplete="off"
                    inputProps={{ maxLength: 250 }}
                    onChange={(e) => handleChange(e)}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid pt={1}>
            {areaButton.length > 0 ? (
              <Box pt={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontSize={'16px'}
                    color="#4B4B4B"
                  >
                    What is wrong with this asset?
                  </Typography>
                </Grid>
                <Box p={1} pt={0} pb={0.5} pl={4}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      color="#AAAAAA"
                      value={radioButton}
                      name="radio"
                      onChange={(e) => handleChange(e)}
                    >
                      <FormControlLabel
                        value="Not working as intended"
                        control={<Radio color="warning" />}
                        label="Not working as intended"
                      />
                      <FormControlLabel
                        value="Its Broken"
                        control={<Radio color="warning" />}
                        label="Its Broken"
                      />
                      <FormControlLabel
                        value="Need Maintainance soon"
                        control={<Radio color="warning" />}
                        label="Need Maintainance soon"
                      />
                      <FormControlLabel
                        value="Need replacement ASAP"
                        control={<Radio color="warning" />}
                        label="Need replacement ASAP"
                      />
                      <FormControlLabel
                        value="Requires part replacement"
                        control={<Radio color="warning" />}
                        label="Requires part replacement"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio color="warning" />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Stack sx={{ width: '100%' }}>
                    {radioButton === 'other' ? (
                      <TextField
                        value={other}
                        name="other"
                        placeholder="Enter the asset fault in short…"
                        inputProps={{
                          maxLength: 250,
                        }}
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                      />
                    ) : (
                      ''
                    )}
                  </Stack>
                </Box>
                <Box pb={3}>
                  <Typography fontSize={'16px'} color="#4B4B4B">
                    Add Description (Optional)
                  </Typography>
                  <Box mt={1}>
                    <FaultDescription
                      value={description}
                      name="description"
                      onChange={(e) => handleChange(e)}
                    />
                  </Box>
                  <Typography fontSize="12px" align="right">
                    {250 - Number(description.length)} characters left
                  </Typography>
                </Box>
                <Stack>
                  <Modal open={load}>
                    <Paper sx={ModalStyle}>
                      <Stack pb={2}>
                        <Stack>
                          <Box display="flex" justifyContent={'center'} pb={1}>
                            <img src={correct} width={90} />
                          </Box>
                        </Stack>
                        <Typography
                          variant="h5"
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                          fontWeight="bold"
                        >
                          Thank you!
                        </Typography>
                        <Typography
                          variant="h6"
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                        >
                          You request was received successfully,
                        </Typography>
                        <Typography
                          variant="h6"
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                        >
                          We have notified the facility manager of this issue.
                        </Typography>
                      </Stack>
                    </Paper>
                  </Modal>

                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      height: '48px',
                      backgroundColor: '#FF6520',
                      color: '#FFFFFF',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                    }}
                    onClick={handleClikedModal}
                    disabled={
                      assetTextBox.trim().length <= 0 ||
                      radioButton === '' ||
                      (radioButton === 'other' && other.trim().length <= 0)
                    }
                  >
                    Submit
                  </Button>
                </Stack>
              </Box>
            ) : (
              ''
            )}
          </Grid>
        </Box>
      </Drawer>
      <Modal open={modal} onClose={() => setModal(false)}>
        <Box sx={style}>
          <Grid container>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="icon-button-file">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={cameraIcon} width="26px" />
                    </Box>
                    <Typography color="#212121">Click an image</Typography>
                  </Stack>
                </label>
                <input
                  accept="image/*"
                  style={inputStyle}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                />
              </AlignedBox>
            </Grid>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="choose-file" id="choose-file-label">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={folderIcon} />
                    </Box>
                    <Typography color="#212121">Upload from Gallery</Typography>
                  </Stack>
                </label>

                <input
                  name="uploadDocument"
                  type="file"
                  id="choose-file"
                  accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                  style={inputStyle}
                  onChange={(e) => {
                    handleCapture(e.target)
                  }}
                />
              </AlignedBox>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      </Box>}
    </Box>
  )
}
export default QrCodeTicket
