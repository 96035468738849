import { Grid, Paper, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { startDeleteMeeting } from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import Popup from './Popup'
import swal from 'sweetalert'
import RsvpIcon from '@mui/icons-material/Rsvp'

const MeetingView = (props) => {
  const {
    attendees,
    timeRange,
    handleRedirect,
    setPopup,
    handleDisplayLoader,
    floor,
    meetingName,
    room,
    guests,
    handleDeleteLoader,
    isPopup,
    id,
    recurring,
    backgroundColor,
    creator,
  } = props
  const dispatch = useDispatch()
  id && handleDisplayLoader()
  const user = useSelector((state) => state.user)
  const meetingInvite = attendees?.filter((ele) => {
    return ele.email === user.data?.email
  })
  const responseStatus = meetingInvite[0]?.responseStatus || ''

  const handleInviteColor = (responseStatus) => {
    if (responseStatus === 'declined') {
      return '#ff726f'
    } else if (responseStatus === 'accepted') {
      return '#02B9A4'
    } else if (responseStatus === 'needsAction'||responseStatus==='tentative') {
      return '#AAAAAA'
    }
    
     else {
      return '#02B9A4'
    }
  }

  const handleDelete = (event, id, handleOpen) => {
    swal({
      title: 'Are you sure you want to delete the meeting?',
      icon: 'warning',
      buttons: [true, 'Yes'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        event.stopPropagation()
        dispatch(startDeleteMeeting({id, handleDeleteLoader, handleOpen}))
      } else {
        swal("Meeting hasn't been deleted")
        handleDeleteLoader(false)
      }
    })
  }
  const inviteResponse = (val) => {
    const res =
      val === 'needsAction'
        ? 'Needs Action'
        : val === 'accepted'
        ? 'Accepted'
        : val === 'declined'
        ? 'Declined'
        : val === 'tentative'
        ? 'Tentative'
        : ''
    return res
  }
  return (
    <>
      {id && (
        <Paper
          sx={{
            borderRadius: '10px',
            width: '100%',
            boxShadow: 0,
            border: `1px solid ${backgroundColor}`,
          }}
        >
          <Grid container item>
            <Grid
              item
              xs={0.5}
              backgroundColor={() => handleInviteColor(responseStatus)}
              m={0}
              p={0}
              borderRadius="10px"
            ></Grid>

            <Grid
              item
              xs={8.5}
              px={1}
              py={1}
              pr={0}
              onClick={() => handleRedirect(id)}
              sx={{ '&:hover': { cursor: 'pointer' } }}
            >
              {/* <Box display={'flex'} justifyContent={'space-between'}> */}
              <Typography variant="caption" fontWeight={500} fontSize="11px">
                {timeRange}
              </Typography>
              {/* </Box> */}
              <Typography fontWeight={450}>{meetingName}</Typography>

              {/* <Typography variant='caption'>{floor} </Typography> */}
              <Typography variant="caption">{` ${floor} | ${room} | ${attendees.length} Guests`}</Typography>
            </Grid>

            <Grid
              item
              xs={3}
              // mb={1}
              px={1}
              py={1}
              display="flex"
              justifyContent="flex-end"
              alignItems={creator ? 'flex-start' : 'flex-end'}
              // border="1px solid red"
            >
              {(!creator && meetingInvite?.length > 0 &&!recurring)&&(
                <Typography
                  color={
                    responseStatus === 'accepted'
                      ? '#02B9A4'
                      : responseStatus === 'declined'
                      ? '#ff726f'
                      : '#AAAAAA'
                  }
                  variant="caption"
                  // fontSize="10px"
                >
                  {inviteResponse(meetingInvite[0].responseStatus)}
                </Typography>
              )}
              {isPopup && (
                <Popup
                  handleDeleteLoader={handleDeleteLoader}
                  creator={creator}
                  id={id}
                  handleDelete={handleDelete}
                />
              )}
            </Grid>

            {/*  <Stack direction='row' >
                            <Typography variant='caption' border='solid'>{`${room} | ${guests} Guests`}</Typography>
                            {!creator && Object.keys(meetingInvite).length > 0 &&
                                <Box display='flex-end'>
                                    <Typography variant='caption'>{meetingInvite.responseStatus}</Typography>
                                </Box>}

                        </Stack> */}

            {/* <Grid item  xs={1}>
                        {isPopup && <Popup handleDeleteLoader={handleDeleteLoader} creator={creator} id={id} handleDelete={handleDelete} />}
                    </Grid> */}
          </Grid>
        </Paper>
      )}
    </>
  )
}

export default MeetingView
