import axios from 'axios'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom/cjs/react-router-dom.min'
import AdditionalDetails from '../pages/MeetingRooms/AdditionalDetails'
import AvailableMeeting from '../pages/MeetingRooms/AvailableMeeting'
import Booking from '../pages/MeetingRooms/Booking'
import MeetingDetails from '../pages/MeetingRooms/MeetingDetails'
import MeetingRoomBooking from '../pages/MeetingRooms/MeetingRoomBooking'
import MeetingRoomStatus from '../pages/MeetingRooms/MeetingRoomStatus'
import { startFetchAppsConfig, startFetchLogo } from '../Redux/ReduxSlice/Common/appsSlice'
import { startGetUpcomingBookings } from '../Redux/ReduxSlice/DeskBooking/bookingAction'
import { startGetAllTickets } from '../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import { startFetchMeetings } from '../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
// import { setUser } from '../Redux/actions/MeetingRooms/userActions'
import { setUser } from '../Redux/ReduxSlice/Common/userSlice'


function MeetingRoomRoutes() {
    const dispatch = useDispatch()
    const tenantCode = useSelector((state) => state?.user?.data?.tenantCode);
    const email = useSelector((state) => state?.user?.data?.email);

    useEffect(() => {
        const date = moment().format('YYYY-MM-DD')
        const code = localStorage.getItem("token")
        //move code into action
        if (code) {
            axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, {
                headers: {
                    'Authorization': code
                }
            }).then(response => {
                if (response.status === 200) {
                    const bid = response.data?.user?.buildingId
                    dispatch(setUser(response.data?.user))
                    dispatch(startFetchLogo())

                    dispatch(startFetchAppsConfig())

                    //     dispatch(startFetchMeetings(date))
                    //    bid&&dispatch(startGetAllTickets(bid))
                    //     const body = {
                    //         currentDate: date,
                    //     }

                    //     dispatch(startGetUpcomingBookings(body))

                } else {
                    window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
                }
            })
                .catch((err) => {
                    if (err.response?.status === 403) {
                        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
                    } else {
                        console.log(err)
                    }
                })
        } else {
            window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
        }
    }, [])
    return (
        <Switch>
            <Route path='/meetingrooms/available' component={AvailableMeeting} />
            <Route path='/meetingrooms/confirmation' component={Booking} />
            <Route path="/meetingrooms/update/attendees" component={AdditionalDetails} exact />
            <Route path='/meetingrooms/meeting' component={MeetingDetails} exact />
            <Route path='/meetingrooms/update' component={MeetingRoomBooking} exact />
            <Route path='/meetingrooms/update/confirmation' component={Booking} exact />
            <Route path='/meetingrooms' component={MeetingRoomBooking} />
            <Route path='/meetingrooms/status' component={MeetingRoomStatus} />
        </Switch>
    )
}

export default MeetingRoomRoutes