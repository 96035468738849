import React, { useState, useEffect } from 'react'
import { Box, Button, Typography, Card } from '@mui/material'
import { DayPicker, useNavigation } from 'react-day-picker'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import 'react-day-picker/dist/style.css'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import Header from './Header'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { setBookingDate, setBookingTime, resetBookingTime } from '../../../Redux/ReduxSlice/deskBooking/bookingTimeSlice'
import { startGetFreeDesks, resetZoneIsLoaded } from '../../../Redux/ReduxSlice/deskBooking/bookingZoneSlice'


const theme = createTheme({
  palette: {
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    orange: {
      main: '#FF6520',
      contrastText: '#fff',
    },
    black: {
      main: 'black',
    },
  },
})

function CustomCaption(CaptionProps) {
  const { goToMonth, nextMonth, previousMonth } = useNavigation()
  let props = CaptionProps
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Button
        disabled={!previousMonth}
        onClick={() => previousMonth && goToMonth(previousMonth)}
      >
        <ArrowBackIosIcon />
      </Button>
      {moment(props.displayMonth).format('MMMM YYYY')}
      <Button
        disabled={!nextMonth}
        onClick={() => nextMonth && goToMonth(nextMonth)}
      >
        <ArrowForwardIosIcon />
      </Button>
    </Box>
  )
}

export default function MonthlyDates() {
  const height = window.innerHeight

  const [selected, setSelected] = useState(new Date())

  const [button, setButton] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const buildingId = useSelector(
    (state) => state?.buildings?.selectedBuilding?._id,
  )
  const dateTime = useSelector((state) => state?.bookingTime)
  const isLoaded = useSelector((state) => state?.zone?.isLoaded)
  const [open, setOpen] = React.useState(false)
  const buildings = useSelector((state) => state?.buildings?.buildings)
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    if (isLoaded) {
      history.push('/deskbooking/floor')
    }
    dispatch(resetZoneIsLoaded())
  }, [isLoaded])
  useEffect(() => {
    if (buildings.length === 0) {
      history.push('/deskbooking/location')
    }
  }, [buildings])

  useEffect(() => {
    dispatch(resetBookingTime())
  }, [])

  const handleButtonClick = (val) => {
    const shift = val
    const timeObj = {
      firstHalf: false,
      secondHalf: false,
    }
    if (shift === '1st half') {
      timeObj.firstHalf = true
    } else if (shift === '2nd half') {
      timeObj.secondHalf = true
    } else if (shift === 'full day') {
      timeObj.firstHalf = true
      timeObj.secondHalf = true
    }

    dispatch(setBookingTime(timeObj))
    setButton(val)
  }

  const handleDayClick = (Date) => {
    setButton('')
    let day = moment(`${Date}`).format('YYYY-MM-DD')
    dispatch(setBookingDate(day))

    setSelected(Date)
  }


  const handleSubmit = (e) => {
    e?.preventDefault()
    setSelected('')
    setButton('')
    const body = {
      buildingId,
      selectedDate: dateTime?.selectedDate,
      firstHalf: dateTime?.selectedTime?.firstHalf,
      secondHalf: dateTime?.selectedTime?.secondHalf,
    }

    dispatch(startGetFreeDesks({body}))
  }

  const array = [
    { title: '1st half' },
    { title: '2nd half' },
    { title: 'full day' },
  ]

  const currentDate = moment().format('YYYY-MM-DD')
  const hour = new Date().getHours()

  const disableButton = (title) => {
    return (
      moment(selected).format('YYYY-MM-DD') === currentDate &&
      hour >= 12 &&
      (title === '1st half' || title === 'full day')
    )
  }


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: '100vh' }}>
        <Header title="Select Date & Time" />
        <Box p={2}>
          <Box backgroundColor="#FFFFFF">
            <Box display="flex" justifyContent="center" pt={1} pb={1}>
              <Typography
                fontWeight={'bold'}
                align="center"
                fontSize="14px"
                variant="h6"
                gutterBottom
              >
                Select date{' '}
              </Typography>
            </Box>
            <Box width="100%" px={2} pb={2}>
              <Card
                width="100%"
                sx={{
                  backgroundColor: '#EEEEEE',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {/* <DayPicker
                mode="single"
                required
                selected={selected}
                onDayClick={(date) => handleDayClick(date)}
                components={{
                  Caption: CustomCaption,
                }}
                disabled={{ before: new Date() }}
                style={{ fontSize: '0.8rem' }}
              /> */}
                <DayPicker
                  mode="single"
                  required
                  selected={selected}
                //   modifiers={{ booked: [new Date(2023, 6, 10), new Date(2023, 6, 19)] }}
                //  modifiersStyles={{ booked: { border: '2px solid currentColor' } }}
                  onDayClick={(date) => handleDayClick(date)}
                  components={{
                    Caption: CustomCaption,
                  }}
                  disabled={[
                    { before: new Date() },
                    (date) => {
                      const today = new Date()
                      const yesterday = new Date(
                        Date.now() - 86400000,
                      ).getDate()
                      const maxAllowedDate = new Date()
                      maxAllowedDate.setDate(today.getDate() + 29)
                      const nextMonth = new Date(
                        today.getFullYear(),
                        today.getMonth() + 1,
                      )
                      nextMonth.setDate(yesterday)
                      return date > maxAllowedDate
                    },
                  ]}
                  style={{ fontSize: '0.8rem' }}
                />
              </Card>
            </Box>
            <Typography
              fontWeight={'bold'}
              fontSize="14px"
              variant="h6"
              textAlign={'center'}
            >
              Select Time
            </Typography>
            <Box
              display="flex"
              spacing={3}
              p={2}
              value={button}
              justifyContent="space-between"
            >
              {array?.map((ele, id) => {
                return (
                  <Box
                    sx={{
                      backgroundColor:
                        button === ele?.title ? '#373737' : '#ffffff',
                      borderRadius: '10px',
                    }}
                  >
                    <Button
                      disabled={disableButton(ele?.title)}
                      sx={{
                        backgroundColor:
                          button === ele?.title ? '#373737' : '#ffffff',
                        color: button === ele?.title ? '#fff' : '#000000',
                        height: '6vh',
                        width: '25vw',

                        textTransform: 'none',
                        borderRadius: '10px',
                      }}
                      variant="outlined"
                      color="neutral"
                      value={ele.title}
                      onClick={() => {
                        handleButtonClick(ele.title)
                      }}
                    >
                      <Typography
                        fontSize={`${Math.round(height - 0.98 * height)}px`}
                      >
                        {ele.title}
                      </Typography>
                    </Button>
                  </Box>
                )
              })}
            </Box>
            <Box
              p={2}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                pt: 3,
              }}
            >
              <Button
                color="orange"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  textTransform: 'none',
                  fontSize: '1rem',
                  mb: '1vh',
                  height: '7vh',
                  width: '100%',
                }}
                disabled={button === ''}
              >
                Search Availability
              </Button>
            </Box>
          </Box>
          <>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'No Desks are free'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  There are no desks free on the selected date and time. Please
                  choose a different date and time.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Okay</Button>
              </DialogActions>
            </Dialog>
          </>
        </Box>
      </Box>
    </ThemeProvider>
  )
}