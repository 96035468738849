import { Box, Button, Grid, TextField, Typography } from '@mui/material'
import moment from 'moment'
import React, { useEffect } from 'react'
import { DayPicker, useNavigation } from 'react-day-picker'
import {
  locationTitleContainer,
  titleText,
} from '../../UI/ManageVistor/Styles/VisitLocationStyle'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useState } from 'react'
import CardComp from '../../UI/MeetingRooms/Card/CardComp'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ClockPicker } from '@mui/x-date-pickers'
import ConfirmButton from '../../UI/DeskBooking/Button/ConfirmButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetVisitorDetails,
  setVisitDateTime,
  setSelectedDate,
  setSelectedDuration,
  setSelectedTime,
  setSelectedValue,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'
import BottomButton from '../../UI/ManageVistor/Button/BottomButton'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import SuccessModal from '../../UI/AssetManager/SuccessModal'
import swal from 'sweetalert'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import InputAdornment from '@mui/material/InputAdornment'
// import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';

const VisitDate = () => {
  const dispatch = useDispatch()
  // const [selectedDate, setSelectedDate] = useState(new Date())
  // const [time, setTime] = useState(moment().format('hh:mm'))
  // const [value, setValue] = useState(dayjs(moment().format('YYYY-MM-DDTHH:mm')))
  // const [selectedDuration, setSelectedDuration] = useState('Select Duration')
  const selectedDate = useSelector((state) => state.visitData?.selectedDate)
  const [modalOpen, setModalOpen] = useState(false)

  const selectedDuration = useSelector(
    (state) => state.visitData?.selectedDuration,
  )
  const selectedTime = useSelector((state) => state?.visitData?.selectedTime)
  console.log(selectedTime,"selectedTimeaa")
  const selectedValue = useSelector((state) => state?.visitData?.selectedValue)
  console.log(selectedValue,"selecteVAlue")
  const [currentView, setCurrentView] = useState('hours')
  const [currentTime, setCurrentTime] = useState(moment(selectedTime))
   const [isTimeSelected, setIsTimeSelected] = useState(false);
  // console.log(currentTime,"cuuuuuuurtttt")

  // const currentTime = moment();
  // const formattedTime = moment(currentTime).format('HH:mm');

  const handleDayClick = (day) => {
    dispatch(setSelectedDate(day))
  }

  const handleChange = (val) => {
    setIsTimeSelected(true)
    dispatch(setSelectedValue(val))
    dispatch(setSelectedTime(moment(val?.$d).format('HH:mm')))
    setCurrentView(currentView == 'hours' ? 'minutes' : 'hours')
    setCurrentTime(moment(val?.$d))
  }

  

  const handleNext = () => {
    const inviteDate = moment(selectedDate).format('YYYY-MM-DD')
    const selectedDateTime = moment(`${inviteDate} ${selectedTime}`)
    if (
      moment(selectedDateTime).valueOf() >=
      moment().subtract(5, 'minutes').valueOf()
    ) {
      const startTimeISO = selectedDateTime.toISOString()
      const endTime = selectedDateTime.clone().add(1440, 'minutes')
      const endTimeISO = endTime.toISOString()
      dispatch(
        setVisitDateTime({ startTime: startTimeISO, endTime: endTimeISO }),
      )
      dispatch(setVisitorComp('visitDetails'))
    } else {
      swal({
        icon: 'error',
        title: 'Selected time is in the past',
        text: 'Please choose a future time.',
      })
    }
  }

  const updateCurrentTime = (val) => {
    setCurrentTime(moment(val?.$d))
  }


  useEffect(() => {
    updateCurrentTime()
    const intervalId = setInterval(updateCurrentTime, 60000)
    return () => clearInterval(intervalId)
  }, [])

    // useEffect(() => {
    // if (selectedValue) {
    //   setIsTimeSelected(true);
    //   dispatch(setSelectedTime(moment(selectedValue?.$d).format('HH:mm')));
    // } 
    // }, [selectedValue]);
  

  function CustomCaption(CaptionProps) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation()
    let props = CaptionProps
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button
          disabled={!previousMonth}
          onClick={() => previousMonth && goToMonth(previousMonth)}
        >
          <ArrowBackIosIcon />
        </Button>
        {moment(props.displayMonth).format('MMMM YYYY')}
        <Button
          disabled={!nextMonth}
          onClick={() => nextMonth && goToMonth(nextMonth)}
        >
          <ArrowForwardIosIcon />
        </Button>
      </Box>
    )
  }

  return (
    <>
      <Box sx={{ paddingTop: '5%' }}>
        <Typography style={titleText}>
          Select the date and time of your visit
        </Typography>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <CardComp>
              <DayPicker
                mode="single"
                required
                selected={selectedDate}
                onDayClick={(date) => handleDayClick(date)}
                components={{
                  Caption: CustomCaption,
                }}
                disabled={[
                  { before: new Date() },
                  (date) => {
                    const today = new Date()
                    const yesterday = new Date(Date.now() - 86400000).getDate()
                    const maxAllowedDate = new Date()
                    maxAllowedDate.setDate(today.getDate() + 29)
                    const nextMonth = new Date(
                      today.getFullYear(),
                      today.getMonth() + 1,
                    )
                    nextMonth.setDate(yesterday)
                    return date > maxAllowedDate
                  },
                ]}
                style={{ fontSize: '12px' }}
              />
            </CardComp>
          </Grid>
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <Typography
                textAlign={'center'}
                fontSize={'20px'}
                fontWeight="600"
              >
                Select Time
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: 'flex', justifyContent: 'center' }}
            >
              <Box sx={{border:'1px #eeeeee', background:'#ffffff'}}>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                    value= {isTimeSelected
                          ? selectedValue
                          : currentTime}
                  InputProps={{
                    className: { textAlign: 'center' },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccessTimeIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(time) => handleChange(time)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      sx={{
                        backgroundColor: 'white',
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
              </Box>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography fontSize={24} fontWeight={600} textAlign="center">
                      {isTimeSelected ? moment(selectedValue?.$d).format('h:mm a') : moment(currentTime).format('h:mm a')}
              </Typography>
            </Grid> */}
            {/* <Grid item xs={12}>
              <Select
                value={selectedDuration}
                onChange={handleDuration}
                fullWidth
                inputProps={{ 'aria-label': 'Without label' }}
                placeholder="Select Duration"
              >
                <MenuItem value="Select Duration">{'Select Duration'}</MenuItem>
                {durations.map((ele, index) => {
                  return (
                    <MenuItem value={ele.value} key={index}>
                      {ele.label}
                    </MenuItem>
                  )
                })}
              </Select>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
      <BottomButton onclick={handleNext} />
    </>
  )
}

export default VisitDate
