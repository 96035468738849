import React, { useState } from 'react'
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from '@mui/material'
import moment from 'moment'
import workingAtHome from '../../../Assets/MeetingRooms/Images/workingAtHome.svg'
import { useDispatch } from 'react-redux'
import {startCancelMeeting} from "../../../Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import Popover from '@mui/material/Popover'
import swal from 'sweetalert'

const Booking = ({ data }) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [loader, setLoader] = useState(false)

  const handleLoader = (val) => {
    setLoader(val)
  }
  const handleDelete = () => {
    const body = { bookingId: data.id }
    swal({
      title: 'Are you sure you want to cancel the booking?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(startCancelMeeting({id:data.id, handleLoader}))
        setLoader(true)
        setOpen(false)
      } else {
        setOpen(false)
      }
    })
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  const [open, setOpen] = useState(false)
  const id = open ? 'simple-popover' : undefined
  return (
    <Grid container item sx={{ height: '100%' }}>
      <Grid
        item
        xs={2}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            border: 'solid 1px grey',
            width: '40px',
            height: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img src={workingAtHome} height="22px" />
        </Box>
      </Grid>
      <Grid item xs={9} sx={{ height: '100%' }}>
        <Stack ml={2} my={1}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            {data.deskCode}
          </Typography>
          <Typography variant="subtitle2">
            {data.firstHalf && data.secondHalf
              ? 'Full Day'
              : data.firstHalf
              ? 'First Half'
              : 'Second Half'}
          </Typography>
          <Typography variant="subtitle2">
            {data.location} | {data.building} | {data.floor}
          </Typography>
          <Stack direction="row">
            <Typography variant="caption" color="#858585">
              Booked for {moment(data.bookingDate).format('Do MMM YYYY')}
            </Typography>
          </Stack>
        </Stack>
      </Grid>

      <Grid item xs={1} sx={{ height: '100%' }} mt={3}>
        <IconButton
          aria-describedby={id}
          variant="contained"
          onClick={handleClick}
        >
          <MoreVertIcon fontSize="small" />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }} onClick={handleDelete}>
            Cancel booking
          </Typography>
        </Popover>
      </Grid>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  )
}

export default Booking
