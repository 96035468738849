import React, { useState, useEffect } from 'react'
import {
  Box,
  Paper,
  Typography,
  Stack,
  Grid,
  Tab,
  Chip,
  Card,
  Modal,
} from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import WebAssetOffOutlinedIcon from '@mui/icons-material/WebAssetOffOutlined'
import StarIcon from '@mui/icons-material/Star'
import {
  IconSx,
  BoxSx,
  IconSx1,
  IconSx2,
  PaperSx,
  TypoSx,
  Item,
  TypoSx2,
  GridSx,
  GridSx1,
  GridSx2,
  TypoSx3,
  BoxSx2,
  BoxSx3,
  ItemSx,
  TypoSx4,
  GridSx3,
  BoxSx4,
  TypoSx5,
  TypoSx6,
  TypoSx7,
  AlignedBox,
  inputStyle,
} from '../../UI/FaultReporting/Styles/ReportStyles'
import { faultImage } from '../../Redux/ReduxSlice/FaultReporting/FaultImageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { ModalStyle } from '../../UI/FaultReporting/Styles/ReportStyles'
import number from '../../Assets/FaultReporting/Images/123.png'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import {
  startGetAllTickets,
  startGetTicket,
} from '../../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import cameraIcon from '../../Assets/FaultReporting/Images/camera.svg'
import folderIcon from '../..//Assets/FaultReporting/Images/folder.svg'
import ticketIcon from '../..//Assets/FaultReporting/Images/ticketIcon.png'

function Report(props) {
  const dispatch = useDispatch()
  const bid = useSelector((state) => state?.user?.data?.buildingId)
  const [value, setValue] = React.useState('1')
  const [modal, setModal] = useState(false)
  const [source, setSource] = useState('')
  const role = useSelector((state) => state.user?.data?.role)
  const setRole = role === 'facility manager' ? true : false
  const admin = setRole
  const adminId = useSelector((state) => state.user?.data?.id)
  const [ticketFilter, setTicketFilter] = useState('all')
  const [fmTicketFilter, setFmTicketFilter] = useState('all')
  const [fmAllTickets, setFmAllTickets] = useState('all')
  const allFaultReports = useSelector((state) => state.faultReports).tickets

  const faultReports = allFaultReports.filter((ticket) => {
    return ticketFilter == 'open'
      ? ticket.status == 'open' || ticket.status == 'assigned'
      : ticketFilter === 'closed'
      ? ticket.status == 'closed'
      : ticket.status == 'open' ||
        ticket.status == 'closed' ||
        ticket.status == 'assigned'
  })
  const openReports = allFaultReports.filter(
    (ticket) => ticket.status == 'open' || ticket.status == 'assigned',
  )
  const closedReports = allFaultReports.filter(
    (ticket) => ticket.status == 'closed',
  )

  //facility manager tickets

  const selfTickets = allFaultReports.filter((ticket) => {
    return fmTicketFilter == 'all'
      ? (ticket.userId == adminId || ticket.assignedToId == adminId) &&
          (ticket.assignedToId == adminId || ticket.userId == adminId)
      : fmTicketFilter == 'raised'
      ? ticket.userId == adminId &&
        (ticket.status == 'open' ||
          ticket.status == 'assigned' ||
          ticket.status == 'closed')
      : ticket.assignedToId == adminId &&
        (ticket.status == 'open' ||
          ticket.status == 'closed' ||
          ticket.status == 'assigned')
  })

  const fmTickets = allFaultReports.filter((ticket) => {
    return fmAllTickets == 'open'
      ? ticket.status == 'open'
      : fmAllTickets == 'in progress'
      ? ticket.status === 'assigned'
      : fmAllTickets === 'closed'
      ? ticket.status === 'closed'
      : ticket.status === 'closed' ||
        ticket.status === 'assigned' ||
        ticket.status === 'assigned' ||
        ticket.status == 'open'
  })

  const selfAcceptedTickets = allFaultReports.filter(
    (ticket) =>
      ticket.assignedToId == adminId &&
      (ticket.status == 'open' ||
        ticket.status == 'closed' ||
        ticket.status == 'assigned'),
  )

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleClick = (val) => {
    setTicketFilter(val)
  }

  const handleFmFilter = (val) => {
    setFmTicketFilter(val)
  }
  const handleFmAllFilter = (val) => {
    setFmAllTickets(val)
  }
  const handleTicketClick = (id) => {
    dispatch(startGetTicket({id,bid,handleRedirect}))
  }
  const handleQrClick = () => {
    dispatch(setComp('QrCode'))
  }
  const handleRedirect = () => {
    dispatch(setComp('Ticket Details'))
  }

  const arr = [
    {
      icon: (
        <IconSx variant="square">
          <FiberManualRecordIcon
            style={{ color: '#FFFFFF' }}
            fontSize="large"
          />
        </IconSx>
      ),
      title: 'Broken? not working?',
      subtitle: 'Report the fault by taking the picture!',
      onclick: () => setModal(true),
    },
    {
      icon: (
        <IconSx variant="square">
          <IconSx2 />
        </IconSx>
      ),
      title: 'Infront of Area code?',
      subtitle: 'Scan Area QR code to report a fault',
      onclick: handleQrClick,
    },
    {
      icon: (
        <IconSx variant="square">
          <img src={number} height="10px" />
        </IconSx>
      ),
      title: 'See something faulty',
      subtitle: 'Report fault by entering an asset ID!',
    },
  ]

  const arr2 = [
    {
      icon: <Chip />,
      title: 'closed by Ravi Ranjan',
      subtitle: <StarIcon />,
      subtitle2: '3.0',
      title2: 'Not working as intended',
      subtitle3:
        'Deescription of the fault that user enterd needs to come here it needs to come under 20 words',
      subtitle4: 'First Floor|Meeting Room|Chair',
      subtitle5: '2 days ago',
    },
  ]
  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        const newUrl = URL.createObjectURL(file)
        setSource(newUrl)
        dispatch(faultImage({newUrl, file}))
        dispatch(setComp('Camera'))
        setModal(false)
      }
    }
  }

  const handleChipFmSelfColor = (val) => {
    return fmTicketFilter == val ? '#FF6520' : ''
  }

  const handleAllFmChipColor = (val) => {
    return fmAllTickets == val ? '#FF6520' : ''
  }

  const descriptionFunc = (desc, issue) => {
    if (desc.length > 60) {
      const newDesc = desc.slice(0, 60) + '....'
      const res = `${issue} | ${newDesc}`

      return res
    } else {
      const res = `${issue} | ${desc && desc}`
      return res
    }
  }
  useEffect(() => {
    dispatch(startGetAllTickets({bid}))
  }, [])

  return (
    <>
      <BoxSx>
        <Stack
          spacing={1.2}
          display={'flex'}
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          {arr.map((ele) => {
            return (
              <PaperSx onClick={ele.onclick} variant="outlined">
                <GridSx container spacing={1.5} p={1.5}>
                  <GridSx1 item xs={2}>
                    {ele.icon}
                  </GridSx1>
                  <Grid item xs={10}>
                    <Box>
                      <TypoSx variant="caption">{ele.title}</TypoSx>
                      <Grid>
                        <TypoSx3> {ele.subtitle}</TypoSx3>
                      </Grid>
                    </Box>
                  </Grid>
                </GridSx>
              </PaperSx>
            )
          })}
        </Stack>
      </BoxSx>
      <BoxSx2>
        <TabContext
          value={value}
          TabIndicatorProps={{ style: { color: '#212121' } }}
        >
          <BoxSx3>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              textColor="#212121"
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#D97D54',
                  marginBottom: 4,
                },
              }}
            >
              <Tab
                style={{ textTransform: 'capitalize' }}
                label="My Tickets"
                value="1"
                sx={{
                  color: value !== '1' ? '#4B4B4B' : '#212121',
                  fontSize: '16px',
                  fontWeight: value == '1' ? 'bold' : 'light',
                }}
              />
              {admin && (
                <Tab
                  style={{ textTransform: 'capitalize' }}
                  label="All Tickets"
                  value="2"
                  sx={{
                    color: value !== '2' ? '#4B4B4B' : '#212121',
                    fontSize: '16px',
                    fontWeight: value == '2' ? 'bold' : 'light',
                  }}
                />
              )}
            </TabList>
          </BoxSx3>
          {admin ? (
            <>
              {value == '1' ? (
                <>
                  <Stack direction="row" spacing={1} p={1.5}>
                    <Chip
                      label={`All `}
                      variant="outlined"
                      sx={{ border: 'solid 1px #AAAAAA' }}
                      onClick={() => handleFmFilter('all')}
                      style={{
                        backgroundColor: handleChipFmSelfColor('all'),
                        color: fmTicketFilter == 'all' ? '#FFFFFF' : '',
                      }}
                    />
                    <Chip
                      label={`Raised `}
                      variant="outlined"
                      sx={{ border: 'solid 1px #AAAAAA' }}
                      onClick={() => handleFmFilter('raised')}
                      style={{
                        backgroundColor: handleChipFmSelfColor('raised'),
                        color: fmTicketFilter == 'raised' ? '#FFFFFF' : '',
                      }}
                    />
                    <Chip
                      label={`Accepted (${String(
                        selfAcceptedTickets.length,
                      ).padStart(2, '0')})`}
                      variant="outlined"
                      sx={{ border: 'solid 1px #AAAAAA' }}
                      onClick={() => handleFmFilter('accepted')}
                      style={{
                        backgroundColor: handleChipFmSelfColor('accepted'),
                        color: fmTicketFilter == 'accepted' ? '#FFFFFF' : '',
                      }}
                    />
                  </Stack>
                </>
              ) : (
                <>
                  <Stack direction="row" spacing={1} p={1.5}>
                    <Chip
                      label={`All `}
                      variant="outlined"
                      onClick={() => handleFmAllFilter('all')}
                      style={{
                        backgroundColor: handleAllFmChipColor('all'),
                        color: fmAllTickets == 'all' ? '#FFFFFF' : '',
                      }}
                    />
                    <Chip
                      label={`Open `}
                      variant="outlined"
                      onClick={() => handleFmAllFilter('open')}
                      style={{
                        backgroundColor: handleAllFmChipColor('open'),
                        color: fmAllTickets == 'open' ? '#FFFFFF' : '',
                      }}
                    />
                    <Chip
                      label={`In Progress `}
                      variant="outlined"
                      onClick={() => handleFmAllFilter('in progress')}
                      style={{
                        backgroundColor: handleAllFmChipColor('in progress'),
                        color: fmAllTickets == 'in progress' ? '#FFFFFF' : '',
                      }}
                    />
                    <Chip
                      label={`Closed `}
                      variant="outlined"
                      onClick={() => handleFmAllFilter('closed')}
                      style={{
                        backgroundColor: handleAllFmChipColor('closed'),
                        color: fmAllTickets == 'closed' ? '#FFFFFF' : '',
                      }}
                    />
                  </Stack>
                </>
              )}
            </>
          ) : (
            <>
              <Stack direction="row" spacing={1} p={1.5}>
                <Chip
                  label={`All (${String(allFaultReports.length).padStart(
                    2,
                    '0',
                  )})`}
                  variant="outlined"
                  onClick={() => handleClick('all')}
                  style={{
                    backgroundColor: ticketFilter == 'all' ? '#FF6520' : '',
                    color: ticketFilter === 'all' ? '#FFFFFF' : '',
                  }}
                />
                <Chip
                  label={`Open (${String(openReports.length).padStart(
                    2,
                    '0',
                  )}) `}
                  variant="outlined"
                  onClick={() => handleClick('open')}
                  style={{
                    backgroundColor: ticketFilter == 'open' ? '#FF6520' : '',
                    color: ticketFilter === 'open' ? '#FFFFFF' : '',
                  }}
                />
                <Chip
                  label={`Closed (${String(closedReports.length).padStart(
                    2,
                    '0',
                  )})`}
                  variant="outlined"
                  onClick={() => handleClick('closed')}
                  style={{
                    backgroundColor: ticketFilter == 'closed' ? '#FF6520' : '',
                    color: ticketFilter === 'closedg' ? '#FFFFFF' : '',
                  }}
                />
              </Stack>
            </>
          )}

          <TabPanel value="1" sx={{ padding: '10px', paddingTop: '1px' }}>
            <Stack spacing={1.2}>
              {(admin ? selfTickets : faultReports).length > 0 ? (
                (admin ? selfTickets : faultReports).map((ele) => {
                  return (
                    <>
                      <Card sx={{ borderRadius: '10px' }} variant="outlined">
                        <Paper>
                          <GridSx2
                            container
                            onClick={() => handleTicketClick(ele.id)}
                          >
                            <Grid item xs={1.5}>
                              <Box
                                sx={{
                                  backgroundColor:
                                    ele.status == 'open'
                                      ? '#FF4E5E'
                                      : ele.status == 'assigned'
                                      ? '#C7D42F'
                                      : '#02B9A4',
                                  height: '2vh',
                                  width: '4vh',
                                  borderRadius: 8,
                                }}
                              >
                                <Typography></Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={7.5}>
                              <TypoSx6>
                                {ele.status == 'open'
                                  ? 'Open'
                                  : ele.status == 'closed'
                                  ? `Closed By ${
                                      admin
                                        ? ele.assignedToId === adminId
                                          ? 'you'
                                          : ele.assignedTo
                                        : ele.assignedTo
                                    }`
                                  : ele.status == 'assigned'
                                  ? `Addressed By ${
                                      admin
                                        ? ele.assignedToId === adminId
                                          ? 'you'
                                          : ele.assignedTo
                                        : ele.assignedTo
                                    }`
                                  : ''}
                              </TypoSx6>
                            </Grid>
                            <GridSx3 xs={3}>
                              {ele.rating && (
                                <Stack direction={'row'}>
                                  <Box>
                                    <StarIcon style={{ color: '#CFBD1C' }} />
                                  </Box>
                                  <Box>
                                    <Typography fontSize="14px">
                                      {ele.rating}
                                    </Typography>
                                  </Box>
                                </Stack>
                              )}
                            </GridSx3>
                            <Grid item xs={12} pt={ele.rating ? 0 : 1}>
                              <TypoSx3 variant="h6">
                                {ele.title && ele.title}{' '}
                              </TypoSx3>
                              <Typography
                                sx={{
                                  fontSize: 12.5,
                                  fontFamily:
                                    "'roboto','Open sans','Helvetica'",
                                  minWidth: '65vw',
                                }}
                                color="text.secondary"
                                gutterBottom
                              >
                                {descriptionFunc(ele.description, ele.issue)}
                              </Typography>
                              <Grid container>
                                <Grid item xs={8}>
                                  <TypoSx5>
                                    {ele.building} | {ele.floor && ele.floor}{' '}
                                  </TypoSx5>
                                </Grid>
                                {ele.ticketNum && (
                                  <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent={'flex-end'}
                                    alignItems="center"
                                  >
                                    <Typography color="#4B4B4B" fontSize="12px">
                                      Ticket ID: {ele.ticketNum}
                                    </Typography>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </GridSx2>
                        </Paper>
                      </Card>
                    </>
                  )
                })
              ) : (
                <Card sx={{ borderRadius: '10px' }}>
                  <Grid container spacing={0} p={3}>
                    <Grid item container xs={12} p={1}>
                      <Grid
                        item
                        xs={12}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          {' '}
                          <img src={ticketIcon} />
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box>
                          <Typography
                            fontSize={'16px'}
                            fontStyle="italic"
                            color="#212121"
                            align="center"
                          >
                            {' '}
                            You don't have any tickets to show here
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Stack>
          </TabPanel>
          <TabPanel value="2" sx={{ padding: '8px', paddingTop: '1px' }}>
            <Grid>
              {fmTickets.length > 0 ? (
                fmTickets.map((ele) => {
                  return (
                    <>
                      <Stack pb={1.2}>
                        <Card sx={{ borderRadius: '10px' }} variant="outlined">
                          <Paper spacing={0.5} variant="outlined">
                            <GridSx2
                              container
                              onClick={() => handleTicketClick(ele.id)}
                              sx={{
                                backgroundColor:
                                  ele.assignedToId === adminId
                                    ? '#FFFFF8'
                                    : 'inherit',
                              }}
                            >
                              <Grid item xs={1.5}>
                                <Box
                                  sx={{
                                    backgroundColor:
                                      ele.status == 'open'
                                        ? '#FF4E5E'
                                        : ele.status == 'assigned'
                                        ? '#C7D42F'
                                        : '#02B9A4',
                                    height: '2vh',
                                    width: '4vh',
                                    borderRadius: 8,
                                  }}
                                >
                                  <Typography></Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={7.5}>
                                <TypoSx6>
                                  {ele.status == 'open'
                                    ? 'Open'
                                    : ele.status == 'closed'
                                    ? admin
                                      ? `Resolved by ${
                                          admin
                                            ? ele.assignedToId === adminId
                                              ? 'you'
                                              : ele.assignedTo
                                            : ele.assignedTo
                                        }`
                                      : `Closed By ${ele.assignedTo}`
                                    : ele.status == 'assigned'
                                    ? `Addressed By ${
                                        admin
                                          ? ele.assignedToId === adminId
                                            ? 'you'
                                            : ele.assignedTo
                                          : ele.assignedTo
                                      }`
                                    : ''}
                                </TypoSx6>
                              </Grid>
                              <GridSx3 xs={3}>
                                {ele.rating && (
                                  <Stack direction={'row'}>
                                    <Box>
                                      <StarIcon style={{ color: '#CFBD1C' }} />
                                    </Box>
                                    <Box>
                                      <Typography fontSize="14px">
                                        {ele.rating}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                )}
                              </GridSx3>
                              <Grid item xs={12} pt={ele.rating ? 0 : 1}>
                                <TypoSx3 variant="h6">
                                  {ele.title && ele.title}
                                </TypoSx3>
                                <Typography
                                  sx={{
                                    fontSize: 12.5,
                                    fontFamily:
                                      "'roboto','Open sans','Helvetica'",
                                    minWidth: '65vw',
                                  }}
                                  color="text.secondary"
                                  gutterBottom
                                >
                                  {descriptionFunc(ele.description, ele.issue)}
                                </Typography>
                                <Grid container>
                                  <Grid item xs={8}>
                                    <TypoSx5>
                                      {ele.building} | {ele.floor && ele.floor}{' '}
                                    </TypoSx5>
                                  </Grid>
                                  {ele.ticketNum && (
                                    <Grid
                                      item
                                      xs={4}
                                      display="flex"
                                      justifyContent={'flex-end'}
                                      alignItems="center"
                                    >
                                      <Typography
                                        color="#4B4B4B"
                                        fontSize="12px"
                                      >
                                        Ticket ID: {ele.ticketNum}
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              </Grid>
                            </GridSx2>
                          </Paper>
                        </Card>
                      </Stack>
                    </>
                  )
                })
              ) : (
                <Card>
                  <Grid container spacing={0}>
                    <Grid item xs={12} p={1}>
                      <Item>
                        {' '}
                        <WebAssetOffOutlinedIcon />{' '}
                      </Item>
                      <ItemSx>
                        <Typography variant="h6" p={1}>
                          {' '}
                          You don't have any tickets to show here
                        </Typography>
                      </ItemSx>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Grid>
          </TabPanel>
        </TabContext>
        <Modal open={modal} onClose={() => setModal(false)}>
          <Box sx={ModalStyle}>
            <Grid container>
              <Grid item xs={6}>
                <AlignedBox>
                  <label htmlFor="icon-button-file">
                    <Stack spacing={1}>
                      <Box display="flex" justifyContent={'center'}>
                        <img src={cameraIcon} width="26px" />
                      </Box>
                      <Typography color="#212121">Click an image</Typography>
                    </Stack>
                  </label>
                  <input
                    accept="image/*"
                    style={inputStyle}
                    id="icon-button-file"
                    type="file"
                    capture="environment"
                    onChange={(e) => handleCapture(e.target)}
                  />
                </AlignedBox>
              </Grid>
              <Grid item xs={6}>
                <AlignedBox>
                  <label htmlFor="choose-file" id="choose-file-label">
                    <Stack spacing={1}>
                      <Box display="flex" justifyContent={'center'}>
                        <img src={folderIcon} />
                      </Box>
                      <Typography color="#212121">
                        Upload from Gallery
                      </Typography>
                    </Stack>
                  </label>

                  <input
                    name="uploadDocument"
                    type="file"
                    id="choose-file"
                    accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                    style={inputStyle}
                    onChange={(e) => {
                      handleCapture(e.target)
                    }}
                  />
                </AlignedBox>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </BoxSx2>
    </>
  )
}

export default Report
