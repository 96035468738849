import React from 'react'
import { Typography } from '@mui/material'

const GreetingTextWidget = (props) => {
    const { title, subTitle,issue } = props
    return (
        <>
            <Typography sx={{ fontSize: 11, fontFamily: "'roboto','Open sans','Helvetica'",minWidth:'65vw',color:'#FF6520' }}>
                {issue}
            </Typography>
            <Typography variant="h6" fontFamily="'roboto','Open sans','Helvetica'" fontSize="15px"  minWidth='65vw'>
                {title}
            </Typography>
            <Typography sx={{ fontSize: 12.5, fontFamily: "'roboto','Open sans','Helvetica'",minWidth:'65vw' }} color="text.secondary" gutterBottom>
                {subTitle}
            </Typography>
        </>
    )
}

export default GreetingTextWidget