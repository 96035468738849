import axios from "axios"
import { GET } from "../../../Helpers/http"
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import errorMessage from '../../../Assets/MeetingRooms/Images/errorMessage.svg'
import feedback from '../../../Assets/MeetingRooms/Images/feedback.svg'
import meetingRoomIcon from '../../../Assets/MeetingRooms/Images/meetingRoomIcon.svg'
import returningVisitor from '../../../Assets/MeetingRooms/Images/returningVisitor.svg'
import workingAtHome from '../../../Assets/MeetingRooms/Images/workingAtHome.svg'
import AssetManager from '../../../Assets/MeetingRooms/Images/assetManager.png'
import { errorModal } from "../../../Helpers/ErrorModal"
export const UPDATE_APPS_CONFIG='UPDATE_APPS_CONFIG'
export const UPDATE_LOGO ='UPDATE_LOGO'
export const UPDATE_IMAGE_LINK='UPDATE_IMAGE_LINK'

const initialState={
    apps:
    [
        { title: 'Desk Booking', configName:'deskBooking',logo: workingAtHome, link:'/deskbooking/home',enabled:false},
        { title: 'Room Booking',configName:'meetingRooms', logo: meetingRoomIcon, link: '/meetingrooms/booking',enabled:false},
        { title: 'Fault Report',configName:'faultReport', logo: errorMessage, link:'/faultreporting',enabled:false},
        { title: 'FeedBack', configName:'feedback',logo: feedback, link:'/feedback',enabled:false},
        // { title: 'Manage Visitor', logo: returningVisitor, link:'/managevisitor' },
        {title:'Manage Visitor',configName:'manageVisitor',logo:returningVisitor,link:'/manageVisitor',enabled:true},
        {title:'Asset Manager',configName:'assetManagement', logo:AssetManager,link:'/assetmanager',enabled:false}
    ],
    logo:'',
    mediaLink:'',
    imageLink:'',
    isLoaded:false,
    logoIsLoaded:false
}


export const startFetchAppsConfig=createAsyncThunk('appsConfig/startFetchAppsConfig',async({token},thunkAPI)=>{
    try{
        const apps=[{app:'deskBooking',link:'db/v1/config'},
                                  {app:'meetingRooms',link:'mr/v1/config'},
                                  {app:'assetManagement',link:'am/v1/config'},
                                  {app:'feedback',link:'fb/v1/config'},
                                  {app:'faultReport',link:'fr/v1/config'},
                                  {app:'manageVisitor',link:'vm/v1/config'} ]
        const configPromises=apps.map((app)=>{
           
                if(app.link){
                    return axios.get(`${process.env.REACT_APP_BASE_URL}${app.link}`, {
                        headers: {
                            'Authorization': localStorage.getItem("token")||token
                        }
                    })
                     }
                     else {
                             return {value:{data:{isServiceEnabled:false}}}
                            }

                })
                Promise.allSettled(configPromises)
            .then((result) => {
                let appsConfig={}
               result.forEach((ele,i)=>{
                    if(ele.status==='fulfilled'){
                        if(ele.value?.status==200){
                            
                            appsConfig[apps[i].app]=ele.value?.data['isServiceEnabled']
                            if(ele?.value?.data?.fileReadUrl){
                                thunkAPI.dispatch(updateImageLink(ele?.value?.data?.fileReadUrl))
                            }                             
                        }
                    }
               })
               const data={services:appsConfig}
              
               thunkAPI.dispatch(updateAppsConfig(data))
         
            })
            
    }
    catch   (err){
        console.log({err})
    }   
})


export const startFetchLogo=createAsyncThunk('appsConfig/startFetchLogo',async(token)=>{
    try{
        const response=await GET(`fr/v1/medialink`,token)
        if(response.status===200){
            return response.data
        }
        else {
            errorModal()
        }
    }
    catch(err){
        errorModal()
    }
})
// const updateLogo=(payload)=>{
//     return {
//         type:UPDATE_LOGO,
//         payload
//     }
// }

// export const updateImageLink=(payload)=>{
//     return {
//         type:UPDATE_IMAGE_LINK,
//         payload
//     }
// }

const appsConfigSlice=createSlice({
    name:'appsConfigSlice',
    initialState,
    reducers:{
        updateImageLink:(state,action)=>{
            state.imageLink=action.payload
        },
        updateAppsConfig:(state,action)=>{

            const apps=state.apps
            const newArr=apps.map((ele)=>{
                const enabled=action.payload?.services[ele.configName]
                return {...ele,enabled:enabled}
            })
            const disabledServices=newArr.filter(ele=>ele.enabled===false).map(app=>app.configName)

            function compare(a, b) {
                if (disabledServices.includes(a.configName) && !disabledServices.includes(b.configName)) {
                  return 1; 
                } else if (!disabledServices.includes(a.configName) && disabledServices.includes(b.configName)) {
                  return -1; 
                } else {
                  return 0; 
                }
              }
              
              // Sort the array
              newArr.sort(compare)
            state.apps=newArr
            state.isLoaded=true
        }
    },
    extraReducers(builder){
        builder.addCase(startFetchLogo.fulfilled,(state,action)=>{
           state.logo=action.payload?.logo
           state.mediaLink=action.payload?.twinVideoUrl
           state.logoIsLoaded=true
        })
    }
})

export const {updateImageLink,updateAppsConfig}=appsConfigSlice.actions

export default appsConfigSlice.reducer




