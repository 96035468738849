import { Button } from '@mui/material'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetEvent } from '../../../Redux/ReduxSlice/MeetingRooms/eventsSlice'
const ConfirmButton = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { title, color, link, action, data, handleRedirect, guestEmail, attendees, setIsError,loader } = props
    const [disabled, setDisabled] = useState(false)
    const handleClick = () => {
        dispatch(resetEvent())
        if (link) {
            history.push(link)
        }
        else {

            if (guestEmail !== '' && !attendees.includes(guestEmail)) {
                setIsError(true)
            }
            else {
                loader&&loader(true)
                dispatch(action({data, handleRedirect}))
                setDisabled(true)
            }
        }

    }
    return (
        <Button sx={{ backgroundColor: color, color: '#F6F6F6', borderRadius: '10px', height: '60px', width: '40vw' }}
            onClick={handleClick} disabled={disabled}>
            {title}
        </Button >
    )
}

export default ConfirmButton

