import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import VistorHeader from '../../UI/ManageVistor/Header/VistorHeader'
import ConfirmVisitDetails from './ConfirmVisitDetails'
import VisitDate from './VisitDate'
import VisitDetails from './VisitDetails'
import VisitLocation from './VisitLocation'
import AllAppointments from './AllAppointments'
import AppointmentDetails from './AppointmentDetails'
import RequestedAppointments from './RequestedAppointments'

const ManageVisitorMain = () => {
    const visitorComp=useSelector(state=>state?.vistorComp?.title)
    const handleHeaderTitle=()=>{
        const title=visitorComp==='confirmVisitDetails'?'Please review your visit details':'Book a Visit'
        const backLink=visitorComp==='VisitDate'?'VisitorLocation':visitorComp==='visitDetails'?'VisitDate':visitorComp==='confirmVisitDetails'?'visitDetails':visitorComp==='VisitorLocation'?'AllAppointments':visitorComp==='AppointmentDetails'?'AllAppointments':""
        return {title,backLink}
    }
    const headerDetails=handleHeaderTitle()
  return (
    <Box>
        <VistorHeader title={headerDetails.title} backLink={headerDetails.backLink} />
        <Box mx={'16px'} >
          {visitorComp==='AllAppointments'?<AllAppointments />:visitorComp==='VisitorLocation'?<VisitLocation />:visitorComp==='VisitDate'?<VisitDate />:
           visitorComp==='visitDetails'?<VisitDetails />:visitorComp==='AppointmentDetails'?<AppointmentDetails />:visitorComp==='confirmVisitDetails'?<ConfirmVisitDetails />:visitorComp==='requestedAppointments'?<RequestedAppointments />:<></> }
        </Box>
    </Box>
  )
}

export default ManageVisitorMain
