import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET, PUT } from "../../../Helpers/http"
export const GET_USER_INFO = 'GET_USER_INFO'
export const EDIT_USER_INFO = 'EDIT_USER_INFO'

// export const startGetUserInfo = (id) => {
//     return (dispatch) => {
//         axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/user/details/${id}`, {
//             headers: {
//                 'Authorization': localStorage.getItem("token")
//             }
//         })
//             .then((result) => {
//                 if (result.status === 200) {
//                     const res = result.data?.data

//                     dispatch(getUserInfo(res))
//                 }
//                 else {
//                     console.log({ result })
//                 }
//             })
//             .catch(err => console.log(err))
//     }
// }
const initialState={
    data:{},
    isLoaded:false
}

export const startGetUserInfo=createAsyncThunk('userInfo/startGetUserInfo',async(id)=>{
    try{
        const response=await GET(`um/v1/user/details/${id}`)
        if(response.status===200){
            return response.data?.data
        }
        else{
            console.log({response})
        }
    }
    catch(err){
        console.log({err})
    }
})

const getUserInfo = (payload) => {
    return {
        type: GET_USER_INFO,
        payload
    }
}


// export const startEditUserInfo = (id, data, closeEditMode) => {
//     return (dispatch) => {
//         axios.put(`${process.env.REACT_APP_BASE_URL}um/v1/user/details/${id}`, data, {
//             headers: {
//                 'Authorization': localStorage.getItem("token")
//             }
//         })
//             .then((result) => {

//                 if (result.status === 200) {
//                     const res = result.data?.data

//                     dispatch(editUserInfo(res))
//                     closeEditMode()

//                 }
//                 else {
//                     console.log({ result })
//                 }

//             })
//             .catch(err => console.log(err))
//     }
// }

export const startEditUserInfo=createAsyncThunk('userInfo/startEditUserInfo',async({id,data,closeEditMode})=>{
    try{
        const response=await PUT(`um/v1/user/details/${id}`,data)
        if(response.status===200){

            closeEditMode()
            return response?.data?.data
        }
        else {
            console.log({response})
        }
    }
    catch(err){
        console.log({err})
    }
})
export const startUploadProfilePic = (image, handleImageUpload) => {
    return (dispatch) => {
        
        axios.post(`${process.env.REACT_APP_ACCOUNTS_URL}v1/user/image`, image, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token')
            }
        })
            .then((res) => {
                if (res.status === 201) {
                    const result = res.data
                    handleImageUpload(result)
                }
                else {
                    console.log({ res })
                }
            })
            .catch(err => { 
                alert('Something went wrong!')
                console.log(err.message) })
    }
}

const editUserInfo = (payload) => {
    return {
        type: EDIT_USER_INFO,
        payload
    }
}

const userInfoSlice=createSlice({
    name:'userInfoSlice',
    initialState,
    reducers:{
        
    },
    extraReducers(builder){
        builder.addCase(startGetUserInfo.fulfilled,(state,action)=>{
            state.data=action.payload
            state.isLoaded=true
        })
        builder.addCase(startEditUserInfo.fulfilled,(state,action)=>{
            state.data=action.payload
            state.isLoaded=true
        })
    }
})

export default userInfoSlice.reducer