import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import backIcon from '../../../Assets/Images/left-arrow.png'
import { setVisitorComp } from '../../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'

const VistorHeader = ({title,backLink}) => {
    const dispatch=useDispatch()
    const history=useHistory()
    const handleClick = () => {
        backLink?dispatch(setVisitorComp(backLink)):history.push('/')
        
    }
  return (
    <>
        <Box p={0} pb={0}>
        <Box sx={{ width: "100%", minHeight: "100%" }}>
            <Grid container spacing={0} display='flex' alignItems={'center'} height={70} backgroundColor='#FF6520' p={2}  >
            <Grid item xs={1.2} >
                <IconButton size='small' float='left' onClick={handleClick} >
                    <img src={backIcon}  width={'24px'} />
                </IconButton>

            </Grid>
            <Grid item xs={9.8} display='flex' >
                <Typography fontSize={20} align='left' color={'#FFFFFF'}>{title}</Typography>
            </Grid>
           
           
        </Grid>

        </Box>
        </Box>
    </>
  )
}

export default VistorHeader
