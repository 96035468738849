import React from 'react'
import ManageVisitorMain from '../../components/ManageVisitor.js/ManageVisitorMain'

const ManageVistor=()=>{
  return (
    <>
        <ManageVisitorMain />
    </>
  )
}

export default ManageVistor
