import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { DELETE, GET, PUT } from "../../../Helpers/http"
import { meetingRoomError } from "../../../Helpers/meetingRoomError"
export const DELETE_MEETING = 'DELETE_MEETING'
export const UPDATE_MEETING = 'UPDATE_MEETING'
export const FETCH_ALL_MEETINGS = 'FETCH_ALL_MEETINGS'
export const UPDATE_LIST_LOADING = "UPDATE_LIST_LOADING"
export const RESET_MEETINGS = "RESET_MEETINGS"
export const UPDATE_SELECTED_MEETING = 'UPDATE_SELECTED_MEETING'
export const UPDATE_DATA_LOADING = "UPDATE_DATA_LOADING"
export const RESET_SELECTED_MEETING = "RESET_SELECTED_MEETING"
export const RESET_LIST_IS_DELETED = 'RESET_LIST_IS_DELETED'
export const UPDATE_INVITATION_STATUS = "UPDATE_INVITATION_STATUS"
export const SET_MEETING_LOCAL_STATE = 'MEETING_LOCAL_STATE'
export const RESET_MEETING_LOCAL_STATE = 'RESET_MEETING_LOCAL_STATE'


const initialState = {
    selectedEvent: {
        isLoaded: false,
        error: {
            message: ""
        },
        data: {},
        localState:{}
    },
    list: {
        isLoaded: false,
        isLoading: true,
        error: {
            message: ""
        },
        data: [],
        isDeleted: false,
        isUpdated: false
    },


}


export const startDeleteMeeting=createAsyncThunk('mr/startDeleteMeeting',async({id,handleDeleteLoader,handleOpen})=>{
    try{
        const response=await DELETE(`mr/v1/event/${id}`)
        if(response.status===200){
            handleDeleteLoader(false)
            handleOpen(true)
            return response?.data
        }
        else {
            meetingRoomError('')
        }
    }
    catch(error){
        meetingRoomError({error})
    }
})



export const startUpdateMeeting=createAsyncThunk('mr/startUpdateMeeting',async({data,handleRedirect})=>{
    try{
        const response=await PUT(`mr/v1/event/attendees`,data)
        if(response.status===200){
            handleRedirect()
            return response.data
        }
        else {
            meetingRoomError('')
        }
    }
    catch(error){
        meetingRoomError({error})
    }   
})

export const startUpdateMeetingData=createAsyncThunk('mr/startUpdateMeetingData',async({data,handleUpdateRoute})=>{
    try{
        const response=await PUT(`mr/v1/event`,data)
        if(response.status===200){
            handleUpdateRoute()
            return response?.data
        }
        else {
            meetingRoomError('')
        }
    }
    catch(error){
        meetingRoomError({error})
    }
})



export const startFetchMeetings=createAsyncThunk('mr/startFetchMeetings',async({date,setIsLoaded,redirect,failRedirect})=>{
    try{
        const response=await GET(`mr/v1/events?selected-date=${date}`)
        if(response.status===200){
            setIsLoaded&&setIsLoaded()
            return response?.data
        }
        else {
            meetingRoomError('')
        }
    }
    catch(error){   
        meetingRoomError({error,redirect,failRedirect})
    }
})


export const resetSelectedMeeting = () => {
    return {
        type: RESET_SELECTED_MEETING
    }
}


export const startFetchMeetingById=createAsyncThunk('mr/startFetchMeetingById',async({id,date,handleRedirect,handleLoader})=>{
    try{
        const response=await GET(`mr/v1/event/${id}`)
        if(response.status===200){
            handleRedirect()
            handleLoader && handleLoader()
            return response.data
        }
        else {
            meetingRoomError({error:{response:{status:400}}})
        }
    }
    catch(error){
        meetingRoomError({error,handleLoader})
    }
})


export const resetListIsDeleted = () => {

    return {
        type: RESET_LIST_IS_DELETED
    }
}

export const startInvitationResponse=createAsyncThunk('mr/startInvitationResponse',async({data,handleLoader})=>{
    try{
        const response=await PUT(`mr/v1/event/confirmation`,data)
        if(response.status===200){
            handleLoader(false)
            return response.data
        }
        else {
            meetingRoomError({error:'',handleLoader})
            
        }
    }
    catch(error){
        meetingRoomError({error,handleLoader})
    }
})


const meetingRoomSlice=createSlice({
    name:'meetingRoomSlice',
    initialState,
    reducers:{
        setMeetingLocalState:(state,action)=>{
            state.selectedEvent.localState=action.payload
        },
        addBookedMeeting:(state,action)=>{
            state.list?.data?.push(action.payload)
           state.list.isLoaded=true
        },
        resetMeetingLocalState:(state,action)=>{
            state.selectedEvent.localState={}
        },
        resetMeetings:(state,action)=>{
            state.list.data=[]
            state.list.isLoading=true
        }
    },
    extraReducers(builder){
        builder.addCase(startFetchMeetings.fulfilled,(state,action)=>{
            if(action.payload){
                state.list.data=action.payload
                state.list.isLoading=false 
            }
        })
    
        builder.addCase(startFetchMeetingById.fulfilled,(state,action)=>{
            if(action.payload){
                state.selectedEvent.data=action.payload
                state.selectedEvent.isLoaded=true 
            }
        })
        builder.addCase(startUpdateMeeting.fulfilled,(state,action)=>{
            if(action.payload){
                const newArr = state.list?.data?.map((ele) => {
                    return (
                        ele.eventId === action.payload?.eventId ? { ...action.payload } :
                            { ...ele }
    
                    )
                })
                state.list.data=newArr
                state.list.isUpdated=true 
            }
        })
        builder.addCase(startDeleteMeeting.fulfilled,(state,action)=>{
            if(action.payload){
                const newArr = state.list.data.filter((ele) => {
                    return ele.id !== action.payload
                })
                state.list.data=newArr
                state.list.isDeleted=true 
            }
        })
        builder.addCase(startUpdateMeetingData.fulfilled,(state,action)=>{
            if(action.payload){
                const newArr = state.list?.data?.map((ele) => {
                    return (
                        ele.eventId === action.payload?.eventId ? { ...action.payload } :
                            { ...ele }
    
                    )
                })
                state.list.data=newArr
                state.list.isUpdated=true 
            }
        })
        builder.addCase(startInvitationResponse.fulfilled,(state,action)=>{
            if(action.payload){
                const id = action.payload?.id
                const newArr = state.list.data.map((ele) => {
                    return (
                        ele.id === id ? { ...action.payload } : { ...ele }
                    )
                })
                state.list.data=newArr
                state.selectedEvent.data=action.payload 
            }
        })
    }
})

export const {setMeetingLocalState,addBookedMeeting,resetMeetingLocalState,resetMeetings}=meetingRoomSlice.actions

export default meetingRoomSlice.reducer
