import { Button, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { resetEvent } from '../../../Redux/ReduxSlice/MeetingRooms/eventsSlice'
import swal from 'sweetalert'

const ConfirmButton = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { title, backgroundColor, onClick,width,disabled} = props
    const handleClick = () => {
        onClick()
    }
    return (
        <Button color='warning' sx={{ backgroundColor: backgroundColor, color: '#F6F6F6', fontWeight:'bold',height:'48px', width:width?width:'45%', textTransform: 'none','&:hover':{backgroundColor:backgroundColor},
    borderRadius:'10px' }}
         disabled={disabled}
         onClick={handleClick}  >
           <Typography fontSize='14px'>{title}</Typography> 
        </Button>
    )
}

export default ConfirmButton