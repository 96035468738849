import { GET, POST } from "../../../Helpers/http"

import axios from "axios"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { meetingRoomError } from "../../../Helpers/meetingRoomError"
import { addBookedMeeting } from "./meetingsSlice"
export const MEETING_RESET = "MEETING_RESET"
export const MEETING_UPDATE_ERROR = "MEETING_UPDATE_ERROR"
export const MEETING_UPDATE_DATA = "MEETING_UPDATE_DATA"
export const SAVE_EVENT_DATA = 'SAVE_EVENT_DATA'
export const UPDATE_EVENT_DATA = 'UPDATE_EVENT_DATA'
export const ADD_BOOKED_MEETING = 'ADD_BOOKED_MEETING'
export const UPDATE_EVENT_ISLOADED = "UPDATE_EVENT_ISLOADED"
export const RESET_EVENT = 'RESET_EVENT'
export const MEETING_ROOM_STATUS='MEETING_ROOM_STATUS'
export const UPDATE_MEETING_BOOKED_STATUS='UPDATE_MEETING_BOOKED_STATUS'

const initialState = {
    isLoaded: false,
    availableRooms: [],
    eventData: {},
    createdEvent: {},
    meetingRoomData:[],
    bookedStatus:[],
    meetingRoomDataIsLoaded:false,
    bookedStatusIsLoaded:false
}

export const startFetchMeetingRooms=createAsyncThunk('mr/startFetchMeetingRooms',async({data,handleLoader})=>{
    try{
        const response=await POST(`mr/v1/resources/rooms`,data)
        if(response.status===200){
            handleLoader&&handleLoader()
            return response.data
        }
        else if (response.data?.message === "No Rooms Available") {
            handleLoader && handleLoader()
            return []
        }
            else{
                meetingRoomError({error:{error:{response:{status:400}}}})

            }

    }
    catch(error){
        meetingRoomError({error})
    }
})



export const startBookMeetingRoom=createAsyncThunk('mr/startBookMeetingRoom',async({data,handleRedirect},thunkAPI)=>{
    try{
        const response=await POST(`mr/v1/event`,data)
        if(response.status===200){
            thunkAPI.dispatch(addBookedMeeting(response?.data))
            handleRedirect()
        }
        else {
            meetingRoomError('')
        }
    }
    catch(error){
        meetingRoomError({error})
    }
})





export const startFetchMeetingRoomStatus=()=>{
    return (dispatch)=>{
        axios.get( `${process.env.REACT_APP_DASHBOARD_URL}common/meetingrooms/latest`, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((response) => {
				const result = response.data
				if (result.success && result.message === "Meeting Room Data Found") {
                    
					dispatch(updateMeetingRooomStatus(result.data))
				} else {
					console.log({ result })
				}
			})
			.catch((err) => {
				console.log('error', err.message)
			})
    }
}

const updateMeetingRooomStatus=(payload)=>{
    return {
        type:MEETING_ROOM_STATUS,
        payload
    }
}

export const startFetchMeetingBookedStatus=()=>{
    return (dispatch)=>{
        const time = new Date().toISOString()
		axios.get(`${process.env.REACT_APP_URL}dashboard/resources/rooms/isbooked/${time}`, { headers: { 'Authorization': localStorage.getItem('token') } })
			.then((response) => {
				const result = response.data
				if (result.success && result.message === "Bookings Status Found") {
                   
					 dispatch(updateMeetingBookedStatus(result.data))
				} else {
					console.log({ result })
				}
			})
			.catch((err) => {
				console.log('error', 'asyncUpdateDashboardMeetingBookedStatus', err.message)
			})
    }
}

const updateMeetingBookedStatus=(payload)=>{
    return {
        type:UPDATE_MEETING_BOOKED_STATUS,
        payload
    }
}


const meetingEventsSlice=createSlice({
    name:'meetingEventsSlice',
    initialState,
    reducers:{
        saveEventData:(state,action)=>{
            state.eventData={ ...action.payload }
        },
        updateIsLoaded:(state,action)=>{
            state.isLoaded=false
        },
        resetEvent:(state,action)=>{
            state.isLoaded=false
            state.availableRooms=[]
            state.eventData={}
            state.createdEvent={}
        }
    },
    extraReducers(builder){
        builder.addCase(startFetchMeetingRooms.fulfilled,(state,action)=>{
            if(action.payload){
                state.availableRooms=action.payload
                state.isLoaded=true
            }
        })
    }
})

export const {saveEventData,updateIsLoaded,resetEvent}=meetingEventsSlice.actions

export default meetingEventsSlice.reducer