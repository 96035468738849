import React, { useState, useEffect } from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const CustomTabContainer = (props) => {
  const { tabList,tabsPanel } = props;
  const [newTabs, setNewTabs] = useState(tabList);
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList
            onChange={handleChange}
            textColor="black"
            aria-label="lab API tabs example"
            TabIndicatorProps={{ style: { backgroundColor: '#FF6520', fontWeight: 'bold' } }}
          >
            {newTabs.map((title, index) => (
              <Tab key={index} label={title} value={String(index + 1)} />
            ))}
          </TabList>
        </Box>
        <TabPanel value="1">{tabsPanel[0]()}</TabPanel>
        <TabPanel value="2">{tabsPanel[1]()}</TabPanel>
        {tabsPanel[2] && <TabPanel value="3">{tabsPanel[2]()}</TabPanel>}
      </TabContext>
    </Box>
  );
};

export default CustomTabContainer;
