import React, { useEffect } from "react";
import {
    Box,
    Typography,
    Grid,
    CircularProgress,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import Header from "./Header";
import AdrianseImage from  '../../../Assets/DeskBooking/Images/AdrianseImage.png'
import buildingImage from  '../../../Assets/DeskBooking/Images/building.jpg'
import { fetchBuildings,setSelectedBuilding } from "../../../Redux/ReduxSlice/deskBooking/bookingLocation";

function Building() {
    
    const buildings = useSelector(state => state?.buildings?.buildings?.buildings)
    const isLoaded=useSelector(state=>state?.buildings?.buildings?.buildingsIsLoaded)
    const selectedLocation=useSelector(state => state?.buildings?.selectedLocation)
    const dispatch = useDispatch()

    useEffect(() => {
       
        if(Object.keys(selectedLocation).length>0){
            dispatch(fetchBuildings(selectedLocation?._id))
        }
        else {
            history.push('/deskbooking/location')
        }
    }, [selectedLocation])

    const history = useHistory()

    const handleClick = (building) => {
        dispatch(setSelectedBuilding(building))
        history.push("/deskbooking/date")
    }

    return (
        <Box>
            <Header title='Select Building' />
            <Box p={2}>

                <Box p={2} display='flex' justifyContent='center' backgroundColor='#FFFFFF' >

                    <Grid container spacing={2} backgroundColor='#FFFFFF' borderRadius='10px'>
                        <Grid item xs={12}>

                            <Typography fontWeight={'bold'} align='center' fontSize='14px' variant="h6" gutterBottom>
                                Select the Building of your visit
                            </Typography>

                        </Grid>
                        <Grid
                            item
                            xs={12}
                            container

                            sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                            {isLoaded ? buildings?.map((building) => (
                                <Grid key={building?._id} item xs={12} onClick={() => { handleClick(building) }} pb={2} >

                                    <img
                                        src={buildingImage}
                                        srcSet={buildingImage}

                                        width='100%'
                                        style={{ borderRadius: '5px' }}
                                    />

                                    <Box>
                                        <Typography fontSize='14px' variant="h6">{building.name}</Typography>


                                    </Box>
                                </Grid>
                            ))
                                :
                                <Box display='flex' width='100%' justifyContent={'center'}><CircularProgress color="inherit" /></Box>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}
export default Building;