import React, { useEffect } from 'react'
import * as L from 'leaflet'
import green from '../../../Assets/DeskBooking/Images/green_dot.png'
import red from '../../../Assets/DeskBooking/Images/red_dot.png'
import orange from '../../../Assets/DeskBooking/Images/orange_dot.png'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const LeafletViewer = (props) => {
  const imageLink=useSelector(state=>state?.config?.imageLink)
  const desks = useSelector((state) => state?.zone?.zoneDesks)
  const zoneMap = useSelector((state) => state?.zone?.zoneMap)
  const { handleChange } = props
  const history=useHistory()
  var imageUrl = `${imageLink}${zoneMap?.light?.url}`

  useEffect(() => {
    if(desks?.length==0){
        history.goBack()
    }
    else {

        handleLoaded()
    }
  }, [])

  const handleLoaded = () => {
    var container = L.DomUtil.get('map')

    if (container != null) {
      document.getElementById(
        'map',
      ).innerHTML = `<div id="map" style={{ height: '600px', backgroundColor: '#fff', width: '100%'}}></div>`
      container._leaflet_id = null
    }

    var mapOptions = {
      center: [100, 100],
      zoom: 8,
      minZoom: 8,
      maxZoom: 11,
      attributionControl: false,
      tap: false,
    }
    var map = new L.Map('map', mapOptions)

    var imageBounds = zoneMap?.light?.imageBounds

    var overlay = L.imageOverlay(imageUrl, imageBounds)
    map.setMaxBounds(imageBounds)
    map.touchZoom.enable()
    overlay.addTo(map)
    overlay.bringToBack()

    var greenIcon = L.icon({
      iconUrl: green,
      iconsize: [1, 1],
      iconAnchor: [14, 14],
    })

    var redIcon = L.icon({
      iconUrl: red,
      iconsize: [1, 1],
      iconAnchor: [14, 14],
    })
    var orangeIcon = L.icon({
      iconUrl: orange,
      iconsize: [1, 1],
      iconAnchor: [14, 14],
    })
    var markers = {}
    addMarkers({ map, greenIcon, redIcon, orangeIcon, markers })

    map.on('click', function (e) {
      let target = e.originalEvent.target
      e.originalEvent.preventDefault()
      e.originalEvent.stopImmediatePropagation()
      const isDesk = markers.hasOwnProperty(target?.id)
      if (isDesk) {
        handleChange('', target.id)
      }
    })
  }
  const addMarkers = ({ map, greenIcon, orangeIcon, redIcon, markers }) => {
    for (let i = 0; i < desks.length; i++) {
      markers[desks[i]?.deskCode] = L.marker(
        { lat: desks[i]?.lat, lng: desks[i].lng },
        {
          icon: desks[i]?.isBooked
            ? desks[i]?.checkedIn
              ? redIcon
              : orangeIcon
            : greenIcon,
        },
      ).addTo(map)
      markers[desks[i].deskCode]._icon.id = desks[i].deskCode
    }
  }

  return (
    <>
      <div
        id="map"
        style={{ height: '800px', backgroundColor: '#fff', width: '100%' }}
      ></div>
    </>
  )
}

export default LeafletViewer
