import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import GreetingTextWidget from '../UI/MeetingRooms/Widgets/HomePage/GreetingTextWidget.js'
import {
  Backdrop,
  CircularProgress,
  Typography,
  Avatar,
} from '@mui/material'
import calendar from '../Assets/MeetingRooms/Images/calendar.png'
import errorMessage from '../Assets/MeetingRooms/Images/errorMessage.svg'
import workingAtHome from '../Assets/MeetingRooms/Images/workingAtHome.svg'
import returningVisitor from "../Assets/MeetingRooms/Images/returningVisitor.svg"
import back from '../Assets/Images/left-arrow.png'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {
  startFetchMeetingById,
  startFetchMeetings,
} from '../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import {
  startGetAllTickets,
  startGetTicket,
} from '../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import { startGetUpcomingBookings } from '../Redux/ReduxSlice/DeskBooking/bookingAction.js'
import { useDispatch, useSelector } from 'react-redux'
import { setComp } from '../Redux/ReduxSlice/FaultReporting/compSlice'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { resetViewAll, setViewAll } from '../Redux/ReduxSlice/Common/RedirectSlice.js'
import { setFeature, startFetchIncomingTransits, startFetchTransit } from '../Redux/ReduxSlice/AssetManagement/assetSlice.js'
import { assetSetComp } from '../Redux/ReduxSlice/AssetManagement/assetCompSlice.js'
import AssetManager from '../Assets/MeetingRooms/Images/assetManager.png'
import { startGetAllAppointments, startGetAppointmentsById } from '../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice.js'
import { setVisitorComp } from '../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp.js'



function Alerts() {
  const date = moment().format('YYYY-MM-DD')
  const bid = useSelector(state => state?.user?.data?.buildingId)
    const userId = useSelector((state) => state?.user?.data?.id)
  const meetings = useSelector((state) => state.meetings?.list?.data)
  const faultReports = useSelector((state) => state.faultReports?.tickets)
  const appsConfig=useSelector(state=>state?.config)
  const apps=appsConfig?.apps
  const appsIsLoaded=appsConfig?.isLoaded
  const latestFaultReport = faultReports.length > 0 ? faultReports[0] : {}
  const inTransitAssetData=useSelector(state=>state?.assetData?.incomingAssets)
  const incomingAssets=inTransitAssetData?.data
  const incomingAssetsIsLoaded=inTransitAssetData?.isLoaded
  const allInTransitAssets=incomingAssetsIsLoaded?incomingAssets.filter(ele=>ele?.status==='in transit'):[]
    const upcomingBookings = useSelector(
    (state) => state.bookings?.upcomingBookings,
    )?.filter(ele=>ele?.bookingDate===date)

    const [navigateLoader, setNavigateLoader] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    
    
    const upcomingMeetings = meetings
    .filter((meeting) => {
        return moment(meeting.startTime).isAfter(moment())
    })
  const allAlerts = ((faultReports.length > 0 ? 1 : 0) + (allInTransitAssets.length) + (upcomingBookings.length) + (upcomingMeetings.length))
   const { appointmentRequests } = useSelector(
    (state) => state.visitData?.allAppointments,
   )
  console.log(appointmentRequests,"appointmentRequestsaaaaaa")
    
  const handleBookingHalf = (first, second) => {
    if (first && second) {
      return 'Full day'
    } else if (first) {
      return 'First Half'
    } else if (second) {
      return 'Second Half'
    }
  }

  const timings = (start, end) => {
    return `${moment(start).format('ddd hh:mm A')} to ${moment(end).format(
      'hh:mm A',
    )}`
  }
  const handleRedirect = () => {
    history.push('/meetingrooms/meeting')
    setNavigateLoader(false)
  }

  const handleNavigate=()=>{
    dispatch(setFeature('checkin'))
    dispatch(assetSetComp('assetQrCodeReader'))
    }

  const handleAssetManagementRedirection=(id)=>{
    dispatch(startFetchTransit({transitId:id,handleNavigate}))
    history.push('/assetmanager')
  }

  const handleFetch = (id) => {
    setNavigateLoader(true)
    const date = moment().format('DD-MM-YYYY')
    dispatch(startFetchMeetingById({id, date, handleRedirect}))
  }

  const handleDescription = (val, issue) => {
    if (val.length === 0) {
      return ''
    } else if (val.length > 0 && val.length < 40) {
      return `${issue} | ${val}`
    } else if (val.length > 40) {
      const final = issue + ' | ' + val.slice(0, 25) + ' ' + '...'
      return final
    }
  }

  const handleFaultReportRedirect = (id) => {
    dispatch(startGetTicket({id,bid,handleRedirect:handleGetFaultReportRedirect}))
  }

  const handleGetFaultReportRedirect = () => {
    history.push('/faultreporting/home')
    dispatch(setComp('Ticket Details'))
  }

  const handleDeskBookingRedirect = () => {
    history.push('/deskbooking/home')
  }

  // const handleVisitorManagementRedirect = () => {
  //   // dispatch(startGetAppointmentsById({ _id }))
  //   dispatch(startGetAllAppointments())
  //   dispatch(setVisitorComp('requestedAppointments'))
  // }
   const handleVisitorManagementRedirect = (ele) => {
    // dispatch(setSelectedAppointments({visitor}))
    dispatch(startGetAppointmentsById({ _id:ele?._id}))
    dispatch(setVisitorComp('AppointmentDetails'))
    history.push('/manageVisitor')
   }
  
  useEffect(() => {
    dispatch(startGetAllAppointments())
  },[])


  useEffect(()=>{
    dispatch(setViewAll())
  },[])
  useEffect(() => {
    
    if(appsIsLoaded){
        const deskBookingEnabled=apps.find((ele)=>ele.configName==='deskBooking')?.enabled
        const roomBookingEnabled=apps.find(ele=>ele.configName==='meetingRooms')?.enabled 
        const faultReportsEnabled= apps.find(ele=>ele.configName==='faultReport')?.enabled 
        const feedbackEnabled=apps.find(ele=>ele.configName==='feedback')?.enabled 
        const assetManagementEnabled=apps.find(ele=>ele.configName==='assetManagement')?.enabled
         const visitorManagement= apps.find(ele=>ele.configName==='manageVisitor')?.enabled

        faultReportsEnabled&&dispatch(startGetAllTickets({bid}))
        // roomBookingEnabled&&dispatch(startFetchMeetings(date, '', 'NA'))
        roomBookingEnabled&&dispatch(startFetchMeetings({date,setIsLoaded:'',redirect:'NA'}))
       
        const body = {
        currentDate: date,
        }
        deskBookingEnabled&&dispatch(startGetUpcomingBookings({body}))
      assetManagementEnabled && dispatch(startFetchIncomingTransits(bid))
      visitorManagement && dispatch(startGetAllAppointments({userId}))
    }
    
  }, [appsIsLoaded])


  const handleBackClick = () => {
    history.push('/')
    dispatch(resetViewAll())
    // dispatch(setComp('Fault Reporting'))
  }

 

  return (
    <>
      <Grid
        container
        spacing={0}
        display="flex"
        alignItems={'center'}
        height={70}
        backgroundColor="#FF6520"
        // mb={2}
        p={2}
      >
        <Grid item xs={1.2}>
          <IconButton size="small" float="left" onClick={handleBackClick}>
            <img src={back} width={'24px'} />
          </IconButton>
        </Grid>
        <Grid item xs={10.8} display="flex">
          <Typography fontSize={20} color="#FFFFFF" align="left">
            {'Notifications'}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ backgroundColor: '#FFFFFF' }}>
        <Grid item container spacing={1} pt={4}>
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            width={'100%'}
          >
            <Typography sx={{ fontWeight: 600, fontSize: '22px' }}>
             Total Notifications
            </Typography>
          </Grid>
          
          <Grid
            item
            xs={12}
            display={'flex'}
            justifyContent="center"
            alignItems="center"
            width={'100%'}
          >
            <Avatar
              sx={{
                backgroundColor: '#FFFFFF',
                border: 'solid 1px #000000',
                width: 50,
                height: 50,
              }}
            >
              <Typography sx={{ color: '#000000', fontSize: '16px' }}>
                {allAlerts}
              </Typography>
            </Avatar>
          </Grid>
        </Grid>

          <Box pt={3}>
          {appointmentRequests.length > 0 && (
            <Stack>
                {appointmentRequests.map((ele) => {
                return (
                    <Box
                      // paddingY={2}
                      borderTop="solid 1px #D3D3d3"
                      // borderBottom={'solid 1px #D3D3d3'}
                    >
                  <Grid
                    container
                    item
                    sx={{
                      minHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '20px',
                    }}
                    onClick={() => {
                        handleVisitorManagementRedirect(ele)
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      sx={{
                        fontSize: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pr: 2
                      }}
                    >
                      <img
                        src={returningVisitor}
                        height="40px"
                        alt="Desk Booking"
                      />
                    </Grid>
                    <Grid item xs={9} >
                      <Typography fontSize="16px">Visitor Management</Typography>
                      <GreetingTextWidget
                     
                     title={`Incoming request`}
                           subTitle={`From ${ele?.visitors
                    ?.map((visitor) => `${visitor?.visitor?.name} - ${visitor?.visitor?.companyName}`)
                    .join(', ')}`}
                   />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        handleVisitorManagementRedirect(ele)
                      }}
                    >
                      <IconButton>
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  </Box>
                )
              })}
            </Stack>
          )}
          </Box> 

        <Box>
          <Box>
          {upcomingMeetings.length > 0 && (
            <Stack>
              {upcomingMeetings.map((ele) => {
                return (
                  // <Item>
                  <Box
                      key={ele?.id}  
                      // paddingY={2}
                      borderTop="solid 1px #D3D3d3"
                      borderBottom={'solid 1px #D3D3d3'}
                    >
                  <Grid
                    container
                    item
                    sx={{
                      minHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                      // backgroundColor: '#FFFFFF',
                      padding: '20px',
                    }}
                    onClick={() => {
                      handleFetch(ele.id)
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      sx={{
                        fontSize: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        height: '100%',
                        pr:2
                      }}
                    >
                      <img src={calendar} height="40px" alt="Calender Icon" />
                    </Grid>
                    <Grid item xs={9}>
                      <Typography fontSize="16px">Meeting</Typography>
                      <GreetingTextWidget
                        title={ele.summary}
                        subTitle={timings(ele.startTime, ele.endTime)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <IconButton>
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  </Box>
                  // </Item>
                )
              })}
            </Stack>
          )}
          </Box>
          <Box>
          {upcomingBookings.length > 0 && (
            <Stack>
              {upcomingBookings.map((ele) => {
                return (
                    <Box
                      // paddingY={2}
                      borderTop="solid 1px #D3D3d3"
                      borderBottom={'solid 1px #D3D3d3'}
                    >
                  <Grid
                    container
                    item
                    sx={{
                      minHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                      // border: '1px solid red',
                      // backgroundColor: '#FFFFFF',
                      padding: '20px',
                      // boxShadow: 2
                    }}
                    onClick={() => {
                      handleDeskBookingRedirect()
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      sx={{
                        fontSize: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pr: 2
                      }}
                    >
                      <img
                        src={workingAtHome}
                        height="40px"
                        alt="Desk Booking"
                      />
                    </Grid>
                    <Grid item xs={9} >
                      <Typography fontSize="16px">Desk Booking</Typography>
                      <GreetingTextWidget
                        title={`${ele.deskCode} - ${ele.floor} - ${ele.zone} - ${ele.building}`}
                        subTitle={handleBookingHalf(
                          ele.firstHalf,
                          ele.secondHalf,
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        handleDeskBookingRedirect()
                      }}
                    >
                      <IconButton>
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  </Box>
                )
              })}
            </Stack>
          )}
          </Box>
          <Box>
          {faultReports.length > 0 && (
            <Stack>
              {
                  // <Item>
                  <Box
                  borderTop="solid 1px #D3D3d3"
                      borderBottom={'solid 1px #D3D3d3'}
                    >
                    <Grid
                      container
                      item
                      onClick={() => {
                        handleFaultReportRedirect(latestFaultReport.id)
                      }}
                      sx={{
                        minHeight: '65px',
                        display: 'flex',
                        alignItems: 'center',
                        // backgroundColor: '#FFFFFF',
                        padding: '20px',
                      }}
                    >
                      <Grid
                        item
                        xs={2}
                        sx={{
                          fontSize: '35px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          pr: 2,
                          // height:ele.description?'75px':'50px',
                        }}
                      >
                        <img
                          src={errorMessage}
                          height="40px"
                          alt="Fault reporting"
                        />
                      </Grid>
                      <Grid item xs={9}>
                        <Typography fontSize="16px">Fault report</Typography>
                        <GreetingTextWidget
                          title={`${latestFaultReport.title}`}
                          subTitle={handleDescription(
                            latestFaultReport.description,
                            latestFaultReport.issue,
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          handleFaultReportRedirect(latestFaultReport.id)
                        }}
                      >
                        <IconButton>
                          <KeyboardArrowRightIcon fontSize="medium" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  // </Item>
                
              }
            </Stack>
          )}
          </Box>


          <Box>
          {allInTransitAssets.length > 0 && (
            <Stack>
              {allInTransitAssets.map((ele) => {
                return (
                    <Box
                      // paddingY={2}
                      borderTop="solid 1px #D3D3d3"
                      borderBottom={'solid 1px #D3D3d3'}
                    >
                  <Grid
                    container
                    item
                    sx={{
                      minHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                      padding: '20px',
                    }}
                    onClick={() => {
                        handleAssetManagementRedirection(ele._id)
                    }}
                  >
                    <Grid
                      item
                      xs={2}
                      sx={{
                        fontSize: '35px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        pr: 2
                      }}
                    >
                      <img
                        src={AssetManager}
                        height="40px"
                        alt="Desk Booking"
                      />
                    </Grid>
                    <Grid item xs={9} >
                      <Typography fontSize="16px">Asset Management</Typography>
                      <GreetingTextWidget
                     
                     title={`Incoming Transit `}
                     subTitle={`From ${ele?.previousBuilding?.name} - ${(ele?.assets).length} asset/s`}
                   />
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      onClick={() => {
                        handleAssetManagementRedirection(ele?._id)
                      }}
                    >
                      <IconButton>
                        <KeyboardArrowRightIcon fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                  </Box>
                )
              })}
            </Stack>
          )}
          </Box>   
              

        </Box>

        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={navigateLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </>
  )
}
export default Alerts