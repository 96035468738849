import { FormControl, OutlinedInput } from '@mui/material'
import React from 'react'

const FeedBackDescription = (props) => {
  const { name, value, onChange } = props
  return (
    <FormControl fullWidth={true}>
      <OutlinedInput
        placeholder="Enter here"
        minRows={5}
        sx={{
          backgroundColor: '#FFFFFF',
          width: '100%',
          borderRadius: '10px',
          borderColor: 'orange',
          fontSize: '16px',
          fontWeight: '400',
          color: '#201F1E',
        }}
        name={name}
        value={value}
        onChange={onChange}
        multiline={true}
      />
    </FormControl>
  )
}

export default FeedBackDescription
