import { Box, Stack, Typography } from '@mui/material'
import React from 'react'

const CardTitle = (props) => {
    const { title, icon, muiIcon, bold, height } = props
    return (
        <Stack direction='row' spacing={2} pb={1}  >
            <Box display='flex' alignItems='center'>
                {muiIcon ? muiIcon : <img src={icon} style={{ height: '16px' }} />}
            </Box>
            <Box display='flex' alignItems='center'>
                <Typography fontWeight={bold ? 'bold' : 400} fontSize='16px'>{title}</Typography>
            </Box>
        </Stack>
    )
}

export default CardTitle