import { FormControl, OutlinedInput, TextareaAutosize } from '@mui/material'
import React from 'react'

const GuestDescription = (props) => {
    const { name, value, onChange, width,placeholder} = props
    return (
    
        <FormControl fullWidth={true}>
            <OutlinedInput
                placeholder={placeholder||"Enter a description for your meeting"}
                minRows={5}
                style={{ backgroundColor: '#FFFFFF', width: '100%', borderRadius: '10px' }}
                name={name}
                value={value}
                onChange={onChange}
                multiline={true}
            />
        </FormControl>
    )
}

export default GuestDescription