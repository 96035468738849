import axios from 'axios'
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'

const initialState = {
    isLoggedIn: false,
    error: {
        message: ""
    },
    data: {
        fullName: "",
        email: "",
        role: "",
        id: "",
        tenantCode:"",
        buildingId:''
        //6418619827a12c72fc64bfb7
    }
}
// export const startGetUserData = () => {
//     return (dispatch) => {
//         axios.get(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, {
//             headers: {
//                 'Authorization': localStorage.getItem("token")
//             }
//         })
//             .then((result) => {
//                 if (result.status === 200) {
//                     dispatch(setUser(result.data?.user))
//                 }
//                 else {
//                     console.log({ result })
//                 }
//             })
//             .catch(err => console.log(err))
//     }
// }

export const startGetUserData=createAsyncThunk('user/startGetUserData',async()=>{
    try{
        const response=await GET(`um/v1/user/validate`)
        if(response.status===200){
            // return response?.data?.user
        }
        else {
            errorModal()
        }
    }
    catch(err){
        errorModal()

    }
})

const userSlice=createSlice({
    name:'userSlice',
    initialState,
    reducers:{
        setUser:(state,action)=>{
            state.data= {fullName: action.payload?.fullName, email: action.payload?.email, buildingId:action.payload?.buildingId,role: action.payload?.role, id: action.payload?._id,tenantCode:action?.payload?.tenantCode }
        }
    },
    extraReducers(builder){
        builder.addCase(startGetUserData.fulfilled,(state,action)=>{
            state.data= {fullName: action.payload?.fullName, email: action.payload?.email, buildingId:action.payload?.buildingId,role: action.payload?.role, id: action.payload?._id,tenantCode:action?.payload?.tenantCode }
        })
    }
})

export const {setUser}=userSlice.actions

export default userSlice.reducer