import { UPDATE_WASHROOM_STATUS } from "../../ReduxSlice/Washroom/washroomAction";

const initialState={
    data:[],
    isLoaded:false
}

const commonFormatter = (obj, floor, area, floorCode) => {
	const object = {}
	Object.keys(obj).forEach(feature => {
		if (feature === "aqi") {
			const params = ['aqi', 'amm', 'temp'] //'rh' removed
			params.forEach(i => {
				object[i] = obj[feature][i] || obj[feature][i] >= 0 ? obj[feature][i] : 'NA'
				// if (obj[feature]?.isLatest) object[i] = obj[feature][i]
				// else object[i] = "NA"
			})
		} else {
			// if (obj[feature].isLatest) {
			const value = obj[feature][`washroom_${feature?.toLowerCase()}`] || obj[feature][`washroom_${feature?.toLowerCase()}`] >= 0 ? obj[feature][`washroom_${feature?.toLowerCase()}`] : 'NA'
			object[feature] = (object[feature] === 'bin' && (!isNaN(value))) ? value - 100 : value
			// }
			// else object[feature] = "NA"
		}
	})
	object.soapSolution = "NA" //no soap data - adding manually
	object.floor = floor
	object.area = area
	object.floorCode = floorCode
	return object
}

const floorDetails = {
	GFFWR1: { floor: 'Ground Floor', area: 'Female Restroom', floorCode: "GFFWR1" },
	GFMWR1: { floor: 'Ground Floor', area: 'Male Restroom', floorCode: "GFMWR1" },
	FFFWR1: { floor: 'First Floor', area: 'Female Restroom', floorCode: "FFFWR1" },
	FFMWR1: { floor: 'First Floor', area: 'Male Restroom', floorCode: "FFMWR1" },
	SFFWR1: { floor: 'Second Floor', area: 'Female Restroom', floorCode: "SFFWR1" },
	SFMWR1: { floor: 'Second Floor', area: 'Male Restroom 1', floorCode: "SFMWR1" },
	SFMWR2: { floor: 'Second Floor', area: 'Male Restroom 2', floorCode: "SFMWR2" },
}

const washroomReducer=(state=initialState,action)=>{
    switch(action.type){
        case UPDATE_WASHROOM_STATUS:{
            const payload = JSON.parse(JSON.stringify(action.payload))
			const newData = []
			Object.keys(payload).forEach(floor => {
				const newObject = commonFormatter(payload[floor], floorDetails[floor]['floor'], floorDetails[floor]['area'], floorDetails[floor]['floorCode'])
				newData.push(newObject)
			})
			return { ...state, isLoaded: true, data: newData }
        }
        default:{
            return {...state}
        }
    }
}

export default washroomReducer