import swal from "sweetalert";

export const meetingRoomError=({error,redirect,failRedirect,handleLoader})=>{
    if(typeof error==='object'){
    if (error.response?.status === 401) {
        //logout here
    } else if(error.response?.status === 303){
        if(!redirect==='NA'){
        window.location.href = error.response?.data?.authUrl
        //dispatch(updateMeetingRoomError("Something Went Wrong"))
        }
    }
    else {
        // alert('Please remove third party access from google and proceed again')
        // history.push('/')
        swal({  
            title: " Oops!",  
            text: "Something went wrong. Please try again later!",  
            icon: "error",  
            button: "Ok",  
          })
          .then((willDelete) => {
            if (willDelete) {
                handleLoader&&handleLoader(false)
                failRedirect&&failRedirect()
                
            } else {
                window.location.reload()
              
            }
          }); 
    }
}
else {
    swal({  
        title: " Oops!",  
        text: "Something went wrong. Please try again later!",  
        icon: "error",  
        button: "Ok",  
      })
      .then((willDelete) => {
        if (willDelete) {
            handleLoader&&handleLoader(false)
            failRedirect&&failRedirect()
            
        } else {
            window.location.reload()
          
        }
      }); 
}
}
