import React from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'

const GuestsButton = (props) => {
    const { guest, setGuests, seatingCapacity } = props
    const handleClick = () => {
        setGuests(guest)
    }

    return (
        <Box sx={{ backgroundColor: guest === seatingCapacity ? "#FF6520" : "inherit",borderRadius:'10px'}}>
        <IconButton variant="contained" sx={{ border: "solid 1px", borderRadius: "10px", width: '9vw', '&:hover': { backgroundColor: "inherit" }, backgroundColor: guest === seatingCapacity ? "#FF6520" : "inherit" }} size='small' onClick={handleClick}>
            <Typography sx={{ color: '#212121' }}>{guest}</Typography>
        </IconButton>
        </Box>
    )
}

export default GuestsButton