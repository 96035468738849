import { Box, LinearProgress, Stack, Typography } from '@mui/material'
import React from 'react'
import StarIcon from '@mui/icons-material/Star'

const FeedbackProgressBar = ({progressBars}) => {
    const handleRatingCount=(count)=>{
        let res=''
        res=(Number(count))>1000?'1k+':count
        return res
    }
  return (
    <>
      <Stack sx={{ width: '100%'}} spacing={0}>
                {progressBars.map((ele)=>{
                    return (

                            <Box  width='100%' display={'flex'} key={ele.label}>
                                <Stack mr={1} direction={'row'} spacing={0.5}>
                                    <Typography>{ele.label}</Typography><StarIcon fontSize={'small'}/>
                                </Stack>   
                                <Box  width='100%' mt={0.8}>

                                    
                                    <LinearProgress
                                    variant="determinate"
                                    sx={{
                                    '&.MuiLinearProgress-root': {
                                        backgroundColor: '#E0E0E0', // Set the background color of the remaining area
                                    },
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: `${ele.color}`, // Set the color of the progress bar
                                    },
                                    '& .MuiLinearProgress-colorPrimary': {
                                        backgroundColor:  `${ele.color}`, // Set the color of the remaining area to match the progress bar
                                    },
                                    }}
                                    value={ele.progress}
                                />
                                </Box>
                                <Box ml={1}>
                                    <Typography sx={{ fontFamily: "'roboto','Open sans','Helvetica'"}} color="text.secondary">{handleRatingCount(ele.count)}</Typography>
                                </Box>     
                            </Box>    
                                
                        
                    )
                })}
                </Stack>
    </>
  )
}

export default FeedbackProgressBar
