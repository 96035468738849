import React from 'react'
import MeetingDetailsPanel from '../../components/MeetingRooms/Container/Panel/MeetingDetailsPanel'

function MeetingDetails() {
    return (
        <div>
            <MeetingDetailsPanel />
        </div>
    )
}
export default MeetingDetails
