import { SET_SELECTEDESK, GET_AVAILABILITY, SET_DEFAULT,RESET_ISLOADED } from "../../ReduxSlice/DeskBooking/deskAction"


const initialState = {
    data: {},
    isAvailable: false,
    isLoaded: false,
}

const deskReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTEDESK: {
            return { ...state, data: action.payload };
        };
        case GET_AVAILABILITY: {
            return { ...state, isAvailable: action.payload, isLoaded: true }
        }
        case SET_DEFAULT: {
            return { ...initialState }
        }
        case RESET_ISLOADED:{
            return {...state,isLoaded:false}
        }
        default: {
            return { ...state}
        }
    }
}

export default deskReducer