
import axios from "axios"
export const SET_SELECTEDESK = "SET_SELECTEDESK"
export const GET_AVAILABILITY = "GET_AVAILABILITY"
export const SET_DEFAULT = "SET_DEFAULT"
export const RESET_ISLOADED = 'RESET_ISLOADED'

export const setSelectedDesk = (payload) => {
    return {
        type: SET_SELECTEDESK, payload
    }
}

export const getAvailability = (payload) => {
    return {
        type: GET_AVAILABILITY, payload
    }
}

export const startGetDeskDetails = (body) => {

    return (dispatch) => {
        axios.get(`${process.env.REACT_APP_BASE_URL}db/v1/desk?desk-id=${body.deskId}&selected-date=${body.selectedDate}&first-half=${body.firstHalf}&second-half=${body.secondHalf}&status=free`, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then(res => {
                if(res.status===200){

                    const result = res.data;
                dispatch(getAvailability(result.available))

            }
            })
            .catch(err => {
                console.log(err)
            })
    }
}

export const setDefaultDesk = () => {
    return {
        type: SET_DEFAULT
    }
}

export const resetIsLoaded = () => {
    return {
        type: RESET_ISLOADED
    }
}
