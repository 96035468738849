import {
  Box,
  Grid,
  Paper,
  Typography,
  Tab,
  Card,
  Rating,
  CircularProgress,
  IconButton,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import StarIcon from '@mui/icons-material/Star'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import HomeStar from '../../Assets/FeedBack/HomeStar.png'
import locationFeedBack from '../../Assets/FeedBack/locationFeedBack.png'
import ScanCodeFeedBack from '../../Assets/FeedBack/ScanCodeFeedBack.png'
import { useDispatch, useSelector } from 'react-redux'
import { setCompFb } from '../../Redux/ReduxSlice/Feedback/CompSliceFb'
import moment from 'moment'
import back from '../../Assets/Images/left-arrow.png'
import {
  startGetAllFeedback,
  startGetFiveRatings,
  startUserRating,
} from '../../Redux/ReduxSlice/Feedback/RatingsSlice'
import { useHistory } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'
import { Divider } from '@material-ui/core'
import { BrokenImageBox } from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles'
import brokenIcon from '../../Assets/FaultReporting/Images/BrokenIcon.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const FeedbackHome = (props) => {
  const imageLink = useSelector(state => state?.config?.imageLink)
  const dispatch = useDispatch()
  const theme = useTheme()
  const [ratingsID, setRatingsID] = useState(0)
  const [value, setValue] = useState('1')
  const history = useHistory()

  const ratings = useSelector((state) => state?.ratings)
  const topRatings = useSelector((state) => state?.ratings?.topRatings)
  const buildingId = useSelector((state) => state?.user?.data?.buildingId)

  const [activeStep, setActiveStep] = React.useState(0)

  const handleStepChange = (step) => {
    setActiveStep(step)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleQrClick = () => {
    dispatch(setCompFb('QrCode'))
  }

  const handleTicketDuration = (start) => {
    return `${moment(start).startOf('seconds').fromNow()}`
  }

  const handleRatingClick = (ratingObj) => {
    dispatch(startUserRating({ ratingId: ratingObj?.id, buildingId }))
  }

  const handleBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    dispatch(startGetAllFeedback({ buildingId }))
  }, [buildingId])

  useEffect(() => {
    dispatch(startGetFiveRatings({ buildingId }))
  }, [buildingId])

  return (
    <>
      <Box>
        <Grid
          container
          spacing={0}
          display="flex"
          alignItems={'center'}
          height={70}
          backgroundColor="#FF6520"
          mb={2}
          p={2}
        >
          <Grid item xs={1.2}>
            <IconButton size="small" float="left" onClick={handleBackClick}>
              <img src={back} width={'24px'} />
            </IconButton>
          </Grid>
          <Grid item xs={10.8} display="flex">
            <Typography fontSize={20} align="left" color="#FFFFFF">
              {'FeedBack'}
            </Typography>
          </Grid>
        </Grid>
        <Box p={2} pt={0}>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              paddingBottom={'20px'}
            >
              <Card
                sx={{
                  borderRadius: '20px',
                  p: 1, boxShadow: 0, width: '100%'
                }}
                onClick={handleQrClick}
              >
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <img src={ScanCodeFeedBack} height="40px" />
                  </Grid>
                  <Grid item xs={10}>
                    <Typography
                      color="#AAAAAA"
                      fontSize="14px"
                      fontWeight={'400'}
                    >
                      {' '}
                      Want to provide feedback?
                    </Typography>
                    <Typography
                      fontSize={'16px'}
                      color="#212121"
                      fontWeight={'400'}
                    >
                      Scan the area QR code to rate{' '}
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Box>
            <Box>
              <AutoPlaySwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={activeStep}
                onChangeIndex={handleStepChange}
                enableMouseEvents
              >
                {topRatings?.map((step, index) => {
                  return (
                    <div key={step.label}>
                      {Math.abs(activeStep - index) <= 1 ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                          }}
                        >
                          <Box
                            style={{
                              height: '190px',
                              width: '90%',
                              // border:"1px solid red",
                              borderRadius: '5px',
                              backgroundColor: 'white',
                            }}
                          >
                            <Box
                              style={{
                                // borderRadius: '5px',
                                height: '150px',
                                width: '100%',
                                backgroundColor: 'grey',
                              }}
                            >
                              <Grid container>
                                <Grid xs={12}>
                                  <Box
                                    style={{
                                      height: '150px',
                                      width: '100%', position: 'relative'
                                    }}
                                  >
                                    {step.images.length > 0 ? (
                                      <img
                                        src={`${imageLink}${step?.images[0]}`}
                                        width={'60px'}
                                      />
                                    ) : (
                                      <>
                                        <BrokenImageBox>
                                          <img
                                            src={brokenIcon}
                                            height={'20px'}
                                            style={{ objectFit: 'cover' }}
                                          />
                                        </BrokenImageBox>
                                      </>
                                    )}

                                    <Box
                                      style={{
                                        position: 'absolute',
                                        bottom: '0px',
                                        left: '10px',
                                      }}
                                    >
                                      <StarIcon sx={{ fontSize: 40, color: 'gold' }} />
                                    </Box>
                                    <Box style={{
                                      position: 'absolute',
                                      bottom: '5px',
                                      left: '50px',
                                    }}>
                                      <Typography
                                        style={{
                                          fontSize: '25px',
                                          fontWeight: '30px',
                                          color: '#ffffff',
                                        }}
                                      >
                                        {step.OverallRating?.averageRating}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Divider />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                marginTop: '7px',
                                marginLeft: '15px',
                              }}
                            >
                              <Typography sx={{ fontSize: '17px' }}>
                                {step?.areaDetails?.area?.name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      ) : null}
                    </div>
                  )
                })}
              </AutoPlaySwipeableViews>
            </Box>
          </Box>

          <TabContext value={value}>
            <Box>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                textColor="#212121"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#D97D54',
                    marginBottom: 4,
                  },
                }}
              >
                <Tab
                  label="My Ratings"
                  value="1"
                  sx={{
                    color: value !== '1' ? '#4B4B4B' : '#212121',
                    fontSize: '16px',
                    fontWeight: value == '1' ? 'bold' : 'light',
                    textTransform: 'none',
                  }}
                />
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: '2px', paddingTop: '5px' }}>
              {ratings?.isLoaded ? (
                <>
                  {ratings?.ratings?.length > 0 ? (
                    <>
                      {ratings?.ratings?.map((rating) => {
                        return (
                          <Box mt={1}>
                            <Paper
                              sx={{
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #DDDDDD',

                                borderRadius: '10px',
                                mb: 2,
                              }}
                              onClick={() => handleRatingClick(rating)}
                            >
                              <Grid container p={1.2}>
                                <Grid xs={6} item>
                                  <Typography
                                    sx={{
                                      fontSize: '16px',
                                      fontWeight: '400',
                                      color: '#212121',
                                    }}
                                  >
                                    {' '}
                                    {rating.area?.name}
                                  </Typography>
                                </Grid>
                                <Grid xs={6} item>
                                  <Rating
                                    name="size-large"
                                    sx={{
                                      fontSize: '18px',
                                      display: 'flex',
                                      justifyContent: 'flex-end',
                                      alignItems: 'flex-end',
                                    }}
                                    value={rating.rating}
                                    onChange={(event, newValue) => {
                                      setRatingsID(newValue)
                                    }}
                                    precision="0.5"
                                    disabled={rating}
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 1 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                </Grid>
                                <Grid
                                  xs={0.5}
                                  item
                                  display={'flex'}
                                  justifyContent="center"
                                  alignItems="center"
                                >
                                  <img src={locationFeedBack} height="16px" />
                                </Grid>
                                <Grid xs={11.5} item>
                                  <Typography
                                    sx={{
                                      fontSize: '14px',
                                      fontWeight: '400',
                                      color: '#212121',
                                      paddingLeft: '3px',
                                    }}
                                  >
                                    {`${rating.floor?.name} | ${rating?.building?.name} `}
                                  </Typography>
                                </Grid>
                                <Grid
                                  xs={12}
                                  item
                                  display={'flex'}
                                  justifyContent="flex-end"
                                >
                                  <Typography
                                    sx={{
                                      fontSize: '12px',
                                      fontWeight: '400',
                                      color: '#BABABA',
                                    }}
                                  >
                                    {handleTicketDuration(rating?.updatedAt)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Box>
                        )
                      })}
                    </>
                  ) : (
                    <>
                      <Card>
                        <Grid item container xs={12} p={1} spacing={2}>
                          <Grid
                            item
                            xs={12}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box>
                              {' '}
                              <img src={HomeStar} />
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <Typography
                                fontSize={'16px'}
                                fontStyle="italic"
                                color="#212121"
                                align="center"
                              >
                                {' '}
                                You have not rated any areas yet!
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Card>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress color="inherit" />
                  </Box>
                </>
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </>
  )
}

export default FeedbackHome
