import React from 'react'
import { styled } from '@mui/system'

export const ModalStyle={
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    outline:'none',
    p: 4,
    borderRadius:'20px'
}
