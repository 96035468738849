import axios from 'axios'
import { setCompFb } from './CompSliceFb'
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { GET, POST } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'

export const CREATE_RATING='CREATE_RATING'
export const ALL_FEEDBACK='ALL_FEEDBACK'
export const USER_RATINGS='USER_RATINGS'
export  const CLEAR_SELECTED_RATING = "CLEAR_SELECTED_RATING"
export const GET_FIVE_POPULAR_AREAS = "GET_FIVE_POPULAR_AREAS"


const initialState={
    isLoaded:false,
    ratings:[],
    topRatings: [],
    selectedRating:{
        rating:{},
        isLoaded:false
    }
}

export const startGetAllFeedback=createAsyncThunk('fb/startGetAllFeedback',async({buildingId})=>{
    try{
        const response=await GET(`fb/v1/ratings?buildingId=${buildingId}`)
        if(response.status===200){
            return response.data
        }
        else {
            errorModal()
        }
    }
    catch{
        errorModal()
    }
})


export const startCreateRating=createAsyncThunk('fb/startCreateRating',async({feedback,handleModal})=>{
    try{
        const response=await POST(`fb/v1/rating`,feedback)
        if(response.status===201){
            handleModal()
            // return response.data
        }
        else {
           errorModal()
        }
    }
    catch(err){
        errorModal()
        
    }
})

export const startUserRating=createAsyncThunk('fb/startUserRating',async({ratingId,buildingId},thunkAPI)=>{
    try{
        const response=await GET(`fb/v1/rating/${ratingId}?buildingId=${buildingId}`)
        if(response.status===200){
            thunkAPI.dispatch(setCompFb("SelectedRating"))
            return response.data
        }
        else {
           errorModal()
            
        }
    }
    catch(err){
        errorModal()

    }
})


export const clearSelectedRating = () => {
    return {
        type : CLEAR_SELECTED_RATING
    }
}



export const startGetFiveRatings=createAsyncThunk('fb/startFiveRatings',async({buildingId})=>{
    try{
        const response=await GET(`fb/v1/areas?buildingId=${buildingId}&limit=5`)
        if(response.status===200){
            return response.data
        }
        else {
            errorModal()
        }
    }
    catch(err){
        errorModal()
    }
})




const fbSlice=createSlice({
    name:'fbSlice',
    initialState,
    reducers:{

    },
    extraReducers(builder){
        builder.addCase(startGetAllFeedback.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.ratings=action.payload?.reverse()
                state.isLoaded=true
            }
        })
        builder.addCase(startGetFiveRatings.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.topRatings=action.payload
                state.isLoaded=true
            }
        })
        builder.addCase(startUserRating.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.selectedRating={rating:action.payload,isLoaded:true}
            }
        })
        // builder.addCase(startCreateRating.fulfilled,(state,action)=>{

        // })
    }
})

export default fbSlice.reducer
