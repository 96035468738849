import React, { useEffect } from 'react'
import { Box, Typography, Grid, CircularProgress } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import Header from './Header'
import locationPin from '../../../Assets/Images/locationPin.png'
import { fetchLocations, setSelectedLocation } from '../../../Redux/ReduxSlice/deskBooking/bookingLocation'
import { startGetUpcomingBookings } from '../../../Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice'
import moment from 'moment'

function Location() {
  const locations = useSelector((state) => state?.buildings?.locations?.locations);

  const locationsIsLoaded = useSelector(
    (state) => state?.buildings?.locations?.locationsIsLoaded,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchLocations())
    const body = {
      currentDate: moment().format('YYYY-MM-DD'),
    }
    dispatch(startGetUpcomingBookings({ body }))
  }, [])

  const history = useHistory()

  const handleClick = (location) => {
    dispatch(setSelectedLocation(location))
    history.push('/deskbooking/building')
  }

  return (
    <Box>
      <Header title="Select Location" />
      <Box p={2}>
        <Box
          p={2}
          pb={0}
          display="flex"
          justifyContent="center"
          backgroundColor="#FFFFFF"
        >
          <Grid container spacing={2} borderRadius="10px">
            <Grid item xs={12}>
              <Typography
                fontWeight={'bold'}
                align="center"
                fontSize="14px"
                variant="h6"
                gutterBottom
              >
                Select the location of your visit
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{ textAlign: 'center', fontWeight: 'bold' }}
            >
              {locationsIsLoaded ? (
                locations.length > 0 ? (
                  locations?.map((location) => (
                    <Grid
                      key={location?._id}
                      item
                      xs={12}
                      height="70%"
                      onClick={() => {
                        handleClick(location)
                      }}
                      pb={2}
                      backgroundColor="#FFFFFF"
                    >
                      <img
                        src={locationPin}
                        srcSet={locationPin}
                        height="100%"
                        width="100%"
                        style={{ borderRadius: '5px' }}
                      />

                      <Box>
                        <Typography fontSize="14px" variant="h6">
                          {location.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    minHeight="20vh"
                    alignItems="center"
                    justifyContent={'center'}
                  >
                    No locations found!
                  </Box>
                )
              ) : (
                <Box display="flex" width="100%" justifyContent={'center'}>
                  <CircularProgress color="inherit" />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}
export default Location
