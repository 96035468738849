import React from 'react'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { IconButton, Typography, Grid } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import qr from '../../../Assets/Images/qr.png'
import back from '../../../Assets/Images/left-arrow.png'
import { useDispatch, useSelector } from 'react-redux';
import { resetMeetingLocalState } from '../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice';
import { assetSetComp } from '../../../Redux/ReduxSlice/AssetManagement/assetCompSlice';
import { setFeature } from '../../../Redux/ReduxSlice/AssetManagement/assetSlice';

const MeetingRoomHeader = (props) => {
    const comp=useSelector(state=>state.assetComp?.title)
    const history = useHistory()
    const dispatch=useDispatch()
    const location = useLocation()
    const viewAll=useSelector(state=>state.redirection?.viewAll)
    const { title, link, title2, title2Logo, trashLogo, handleDeleteClick, eventId } = props
    const handleClick = () => {
       if(history.location.pathname==='/meetingrooms/update'){
            history.push('/meetingrooms/meeting')
       }
       else if(history.location.pathname==='/meetingrooms/meetings'){
            history.push('/')
       }
       else if(history.location.pathname==='/meetingrooms/available'){
        history.push('/meetingrooms/booking')
       }
       else if(history.location.pathname==='/meetingrooms/meeting'){
        if(viewAll){
            history.push('/notifications')
        }
        else {

            history.push('/meetingrooms/meetings')
        }
       }
       else if(comp==='AssetTransfer'){
            dispatch(assetSetComp('assetQrCodeReader'))
       }
       else {
        history.push('/')
       }
    }
    const handleHomeClick = () => {
        history.push('/')
    }

    const handleQrClick=()=>{
        dispatch(setFeature('transfer'))
        dispatch(assetSetComp('assetQrCodeReader'))
    }
    return (
        <Grid container spacing={1} display='flex' alignItems={'center'} height={70} backgroundColor='#FF6520' p={2} >
            {!title2 && <Grid item xs={1.2} >
                <IconButton size='small' float='left' onClick={handleClick}>
                    <img src={back} width={'24px'} />
                </IconButton>

            </Grid>}
            <Grid item xs={!trashLogo ? 9.8 : 8.8} >
                <Typography fontSize={20} align='left' color='#FFFFFF'>{title}</Typography>
            </Grid>
            
                

            {/* {location.pathname === '/meetingrooms/booking' && (
                <Grid item xs={1} >
                    <IconButton p={0} m={0} onClick={handleHomeClick}><HomeIcon /></IconButton>
                </Grid>
            )}
            {location.pathname === '/meetingrooms/meetings' && (
                <Grid item xs={1} >
                    <IconButton p={0} m={0} onClick={handleHomeClick}><HomeIcon /></IconButton>
                </Grid>
            )} */}
            {title2Logo &&
                <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton>
                        <img src={title2Logo} />
                        <Typography align='center' fontSize={15} >{title2}</Typography>
                    </IconButton>
                </Grid>}
            {/*   */}
        </Grid>
    )
}

export default MeetingRoomHeader