
import { styled } from '@mui/system'
import { Box , Paper} from '@mui/material';
import { AddBox } from '@material-ui/icons';
import { useSelector } from 'react-redux';


export const imageStyle={
    width: "100%", 
    height: "50%",
    objectFit: 'cover',
  
  }

  export const BackButtonStyle={
    position: 'absolute',
    // bottom:'4em',
    // right:'4em',
    top:'2em',
    height:'18px',
    borderRadius:'1.5em',
    color:'white',
    textTransform:'uppercase',
    padding:'1em 1.5em',
    
    display:'flex',
    alignItems:'center',
    
  }
export const ItemCard = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


// export const MuiButton = styled(Box)(({ theme }) => ({
//     padding: theme.spacing(0),
//     height : "200%",
//     width : "90%",
//     backgroundColor: "#f2f2f2",
//     display: 'flex',
//     justifyContent : "center" ,
//     alignItems : "center",
//     borderRadius : "5px"
// }))

// export const FloorButton = styled(Box)(({ theme }) => ({
//     padding: theme.spacing(0),
//     border : "1px solid red"
// }))

export const MuiCard = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    height:"50px",
    width:"100%",
    border:"1px solid black",
    borderRadius: "5px",
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
}))

export const SelectedBox=styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    height:"50px",
    width:"100%",
    border:"1px solid black",
    borderRadius: "5px",
    display:"flex",
    justifyContent:"center",
    alignItems: "center"
}))
export const MuiBoxBottom = styled(Box)(({ theme }) => ({
    height:"100%",
     width: "100%",
     border: "1px solid black",
    borderRadius: "5px",
}))

export const ModalStyle = {
    position: "absolute",
    // border:"1px solid black",
    top: "50%",
    // height: "50%",
    // bottom: -10,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    outline: "0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
    height:'290px'
    // pl: 2,
    //m:1
};