import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff'
import React, { useEffect, useState } from 'react'
import Carousel from 'react-material-ui-carousel'
import { useDispatch, useSelector } from 'react-redux'
import {
  setFeature,
  startFetchAssets,
  startFetchIncomingTransits,
  startFetchOutgoingTransits,
  startFetchTransit,
} from '../../Redux/ReduxSlice/AssetManagement/assetSlice'
import {
  BoxSx3,
  GridSx,
  GridSx1,
  TypoSx,
} from '../../UI/FaultReporting/Styles/ReportStyles'
import { BrokenImageBox } from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles'
import BrokenImage from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import { styled, useTheme } from '@material-ui/styles'
import { TabContext, TabList } from '@mui/lab'
import brokenIcon from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import moment from 'moment'
import { assetSetComp } from '../../Redux/ReduxSlice/AssetManagement/assetCompSlice'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { AssetModalData } from '../../UI/AssetManager/AssetModalData'

const AssetList = () => {
  const imageLink = useSelector((state) => state?.config?.imageLink)

  const bid = useSelector((state) => state.user?.data?.buildingId)
  const history = useHistory()
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedAsset, setSelectedAsset] = useState({})
  const userId = useSelector((state) => state?.user?.data?.id)
  const userBuilding = useSelector((state) => state?.user?.data?.buildingId)
  const userName = useSelector((state) => state?.user?.data?.fullName)
  const assetData = useSelector((state) => state?.assetData?.assetData)
  const outgoingTransits = useSelector(
    (state) => state?.assetData?.outgoingAssets?.data,
  )
  const incomingTransits = useSelector(
    (state) => state?.assetData?.incomingAssets?.data,
  )
  const incomingTransitsIsLoaded = useSelector(
    (state) => state?.assetData?.incomingAssets?.isLoaded,
  )
  const outgoingTransitsIsLoaded = useSelector(
    (state) => state?.assetData?.outgoingAssets?.isLoaded,
  )
  const [value, setValue] = useState('1')
  const [transit, setTransit] = useState([])
  const isLoaded = useSelector((state) => state?.assetData?.isLoaded)

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }))

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    paddingLeft: '20px',

    outline: 'none',
    borderRadius: '10px',
  }

  const handleSelectedAssset = (asset) => {
    setSelectedAsset(asset)
    setModalOpen(true)
  }

  const handleModalClose = () => {
    setSelectedAsset({})
    setModalOpen(false)
  }
  const handleTabChange = (e, val) => {
    setValue(val)
  }
  const handleNavigate = () => {
    dispatch(setFeature('checkin'))
    dispatch(assetSetComp('assetQrCodeReader'))
  }
  const handleTransitClick = (id) => {
    dispatch(startFetchTransit({ transitId: id, handleNavigate }))
    // dispatch(setFeature('checkin'))
  }
  const handleStatus = (status) => {
    const backgroundColor =
      status === 'Overdue' ||
      status === 'In Progress(Overdue)' ||
      status === 'Scheduled(Overdue)'
        ? '#FF4E5E'
        : status === 'Expired'
        ? '#FF4E5E'
        : status === 'Scheduled'
        ? '#A0E400'
        : '#A0E400'
    return (
      <Chip
        label={status ? status : 'NA'}
        variant="outlined"
        sx={{ border: `solid 1px ${backgroundColor}`, height: 20 }}
        style={{ backgroundColor: backgroundColor, color: '#FFFFFF' }}
      />
    )
  }

  useEffect(() => {
    if (!bid || !userId) {
      history.push('/')
    }
    dispatch(startFetchAssets({ userId, bid }))
  }, [])

  useEffect(() => {
    if (value === '3') {
      dispatch(startFetchOutgoingTransits(userBuilding))
    }
    if (value === '2') {
      dispatch(startFetchIncomingTransits(userBuilding))
    }
  }, [value])

  const AssetDisplay = ({ assets, status }) => {
    const finalAssets = assets.length > 2 ? assets.slice(0, 2) : assets
    const remainingLength = assets.length - finalAssets.length
    return (
      <>
        {finalAssets.map((asset) => {
          return (
            <Grid item container xs={12} spacing={1} my={1}>
              <Grid item xs={4}>
                <Box
                  sx={{ height: '100%', display: 'flex', alignItems: 'center' }}
                >
                  <Box
                    sx={{
                      backgroundColor: '#D9D9D9',
                      borderRadius: '10px',
                      height: '4vh',
                      width: '70%',

                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {asset.imageLinks.length > 0 ? (
                      <>
                        <img
                          src={`${imageLink}${asset.imageLinks[0]}`}
                          height={'100%'}
                          width={'100%'}
                        />
                      </>
                    ) : (
                      <BrokenImageBox>
                        <img
                          src={brokenIcon}
                          height={'12px'}
                          style={{ objectFit: 'cover' }}
                        />
                      </BrokenImageBox>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                container
                xs={8}
                display={'flex'}
                alignItems={'center'}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="caption"
                    fontSize={'14px'}
                    color="text.primary"
                  >
                    {asset?.assetName}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: 12.5,
                      fontFamily: "'roboto','Open sans','Helvetica'",
                      minWidth: '65vw',
                    }}
                    color="text.secondary"
                  >
                    {asset.description}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        <Grid container>
          <Grid item xs={6}>
            {remainingLength > 0 && (
              <Box sx={{ pl: 1 }}>
                <Typography>{`+ ${remainingLength} more`}</Typography>
              </Box>
            )}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Typography
              sx={{ color: status === 'in transit' ? '#ffb234' : '#34b233' }}
            >
              {status === 'in transit'
                ? 'In Transit'
                : status === 'completed'
                ? 'Transfer completed!'
                : ''}
            </Typography>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <Box width="100%">
      {isLoaded ? (
        <>
          <Grid item container spacing={1}>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width={'100%'}
            >
              <Typography sx={{ fontWeight: 550, fontSize: '16px' }}>
                Equipments and Assets issued to
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width={'100%'}
            >
              <Typography sx={{ fontWeight: 600, fontSize: '22px' }}>
                {userName}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display={'flex'}
              justifyContent="center"
              alignItems="center"
              width={'100%'}
            >
              <Avatar
                sx={{
                  backgroundColor: '#FFFFFF',
                  border: 'solid 1px #000000',
                  width: 50,
                  height: 50,
                }}
              >
                <Typography sx={{ color: '#000000', fontSize: '16px' }}>
                  {assetData.length || '0'}
                </Typography>
              </Avatar>
            </Grid>
          </Grid>

          <Box width={'100%'}>
            <TabContext
              value={value}
              TabIndicatorProps={{ style: { color: '#212121' } }}
            >
              <BoxSx3>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                  textColor="#212121"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: '#D97D54',
                      marginBottom: 4,
                    },
                  }}
                >
                  <Tab
                    style={{ textTransform: 'capitalize' }}
                    label="Tagged Assets"
                    value="1"
                    sx={{
                      color: value !== '1' ? '#4B4B4B' : '#212121',
                      fontSize: '16px',
                      fontWeight: value == '1' ? 'bold' : 'light',
                      typography: { fontSize: '14px' },
                    }}
                  />
                  <Tab
                    style={{ textTransform: 'capitalize' }}
                    label="Incoming Transits"
                    value="2"
                    sx={{
                      color: value !== '2' ? '#4B4B4B' : '#212121',
                      fontSize: '16px',
                      fontWeight: value == '2' ? 'bold' : 'light',
                      typography: { fontSize: '14px' },
                    }}
                  />
                  <Tab
                    style={{ textTransform: 'capitalize' }}
                    label="Outgoing Transits"
                    value="3"
                    sx={{
                      color: value !== '3' ? '#4B4B4B' : '#212121',
                      fontSize: '16px',
                      fontWeight: value == '3' ? 'bold' : 'light',
                      typography: { fontSize: '14px' },
                    }}
                  />
                  {/* {admin&&<Tab style={{ textTransform:'capitalize'}} label="All Tickets" value="2" sx={{color:value!=='2'?'#4B4B4B':'#212121', fontSize:'16px',fontWeight:value=='2'?'bold':'light'}} />} */}
                </TabList>
              </BoxSx3>
              {value === '1' ? (
                assetData.length > 0 ? (
                  <Box marginTop={2}>
                    <Grid container spacing={2} paddingLeft={2}>
                      <Box marginTop={2}>
                        {assetData.map((ele) => (
                          <Box
                            paddingY={3}
                            borderTop="solid 1px #D3D3d3"
                            borderBottom={'solid 1px #D3D3d3'}
                          >
                            <Grid
                              item
                              container
                              xs={12}
                              onClick={() => handleSelectedAssset(ele)}
                              spacing={2}
                            >
                              <Grid item xs={4}>
                                {ele.imageLinks.length ? (
                                  <Box
                                    display="flex"
                                    height="100%"
                                    alignItems={'center'}
                                  >
                                    <Carousel
                                      autoPlay={true}
                                      interval={2000}
                                      duration={1500}
                                      navButtonsAlwaysInvisible
                                      stopAutoPlayOnHover={false}
                                      animation="slide"
                                      sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                      indicators=""
                                    >
                                      {ele.imageLinks?.map((el) => (
                                        <Box>
                                          <Box
                                            display="flex"
                                            alignItems={'center'}
                                            height="100%"
                                            justifyContent={'center'}
                                            width="100%"
                                          >
                                            <img
                                              src={`${imageLink}${el}`}
                                              style={{ objectFit: 'cover' }}
                                              width={100}
                                            />
                                          </Box>
                                        </Box>
                                      ))}
                                    </Carousel>
                                  </Box>
                                ) : (
                                  <Box
                                    sx={{
                                      height: '100%',
                                      display: 'flex',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        backgroundColor: '#D9D9D9',
                                        borderRadius: '10px',
                                        height: '8vh',
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <BrokenImageBox>
                                        <img
                                          src={BrokenImage}
                                          height={'10px'}
                                          style={{ objectFit: 'cover' }}
                                        />
                                      </BrokenImageBox>
                                    </Box>
                                  </Box>
                                )}
                              </Grid>
                              <Grid item container xs={8}>
                                <Grid item xs={12}>
                                  <Typography variant={'h6'}>
                                    {ele.assetName}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Box>
                                    <TypoSx variant="caption">{`serial no - ${ele.serialNumber}`}</TypoSx>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} container>
                                  <Grid item xs={6}>
                                    <TypoSx variant="caption">
                                      {' '}
                                      Maintenance status
                                    </TypoSx>
                                  </Grid>
                                  <Grid item xs={6} display="flex" pl={1}>
                                    <Box>
                                      {handleStatus(ele.maintenanceStatus)}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Modal
                              open={modalOpen && selectedAsset._id === ele._id}
                              onClose={handleModalClose}
                            >
                              <AssetModalData
                                selectedAsset={selectedAsset}
                                ele={ele}
                              />
                            </Modal>
                          </Box>
                        ))}
                      </Box>
                    </Grid>
                  </Box>
                ) : (
                  <Box marginTop={2} flex alignContent={'center'} py={'50%'}>
                    <Stack alignItems={'center'}>
                      <Typography style={{ textAlign: 'center' }}>
                        No assets tagged to you!
                      </Typography>
                    </Stack>
                  </Box>
                )
              ) : value === '3' ? (
                outgoingTransits.length > 0 ? (
                  <Box mt={2}>
                    {outgoingTransits.map((ele) => {
                      return (
                        <Box
                          paddingY={1}
                          borderTop="solid 1px #D3D3d3"
                          borderBottom={'solid 1px #D3D3d3'}
                          onClick={() => handleTransitClick(ele?._id)}
                        >
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'flex-end',
                              }}
                            >
                              <Typography>
                                {moment(ele.initiatedOn).format('YYYY-MM-DD')}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Box pl={1}>
                            <Typography fontSize={'12px'}>Assets :</Typography>
                          </Box>
                          {ele.assets?.length > 0 && (
                            <AssetDisplay
                              assets={ele.assets}
                              status={ele.status}
                            />
                          )}
                        </Box>
                      )
                    })}
                  </Box>
                ) : (
                  <Box marginTop={2} flex alignContent={'center'} py={'50%'}>
                    <Stack alignItems={'center'}>
                      <Typography style={{ textAlign: 'center' }}>
                        No out-going transits!
                      </Typography>
                    </Stack>
                  </Box>
                )
              ) : value === '2' ? (
                <>
                  <Box mt={2}>
                    {incomingTransitsIsLoaded && incomingTransits.length > 0 ? (
                      incomingTransits.map((ele) => {
                        return (
                          <Box
                            paddingY={1}
                            borderTop="solid 1px #D3D3d3"
                            borderBottom={'solid 1px #D3D3d3'}
                            onClick={() => handleTransitClick(ele?._id)}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={12}
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'flex-end',
                                }}
                              >
                                <Typography>
                                  {moment(ele.initiatedOn).format('YYYY-MM-DD')}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Box pl={1}>
                              <Typography fontSize={'12px'}>
                                Assets :
                              </Typography>
                            </Box>
                            {ele.assets?.length > 0 && (
                              <AssetDisplay
                                assets={ele.assets}
                                status={ele.status}
                              />
                            )}
                          </Box>
                        )
                      })
                    ) : (
                      <Box
                        marginTop={2}
                        flex
                        alignContent={'center'}
                        py={'50%'}
                      >
                        <Stack alignItems={'center'}>
                          <Typography style={{ textAlign: 'center' }}>
                            No incoming assets!
                          </Typography>
                        </Stack>
                      </Box>
                    )}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </TabContext>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: '20vh',
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default AssetList
