import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import AssetList from '../../components/AssetManager/AssetList'
import AssetMain from '../../components/AssetManager/AssetMain'
import Header from '../../components/MeetingRooms/Header/Header'
import ComingSoon from '../MeetingRooms/ComingSoon'


const AssetManager=(props)=>{
    const comp=useSelector(state=>state?.assetComp?.title)
    return (
    <Box   backgroundColor='#FFFFFF' minHeight={'100vh'}>
         {comp!=='AssetInfo'&&<Header title='Asset Manager'  />}
         <Box p={comp==='AssetInfo'?0:2}>
                {/* <ComingSoon /> */}
                <AssetMain />
                {/* <AssetList /> */}
         </Box>
         
    </Box>
    )
}

export default AssetManager