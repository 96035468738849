import { UPDATE_AQI_DATA } from "../../ReduxSlice/Common/aqiAction";

const initialState={
    isLoaded: false,
	error: "",
	data: []
}

const aqiReducer=(state=initialState,action)=>{
    switch(action.type){
        case UPDATE_AQI_DATA: {
			const newData = []
			action.payload.forEach(feature => {
				const newFeature = {}
				newFeature.name = feature.air_pressure ? "Air Pressure" :
					feature.co2 ? "CO2" :
						feature.humidity ? "Humidity" :
							feature.illuminance ? "Illuminance" :
								feature.radon ? "Radon" :
									feature.temperature ? "Temperature" :
										feature.voc ? "TVOC" : ""
				newFeature.value = feature.co2 || feature.temperature || feature.illuminance || feature.air_pressure || feature.humidity || feature.radon || feature.voc || 'NA'
				newFeature.area = feature.area
				newFeature.floor = feature.floor
				newFeature.sensorId = feature.sensorId
				newFeature.units = feature.units
				newData.push(newFeature)
			})
			return { ...state, isLoaded: true, data: newData }
		}
        default:{
            return {...state}
        }
    }
}

export default aqiReducer