import { Grid, Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useDispatch } from 'react-redux'
import Popup from './Popup'
const MeetingViewDeskBooking = (props) => {
    const { timeRange, handleRedirect, floor, meetingName, room, guests, isPopup, id } = props
    const handleDelete = (event, id) => {
        event.stopPropagation()

    }
    return (
        <Paper sx={{ borderRadius: '10px', boxShadow: 0, border: '1px solid #02B9A4' }} >
            <Grid container>
                <Grid item xs={0.5} backgroundColor='#02B9A4' m={0} p={0} borderRadius='10px'></Grid>
                <Grid item xs={10.5} px={1} py={1} onClick={() => handleRedirect(id)}>
                    <Box display={'flex'} justifyContent={'space-between'} m={0} p={0}>
                        <Typography variant='caption'>{timeRange}</Typography>
                        <Typography variant='caption' sx={isPopup ? { paddingLeft: "45%" } : ''}>{floor} </Typography>
                    </Box>
                    <Typography >{meetingName}</Typography>
                    <Typography variant='caption' >{`${room} | ${guests} Guests`}</Typography>
                </Grid>
                <Grid item display="flex" xs={1} m={0} p={0} pt={1} justifyContent="flex-end">
                    {isPopup && <Popup id={id} handleDelete={handleDelete} />}
                </Grid>
            </Grid>
        </Paper>
    )
}

export default MeetingViewDeskBooking