import { Container } from '@mui/material'
import React from 'react'
import AvailableMeetingRoom from '../../components/MeetingRooms/Container/Panel/AvailableMeeting'

const AvailableMeeting = (props) => {
    return (
        <>
            <AvailableMeetingRoom />
        </>
    )
}

export default AvailableMeeting