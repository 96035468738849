import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { FRGET, GET, POST, PUT } from '../../../Helpers/http'
import moment from 'moment'
import swal from 'sweetalert'
import { errorModal } from '../../../Helpers/ErrorModal'

const initialState = {
  isBooked: false,
  todaysBookings: [],
  upcomingBookings: [],
  upcomingBookingsIsLoaded: false,
  data: [],
}

export const startSaveBooking = createAsyncThunk(
  'desks/startSaveBooking',
  async ({ newBooking, handleRedirect }) => {
    try {
      const response = await POST(`db/v1/booking`, newBooking)
      if (response.status === 201) {
        const result = response?.data
        handleRedirect(true)
        return result
      } else {
        console.log({ response })
      }
    } catch(err){
        errorModal()
    }
  },
)

export const startGetUpcomingBookings = createAsyncThunk(
  'desks/startGetUpcomingBookings',
  async ({ body,decodedCode,handleLoader }) => {
    try {
      const response = await GET(
        `db/v1/bookings?upcoming=true&date=${body.currentDate}`,
        decodedCode
      )
      if (response.status === 200) {
        const result = response.data
        handleLoader && handleLoader(false)
        return result
      }else{
        console.log({response})
      }
    } catch (err) {
      console.log(err)
    }
  },
)

export const startCancelMeeting = createAsyncThunk(
  'desks/startCancelMeeting',
  async ({id,handleLoader}) => {
    
    try {
      const response = await PUT(`db/v1/booking/${id}`,{ status: false })
      console.log(response, "cancel booking")
      if (response.status === 200) {
        const result = response.data
        swal('Booking has been cancelled!', {
          icon: 'success',
        })
        handleLoader(false)
        return result
      }
      else{
        console.log({response})
      }
    } catch (err) {
      console.log('error', err)
    }
  },
)

export const startCheckinMeeting = createAsyncThunk("",async(id) =>{
  try{
    const response = await PUT(`db/v1/booking/${id}`, { checkedIn: true },)
     if(response.status == 200){
      const result = response.data
      return result
     }else{
      console.log({response})
     }
  }
  catch(err){
    console.log("error")
  }
}

)

const bookingCrudOperationsSlice = createSlice({
  name: 'bookingCrudOperationsSlice',
  initialState,
  reducers: {
    saveBooking: (state, action) => {
      state.data.push(action.payload)
      state.isBooked = true
    },
    resetBookingTime: (state, action) => {
      state.selectedDate = moment().format('YYYY-MM-DD')
    },
  },
  extraReducers(builder) {
    builder
      .addCase(startSaveBooking.fulfilled, (state, action) => {
        if(action.payload){
        state.data = action?.payload
        state.isBooked = true
        }
      })
      .addCase(startGetUpcomingBookings.pending, (state) => {
        state.upcomingBookingsIsLoaded = false
      })
      .addCase(startGetUpcomingBookings.fulfilled, (state, action) => {
        if(action.payload){
        state.upcomingBookings = action?.payload
        state.upcomingBookingsIsLoaded = true
        }
      })
      .addCase(startCancelMeeting.fulfilled, (state, action) => {
        if(action.payload){
        const updatedBookings = state.upcomingBookings.filter((ele) => {
          return ele.id !== action?.payload?.id
        })
        state.upcomingBookings = updatedBookings
        }
      })
      .addCase(startCheckinMeeting.fulfilled , (state, action) => {
        if(action.payload){
        const checkInbooking=state.upcomingBookings.map((ele)=>{
                if(ele.id==action?.payload?.id){
                    return {...ele,checkedIn:true}
                }
                else {
                    return {...ele}
                }
            })
            state.upcomingBookings=checkInbooking
        }
      })
  },
})

export const {
  saveBooking,
  resetBookingTime,
  saveTodaysBookings,
} = bookingCrudOperationsSlice.actions
export default bookingCrudOperationsSlice.reducer
