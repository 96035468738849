import { Backdrop, Box, CircularProgress, Grid, Typography } from '@mui/material'
import React, { useEffect , useState} from 'react'
import { lightTheme } from '../../Helpers/ThemeHandler'
import ConfirmButton from '../../UI/DeskBooking/Button/ConfirmButton'
import { locationTitleContainer } from '../../UI/ManageVistor/Styles/VisitLocationStyle'
import CardComp from '../../UI/MeetingRooms/Card/CardComp'
import buildings from '../../Assets/Images/buildings.jpeg'
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker'
import { useDispatch, useSelector } from 'react-redux'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'
import {
  resetBuildingLocation,
  resetVisitorDetails,
  setSelectedLocation,
  setSelectedVisitLocation,
  startGetAllBuildings,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import BottomButton from '../../UI/ManageVistor/Button/BottomButton'

const VisitLocation = () => {
  const dispatch = useDispatch()
  // const locationsIsloaded=useState(true)
      // const [selected, setSelected] = useState({ location: {}, building: {} });

  const locations = useSelector((state) => state?.visitData?.locations?.data)
  const selectedLocation = useSelector((state) => state?.visitData?.selectedLocation)
    const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);

  console.log("selectttt",selectedLocation)

  const locationsIsLoaded = useSelector(
    (state) => state.visitData?.locations?.locationsIsLoaded,
  )

 const handleDisabled = () => {
   return !(Object.keys(selectedLocation?.building).length>0)
}

  const handleLocationClick = (ele) => {
    dispatch(setSelectedVisitLocation(ele))
  }
  const handleNextClick = () => {
    dispatch(setVisitorComp('VisitDate'))
  }

  useEffect(() => {
    if (locationsIsLoaded && locations && locations.length > 0) {
      setSelectedLocation(locations[0]);
      setSelectedLocationIndex(0);
      dispatch(setSelectedVisitLocation(locations[0]));
    }
  }, [locationsIsLoaded, locations]);

  useEffect(() => {
    dispatch(startGetAllBuildings())
    // return () => {
    //    resetVisitorDetails()
    //  }
  }, [])

  // useEffect(() => {
  //   dispatch(resetVisitorDetails())
  // },[])

  return (
    <Box>
      <Box style={locationTitleContainer}>
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          Select the location of your visit.
        </Typography>
      </Box>
      <Box marginTop={'16px'}>
        <Grid item container spacing={2}>
          {locationsIsLoaded ? (
            locations.map((ele) => {
              return (
                <Grid item key={ele._id} xs={locations.length > 1 ? 6 : 12}>
                  <Box
                    p={1}
                    style={{
                      backgroundColor:
                        ele.location === selectedLocation?.location
                          ? '#DDDDDD'
                          : 'inherit',
                      borderRadius: '16px',
                    }}
                    boxShadow={
                      ele.location === selectedLocation?.location ? 2 : 0
                    }
                  >
                    <Box onClick={() => handleLocationClick(ele)}>
                      <CardComp height={15} border={'16'}>
                        <Box
                          style={{
                            backgroundImage: `url(${buildings})`,
                            backgroundSize: 'cover',
                            height: '100%',
                            width: '100%',
                          }}
                        ></Box>
                      </CardComp>
                    </Box>
                    <Typography
                      fontSize={'14px'}
                      fontWeight={'600'}
                      textAlign={'center'}
                    >
                      {ele.name}
                    </Typography>
                    <Typography fontSize={'12px'} textAlign={'center'}>
                      {ele.location?.name}
                    </Typography>
                  </Box>
                </Grid>
              )
            })
          ) : (
               <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          // open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            // <Typography
            //   sx={{
            //     display: 'flex',
            //     alignItems: 'center',
            //     justifyContent: 'center',
            //   }}
            // >
            //   Loading...
            // </Typography>
          )}
        </Grid>
      </Box>
      <BottomButton
        disabled={handleDisabled()}
        onclick={handleNextClick} />
    </Box>
  )
}

export default VisitLocation
