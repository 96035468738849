import { Box, Grid, Stack, Typography } from '@mui/material'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { locationTitleContainer } from '../../UI/ManageVistor/Styles/VisitLocationStyle'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined'
import ConfirmButton from '../../UI/DeskBooking/Button/ConfirmButton'
import BottomButton from '../../UI/ManageVistor/Button/BottomButton'
import CardComp from '../../UI/MeetingRooms/Card/CardComp'
import SuccessModal from '../../UI/AssetManager/SuccessModal'
import { useState } from 'react'
import { useEffect } from 'react'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'
import { resetVisitorDetails, startSaveAppointments } from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import OverlayLoader from '../../UI/Common/Loader/OverlayLoader'

const ConfirmVisitDetails = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loader,setLoader]=useState(false)
  const dispatch = useDispatch()
  const { visitorsInfo, visitDateTime } = useSelector(
    (state) => state?.visitData,
  )
  const selectedLocation = useSelector(
    (state) => state.visitData?.selectedLocation,
  )
  console.log(selectedLocation,"selectedLocationsss")
  const visitorBySearch = useSelector(
    (state) => state?.visitData?.visitorSearch,
  )
  const appointments = useSelector(
    (state) => state.visitData?.allAppointments?.appointments,
  )

  const invitedVisitors = useSelector(state => state.visitData?.visitorsInfo?.visitors)

  const handleVisitors = () => {
    const visitorNames = invitedVisitors.map((ele => ele.name))
    return visitorNames.join(', ')
  }
  // console.log('appointments', visitDateTime)
  const visitInfo = [
    {
      title: 'Visiting',
      data: `${selectedLocation?.building?.name}, ${selectedLocation?.location?.name}`,
      icon: <LocationOnOutlinedIcon />,
    },
    {
      title: 'At',
      data: `${moment(new Date(visitDateTime?.startTime)).format(
        'DD MMM YYYY hh:mm A',
      )}`,
      icon: <AccessTimeOutlinedIcon />,
    },
    {
      title: 'To meet',
      data: handleVisitors(),
      icon: <PermIdentityOutlinedIcon />,
    },
    {
      title: 'Purpose of visit',
      data: `${visitorsInfo?.purpose}`,
      icon: <TopicOutlinedIcon />,
    },
  ]
  
  const handleModal = () => {
    setLoader(false)
      setModalOpen(true)
  }
  const handleFail=()=>{
    setLoader(false)
    alert('Oops something went wrong!')
  }
  const handleConfirm = () => {
    let invitedVisitorsId=[]
   invitedVisitors.forEach(ele => {
      invitedVisitorsId.push({visitor:ele?._id})
    })
     const body = {
        location: selectedLocation?.location?._id || '',
        building: selectedLocation.building?._id || '',
        startTime: visitDateTime?.startTime || '',
        endTime: visitDateTime?.endTime || '',
        purpose: visitorsInfo?.purpose || '',
        visitors:invitedVisitorsId || []
     }
     setLoader(true)
      dispatch(startSaveAppointments({body,handleModal,handleFail}))
  }
     

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        dispatch(setVisitorComp('AllAppointments'))
        dispatch(resetVisitorDetails())
        setModalOpen(false)
          },3000)
      }
  },[modalOpen])
    // }
  

  return (
    <Box style={locationTitleContainer}>
      <CardComp padding={'16px'} border="20">
        <Grid container spacing={3}>
          {visitInfo?.map((ele,index) => {
            return (
              <Grid item xs={12} key={index}>
                <Stack direction={'row'}>
                  {ele.icon}
                  <Box pl={1}>
                    <Typography style={{ fontSize: '16px', fontWeight: '600' }}>
                      {ele?.title}
                    </Typography>
                  </Box>
                </Stack>
                <Box pl={3.5}>
                  <Typography style={{ fontSize: '16px' }}>
                    {ele?.data}
                  </Typography>
                </Box>
              </Grid>
            )
          })}
        </Grid>
      </CardComp>
      <BottomButton title="Confirm" onclick={ handleConfirm} />
      <SuccessModal
        open={modalOpen}
        message={
          'Your invite has been successfully shared with the invitees on their respsective mails!'
        }
      />
      <OverlayLoader open={loader}/>
    </Box>
  )
}

export default ConfirmVisitDetails
