
import axios from 'axios'
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { GET, POST, PUT } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'
export const GET_ALL_TICKETS = 'GET_ALL_TICKETS'
export const CREATE_TICKET = 'CREATE_TICKET'
export const SELECTED_TICKET = 'SELECTED_TICKET'
export const ACCEPT_TICKET = 'ACCEPT_TICKET'
export const CLEAR_SELECTED_TICKET = 'CLEAR_SELECTED_TICKET'

const initialState={
    tickets:[],
    isLoaded:false,
    selectedTicket:{
        ticket:{},
        isLoaded:false,
    }
}  


export const startGetAllTickets=createAsyncThunk('tickets/startGetAllTickets',async({bid,decodedCode,popUp})=>{
    try{
        const response=await GET(`fr/v1/faultreports?buildingId=${bid}`,decodedCode)
        if(response.status===200){
            return response.data
        }
        else {
            errorModal()
        }
    }
    catch(err){

        !popUp&&errorModal()
    }
})


export const startCreateTicket=createAsyncThunk('tickets/startCreateTicket',async({ticket, handleLoad})=>{
    try{
        const response=await POST('fr/v1/faultreport',ticket)
        if(response.status===201){
            handleLoad()
            return response.data
        }
    }
    catch(err){errorModal()
    }
})


export const startGetTicket=createAsyncThunk('tickets/startGetTicket',async({id,bid,handleRedirect})=>{
    try{
        const response=await GET(`fr/v1/faultreport/${id}?buildingId=${bid}`)
        if(response.status===200){
             handleRedirect()
                return response.data
        }
        else {
            errorModal()
        }
    }
    catch(err){errorModal()
    }
})

export const startUpdateFeedback=createAsyncThunk('tickets/startUpdateFeedback',async({id,data, bid,handleModal})=>{
    try{
        const response=await PUT(`fr/v1/faultreport/${id}?buildingId=${bid}`,data)
        if(response.status===200){
            handleModal()
        }
        else{
            errorModal()
        } 
    }
    catch(err){errorModal()
    }
})

//admin api's

export const startAcceptTicket=createAsyncThunk('tickets/startAcceptTicket',async({ticket,bid, toggleAccepted})=>{
    try{
        const response=await POST(`fr/v1/service?buildingId=${bid}`,ticket)
        if(response.status===201){
            toggleAccepted()
            return response.data
        }
    }
    catch{

    }
})



export const startUpdateTicket=createAsyncThunk('tickets/startUpdateTicket',async({ticket,bid, handleModal})=>{
    try{
        const response=await PUT(`fr/v1/service?buildingId=${bid}`,ticket)
        if(response.status===200){
            handleModal()

        }   
        else {
            errorModal()
        }
    }
    catch(err){errorModal()
    }
})



export const ticketSlice = createSlice({
    name: 'ticketSlice',
    initialState,
    reducers: {
       clearSelectedTicket:(state,action)=>{
        state.selectedTicket={ticket:{},isLoaded:false}
       }
      
    },
    extraReducers(builder) {
        builder
        .addCase(startGetAllTickets.pending, (state, action) => {

              state.isLoaded=false
        })
        builder
          .addCase(startGetAllTickets.fulfilled, (state, action) => {
            if(action.payload){
                
                state.tickets = action.payload?.faults
                state.isLoaded=true
            }
          })
          builder.addCase(startCreateTicket.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.tickets=[...state.tickets,action.payload]
            }
          })
          builder.addCase(startGetTicket.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.selectedTicket={ticket:{...action.payload},isLoaded:true}
            }
          })
          builder.addCase(startAcceptTicket.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.selectedTicket={ticket:{...action.payload},isLoaded:true}
            }
          })
          
      }

  })

  export const {clearSelectedTicket}=ticketSlice.actions

  export default ticketSlice.reducer