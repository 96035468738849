import React, { useState } from 'react'
import {
    Box,
    Button,
    Typography,
    Divider,
    CardContent,
    Grid,
    IconButton,
    Badge,
    CircularProgress,
    Backdrop,
} from '@mui/material'
import CardComp from '../../../../UI/MeetingRooms/Card/CardComp'
import GreetingTextWidget from '../../../../UI/MeetingRooms/Widgets/HomePage/GreetingTextWidget'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
    startFetchMeetingById,
    startFetchMeetings,
} from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import moment from 'moment'
import calendar from '../../../../Assets/MeetingRooms/Images/calendar.png'
import { useHistory } from 'react-router-dom'
import {
    startGetAllTickets,
    startGetTicket,
} from '../../../../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import { startGetUpcomingBookings } from '../../../../Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice'
import { setFeature, setTabValue, startFetchIncomingTransits, startFetchTransit } from '../../../../Redux/ReduxSlice/AssetManagement/assetSlice'
import { setComp } from '../../../../Redux/ReduxSlice/FaultReporting/compSlice'
import errorMessage from '../../../../Assets/MeetingRooms/Images/errorMessage.svg'
import workingAtHome from '../../../../Assets/MeetingRooms/Images/workingAtHome.svg'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { setViewAll } from '../../../../Redux/ReduxSlice/Common/RedirectSlice'
import AssetManager from '../../../../Assets/MeetingRooms/Images/assetManager.png'
import Tooltip from '@mui/material/Tooltip'
import { assetSetComp } from '../../../../Redux/ReduxSlice/AssetManagement/assetCompSlice'

const HomeCard = (props) => {
    const bid = useSelector((state) => state?.user?.data?.buildingId)
    const appsConfig = useSelector(state => state?.config)
    const apps = appsConfig?.apps
    const appsIsLoaded = appsConfig?.isLoaded

    const vcardName = useSelector(state => state?.userInfo?.data?.fullName)
    const name = useSelector((state) => state?.user?.data?.fullName)
    const meetings = useSelector((state) => state?.meetings?.list?.data)
    const faultReports = useSelector((state) => state?.faultReports?.tickets)
    const date = moment().format('YYYY-MM-DD')
    const faultReportsIsLoded = useSelector(
        (state) => state?.faultReports?.isLoaded,
    )
    const upcomingBookings = useSelector(
        (state) => state?.bookings?.upcomingBookings,
    )
    const todaysUpcomingBookings = upcomingBookings?.filter(ele => ele?.bookingDate === date)
    const deskUpcomingBookingIsLoaded = useSelector(
        (state) => state?.bookings?.upcomingBookingsIsLoaded,
    )
    const allLoaded = faultReportsIsLoded && deskUpcomingBookingIsLoaded

    const history = useHistory()
    const dispatch = useDispatch()
    const loaded = useSelector((state) => state?.meetings?.list?.isLoading)
    const [allNotifications, setAllNotifications] = useState([])
    const incomingTransits = useSelector(state => state?.assetData?.incomingAssets?.data)?.filter((ele) => ele?.status === 'in transit')
    const [merged, setMerged] = useState(false)
    const arr = [
        'There is nothing impossible to they who will try',
        'Spread love everywhere you go',
        'Belief creates the actual fact',
        "Believe you can and you're halfway there",
        "Try to be a rainbow in someone's cloud",
    ]
    const [randomGreeting, setRandomGreeting] = useState('')
    const [upcomingDeskBooking, setUpcomingDeskBooking] = useState({})

    const latestFaultReport = faultReports.length > 0 ? faultReports[0] : {}
    const latestIncomingTransit = incomingTransits.length > 0 ? incomingTransits[0] : {}

    const handleDescription = (issue, val) => {
        if (val.length == 0) {
            return ''
        } else if (val.length > 0 && val.length < 40) {
            return val
        } else if (val.length > 40) {
            const final = val.slice(0, 38) + ' ' + '...'
            return issue + ' | ' + final
        }
    }
    const handleNavigate = () => {
        dispatch(setFeature('checkin'))
        dispatch(assetSetComp('assetQrCodeReader'))
    }
    const handleAssetManagementRedirection = (id) => {
        dispatch(startFetchTransit({ transitId: id, handleNavigate }))
        history.push('/assetmanager')
    }

    const upcomingMeetings = meetings
        .filter((meeting) => {
            return moment(meeting.startTime).isAfter(moment())
        })

    const handleNotificationsCount = () => {
        const count = ((todaysUpcomingBookings.length > 0 ? todaysUpcomingBookings.length : 0) + (upcomingMeetings.length > 0 ? upcomingMeetings.length : 0) + (Object.keys(latestFaultReport).length > 0 ? 1 : 0) + (incomingTransits.length > 0 ? incomingTransits.length : 0))
        return count
    }
    useEffect(() => {

        if (appsIsLoaded) {
            const deskBookingEnabled = apps.find((ele) => ele.configName === 'deskBooking')?.enabled
            const roomBookingEnabled = apps.find(ele => ele.configName === 'meetingRooms')?.enabled
            const faultReportsEnabled = apps.find(ele => ele.configName === 'faultReport')?.enabled
            const feedbackEnabled = apps.find(ele => ele.configName === 'feedback')?.enabled
            const assetManagementEnabled = apps.find(ele => ele.configName === 'assetManagement')?.enabled

            faultReportsEnabled && dispatch(startGetAllTickets({ bid }))
            // roomBookingEnabled&&dispatch(startFetchMeetings(date, '', 'NA'))
            roomBookingEnabled && dispatch(startFetchMeetings({ date, setIsLoaded: '', redirect: 'NA' }))

            const body = {
                currentDate: date,
            }
            deskBookingEnabled && dispatch(startGetUpcomingBookings({ body }))
            assetManagementEnabled && dispatch(startFetchIncomingTransits(bid))
        }

    }, [appsIsLoaded])

    useEffect(() => {
        if (upcomingMeetings.length > 0) {
            const res = allNotifications.filter((ele) => {
                return ele.id == upcomingMeetings[0].id
            })
            if (res.length == 0) {
                setAllNotifications([...allNotifications, upcomingMeetings[0]])
                setMerged(true)
            }
        } else {
            setMerged(true)
        }
    }, [upcomingMeetings])
    useEffect(() => {
        if (Object.keys(upcomingDeskBooking).length > 0) {
            const res = allNotifications.filter((ele) => {
                return ele.id == upcomingDeskBooking.id
            })

            if (res.length == 0) {
                setAllNotifications([...allNotifications, upcomingDeskBooking])
                setMerged(true)
            }
        } else {
            setMerged(true)
        }
    }, [upcomingDeskBooking])

    useEffect(() => {
        if (Object.keys(latestFaultReport).length > 0) {
            const res = allNotifications.filter(
                (ele) => ele.id == latestFaultReport.id,
            )
            if (res.length === 0) {
                setAllNotifications([...allNotifications, latestFaultReport])
                setMerged(true)
            }
        } else {
            setMerged(true)
        }
    }, [latestFaultReport])

    useEffect(() => {
        if (Object.keys(latestIncomingTransit).length > 0 && latestIncomingTransit?.status === 'in transit') {
            const res = allNotifications.filter(ele => ele._id === latestIncomingTransit?._id)
            if (res.length === 0) {
                setAllNotifications([...allNotifications, latestIncomingTransit])
                setMerged(true)
            }

        }
    }, [latestIncomingTransit])

    useEffect(() => {
        const greetingText = arr[Math.floor(Math.random() * arr.length)]
        setRandomGreeting(greetingText)
    }, [])
    useEffect(() => {
        if (upcomingBookings?.length > 0) {
            const date = moment().format('YYYY-MM-DD')
            const now = new Date().getHours()

            if (now > 12) {
                const currentMeeting = upcomingBookings.filter((ele) => {
                    return (
                        (ele.bookingDate === date && ele.secondHalf === true) ||
                        (ele.bookingDate === date &&
                            ele.firstHalf &&
                            ele.secondHalf === true)
                    )
                })

                if (currentMeeting.length > 0) {
                    setUpcomingDeskBooking(currentMeeting[0])
                    const newUpcoming = upcomingBookings.filter(
                        (booking) => booking?.id !== currentMeeting[0]?.id,
                    )
                } else {
                    setUpcomingDeskBooking({})
                }
            } else {
                const currentMeeting = upcomingBookings.filter((ele) => {
                    return (
                        (ele.bookingDate === date && ele.firstHalf === true) ||
                        (ele.bookingDate === date &&
                            ele.firstHalf &&
                            ele.secondHalf === true)
                    )
                })
                if (currentMeeting.length > 0) {
                    const newUpcoming = upcomingBookings.filter(
                        (booking) => booking?.id !== currentMeeting[0]?.id,
                    )

                    setUpcomingDeskBooking(currentMeeting[0])
                } else {
                    setUpcomingDeskBooking({})
                }
            }
        } else {
            setUpcomingDeskBooking({})
        }
    }, [upcomingBookings])
    const handleRedirect = () => {
        history.push('/meetingrooms/meeting')
        setNavigateLoader(false)
    }

    const handleFaultReportRedirect = (id) => {
        dispatch(startGetTicket({ id, bid, handleRedirect: handleGetFaultReportRedirect }))
    }
    const handleFetch = (id) => {
        setNavigateLoader(true)
        const date = moment().format('DD-MM-YYYY')
        dispatch(startFetchMeetingById({ id, date, handleRedirect }))
    }

    const handleGetFaultReportRedirect = () => {
        history.push('/faultreporting')
        dispatch(setComp('Ticket Details'))
    }
    const timings = (start, end) => {
        return `${moment(start).format('ddd hh:mm A')} to ${moment(end).format(
            'hh:mm A',
        )}`
    }

    const [navigateLoader, setNavigateLoader] = useState(false)

    const handleBookingHalf = (first, second) => {
        if (first && second) {
            return 'Full day'
        } else if (first) {
            return 'First Half'
        } else if (second) {
            return 'Second Half'
        }
    }

    const handleViewAll = () => {

        history.push('/notifications')
    }

    const handleDeskBookingRedirect = () => {
        history.push('/deskbooking/home')
    }
    return (
        <Box
            sx={{
                background: 'linear-gradient(180deg, #FF6520 45%, #F6F6F6 20%);',
                padding: 4,
                pt: 1,
            }}
        >
            <CardComp border="8" minHeight="300px">
                <CardContent
                    sx={{ '&:last-child': { pb: 1.5 }, paddingX: 2.5 }}
                    width="100%"
                >
                    <Grid container width="100%">
                        <Grid item xs={10.5}>
                            <Box display="flex">
                                <Typography
                                    variant
                                    sx={{
                                        fontFamily: "'roboto','Open sans','Helvetica'",
                                        fontSize: 11,
                                    }}
                                    gutterBottom
                                >
                                    {moment().format('MMMM Do YYYY')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant="h5"
                                    sx={{ fontFamily: "'roboto','Open sans','Helvetica'" }}
                                    fontWeight="bold"
                                    gutterBottom
                                >
                                    {vcardName || name}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={0.5} display="flex" alignItems="flex-start">
                            <Tooltip title="Notifications">
                                <IconButton>
                                    {allNotifications.length > 0 ? (
                                        <Badge badgeContent={handleNotificationsCount()} onClick={handleViewAll} color="error">
                                            <NotificationsOutlinedIcon
                                                fontSize="medium"
                                                onClick={handleViewAll}
                                            />
                                        </Badge>
                                    ) : (
                                        <NotificationsOutlinedIcon fontSize="medium" />
                                    )}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Box sx={{ padding: 2, paddingX: 0, paddingBottom: 1, pr: 0 }}>
                        {merged ? (
                            <>
                                {allNotifications.length > 0 ? (
                                    <>
                                        <Grid container xs={12} spacing={1.5} width={'100%'}>
                                            {Object.keys(upcomingDeskBooking).length > 0 && (
                                                <Grid
                                                    container
                                                    item
                                                    key={upcomingDeskBooking.id}
                                                    xs={12}
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={1.5}
                                                        display="flex"
                                                        alignItems="top"
                                                        mt={'0.2vh'}
                                                    >
                                                        <Box height="40%">
                                                            <img
                                                                src={workingAtHome}
                                                                height="16px"
                                                                alt="Desk Booking"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={9.5}>
                                                        <GreetingTextWidget
                                                            issue={'DESK BOOKING'}
                                                            title={`${upcomingDeskBooking.deskCode} - ${upcomingDeskBooking.floor} - ${upcomingDeskBooking.zone} - ${upcomingDeskBooking.building}`}
                                                            subTitle={handleBookingHalf(
                                                                upcomingDeskBooking.firstHalf,
                                                                upcomingDeskBooking.secondHalf,
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} onClick={handleDeskBookingRedirect}>
                                                        <IconButton>
                                                            <KeyboardArrowRightIcon fontSize="medium" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {upcomingMeetings.length > 0 && (
                                                <Grid
                                                    container
                                                    item
                                                    key={upcomingMeetings[0].id}
                                                    xs={12}
                                                    spacing={1}

                                                >
                                                    <Grid
                                                        item
                                                        xs={1.5}
                                                        display="flex"
                                                        alignItems="top"
                                                        mt={'0.2vh'}
                                                    >
                                                        <Box height="40%">
                                                            <img
                                                                src={calendar}
                                                                height="16px"
                                                                alt="Calender Icon"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={9.5}>
                                                        <GreetingTextWidget
                                                            issue={'ROOM BOOKING'}
                                                            title={upcomingMeetings[0].summary}
                                                            subTitle={timings(
                                                                upcomingMeetings[0].startTime,
                                                                upcomingMeetings[0].endTime,
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        onClick={() => {
                                                            handleFetch(upcomingMeetings[0].id)
                                                        }}
                                                    >
                                                        <IconButton>
                                                            <KeyboardArrowRightIcon fontSize="medium" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )}

                                            {Object.keys(latestFaultReport).length > 0 && (
                                                <Grid
                                                    container
                                                    item
                                                    key={latestFaultReport.id}
                                                    xs={12}
                                                    spacing={1}
                                                    width="100%"
                                                >
                                                    <Grid
                                                        item
                                                        xs={1.5}
                                                        display="flex"
                                                        alignItems="flex-start"
                                                        mt={'0.2vh'}
                                                    >
                                                        <Box
                                                            height="40%"
                                                            display="flex"
                                                            alignItems="flex-start"
                                                        >
                                                            <img
                                                                src={errorMessage}
                                                                height="16px"
                                                                alt="Fault reporting"
                                                            />
                                                        </Box>
                                                    </Grid>

                                                    <Grid item xs={9.5}>
                                                        <GreetingTextWidget
                                                            issue={'FAULT REPORT'}
                                                            title={`${latestFaultReport.title}`}
                                                            subTitle={handleDescription(
                                                                latestFaultReport.issue,
                                                                latestFaultReport.description,
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={1}
                                                        onClick={() => {
                                                            handleFaultReportRedirect(latestFaultReport.id)
                                                        }}
                                                        display="flex"
                                                        alignItems="flex-start"
                                                    >
                                                        <IconButton>
                                                            <KeyboardArrowRightIcon fontSize="medium" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            )}
                                            {(allNotifications.length < 3 && Object.keys(latestIncomingTransit).length > 0 && latestIncomingTransit?.status === 'in transit') &&
                                                <Grid
                                                    container
                                                    item
                                                    key={incomingTransits[0]._id}
                                                    xs={12}
                                                    spacing={1}
                                                    mt={'0.2vh'}
                                                >
                                                    <Grid
                                                        item
                                                        xs={1.5}
                                                        display="flex"
                                                        alignItems="top"
                                                        mt={'0.4vh'}
                                                    >
                                                        <Box height="40%">
                                                            <img
                                                                src={AssetManager}
                                                                height="16px"
                                                                alt="Desk Booking"
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={9.5}>
                                                        <GreetingTextWidget
                                                            issue={'ASSET MANAGEMENT'}
                                                            title={`Incoming Transit `}
                                                            subTitle={`From ${incomingTransits[0]?.previousBuilding?.name} - ${(incomingTransits[0]?.assets).length} asset/s`}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} onClick={() => handleAssetManagementRedirection(incomingTransits[0]?._id)} display='flex' alignItems={'flex-start'}>
                                                        <IconButton>
                                                            <KeyboardArrowRightIcon fontSize="medium" />
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>}
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid
                                        container
                                        xs={12}
                                        spacing={1.5}
                                        width="85vw"
                                        height="100%"
                                        minHeight="150px"
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                        >
                                            <Typography>No notifications for today!</Typography>
                                        </Grid>
                                    </Grid>
                                )}

                            </>
                        ) : (
                            <>
                                <Grid container xs={12} width="80vw">
                                    <Grid item xs={12} display="flex" justifyContent="center">
                                        <CircularProgress color="inherit" size="1.5rem" />
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Box>

                    {merged && allNotifications.length > 0 && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                width: '100%',
                            }}
                        >
                            <Button
                                size="small"
                                sx={{ color: '#FF6520' }}
                                onClick={handleViewAll}
                            >
                                <Typography sx={{ fontSize: '12px' }}>View All</Typography>
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </CardComp>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={navigateLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    )
}

export default HomeCard