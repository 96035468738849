import React, { useState, useEffect } from 'react'
import Header from '../../Header/Header'
import {
  Box,
  Card,
  FormControl,
  Stack,
  Button,
  Grid,
  Typography,
  Container,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined'
import moment from 'moment'
import MeetingView from '../../../../UI/MeetingRooms/Widgets/MeetingRoom/MeetingView'
import { useHistory, useLocation } from 'react-router-dom'
import {
  resetListIsDeleted,
  resetMeetings,
  startFetchMeetingById,
  startFetchMeetings,
} from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import { useDispatch, useSelector } from 'react-redux'
import MonthPicker from '@mui/x-date-pickers/MonthPicker'

const MyMeetings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation().pathname
  const [selectedDate, setSelectedDate] = useState(
    moment().format('DD-MM-YYYY'),
  )

  const meetings = useSelector((state) => state.meetings?.list?.data)
  const sortedMeetings = meetings
  const [loader, setLoader] = useState(false)
  const handleLoader = () => {
    setLoader(false)
  }
  const [displayLoader, setDisplayLoader] = useState(false)
  const [popUp, setPopup] = useState(true)

  const [deleteLoader, setDeleteLoader] = useState(false)
  const handleDeleteLoader = (val) => {
    setDeleteLoader(val)
  }
  const isLoading = useSelector((state) => {
    return state?.meetings?.list?.isLoading
  })
  const selectedEvent = useSelector(
    (state) => state?.meetings?.selectedEvent.data,
  )

  const handleDisplayLoader = () => {
    !displayLoader && setDisplayLoader(true)
  }
  const meetingTime = (start, end) => {
    const meetingStartTime = moment(start).format('HH:mm A')
    const meetingEndTime = moment(end).format('HH:mm A')
    return `${meetingStartTime} to ${meetingEndTime}`
  }

  const arr = []
  for (let i = 0; i <= 2; i++) {
    const momentDate = moment().subtract(i, 'days').format('ddd DD-MM-YYYY')

    arr.push({
      day: momentDate.slice(0, 3),
      date: momentDate.slice(4, 6),
      fullDate: momentDate.slice(4),
    })
    if (i === 2) arr.reverse()
  }
  for (let i = 0; i <= 15; i++) {
    const momentDate = moment()
      .add(i + 1, 'days')
      .format('ddd DD-MM-YYYY')

    arr.push({
      day: momentDate.slice(0, 3),
      date: momentDate.slice(4, 6),
      fullDate: momentDate.slice(4),
    })
  }
  const failRedirect=()=>{
    history.push('/')
    window.location.reload()
  }
  useEffect(() => {
    dispatch(startFetchMeetings({date:selectedDate.split('-').reverse().join('-'),setIsLoaded:'',redirect:'',failRedirect}))
  }, [selectedDate])
  useEffect(() => {
    return () => {
      dispatch(resetListIsDeleted())
    }
  }, [])
  useEffect(() => {}, [meetings])
  const handleOnClick = (date) => {
    dispatch(resetMeetings())
    setSelectedDate(date)
  }

  const handleRedirect = () => {
    history.push('/meetingrooms/meeting')
  }
  const handleFetch = (id) => {
    setLoader(true)
    dispatch(
      startFetchMeetingById({id,date:selectedDate, handleRedirect, handleLoader}),
    )
  }

  return (
    <Box height="100vh">
      <Typography variant="h5" fontSize="16px" gutterBottom>
        {moment().format('MMMM YYYY')}{' '}
      </Typography>
      <Stack p={0} spacing={2} width="100%" sx={{ overflowY: 'hidden' }}>
        <div>
          <Grid
            item
            xs={0.5}
            display="flex"
            direction="row"
            backgroundColor="#4B4B4B"
            container
            wrap="nowrap"
            align="center"
            justifyContent="space-between"
            sx={{
              overflow: 'auto',
              minWidth: '100%',
              height: '60px',
              borderRadius: '10px',
              overflowY: 'hidden',
            }}
          >
            {arr.map((item, index) => {
              return (
                <Grid
                  item
                  display="flex"
                  direction="row"
                  mx={1}
                  height={'100%'}
                  container
                  align="center"
                  key={index}
                  sx={{ '&:hover': { cursor: 'pointer' } }}
                >
                  <Box
                    sx={{
                      color: '#FFFFFF',
                      backgroundColor:
                        selectedDate === item.fullDate ? '#FF6520' : '#4B4B4B',
                      p: 1,
                      borderRadius: '0px',
                      width: '40px',
                    }}
                    onClick={() => {
                      handleOnClick(item.fullDate)
                    }}
                  >
                    <Stack m={0} p={0}>
                      <Grid item xs={12} pb={0}>
                        <Typography variant="body1" fontSize="12px">
                          {item.day}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography
                          variant="h6"
                          fontSize="14px"
                          fontWeight={550}
                        >
                          {item.date}
                        </Typography>
                      </Grid>
                    </Stack>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
        </div>
      </Stack>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={deleteLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack pt={2} spacing={2} direction="column">
        {!isLoading && sortedMeetings.length > 0 ? (
          sortedMeetings.map((meeting) => {
            return (
              <MeetingView
                handleDisplayLoader={handleDisplayLoader}
                handleRedirect={handleFetch}
                backgroundColor={meeting.creator ? '#02B9A4' : '#AAAAAA'}
                timeRange={meetingTime(meeting.startTime, meeting.endTime)}
                floor={meeting.floor}
                creator={meeting.creator}
                recurring={meeting.recurring}
                meetingName={meeting.summary}
                room={meeting.name}
                guests={meeting.seatingCapacity}
                isPopup={popUp}
                id={meeting.id}
                handleDeleteLoader={handleDeleteLoader}
                attendees={meeting.guests}
              />
            )
          })
        ) : !isLoading && sortedMeetings.length === 0 ? (
          <div>No meetings on this date</div>
        ) : (
          <Box display={'flex'} width="100%" justifyContent={'center'}>
            <CircularProgress />
          </Box>
        )}
      </Stack>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
export default MyMeetings
