import { FormControl, OutlinedInput } from '@mui/material'
import React from 'react'

const AddGuestInput = (props) => {
    const { name, value, onChange, isError, width,onblur,placeholder,height } = props
    return (
        <FormControl fullWidth={true}>
            <OutlinedInput
                placeholder={placeholder||"Enter guest email ID"}
                style={{ backgroundColor: '#FFFFFF', height: height||"8vh", width: '100%', borderRadius: "10px", color: isError ? "red" : "inherit" }}
                name={name}
                onBlur={onblur}
                value={value}
                onChange={onChange}
                autoComplete='off'
            />
        </FormControl>
    )
}

export default AddGuestInput