import React, { useEffect, useState } from 'react'
import Header from '../../Header/Header'
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import CardTitle from '../../../../UI/MeetingRooms/Widgets/MeetingRoom/CardTitle'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { startInvitationResponse } from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import meet from '../../../../Assets/MeetingRooms/Images/meet.png'

import text from '../../../../Assets/MeetingRooms/Images/text.png'
import meetingRoomSize from '../../../../Assets/MeetingRooms/Images/meetingRoomSize.png'
import calendar from '../../../../Assets/MeetingRooms/Images/calendar.png'
import stairs from '../../../../Assets/MeetingRooms/Images/stairs.png'
import squareLayouts from '../../../../Assets/MeetingRooms/Images/squareLayouts.png'
import building from '../../../../Assets/MeetingRooms/Images/building.png'
import information from '../../../../Assets/MeetingRooms/Images/information.png'
import attendeesIcon from '../../../../Assets/MeetingRooms/Images/attendeesIcon.png'
import addUserIcon from '../../../../Assets/MeetingRooms/Images/addUserIcon.png'
import locationMeetingRoom from '../../../../Assets/MeetingRooms/Images/locationMeetingRoom.png'
import MeetingRoomHeader from '../../Header/MeetingRoomHeader'

function MeetingDetailsPanel() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loader, setLoader] = useState(false)
  const handleLoader = (val) => {
    setLoader(val)
  }
  const isLoaded = useSelector((state) => {
    return state.meetings?.selectedEvent?.isLoaded
  })
  const meeting = useSelector((state) => {
    return state.meetings?.selectedEvent?.data
  })
  const user = useSelector((state) => state.user)
  const meetingInvite = meeting?.guests?.filter((ele) => {
    return ele.email === user?.data?.email
  })

  const location = useLocation()
  useEffect(() => {
    if (
      location.pathname === '/meetingrooms/meeting' &&
      Object.keys(meeting)?.length === 0
    ) {
      history.push('/meetingrooms/meetings')
    }
  }, [])
  const handleEditClick = () => {
    history.push('/meetingrooms/update')
  }

  const eventTime = (startTime, endTime) => {
    const startDate = moment(startTime).format('ddd DD MMM')
    const endDate = moment(endTime).format('ddd DD MMM')
    const start = moment(startTime).format('hh:mm A')
    const end = moment(endTime).format('hh:mm A')

    return startDate === endDate
      ? `${startDate} ${start} to ${end} `
      : `${startDate} ${start} to ${endDate} ${end} `
  }

  const handleInviteClick = (data) => {
    dispatch(startInvitationResponse({ data, handleLoader }))
    handleLoader(true)
  }

  return (
    <Box>
      <MeetingRoomHeader title="Meeting Details" />
      <Box p={2} height="100vh">
        <Box>
          <Card
            sx={{
              marginBottom: '20px',
              borderRadius: '15px',
              position: 'relative',
            }}
          >
            {isLoaded ? (
              <Stack p={2} spacing={1}>
                <CardTitle icon={text} bold="true" title={meeting.summary} />
                {meeting.creator && (
                  <Button
                    sx={{ position: 'absolute', right: '2px', top: '1px' }}
                    onClick={handleEditClick}
                  >
                    <ModeEditOutlinedIcon />
                  </Button>
                )}
                <CardTitle
                  icon={meetingRoomSize}
                  title={meeting.seatingCapacity}
                />
                <CardTitle
                  icon={calendar}
                  title={eventTime(meeting.startTime, meeting.endTime)}
                />
                {meeting.hangoutLink.length > 0 && (
                  <Grid
                    container
                    item
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={1}>
                      <img src={meet} style={{ height: '16px' }} />
                    </Grid>
                    <Grid item xs={11}>
                      {meeting.hangoutLink.length > 0 && (
                        <a href={`${meeting.hangoutLink}`} target="_blank">
                          <Button
                            variant="contained"
                            sx={{
                              textTransform: 'none',
                              fontWeight: 'bold',
                            }}
                          >
                            Join with Google Meet
                          </Button>
                        </a>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        fontSize: '14px',
                        marginLeft: '27px',
                        marginTop: '2px',
                      }}
                    >
                      <p>
                        <b>
                          <a href={`${meeting.hangoutLink}`} target="_blank">
                            {meeting.hangoutLink}
                          </a>
                        </b>
                      </p>
                    </Grid>
                  </Grid>
                )}
                {meeting.floor.length > 0 && (
                  <CardTitle icon={stairs} title={meeting.floor} />
                )}

                {meeting.building.length > 0 && (
                  <CardTitle icon={building} title={meeting.building} />
                )}
                <Box>
                  {meeting.location.length > 0 ? (
                    <CardTitle
                      icon={locationMeetingRoom}
                      title={meeting.location}
                    />
                  ) : (
                    ''
                  )}
                </Box>

                <Box display="flex">
                  {meeting.building.length > 0 && (
                    <CardTitle
                      icon={squareLayouts}
                      title={meeting.amenities?.join(', ')}
                    />
                  )}
                </Box>
                <Stack>
                  <Box>
                    <CardTitle icon={addUserIcon} title="Guests" />
                    <Stack spacing={2} marginLeft="15px" pb={1}>
                      {meeting?.guests?.length === 0 ? (
                        <Typography ml={1}>No invitees</Typography>
                      ) : (
                        meeting?.guests?.map((ele) => (
                          <Stack direction="row" spacing={1} key={ele}>
                            <Box display="flex" alignItems="center">
                              <img
                                src={attendeesIcon}
                                style={{ height: '16px' }}
                              />
                            </Box>
                            <Typography>{ele.email}</Typography>
                          </Stack>
                        ))
                      )}
                    </Stack>
                  </Box>
                  <CardTitle
                    icon={information}
                    title={meeting.description || '-'}
                  />
                </Stack>
                {!meeting.creator &&
                  meetingInvite?.length > 0 &&
                  !meeting.recurring && (
                    <>
                      <Divider />
                      <Grid container>
                        <Grid item xs={2} display="flex" alignItems="center">
                          <Typography fontWeight={'bold'}>Going?</Typography>
                        </Grid>
                        <Grid item xs={10}>
                          <Stack direction="row" spacing={5} display="flex-end">
                            <Box
                              backgroundColor={
                                meetingInvite[0]?.responseStatus === 'accepted'
                                  ? '#E8EDFF'
                                  : 'none'
                              }
                              borderRadius="5px"
                            >
                              <Button
                                onClick={() => {
                                  handleInviteClick({
                                    eventId: meeting.id,
                                    responseStatus: 'accepted',
                                  })
                                }}
                              >
                                <DoneOutlinedIcon />
                              </Button>
                            </Box>
                            <Box
                              backgroundColor={
                                meetingInvite[0]?.responseStatus === 'declined'
                                  ? '#E8EDFF'
                                  : 'none'
                              }
                              borderRadius="5px"
                            >
                              <Button
                                onClick={() => {
                                  handleInviteClick({
                                    eventId: meeting?.id,
                                    responseStatus: 'declined',
                                  })
                                }}
                              >
                                <CloseOutlinedIcon />
                              </Button>
                            </Box>
                            <Box
                              backgroundColor={
                                meetingInvite[0]?.responseStatus === 'tentative'
                                  ? '#E8EDFF'
                                  : 'none'
                              }
                              borderRadius="5px"
                            >
                              <Button
                                onClick={() => {
                                  handleInviteClick({
                                    eventId: meeting.id,
                                    responseStatus: 'tentative',
                                  })
                                }}
                              >
                                Maybe
                              </Button>
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </>
                  )}
              </Stack>
            ) : (
              <></>
            )}
          </Card>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  )
}
export default MeetingDetailsPanel
