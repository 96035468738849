import React, { useEffect, useState } from 'react'
import {
  Box,
  Modal,
  Paper,
  Rating,
  Stack,
  TextField,
  Typography,
  Card,
  Divider,
} from '@mui/material'
import {
  BrokenImageBox,
  FaultImageCard,
  NoTopPaadingBox,
  PaddingBox,
  TextFieldStyle,
  ModalStyle,
  imageModalStyle,
} from '../../UI/FaultReporting/Styles/UserDetailsStyles'
import ConfirmButton from '../../UI/FaultReporting/Button/ConfirmButton'
import BrokenImage from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import { useDispatch, useSelector } from 'react-redux'
import { startUpdateFeedback } from '../../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import StarIcon from '@mui/icons-material/Star'
import correct from '../../Assets/Images/correct.svg'

const UserTicketDetails = (props) => {
  const imageLink = useSelector(state => state?.config?.imageLink)
  const bid = useSelector((state) => state?.user?.data?.buildingId)
  const dispatch = useDispatch()
  const { ticketResolved } = props
  const ticket = useSelector(
    (state) => state.faultReports?.selectedTicket?.ticket,
  )
  const [ratings, setRatings] = useState(ticket.rating ? ticket.rating : 0)
  const serviceImageUrl = ticket.serviceImageUrl
  const [feedback, setFeedBack] = useState('')
  const [imageModal, setImageModal] = useState(false)
  const [open, setOpen] = React.useState(false)
  const handleFeedBack = (e) => {
    const val = e.target.value
    setFeedBack(val)
  }

  const handleSubmit = () => {
    const data = {
      feedback: feedback,
      rating: ratings,
    }
    dispatch(startUpdateFeedback({ id: ticket?.id, data, bid, handleModal }))
  }

  const handleModal = () => {
    setOpen(true)
  }
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(setComp('Fault Reporting'))
        setOpen(false)
      }, 2000)
    }
  }, [open])
  return (
    <Box marginTop="0.8vh">
      {ticketResolved == 'closed' ? (
        <>
          <Box>
            <Typography align="center" color="#212121">
              This ticket has been resolved by
            </Typography>
            <Typography align="center" fontWeight={700} color="#212121">
              {ticket.assignedTo}
            </Typography>
          </Box>
          {
            serviceImageUrl && <PaddingBox onClick={() => serviceImageUrl && setImageModal(true)}>
              <FaultImageCard>
                <BrokenImageBox>
                  <img
                    src={
                      serviceImageUrl
                        ? `${imageLink}${serviceImageUrl}`
                        : BrokenImage
                    }
                    height={serviceImageUrl ? '100%' : '20px'}
                    width={serviceImageUrl && '100%'}
                    style={{ objectFit: 'cover' }}
                  />
                </BrokenImageBox>
              </FaultImageCard>
            </PaddingBox>
          }

          <NoTopPaadingBox>
            <Typography align="center" color="#212121">
              "{ticket.serviceComments}"
            </Typography>
          </NoTopPaadingBox>

          <Box p={2} pt={1} pb={0}>
            <Typography
              fontWeight="bold"
              fontSize="18px"
              align="center"
              color="#212121"
            >
              Feedback
            </Typography>
            <Typography fontSize="18px" align="center" color="#212121">
              {ticket.reviewed ? '' : 'Please rate your experience!'}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mt={1}
          >
            {ticket.reviewed ? (
              <Card
                sx={{
                  borderRadius: '10px',
                  width: '95%',
                  marginBottom: '10px',
                  border: '1px solid #e0e0e0',
                }}
              >
                <Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Rating
                      name="size-large"
                      sx={{ fontSize: '50px' }}
                      value={ratings}
                      onChange={(event, newValue) => {
                        setRatings(newValue)
                      }}
                      precision="0.5"
                      disabled={ticket.reviewed}
                      emptyIcon={
                        <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                      }
                    />
                  </Box>
                  <Box>
                    <Typography align="center" fontSize="18px" mt={1} mb={1}>
                      {ticket.feedback}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ) : (
              <Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  mt={1}
                >
                  <Rating
                    name="size-large"
                    sx={{ fontSize: '50px' }}
                    value={ratings}
                    onChange={(event, newValue) => {
                      setRatings(newValue)
                    }}
                    precision="0.5"
                    disabled={ticket.rating}
                    emptyIcon={
                      <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                    }
                  />
                </Box>
                <Box>
                  <Typography align="center" fontSize="18px" mt={2}>
                    {ticket.feedback}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          {!ticket.reviewed && (
            <>
              <PaddingBox>
                <TextField
                  label=""
                  style={TextFieldStyle}
                  placeholder="Enter your feedback (Optional)"
                  multiline
                  inputProps={{ maxLength: 250 }}
                  onChange={handleFeedBack}
                />
                <Box>
                  <Typography fontSize={'12px'} align="right">
                    {250 - Number(feedback.length)} characters left
                  </Typography>
                </Box>
              </PaddingBox>

              <NoTopPaadingBox>
                <ConfirmButton
                  backgroundColor="#DC5D3B"
                  title="Submit"
                  width="100%"
                  onClick={handleSubmit}
                />
              </NoTopPaadingBox>
            </>
          )}
        </>
      ) : (
        <Box>
          <Typography align="center">
            {ticket.status === 'open'
              ? 'This ticket has not been assigned yet.'
              : 'This ticket is being addressed by'}
          </Typography>
          {ticket.status == 'assigned' && (
            <Typography align="center" fontWeight={700}>
              {ticket.assignedTo}
            </Typography>
          )}
        </Box>
      )}

      <Modal open={open}>
        <Paper sx={ModalStyle}>
          <Stack pb={2}>
            <Stack>
              <img src={correct} height="60px" />
            </Stack>
            <Typography
              variant="h5"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'center'}
              fontWeight="bold"
            >
              Thank you!
            </Typography>
            <Typography
              variant="h6"
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'center'}
            >
              Your feedback was submitted!
            </Typography>
          </Stack>
        </Paper>
      </Modal>
      <Modal open={imageModal} onClose={() => setImageModal(false)}>
        <Paper sx={imageModalStyle}>
          <img
            src={
              serviceImageUrl
                ? `${imageLink}${serviceImageUrl}`
                : BrokenImage
            }
            height="100%"
            width="100%"
          />
        </Paper>
      </Modal>
    </Box>
  )
}

export default UserTicketDetails
