import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET } from '../../../Helpers/http';

const initialState = {
    isLoaded: false,
    zones: [],
    selectedZone: {},
    inSelectedZone: false,
    zoneDesks: [],
    zoneMap: {},
    zoneDesksLoaded: false,
    zoneisLoaded:false
};

export const startGetFreeDesks = createAsyncThunk(
    'zone/startGetFreeDesks',
    async ({body}) => {
        try {
            const response = await GET(
                `db/v1/desks?status=free&bid=${body.buildingId}&selected-date=${body.selectedDate}&first-half=${body.firstHalf}&second-half=${body.secondHalf}`
            );
            if (response.status === 200) {
                const result = response?.data;
                return result;
            }else{
                console.log({response})
            }
        } catch (err) {
            console.log(err);
        }
    }
);

export const getAllDesksByZone = createAsyncThunk('desks/getAllDesksByZone', async ({ body, redirect }) => {
    try {
        const response = await GET(
            `db/v1/desks?app-type=pwa&zone-id=${body.zoneId}&selected-date=${body.selectedDate}&first-half=${body.firstHalf}&second-half=${body.secondHalf}`
        );
        if (response.status === 200) {
            const result = response.data;
            redirect(true)
            return result;
        } else {
            console.log({ response });
        }
    } catch (err) {
        console.log({ err });
    }
});

const bookingZoneSlice = createSlice({
    name: 'bookingZoneSlice',
    initialState,
    reducers: {
        updateDesksByZone: (state, action) => {
            state.selectedZone = action?.payload;
        },
        setZoneDetails: (state, action) => {
            state.selectedZone = {...action.payload};
            state.inSelectedZone = true;
        },

        setZoneDesks: (state, action) => {
            state.zoneDesks = action?.payload?.data;
            state.zoneMap = action?.payload?.zoneMap;
            state.zoneDesksLoaded = true;
        },
        updateDesksByZone: (state, action) => {
            state.zones = action?.payload;
            state.isLoaded = true;
        },
        setSelectedZone: (state, action) => {
            state.selectedZone = { ...action.payload };
            state.inSelectedZone = true;
        },
        saveAllDesksByZone: (state, action) => {
            state.zoneDesks = [...action.payload.data];
            state.zoneMap = action?.payload?.zoneMap;
            state.zoneDesksLoaded = true;
        },
        resetIsLoaded: (state) => {
            state.isLoaded = false;
        },
        resetZoneDesksLoaded: (state) => {
            state.zoneDesksLoaded = false;
        },
        resetZoneInSelectedZone: (state) => {
            state.inSelectedZone = false;
        },
         resetZoneIsLoaded: (state) => {
            state.isLoaded = false;
        },
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(startGetFreeDesks.pending, (state) => {
                state.isLoaded = false;
            })
            .addCase(getAllDesksByZone.pending, (state) => {
                state.zoneDesksLoaded = false;
            })
            .addCase(startGetFreeDesks.fulfilled, (state, action) => {
                state.zones = action?.payload;
                state.isLoaded = true;
            })
        .addCase(getAllDesksByZone.fulfilled, (state, action) => {
            state.zoneDesks = action?.payload?.data;
            state.inSelectedZone = true;
            state.zoneMap = action?.payload?.zoneMap
        });
    },
});

export const {
    updateDesksByZone,
    setSelectedZone,
    saveAllDesksByZone,
    resetIsLoaded,
    resetZoneDesksLoaded,
    setZoneDetails,
    resetZoneInSelectedZone,
    resetZoneIsLoaded
    
} = bookingZoneSlice.actions;

export default bookingZoneSlice.reducer;