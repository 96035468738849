import { Circle } from '@mui/icons-material'
import { Box, Card, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {  startFetchMeetingBookedStatus, startFetchMeetingRoomStatus } from '../../Redux/ReduxSlice/MeetingRooms/eventsSlice'

const MeetingRoomStatus=(props)=>{
    const location=useLocation()
    const dispatch=useDispatch()
    const occupancy = useSelector(state => state.event?.meetingRoomData)
	const bookedStatus = useSelector(state => state.event?.bookedStatus)
    const occupancyIsLoaded= useSelector(state => state.event?.meetingRoomDataIsLoaded)
    const bookedStatusIsLoaded=useSelector(state => state.event?.bookedStatusIsLoaded)

    useEffect(() => {
        
        dispatch(startFetchMeetingRoomStatus())
        dispatch(startFetchMeetingBookedStatus())
        
    }, [location.pathname])
    return (
        <>
        {(occupancyIsLoaded&&bookedStatusIsLoaded)?
        <Card sx={{p:2}}>
			<Grid container display={'flex'} justifyContent={'center'} height={'70%'}>
				<Grid container item mb={1} xs={12} >
					<Grid item xs={7} ><Typography fontSize={12} fontWeight={600}>Floor</Typography></Grid>
					<Grid item xs={2} textAlign={'center'} ><Typography fontSize={12} fontWeight={600}>Booked</Typography></Grid>
					<Grid item xs={3} textAlign={'center'} ><Typography fontSize={12} fontWeight={600}>Occupancy</Typography></Grid>
				</Grid>
				{
					occupancy?.map((ele, i) => {
						return (<Grid container item mb={1} xs={12} key={i}>
							<Grid item xs={7} ><Typography fontSize={12}>{`${ele.googleMeetingRoomName || `${ele.floor}-${ele.subarea}`}`}</Typography></Grid>
							<Grid item xs={2} textAlign={'center'} >{
								ele.subarea === "GFMR1" ? (
									bookedStatus['GF-MR-01'] === true ? <Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
										bookedStatus['GF-MR-01'] === false ? <Circle sx={{ color: '#02B9A4' }} fontSize='small' /> :
											<Circle sx={{ color: '#C4C4C4' }} fontSize='small' />
								) :
									ele.subarea === "GFMR2" ? (
										bookedStatus['GF-MR-02'] === true ? <Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
											bookedStatus['GF-MR-02'] === false ? <Circle sx={{ color: '#02B9A4' }} fontSize='small' /> :
												<Circle sx={{ color: '#C4C4C4' }} fontSize='small' />
									) :
										ele.subarea === "FFMR1" ? (
											bookedStatus['BLR-GOOG-MEET'] === true ? <Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
												bookedStatus['BLR-GOOG-MEET'] === false ? <Circle sx={{ color: '#02B9A4' }} fontSize='small' /> :
													<Circle sx={{ color: '#C4C4C4' }} fontSize='small' />
										) :
											ele.subarea === "SFMR1" ? (
												bookedStatus['BLR-BOARD-ROOM'] === true ? <Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
													bookedStatus['BLR-BOARD-ROOM'] === false ? <Circle sx={{ color: '#02B9A4' }} fontSize='small' /> :
														<Circle sx={{ color: '#C4C4C4' }} fontSize='small' />
											) :
												ele.subarea === "TFMR1" ? (
													bookedStatus['TERRACE MEETING POD'] === true ? <Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
														bookedStatus['TERRACE MEETING POD'] === false ? <Circle sx={{ color: '#02B9A4' }} fontSize='small' /> :
															<Circle sx={{ color: '#C4C4C4' }} fontSize='small' />
												) : ''
							}</Grid>
							<Grid item xs={3} textAlign={'center'} container spacing={0} >
								<Grid item xs={6}>
									{
										ele.meeting_room_occupancy === "NA" || ele.isLatest === false ?
											<Circle sx={{ color: '#C4C4C4' }} fontSize='small' /> :
											ele.meeting_room_occupancy > 0 ?
												<Circle sx={{ color: '#FF4E5E' }} fontSize='small' /> :
												<Circle sx={{ color: '#02B9A4' }} fontSize='small' />
									}
								</Grid>
								<Grid item xs={6} textAlign={'start'}>
									<Typography variant='caption' ml={1} >
										{
											ele.meeting_room_occupancy === "NA" || ele.isLatest === false ?
												" NA" : `${ele.meeting_room_occupancy}/${ele.maxCount}`
										}
									</Typography>
								</Grid>
							</Grid>
						</Grid>)
					})
				}
			</Grid>
		</Card>
        :
            <>
                <Typography>Loading..</Typography>
            </>
        }
        </>
    )
}

export default MeetingRoomStatus