import React from 'react'
import { useState, useEffect } from 'react'

import QrReader from 'modern-react-qr-reader'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@material-ui/core'
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@mui/material'
import Nav from '../../UI/FaultReporting/Widgets/Header'
import { startSubArea } from '../../Redux/ReduxSlice/FaultReporting/AreaSlice'
import { ModalStyle } from '../../UI/FaultReporting/Styles/QrCodeStyles'
import { assetSetComp } from '../../Redux/ReduxSlice/AssetManagement/assetCompSlice'
import {
  assetsToTransfer,
  asyncFetchAssetInfo,
  clearAssetsToMove,
  clearSingleAsset,
  fetchAssetStatus,
  removeAssetToTransfer,
  startUpdateTransit,
  startVerifyAssetInTransit,
} from '../../Redux/ReduxSlice/AssetManagement/assetSlice'
import brokenIcon from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import { BrokenImageBox } from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles'
import { TypoSx } from '../../UI/FaultReporting/Styles/ReportStyles'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import StatusButton from '../../UI/AssetManager/StatusButton'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import SuccessModal from '../../UI/AssetManager/SuccessModal'
import { AssetModalData } from '../../UI/AssetManager/AssetModalData'

const AssetQrCodeReader = () => {
  const dispatch = useDispatch()
  const bid = useSelector((state) => state?.user?.data?.buildingId)
  const comp = useSelector((state) => state.assetComp).title
  const feature = useSelector((state) => state?.assetData?.feature)
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const assetList = useSelector((state) => state?.assetData?.assetsToMove?.data)
  const transitDetails = useSelector(
    (state) => state?.assetData?.singleTransit?.data,
  )
  const trasitDetailsIsLoaded = useSelector(
    (state) => state?.assetData?.singleTransit?.isLoaded,
  )
  const userBid = useSelector((state) => state?.user?.data?.buildingId)
  const [selectedAsset, setSelectedAsset] = useState({})
  const [selectedAssetModal, setSelectedAssetModal] = useState(false)

  const handleSelectedAssset = (asset) => {
    setSelectedAsset(asset)
    setSelectedAssetModal(true)
  }
  const handleSelectedAssetModalClose = () => {
    setSelectedAsset({})
    setSelectedAssetModal(false)
  }
  const handleError = () => {
    alert('The asset is already in transit')
  }
  const handleScan = (e) => {
    // const bid='6412a551267ca61a2ff8b301'
    if (e) {
      if (feature === 'transfer') {
        const qrcode = assetList.find((ele) => ele.qrCode === e)
        if (!qrcode) {
          dispatch(
            fetchAssetStatus({ qrcode: e, toggleLoader, handleError, bid }),
          )
          setLoader(true)
        }
      } else if (feature === 'checkin') {
        const asset = transitDetails?.assets?.find((ele) => ele.qrCode === e)
        const assetVerified = asset?.assetVerified
        if (!assetVerified) {
          dispatch(
            startVerifyAssetInTransit({
              qrcode: e,
              transitId: transitDetails?._id,
              toggleLoader,
            }),
          )
          setLoader(true)
        }
      }
    }
  }
  const toggleLoader = () => {
    setLoader(false)
  }

  const handleRemoveAsset = (ele) => {
    // const newArr=assetList.filter(data=>data.id!==ele.id)
    dispatch(removeAssetToTransfer(ele))
  }
  const handleNavigateComp = () => {
    // dispatch(assetsToTransfer(assetList))
    dispatch(assetSetComp('AssetTransfer'))
  }

  const handleAcceptTransit = () => {
    const assetIds = transitDetails?.assets
      .filter((ele) => ele.assetVerified === true)
      .map((obj) => obj._id)
    const formData = {
      assets: assetIds,
      recievedOn: 1683121442910,
      status: 'completed',
    }
    const handleAcceptSuccess = () => {
      dispatch(clearAssetsToMove())
      setModalOpen(true)
    }
    dispatch(
      startUpdateTransit({
        transitId: transitDetails?._id,
        data: formData,
        handleSuccess: handleAcceptSuccess,
      }),
    )
  }

  useEffect(() => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(
        {
          video: true,
        },
        function (localMediaStream) {},
        function (err) {
          alert(
            'The following error occurred when trying to access the camera: ' +
              err,
          )
        },
      )
    } else {
      alert('Sorry, browser does not support camera access')
    }
    return () => {
      if (feature === 'checkin') {
        dispatch(clearSingleAsset())
      }
    }
  }, [])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }, [open])

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        dispatch(assetSetComp('AssetHome'))
      }, 3000)
    }
  }, [modalOpen])
  return (
    <Box height="100vh">
      {/* <Nav title='Scan Area Qr code' route='/' /> */}
      {comp == 'assetQrCodeReader' && (
        <Box display="flex" justifyContent={'center'}>
          <QrReader
            delay={300}
            facingMode="environment"
            //onError={this.handleError}
            onScan={handleScan}
            style={{ width: '70%' }}
          />
        </Box>
      )}

      {
        <Box sx={{ marginTop: 2 }}>
          {feature === 'transfer' &&
            assetList.length > 0 &&
            assetList.map((ele) => {
              return (
                <>
                  <Box
                    paddingY={2}
                    borderTop="solid 1px #D3D3d3"
                    borderBottom={'solid 1px #D3D3d3'}
                  >
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={3.5}>
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '10px',
                              height: '7vh',
                              width: '90%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <BrokenImageBox>
                              <img
                                src={brokenIcon}
                                height={'10px'}
                                style={{ objectFit: 'cover' }}
                                onClick={() => handleSelectedAssset(ele)}
                              />
                            </BrokenImageBox>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        container
                        xs={7}
                        onClick={() => handleSelectedAssset(ele)}
                      >
                        <Grid item xs={12}>
                          <Typography variant={'h6'}>
                            {ele.assetName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <TypoSx variant="caption">{`model - ${ele.model} `}</TypoSx>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item xs={1.5}>
                        <IconButton onClick={() => handleRemoveAsset(ele)}>
                          <CloseOutlinedIcon fontSize={'medium'} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                  <Modal
                    open={selectedAssetModal && selectedAsset._id === ele._id}
                    onClose={handleSelectedAssetModalClose}
                  >
                    <AssetModalData selectedAsset={selectedAsset} ele={ele} />
                  </Modal>
                </>
              )
            })}
          {feature === 'checkin' &&
            transitDetails?.assets?.length > 0 &&
            transitDetails?.assets.map((ele) => {
              return (
                <>
                  <Box
                    paddingY={2}
                    borderTop="solid 1px #D3D3d3"
                    borderBottom={'solid 1px #D3D3d3'}
                    onClick={() => handleSelectedAssset(ele)}
                  >
                    <Grid item container xs={12} spacing={2}>
                      <Grid item xs={3.5}>
                        <Box
                          sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '10px',
                              height: '7vh',
                              width: '90%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <BrokenImageBox>
                              <img
                                src={brokenIcon}
                                height={'10px'}
                                style={{ objectFit: 'cover' }}
                              />
                            </BrokenImageBox>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item container xs={7}>
                        <Grid item xs={12}>
                          <Typography variant={'h6'}>
                            {ele.assetName}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <TypoSx variant="caption">{`model - ${ele.model} `}</TypoSx>
                          </Box>
                        </Grid>
                      </Grid>
                      {(ele.assetVerified ||
                        transitDetails?.status === 'completed') && (
                        <Grid
                          item
                          xs={1.5}
                          sx={{ display: 'flex', alignItems: 'center' }}
                        >
                          <IconButton>
                            <DoneOutlinedIcon
                              fontSize={'medium'}
                              style={{ color: '#34b233' }}
                            />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                  <Modal
                    open={selectedAssetModal && selectedAsset._id === ele._id}
                    onClose={handleSelectedAssetModalClose}
                  >
                    <AssetModalData selectedAsset={selectedAsset} ele={ele} />
                  </Modal>
                </>
              )
            })}
        </Box>
      }
      {(assetList.length > 0 ||
        (Object.keys(transitDetails)?.length > 0 &&
          transitDetails.status === 'in transit' &&
          (feature === 'checkin'
            ? !(userBid === transitDetails?.previousBuilding?._id)
            : true))) && (
        <Box sx={{ width: '100%', p: 2 }}>
          <StatusButton
            backgroundColor="#DC5D3B"
            title={feature === 'checkin' ? 'Accept' : 'Transfer'}
            width="100%"
            onClick={
              feature === 'checkin' ? handleAcceptTransit : handleNavigateComp
            }
          />
        </Box>
      )}
      <Modal open={open}>
        <Paper sx={ModalStyle}>
          <Typography>Unable to scan. Please try again</Typography>
        </Paper>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {modalOpen && (
        <SuccessModal
          open={modalOpen}
          message={'Transit was successfully completed!'}
        />
      )}
    </Box>
  )
}

export default AssetQrCodeReader
