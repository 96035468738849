import React, { useEffect, useState } from 'react'
import {
  Typography,
  Box,
  Grid,
  Backdrop,
  Card,
  CircularProgress,
  Modal,
  Stack,
} from '@mui/material'
import {
  CameraIonBox,
  ImageBox,
  ModalCard,
  MuiBox,
  imageStyle,
  inputStyle,
  GalleryBox,
  BackButtonStyle,
  CardStyle,
  AlignedBox,
} from '../../UI/FaultReporting/Styles/CameraStyles'
import ConfirmButton from '../../UI/FaultReporting/Button/ConfirmButton'
import { useDispatch, useSelector } from 'react-redux'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { useHistory } from 'react-router-dom'
import backIcon from '../../Assets/Images/left-arrow.png'
import {
  clearFaultImage,
  faultImage,
  startSetImage,
} from '../../Redux/ReduxSlice/FaultReporting/FaultImageSlice'
import cameraIcon from '../../Assets/FaultReporting/Images/camera.svg'
import folderIcon from '../..//Assets/FaultReporting/Images/folder.svg'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

const CameraComp = () => {
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(true)
  const [imageBase64, setImageBase64] = useState('')
  
  const [loader, setLoader] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const image = useSelector((state) => state?.faultImage).image
  const url = useSelector((state) => state.faultImage).url
  const [source, setSource] = useState(image ? image : '')
  const [crop, setCrop] = useState({
    aspect:undefined,
    unit: '%',
    x: 25,
    y: 25,
    height: 25,
    width: 50,
  })
  const [image1, setImage1] = useState(null)

  const handleRetake = () => {
    setModal(true)
    setImageBase64('')
  }

  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        let formData = new FormData()
        formData.append('file', target.files[0])
        const newUrl = URL.createObjectURL(file)

        dispatch(startSetImage(formData))
        dispatch(faultImage({newUrl, file}))
        setSource(newUrl)
        setModal(false)
      }
    }
  }
  const convertBase64 = (image) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(image)
      fileReader.onloadend = () => {
        const baseURL = fileReader.result
        setImageBase64(baseURL)
        return resolve(baseURL)
      }
    })
  }

  const cropImageNow = () => {
    const canvas = document.createElement('canvas')
    const scaleX = image1.naturalWidth / image1.width
    const scaleY = image1.naturalHeight / image1.height

    
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')

    const pixelRatio = window.devicePixelRatio
    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image1,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    // Converting to base64
    const base64Image = canvas.toDataURL('image/jpeg')
    return base64Image
  }

  const handleProceed = () => {
    const output = cropImageNow()
    fetch(output)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'File name', { type: 'image/png' })
        setButtonDisabled(true)

        let formData = new FormData()
        formData.append('file', file)
        dispatch(startSetImage({formData, handleRedirect}))
        setLoader(true)
      })
  }
  const handleBackClick = () => {
    dispatch(setComp('Fault Reporting'))
    dispatch(clearFaultImage())
  }

  const handleRedirect = () => {
    dispatch(setComp('Asset Details'))
    setLoader(false)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 'none',
    borderRadius: '20px',
  }

 

  return (
    <MuiBox>
      {source && (
        <ImageBox>
          <ReactCrop
            src={source}
            style={{ height: '100%' }}
            onImageLoaded={setImage1}
            crop={crop}
            onChange={setCrop}
          />
          <Box style={BackButtonStyle} onClick={handleBackClick}>
            <img src={backIcon} height="24px" />
          </Box>
        </ImageBox>
      )}

      {confirmationOpen && (
        <Card
          sx={CardStyle}
        >
          <Box>
            <Box>
              <Typography fontSize="14px" align="center" mb={4}>
                Proceed with the above image?
              </Typography>
              <Stack spacing={3} direction="row" sx={{ mt: 3 }}>
                <ConfirmButton
                  backgroundColor="#212121"
                  title="No, Retake"
                  onClick={handleRetake}
                  disabled={buttonDisabled}
                />
                <ConfirmButton
                  backgroundColor="#FF6520"
                  title="Yes"
                  onClick={handleProceed}
                  disabled={buttonDisabled}
                />
              </Stack>
            </Box>
          </Box>
        </Card>
      )}
      <Modal open={modal} onClose={() => setModal(false)}>
        <Box sx={style}>
          <Grid container>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="icon-button-file">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={cameraIcon} width="26px" />
                    </Box>
                    <Typography color="#212121">Click an image</Typography>
                  </Stack>
                </label>

                <input
                  accept="image/*"
                  style={inputStyle}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                />
              </AlignedBox>
            </Grid>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="choose-file" id="choose-file-label">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={folderIcon} />
                    </Box>
                    <Typography color="#212121">Upload from Gallery</Typography>
                  </Stack>
                </label>

                <input
                  name="uploadDocument"
                  type="file"
                  id="choose-file"
                  accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                  style={inputStyle}
                  onChange={(e) => {
                    handleCapture(e.target)
                  }}
                />
              </AlignedBox>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MuiBox>
  )
}

export default CameraComp
