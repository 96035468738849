import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography, Card, CardContent, Grid, Paper } from "@mui/material";
import Header from "../../Header/Header";
import { useHistory } from "react-router-dom";
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import { useDispatch, useSelector } from "react-redux";
import { startFetchMeetingRooms, startBookMeetingRoom, updateIsLoaded } from "../../../../Redux/ReduxSlice/MeetingRooms/eventsSlice";
import { resetMeetingLocalState, startUpdateMeeting, startUpdateMeetingData } from "../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice";
import { autoPlay } from 'react-swipeable-views-utils';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import MobileStepper from '@mui/material/MobileStepper';
import { useTheme } from "@material-ui/styles";
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import stairs from '../../../../Assets/MeetingRooms/Images/stairs.png'
import squareLayouts from '../../../../Assets/MeetingRooms/Images/squareLayouts.png'
import meetingRoomSize from '../../../../Assets/MeetingRooms/Images/meetingRoomSize.png'
import MeetingRoomHeader from "../../Header/MeetingRoomHeader";

export default function AvailableMeetingRoom(props) {
  const availabeRooms = useSelector(state => state.event.availableRooms)
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const [disabled, setDisabled] = useState(false)
  const history = useHistory()
  const {eventData,isLoaded} = useSelector(state => state.event)
//   const isLoaded = useSelector(state => state.meetings.list?.isLoaded)
  const dispatch = useDispatch()
  const handleUpdateRoute = () => {
    history.push('/meetingrooms/update/confirmation')
  }
  const handleRedirect = () => {
    history.push("/meetingrooms/confirmation")
  }
  const handleClick = (id) => {
    const event = { ...eventData, resourceId: id }
    if (eventData.isUpdate) {
    //   delete eventData['isUpdate']
      const {isUpdate,...rest}=eventData  
      const updatedEvent={...rest,resourceId:id}
      dispatch(startUpdateMeetingData({data:updatedEvent, handleUpdateRoute}))
      setDisabled(true)
    } else {
      dispatch(startBookMeetingRoom({data:event, handleRedirect}))
      dispatch(resetMeetingLocalState())
      setDisabled(true)
    }
  }


  useEffect(() => {

    dispatch(updateIsLoaded())
  }, [])

  useEffect(() => {
    ((isLoaded&&Object.keys(eventData)?.length === 0)||Object.keys(eventData)?.length === 0) && history.push("/meetingrooms/booking")

  }, [])
  const images = [
    {
      label: 'San Francisco – Oakland Bay Bridge, United States',
      imgPath:
        'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bird',
      imgPath:
        'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
    },
    {
      label: 'Bali, Indonesia',
      imgPath:
        'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250',
    },
    {
      label: 'Goč, Serbia',
      imgPath:
        'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
    },
  ];
  

   const cardStyle = {
    position: "absolute",
    // border:"1px solid black",
    top: "50%",
    // height: "50%",
    // bottom: -10,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    outline: "0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
    height:'20vh'
    // pl: 2,
    //m:1
};

  return (
    <Box height={'100vh'}>
      <Box>
      <MeetingRoomHeader title='Available meeting rooms' />
      </Box>
      <Grid container spacing={1} p={2}>

        {availabeRooms.length > 0 ? availabeRooms.map((ele) => (
          <Grid item xs={12}  >
            <Card sx={{ boxShadow: 2, p: 2 }}>

              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems="center"
                spacing={1}

              >
                <Box pb={0}  >
                  <Box p={0.5} pb={1} >
                    <Typography variant="h5" fontSize={"15px"} fontWeight='bold'>
                      {ele.name}
                    </Typography>
                  </Box>
                  <Stack direction="row" spacing={1} p={0.5}  >
                    <img src={meetingRoomSize} height='14px' />
                    <Typography sx={{ fontSize: '10px' }}>{ele.capacity.padStart(2, '0')}</Typography>
                    <img src={stairs} height='14px' />
                    <Typography sx={{ fontSize: '12px' }} color='#4B4B4B'>{ele.floorName}</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1} p={0.5}  >
                    <img src={squareLayouts} height='14px' />
                    <Typography sx={{ fontSize: '12px' }} color='#4B4B4B'>{ele.amenities.join(', ')}</Typography>
                  </Stack>
                </Box>
                <Button
                  variant="outlined"
                  sx={{
                    color: "#FF6520",
                    borderColor: "#FF6520",
                    height: "100%",
                    textTransform: 'none',
                    fontWeight: 'bold',

                  }}
                  disabled={disabled}
                  onClick={() => { handleClick(ele.resourceId) }}
                >
                  Book
                </Button>

              </Stack>

            </Card>

          </Grid>

        )) : (
        <Box >

        
          <Paper sx={cardStyle}>
            <Box height='100%' sx={{display:'flex', alignItems:'center'}}>
          <Typography fontSize={'16px'} align='center'>
                  Sorry, no rooms available for the chosen criteria. Please go back and choose another building!
          </Typography>
          </Box>
          </Paper>
          </Box>
          )}
      </Grid>
    </Box>
  );
}
