import { Box } from '@mui/material'
import React from 'react'
import Header from '../../components/MeetingRooms/Header/Header'
import MeetingRoomPanel from '../../components/MeetingRooms/Container/Panel/MeetingRoomPanel'
import TabsContainer from '../../UI/MeetingRooms/TabsContainer/TabsContainer'
import MyMeetings from '../../components/MeetingRooms/Container/Panel/MyMeetings'
import { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MeetingRoomStatus from './MeetingRoomStatus'
import MeetingRoomHeader from '../../components/MeetingRooms/Header/MeetingRoomHeader'
const MeetingRoomBooking = (props) => {
    const location = useLocation()
    const history = useHistory()
    useEffect(() => {
        location.pathname === "/meetingrooms" && history.push("/meetingrooms/booking")
    }, [location.pathname])

    const firstTab = location.pathname === "/meetingrooms/update" ? "Update Meeting" : "Book a Room"
    return (
        <Box height='100%'>
            <Box ><MeetingRoomHeader title='Meeting Room Booking' link='/home' /></Box>
            <TabsContainer tabList={[firstTab, 'My meetings']} tabsPanel={[MeetingRoomPanel, MyMeetings]} />
            {/* <TabsContainer tabList={[firstTab, 'My meetings','Meeting rooms']} tabsPanel={[MeetingRoomPanel, MyMeetings,MeetingRoomStatus]} /> */}
        </Box >
    )
}

export default MeetingRoomBooking