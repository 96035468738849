import { useState, useEffect } from 'react'
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import ConfirmButton from '../../../UI/DeskBooking/Button/ConfirmButton'
import Booking from '../../../UI/DeskBooking/Card/Booking'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import Header from './Header'
import { useHistory } from 'react-router-dom'
import deskPass from '../../../Assets/DeskBooking/Images/deskPass.svg'
import swal from 'sweetalert'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import {startGetUpcomingBookings, startCancelMeeting , startCheckinMeeting} from "../../../Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice"

const DeskBookingHome = (props) => {
  const history = useHistory()
  const [bookedMeetings, setBookedMeetings] = useState(false)
  const appConfig=useSelector(state=>state?.config?.apps)?.find(ele=>ele?.configName==='deskBooking')
  const [upcoming, setUpcoming] = useState({})
  const dispatch = useDispatch()
  const upcomingBookings = useSelector(
    (state) => state?.bookings?.upcomingBookings,
  )
  const [newUpcomingBookings, setNewUpcomingBookings] = useState([])
  const [loader, setLoader] = useState(false)

  const handleNavigate = () => {
    history.push('/deskbooking/location')
  }
  const handleLoader = (val) => {
    setLoader(val)
  }

  useEffect(() => {
    
    
    const body = {
      currentDate: moment().format('YYYY-MM-DD'),
    }
    dispatch(startGetUpcomingBookings({body, handleLoader}))
    setLoader(true)
  }, [])

  useEffect(() => {
    if (upcomingBookings?.length > 0) {
      const date = moment().format('YYYY-MM-DD')
      const now = new Date().getHours()

      if (now > 12) {
        const currentMeeting = upcomingBookings.filter((ele) => {
          return (
            (ele.bookingDate === date && ele.secondHalf === true) ||
            (ele.bookingDate === date &&
              ele.firstHalf &&
              ele.secondHalf === true)
          )
        })

        if (currentMeeting.length > 0) {
          setUpcoming(currentMeeting[0])

          const newUpcoming = upcomingBookings.filter(
            (booking) => booking.id !== currentMeeting[0].id,
          )
          const pastBooking = newUpcoming.filter((ele) => {
            return ele.bookingDate === date && ele.firstHalf === true
          })
          const newUpcomingBookings = newUpcoming.filter((ele) => {
            let upcoming = pastBooking.filter(
              (booking) => booking.id === ele.id,
            )
            if (upcoming.length > 0) {
              return false
            } else {
              return true
            }
          })

          setNewUpcomingBookings(newUpcomingBookings)
          setBookedMeetings(true)
        } else {
          setUpcoming({})
          const pastBooking = upcomingBookings.filter((ele) => {
            return ele.bookingDate === date && ele.firstHalf === true
          })

          const newUpcomingBookings = upcomingBookings.filter((ele) => {
            let upcoming = pastBooking.filter(
              (booking) => booking.id === ele.id,
            )

            if (upcoming.length > 0) {
              return false
            } else {
              return true
            }
          })

          setNewUpcomingBookings(newUpcomingBookings)
          setBookedMeetings(true)
        }
      } else {
        const currentMeeting = upcomingBookings.filter((ele) => {
          return (
            (ele.bookingDate === date && ele.firstHalf === true) ||
            (ele.bookingDate === date &&
              ele.firstHalf &&
              ele.secondHalf === true)
          )
        })

        if (currentMeeting.length > 0) {
          const newUpcoming = upcomingBookings.filter(
            (booking) => booking.id !== currentMeeting[0].id,
          )

          setUpcoming(currentMeeting[0])
          setNewUpcomingBookings(newUpcoming)
          setBookedMeetings(true)
        } else {
          setUpcoming({})
          setNewUpcomingBookings(upcomingBookings)
          setBookedMeetings(true)
        }
      }
    } else {
      setUpcoming({})
      setNewUpcomingBookings([])
    }
  }, [upcomingBookings])

  const handleCancelMeeting = () => {
    const data = { bookingId: upcoming?.id }
    dispatch(startCancelMeeting({id:upcoming?.id, handleLoader}))
    setLoader(true)
  }

  const cancelMeeting = () => {
    swal({
      title: 'Are you sure you want to cancel the booking?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleCancelMeeting()
      } else {
        // swal("Meeting is not cancelled");
      }
    })
  }

  const handleCheckIn = () => {
    const bookingId = upcoming?.id
    dispatch(startCheckinMeeting(bookingId))
  }

  const checkIn = () => {
    swal({
      title: 'Are you sure you want to check in?',
      icon: 'info',
      buttons: {
        cancel: {
          text: 'Cancel',
          value: null,
          visible: true,
        },
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        handleCheckIn()
      } else {
        //   swal("");
      }
    })
  }

  return (
    <Box p={0}>
      <Header title="Desk Booking" />
      <Box p={2}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: '#FF6520',
              textTransform: 'none',
              marginBottom: 2,
              width: '92%',
              height: '60px',
              fontWeight: 'bold',
            }}
            onClick={handleNavigate}
          >
            Book a desk
          </Button>
        </Box>
        <Typography align="center" fontWeight={'bold'} pt={2}>
          Current Booking
        </Typography>
        {Object.keys(upcoming).length > 0 ? (
          <>
            <Box sx={{ boxShadow: 0 }}>
              <Box
                style={{
                  backgroundImage: `url(${deskPass})`,
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '200px',
                }}
              >
                <Box width="100%" m={2}>
                  <Stack pl={'23vw'} pt={'3.5vh'} spacing={'1.5vh'}>
                    <Stack direction="row">
                      <Stack>
                        <Typography fontSize={24} fontWeight={'bold'}>
                          {upcoming?.deskCode}
                        </Typography>
                        <Typography color="#AAAAAA" fontSize="16px">
                          Seat no
                        </Typography>
                      </Stack>
                      {upcoming?.checkedIn && (
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          width="65%"
                          mt={0.5}
                        >
                          <CheckCircleOutlineIcon style={{ color: 'green' }} />
                          <Box ml={0.5}>
                            <Typography fontSize={14}>Checked In!</Typography>
                          </Box>
                        </Box>
                      )}
                    </Stack>
                    <Stack>
                      <Typography fontSize={16} color="#212121">
                        {upcoming?.building}, {upcoming?.floor}
                      </Typography>

                      <Typography>
                        {moment(upcoming?.bookingDate).format('Do MMM YYYY')},{' '}
                        {upcoming?.firstHalf && upcoming?.secondHalf
                          ? 'Full Day'
                          : upcoming.firstHalf
                          ? 'First Half'
                          : 'Second Half'}
                      </Typography>
                    </Stack>
                  </Stack>
                </Box>
              </Box>
              {!upcoming.checkedIn && (
                <Stack
                  direction="row"
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  spacing={1}
                  m={0}
                  p={1}
                  pb={'2.2vh'}
                  pt={'1.2vh'}
                >
                  <ConfirmButton
                    backgroundColor="#494949"
                    title={'Cancel Booking'}
                    onclick={cancelMeeting}
                  />
                  <ConfirmButton
                    backgroundColor="#FF6520"
                    title={'Check In'}
                    onclick={checkIn}
                  />
                </Stack>
              )}
            </Box>
          </>
        ) : (
          <Paper
            sx={{
              height: '40vh',
              mx: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 3,
              mt: 2,
            }}
          >
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography align="center" width="70%" pb={1}>
                  You don't have any desks booked for current time!
                </Typography>
              </Box>
            </Stack>
          </Paper>
        )}

        {bookedMeetings && newUpcomingBookings.length > 0 ? (
          <Box p={0}>
            <Box width="100%" borderRadius="2px">
              <Typography
                fontWeight={'bold'}
                align="center"
                fontSize="14px"
                variant="h6"
                mb={1}
                pl={2}
              >
                {' '}
                Upcoming Bookings{' '}
              </Typography>
              {newUpcomingBookings?.map((ele) => (
                <>
                  <Paper
                    sx={{
                      height: '100%',
                      mt: 2,
                      boxShadow: 2,
                      borderRadius: '8px',
                    }}
                  >
                    <Booking data={ele} />
                  </Paper>
                </>
              ))}
            </Box>
          </Box>
        ) : (
          <Paper
            sx={{
              height: '40vh',
              mx: 2,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mb: 3,
              mt: 2,
              backgroundColor: '#D9D9D9',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <Typography pb={1}>No upcoming bookings</Typography>
            </Box>
          </Paper>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  )
}

export default DeskBookingHome
