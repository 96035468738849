import React from 'react'
import { styled } from '@mui/system'
import { Box, Card } from '@mui/material'

export const FaultImageCard=styled(Card)({
    backgroundColor:'#D9D9D9',
    borderRadius:'10px',
    height:'15vh',
    width:'100%',
})

export const TextFieldStyle={
    backgroundColor:"#FFFFFF",
    width:'100%',
    marginBottom:'1vh'
}

export const PaddingBox=styled(Box)({
    padding:'16px'
})

export const NoTopPaadingBox=styled(Box)({
    padding:'16px',
    paddingTop:'0px'
})

export const BrokenImageBox=styled(Box)({
    display:'flex',
    justifyContent:'center', 
    alignItems:'center', 
    height:'100%'
})

export const ModalStyle = {
    position: "absolute",
    // border:"1px solid black",
    top: "50%",
    // height: "50%",
    // bottom: -10,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    outline: "0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
    // pl: 2,
    //m:1
};

export const imageModalStyle={
    position: 'absolute',
    outline:'none',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    height:'80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
   
    borderRadius:'20px'
}