import React, { useEffect, useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import Header from '../../Header/Header'
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
  IconButton,
  Grid,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CardTitle from '../../../../UI/MeetingRooms/Widgets/MeetingRoom/CardTitle'
import AddGuestInput from '../../AddGuestInput/AddGuestInput'
import GuestDescription from '../../GuestDescription/GuestDescription'
import ConfirmButton from '../../../../UI/MeetingRooms/Button/ConfirmButton'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  startDeleteMeeting,
  startUpdateMeeting,
  resetListIsDeleted,
} from '../../../../Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import ClearIcon from '@mui/icons-material/Clear'
import { useHistory } from 'react-router-dom'
import swal from 'sweetalert'

import garbage from '../../../../Assets/MeetingRooms/Images/garbage.png'
import text from '../../../../Assets/MeetingRooms/Images/text.png'
import meetingRoomSize from '../../../../Assets/MeetingRooms/Images/meetingRoomSize.png'
import calendar from '../../../../Assets/MeetingRooms/Images/calendar.png'
import stairs from '../../../../Assets/MeetingRooms/Images/stairs.png'
import information from '../../../../Assets/MeetingRooms/Images/information.png'
import addUserIcon from '../../../../Assets/MeetingRooms/Images/addUserIcon.png'
import img from '../../../../Assets/MeetingRooms/Images/img.svg'
import MeetingRoomHeader from '../../Header/MeetingRoomHeader'

function AdditionalDetails(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [attendeesList, setAttendeesList] = useState([])
  const [isError, setIsError] = useState(false)
  const [guestEmail, setGuestEmail] = useState('')
  const style = 'sx={{ stroke: "#ffffff", strokeWidth: 1 }}'
  const [description, setDescription] = useState('')
  const createdEvent = useSelector((state) => {
    const length = state?.meetings?.list?.data?.length
    return state?.meetings?.list?.data[length - 1] || ''
  })
  const createdEventIsLoaded=useSelector(state=>state?.meetings?.list?.isLoaded)
  const eventId = createdEvent?.id || ''
  const meetingIsDeleted = useSelector(
    (state) => state?.meetings?.list?.isDeleted,
  )
  const [loader, setLoader] = useState(false)
  const handleLoader = (val) => {
    setLoader(val)
  }
  const handleChange = (e) => {
    if (e.target.name === 'email') {
      setIsError(false)
      const value = e.target.value
      setGuestEmail(e.target.value)
      if (value[value.length - 1] === ',') {
        let finalVal
        finalVal = value.slice(0, -1)
        if (
          isEmail(finalVal.trim()) &&
          !attendeesList.includes(finalVal.toLowerCase().trim())
        ) {
          setAttendeesList([...attendeesList, finalVal.toLowerCase().trim()])
          setGuestEmail('')
        } else {
          setIsError(true)
        }
      }
    }

    if (e.target.name === 'description') setDescription(e.target.value)
  }

  const handleGuestInputBlur = () => {
    if (guestEmail.length > 0) {
      if (
        isEmail(guestEmail.trim()) &&
        !attendeesList.includes(guestEmail.toLowerCase().trim())
      ) {
        setAttendeesList([...attendeesList, guestEmail.toLowerCase().trim()])
        setGuestEmail('')
      } else {
        setIsError(true)
      }
    }
  }
  const handleRedirect = () => {
    history.push('/meetingrooms/update/confirmation')
    handleLoader(false)
  }
  const handleDelete = (email) => {
    setAttendeesList(attendeesList.filter((e) => e !== email))
  }
  const eventTime = (startTime, endTime) => {
    const startDate = moment(startTime).format('ddd DD MMM')
    const endDate = moment(endTime).format('ddd DD MMM')
    const start = moment(startTime).format('hh:mm A')
    const end = moment(endTime).format('hh:mm A')

    return startDate === endDate
      ? `${startDate} ${start} to ${end} `
      : `${startDate}-${endDate} ${start} to ${end} `
  }

  const handleDeleteClick = () => {
    swal({
      title: 'Are you sure you want to cancel this meeting?',
      icon: 'warning',
      buttons: [true, 'Yes'],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleLoader(true)
        dispatch(startDeleteMeeting(createdEvent.id, handleLoader))
      } else {
        swal("Meeting hasn't been cancelled")
      }
    })
  }
  useEffect(() => {
    if (meetingIsDeleted) {
      history.push('/meetingrooms/booking')
    }
  }, [meetingIsDeleted])

  useEffect(()=>{
    if(createdEventIsLoaded&&Object.keys(createdEvent).length===0){
        history.push('/meetingrooms/booking')
    }
  },[])

  const handleData=()=>{
    // { eventId, attendeesArr: attendeesList, description }
    const formData={}
    formData.eventId=eventId
    formData.attendeesArr=attendeesList
    if(description){
        formData.description=description
    }
    return formData
  }
  return (
    <Box height="100vh">
      <Grid container>
        <Grid item xs={12}>
          <MeetingRoomHeader
            title="Add Additional Details"
            trashLogo={garbage}
            handleDeleteClick={handleDeleteClick}
            id={eventId}
          />
        </Grid>
      </Grid>
      <Box p={2}>
        <Box>
          <Box pb={1.5}>
            <Card sx={{ borderRadius: '20px', p: 1, boxShadow: 0 }}>
              <Grid container>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <img src={img} height="40px" />
                </Grid>
                <Grid item xs={10}>
                  <Typography color="#AAAAAA" fontSize="14px">
                    Scheduled at
                  </Typography>
                  <Typography fontSize={'16px'} color="#212121">
                    {createdEvent.name}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>

          <Card
            sx={{
              marginBottom: '20px',
              borderRadius: '15px',
              position: 'relative',
            }}
          >
            <Stack p={2} spacing={1}>
              <CardTitle icon={text} bold="true" title={createdEvent.summary} />

              <CardTitle
                icon={meetingRoomSize}
                title={createdEvent.seatingCapacity}
              />
              <CardTitle
                icon={calendar}
                title={eventTime(createdEvent.startTime, createdEvent.endTime)}
              />
              <CardTitle icon={stairs} title={createdEvent.floor} />
            </Stack>
          </Card>

          <Box p={1} pr={0}>
            <CardTitle icon={addUserIcon} title="Add Guests" />
            <AddGuestInput
              name="email"
              onChange={handleChange}
              value={guestEmail}
              onblur={handleGuestInputBlur}
              isError={isError}
              width="90vw"
            />
            <Box p={1}>
              {attendeesList.map((email) => {
                return (
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    key={email}
                  >
                    <Typography key={email}>{email}</Typography>
                    <IconButton
                      sx={{ m: 0, p: 0 }}
                      onClick={() => {
                        handleDelete(email)
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box p={1} pb={3} pr={0}>
            <CardTitle icon={information} title="Description (Optional)" />
            <GuestDescription
              value={description}
              name="description"
              width="90vw"
              onChange={handleChange}
            />
          </Box>
        </Box>

        <Box
          p={0}
          display="flex"
          justifyContent="center"
          width="100%"
        >
          <Stack direction="row" spacing={2}>
            <ConfirmButton
              title="Cancel"
              color="#212121"
              link="/meetingrooms/confirmation"
            />
            <ConfirmButton
              setIsError={setIsError}
              guestEmail={guestEmail}
              attendees={attendeesList}
              loader={handleLoader}
              title="Confirm"
              color="#FF6520"
              action={startUpdateMeeting}
              handleRedirect={handleRedirect}
              data={handleData()}
            />
          </Stack>
        </Box>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
    </Box>
  )
}
export default AdditionalDetails
