import React from 'react'
import { useState,useEffect } from "react";
import QrReader from 'modern-react-qr-reader'
import { setCompFb } from '../../Redux/ReduxSlice/Feedback/CompSliceFb';
import { useDispatch , useSelector} from 'react-redux';
import { Box, Modal, Paper, Typography ,Backdrop , CircularProgress} from '@mui/material';
import { ModalStyle } from '../../UI/FeedBack/QrCodeFeedBackStyles';
import {Grid, IconButton} from '@mui/material'
import { startGetSubArea } from '../../Redux/ReduxSlice/Feedback/SubAreaSlice';
import back from '../../Assets/Images/left-arrow.png'
import swal from 'sweetalert';


const QrCodeFeedBack=()=>{
  const dispatch=useDispatch()
  const [loader, setLoader] = useState(false)
    const [open,setOpen]=useState(false)
  const buildingId = useSelector(state => state?.user?.data?.buildingId)
  
  const handleError=()=>{
    swal({  
        title: " Oops!",  
        text: "The qr code you are trying to scan does not belong to your building!",  
        icon: "error",  
        button: "Ok",  
      })
      .then((okay) => {
        if (okay) {
            setLoader(false)
            
        } else {
           setLoader(false)                       
        }
      }); 
    setLoader(false)
  }
  const handleScan=(e)=>{
    if(e){
      const scannedString=e
      dispatch(startGetSubArea({scannedString, buildingId,handleError}))      
      setLoader(true)
    }
  }

  useEffect(() => {
    if(navigator?.mediaDevices?.getUserMedia){
        navigator.mediaDevices.getUserMedia(
        {
          video: true
        }, 
        function(localMediaStream){}, 
        function(err){
          alert('The following error occurred when trying to access the camera: ' + err); 
        }
      );
      } else {
        alert('Sorry, browser does not support camera access');
      }
  }, [])


  useEffect(()=>{
    if(open){
      setTimeout(()=>{
        setOpen(false)
      },3000)
    }
  },[open])

   const handleBackClick = () => {
    dispatch(setCompFb('Feed Back Home'))
  }
    return (
        <Box height='100vh'>
            <Grid
          container
          spacing={0}
          display="flex"
          alignItems={'center'}
          height={70}
          backgroundColor="#FF6520"
          mb={2}
          p={2}  
        >
          <Grid item xs={1.2}>
            <IconButton size="small" float="left" onClick={handleBackClick}>
              <img src={back} width={'24px'} />
            </IconButton>
          </Grid>
          <Grid item xs={10.8} display="flex" pl={2}>
            <Typography fontSize={20} align="left" color='#FFFFFF'>
              {'FeedBack'}
            </Typography>
          </Grid>
        </Grid>
        <QrReader
          delay={300}
          facingMode="environment"
          onScan={handleScan}
          style={{ width: '100%'}}
        />

        <Modal open={open}>
            <Paper sx={ModalStyle}>
                  <Typography>Unable to scan. Please try again</Typography>
            </Paper>
        </Modal>
          <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        </Box>
    )
}

export default QrCodeFeedBack