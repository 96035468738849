import { Switch } from "react-router-dom";
import Location from "../components/DeskBooking/Container/Location"
import TransitionsModal from "../components/DeskBooking/TransitionsModal"
import MonthlyDates from "../components/DeskBooking/Container/Date"

import Floor from "../components/DeskBooking/Container/Floor";
import Confirmed from "../components/DeskBooking/Container/confirmed";
import MeetingDetailsPanel from "../components/DeskBooking/Container/MeetingDetailsPanel"
import DeskBookingHome from "../components/DeskBooking/Container/DeskBookingHome";
import Home from '../pages/MeetingRooms/Home'
import FaultReporting from "../pages/FaultReporting/FaultReporting";
import MeetingRoomRoutes from "./MeetingRoomRoutes";
import Alerts from "../pages/Alerts";
import FeedBack from "../pages/FeedBack/Feedback";
 
import AssetManager from "../pages/AssetManager/AssetManager";
import AccountProfile from "../pages/Profile";
import Occupancy from "../pages/Occupancy/Occupancy";
import Login from "../pages/Login";
import PrivateRoute from "../components/Authentication/PrivateRoute";
import Building from "../components/DeskBooking/Container/Building";
import ImageTest from "../pages/ImageTest";
import ManageVistor from "../pages/ManageVisitor/ManageVistor";

const Router = (props) => {
    return (
        <Switch>
            <PrivateRoute path="/" component={Home} exact />
            <PrivateRoute path="/meetingrooms" component={MeetingRoomRoutes} />
            <PrivateRoute path="/deskbooking/location"  component={Location} exact />
            <PrivateRoute path="/deskbooking/building"  component={Building} exact />
            <PrivateRoute path='/notifications' component={Alerts}exact />
            <PrivateRoute path='/feedback' component={FeedBack} />
            <PrivateRoute path='/profile' component={AccountProfile} />
            <PrivateRoute path='/assetmanager' component={AssetManager} exact />
            <PrivateRoute path="/deskbooking/modal" component={TransitionsModal} exact />
            <PrivateRoute path='/deskbooking/home' component={DeskBookingHome} />
            <PrivateRoute path="/deskbooking/date" component={MonthlyDates} exact />
            <PrivateRoute path="/deskbooking/upcoming" component={DeskBookingHome} exact />
            <PrivateRoute path="/deskbooking/floor" component={Floor} />
            <PrivateRoute path="/deskbooking/confirmed" component={Confirmed} />
            <PrivateRoute path="/deskbooking/meetingDetailsPanel" component={MeetingDetailsPanel} />
            <PrivateRoute path='/faultreporting' component={FaultReporting} />
            <PrivateRoute path='/occupancy' component={Occupancy} />
            <PrivateRoute path='/manageVisitor' component={ManageVistor} />
            <PrivateRoute path='/login' component={Login} />
        </Switch>
    );
}

export default Router