import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
export const SET_COMP_FB='SET_COMP_FB'


const initialState={
    title:'Feed Back Home',
}

const fbCompSlice=createSlice({
    name:'fbCompSlice',
    initialState,
    reducers:{
        setCompFb:(state,action)=>{
            state.title=action.payload
        }
    }
})

export const {setCompFb}=fbCompSlice.actions

export default fbCompSlice.reducer
// export const setCompFb=(data)=>{
//     return {
//         type:SET_COMP_FB,
//         payload:data
//     }
// }