import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  Skeleton,
  Button,
  Drawer,
  Paper,
  Grid,
  Card,
  Stack,
  IconButton,
  TextField,
  Modal,
} from '@mui/material'
import {
  ModalStyle,
  MuiCard,
  MuiBoxBottom,
  imageStyle,
  BackButtonStyle,
  SelectedBox,
} from '../../UI/FaultReporting/Styles/AssetDetailsStyles'
import back from '../../Assets/Images/left-arrow.png'
import ClearIcon from '@mui/icons-material/Clear'
import { Global } from '@emotion/react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import FaultDescription from '../../components/FaultReporting/Panel/FaultDescription'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { useDispatch, useSelector } from 'react-redux'
import correct from '../../Assets/Images/correct.svg'

import {
  resetBuildings,
  startGetBuildings,
} from '../../Redux/ReduxSlice/FaultReporting/BuildingSlice'
import {
  resetFloor,
  startGetFloors,
} from '../../Redux/ReduxSlice/FaultReporting/FloorSlice'
import {
  resetArea,
  startGetAreas,
} from '../../Redux/ReduxSlice/FaultReporting/AreaSlice'
import { startCreateTicket } from '../../Redux/ReduxSlice/FaultReporting/TicketsSlice'

function AssetDetails(props) {
  const imageLink=useSelector(state=>state?.config?.imageLink)
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const [buildingsButton, setBuildingsButton] = useState({})
  const [FloorButton, setFloorButton] = useState('')
  const [areaButton, setAreaButton] = useState('')
  const [description, setDescription] = useState('')
  const [radioButton, setRadioButton] = useState('')

  const [load, setLoad] = useState(false)
  const [assetTextBox, setAssetTextBox] = useState('')
  const faultImageUrl = useSelector((state) => state.faultImage).awsUrl
  const [other, setOther] = useState('')

  const buildings = useSelector((state) => state.frBuildings).buildings
  const buildingsIsLoaded = useSelector((state) => state.frBuildings).isLoaded

  const frFloors = useSelector((state) => state.frFloors).floors
  const floorsIsLoaded = useSelector((state) => state.frFloors).isLoaded

  const frAreas = useSelector((state) => state.frAreas).areas

  const areasIsLoaded = useSelector((state) => state.frAreas).isLoaded
  const handleClick = () => {
    setOpen(true)
  }

  const imageBoxStyle = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '85vh',
    display: 'inline-block',
    position: 'relative',
  }

  const handleChange = (e, category) => {
    if (category === 'building') setBuildingsButton(e)
    if (category === 'floor') setFloorButton(e)
    if (category === 'area') setAreaButton(e)
    if (e.target?.name === 'description') setDescription(e.target.value)
    if (e.target?.name === 'radio') setRadioButton(e.target.value)
    if (e.target?.name === 'assetTextBox') setAssetTextBox(e.target.value)
    if (e.target?.name === 'other') setOther(e.target.value)
  }

  const handleLoad = () => {
    setLoad(true)
  }

  const handleClikedModal = (e) => {
    e.preventDefault()
    const ticket = {
      reportedOn: Number(new Date()),
      buildingId: buildingsButton.id,
      floorId: FloorButton.id ? FloorButton.id : '',
      imageUrl: faultImageUrl,
      areaId: areaButton.id ? areaButton.id : '',
      description: description,
      issue: radioButton == 'other' ? other : radioButton,
      title: assetTextBox,
    }

    dispatch(startCreateTicket({ticket, handleLoad}))
  }

  useEffect(() => {
    if (load) {
      setTimeout(() => {
        dispatch(setComp('Fault Reporting'))
        setLoad(false)
        dispatch(resetArea())
        dispatch(resetBuildings())
        dispatch(resetFloor())
        setBuildingsButton('')
        setFloorButton('')
        setAreaButton('')
        setDescription('')
        setRadioButton('')
      }, 2000)
    }
  }, [load])

  useEffect(() => {
    return () => {
      dispatch(resetBuildings())
      dispatch(resetArea())
      dispatch(resetFloor())
    }
  }, [])

  const handleBackClick = () => {
    dispatch(setComp('Camera'))
  }

  const handleRemoveFloor = () => {
    setFloorButton('')
    setAreaButton('')
    setAssetTextBox('')
    setRadioButton('')
    setOther('')
  }

  const handleRemoveArea = () => {
    setAreaButton('')
    setAssetTextBox('')
    setRadioButton('')
    setOther('')
  }

  const handleRemoveBuilding = () => {
    setBuildingsButton('')
    setFloorButton('')
    setAreaButton('')
  }


  const handleClickSkipFloor = (areaButton) => {
    setFloorButton(areaButton)
  }

  const handleClickSkipArea = (assetTextBox) => {
    setAreaButton(assetTextBox)
  }

  useEffect(() => {
    dispatch(startGetBuildings())
  }, [])

  useEffect(() => {
    if (Object.keys(buildingsButton).length>0) {
      dispatch(startGetFloors({id:buildingsButton?.id}))
    }
  }, [buildingsButton])

  useEffect(() => {
    if (FloorButton) {
      dispatch(startGetAreas({buildingId:buildingsButton?.id,floorId:FloorButton?.id || ''}))
    }
  }, [FloorButton])

  return (
    <Box onClick={handleClick}>
      <Box style={imageBoxStyle}>
        <img
          src={`${imageLink}${faultImageUrl}`}
          alt={'snap'}
          style={imageStyle}
        ></img>
        <Box style={BackButtonStyle} onClick={handleBackClick}>
          <img src={back} height="24px" />
        </Box>
      </Box>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(65%)`,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
        }}
      />
      <Box></Box>
      <Drawer
        anchor="bottom"
        open={true}
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box p={1} m={1}>
          <Grid container item spacing={1.5} pb={0}>
            <Grid item xs={12}>
              <Typography variant="h5" fontSize={'20px'}>
                Please add asset details
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" fontSize={'16px'} color="#4B4B4B">
                Select the building of the asset being reported
              </Typography>
            </Grid>
          </Grid>
          <Grid pt={0.5}>
            {Object.keys(buildingsButton).length > 0 ? (
              <Grid container pt={1}>
                <Grid item xs={6}>
                  <Typography
                    key={buildingsButton.id}
                    fontSize="1.1rem"
                    gutterBottom
                    color="#212121"
                  >
                    <SelectedBox>
                      <Typography
                        variant="button"
                        fontSize="14px"
                        textTransform="none"
                        display={'flex'}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {buildingsButton.name || "skipped"}
                      </Typography>
                    </SelectedBox>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    sx={{
                      m: 0,
                      p: 0,
                    }}
                    onClick={handleRemoveBuilding}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ) : (
              <Grid container item spacing={1} pt={1}>
                {buildingsIsLoaded &&
                  buildings?.map((building) => (
                    <Grid
                      key={building.id}
                      item
                      xs={6}
                      value={buildingsButton.name}
                      onClick={() => {
                        handleChange(building, 'building')
                      }}
                    >
                      <MuiCard>
                        <Typography
                          variant="button"
                          fontSize="14px"
                          textTransform="none"
                          display={'flex'}
                          justifyContent="center"
                          alignItems="center"
                        >
                          {building.name}
                        </Typography>
                      </MuiCard>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>

          <Grid mt={1.5}>
            {Object.keys(buildingsButton).length > 0 &&
              (Object.keys(FloorButton).length > 0 ? (
                <Grid>
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      fontSize={'16px'}
                      color="#4B4B4B"
                    >
                      Select the floor of the asset
                    </Typography>
                    <Grid container pt={1}>
                      <Grid item xs={6}>
                        <MuiCard>
                          <Typography
                            variant="button"
                            fontSize="14px"
                            textTransform="none"
                            color="#212121"
                          >
                            {FloorButton.name || "skipped"}
                          </Typography>
                        </MuiCard>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton
                          sx={{
                            m: 0,
                            p: 0,
                          }}
                          onClick={handleRemoveFloor}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              ) : (
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontSize={'16px'}
                      color="#4B4B4B"
                    >
                      Select the floor of the asset
                    </Typography>
                  </Grid>
                  {floorsIsLoaded &&
                    frFloors.map((floor) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          width="100%"
                          key={floor.id}
                          value={FloorButton.name}
                          onClick={() => {
                            handleChange(floor, 'floor')
                          }}
                        >
                          <MuiCard>
                            <Typography
                              variant="button"
                              fontSize="14px"
                              textTransform="none"
                              color="#212121"
                            >
                              {floor.name}
                            </Typography>
                          </MuiCard>
                        </Grid>
                      )
                    })}
                  <Grid item xs={12} mt={0}>
                    <MuiBoxBottom onClick={handleClickSkipFloor}>
                      <Typography
                        p={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Not Sure, which floor? Click here to skip
                      </Typography>
                    </MuiBoxBottom>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          <Grid mt={1.5}>
            {Object.keys(buildingsButton).length > 0 &&
              Object.keys(FloorButton).length > 0 &&
              (Object.keys(areaButton).length > 0 ? (
                <Grid item xs={12}>
                  <Stack>
                    <Typography
                      variant="subtitle2"
                      fontSize={'16px'}
                      color="#4B4B4B"
                    >
                      Select the area of the asset
                    </Typography>
                    <Grid container pt={1}>
                      <Grid item xs={6}>
                        <Typography
                          key={areaButton}
                          fontSize="18px"
                          gutterBottom
                          color="#212121"
                        >
                          <SelectedBox>
                            <Typography
                              variant="button"
                              fontSize="14px"
                              textTransform="none"
                              display={'flex'}
                              justifyContent="center"
                              alignItems="center"
                            >
                              {areaButton.name || "skipped"}
                            </Typography>
                          </SelectedBox>
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        display="flex"
                        justifyContent={'flex-end'}
                        alignItems="center"
                      >
                        <IconButton
                          sx={{
                            m: 0,
                            p: 0,
                          }}
                          onClick={handleRemoveArea}
                        >
                          <ClearIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid>
              ) : (
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontSize={'16px'}
                      color="#4B4B4B"
                    >
                      Select the area of the asset
                    </Typography>
                  </Grid>
                  {areasIsLoaded &&
                    frAreas.map((area) => {
                      return (
                        <Grid
                          item
                          xs={6}
                          key={area.id}
                          name="area"
                          value={areaButton.name}
                          onClick={() => {
                            handleChange(area, 'area')
                          }}
                        >
                          <MuiCard>
                            <Typography
                              variant="button"
                              fontSize="14px"
                              textTransform="none"
                              color="#212121"
                            >
                              {area.name}
                            </Typography>
                          </MuiCard>
                        </Grid>
                      )
                    })}
                  <Grid item xs={12} mt={0}>
                    <MuiBoxBottom onClick={handleClickSkipArea}>
                      <Typography
                        p={2}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        Not Sure, which area? Click here to skip
                      </Typography>
                    </MuiBoxBottom>
                  </Grid>
                </Grid>
              ))}
          </Grid>

          <Grid mt={1.5}>
            {Object.keys(buildingsButton).length > 0 &&
              Object.keys(areaButton).length > 0 && (
                <Grid container item spacing={1}>
                  <Grid item xs={12}>
                    <Typography
                      variant="subtitle2"
                      fontSize={'16px'}
                      color="#4B4B4B"
                    >
                      Name of the asset
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      sx={{ width: '100%' }}
                      variant="outlined"
                      value={assetTextBox}
                      name="assetTextBox"
                      autoComplete="off"
                      inputProps={{ maxLength: 30 }}
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>
              )}
          </Grid>
          <Grid pt={1}>
            {Object.keys(buildingsButton).length > 0 &&
            Object.keys(FloorButton).length > 0 &&
            Object.keys(areaButton).length > 0 ? (
              <Box pt={1}>
                <Grid item xs={6}>
                  <Typography
                    variant="subtitle2"
                    fontSize={'16px'}
                    color="#4B4B4B"
                  >
                    What is wrong with this asset?
                  </Typography>
                </Grid>
                <Box p={1} pt={0} pb={0.5} pl={2}>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      color="#AAAAAA"
                      value={radioButton}
                      name="radio"
                      onChange={(e) => handleChange(e)}
                    >
                      <FormControlLabel
                        value="Not working as intended"
                        control={<Radio color="warning" />}
                        label="Not working as intended"
                      />
                      <FormControlLabel
                        value="Its Broken"
                        control={<Radio color="warning" />}
                        label="Its Broken"
                      />
                      <FormControlLabel
                        value="Need Maintainance soon"
                        control={<Radio color="warning" />}
                        label="Need Maintainance soon"
                      />
                      <FormControlLabel
                        value="Need replacement ASAP"
                        control={<Radio color="warning" />}
                        label="Need replacement ASAP"
                      />
                      <FormControlLabel
                        value="Requires part replacement"
                        control={<Radio color="warning" />}
                        label="Requires part replacement"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio color="warning" />}
                        label="Other"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Stack sx={{ width: '100%' }}>
                    {radioButton === 'other' ? (
                      <TextField
                        value={other}
                        name="other"
                        placeholder="Enter the asset fault in short"
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                        onChange={(e) => handleChange(e)}
                      />
                    ) : (
                      ''
                    )}
                  </Stack>
                </Box>
                <Box pb={3} pt={1}>
                  <Typography fontSize={'16px'} color="#4B4B4B">
                    Add Description (Optional)
                  </Typography>
                  <Box pt={1}>
                    <FaultDescription
                      value={description}
                      name="description"
                      onChange={(e) => handleChange(e)}
                      maxLength={{maxLength:180}}
                    />
                  </Box>
                  <Typography fontSize="12px" align="right">
                    {180 - Number(description.length)} characters left
                  </Typography>
                </Box>
                <Stack>
                  <Modal open={load}>
                    <Paper sx={ModalStyle}>
                      <Stack pb={2} p={2}>
                        <Box display="flex" justifyContent="center" pb={3}>
                          <img src={correct} width="75px" />
                        </Box>
                        <Typography
                          variant="h5"
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                          fontWeight="bold"
                          fontSize={'24px'}
                          pb={2}
                        >
                          Thank you!
                        </Typography>
                        <Typography
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                          fontSize="16px"
                        >
                          You request was received successfully,
                        </Typography>
                        <Typography
                          display={'flex'}
                          justifyContent={'center'}
                          alignItems={'center'}
                          textAlign={'center'}
                          fontSize="16px"
                        >
                          We have notified the facility manager of this issue.
                        </Typography>
                      </Stack>
                    </Paper>
                  </Modal>

                  <Button
                    variant="contained"
                    color="warning"
                    sx={{
                      width: '100%',
                      height: '48px',

                      color: '#FFFFFF',
                      fontSize: '16px',
                      fontWeight: 'bold',
                      textTransform: 'none',
                      borderRadius: '10px',
                    }}
                    onClick={handleClikedModal}
                    disabled={
                      assetTextBox.trim().length <= 0 ||
                      radioButton === '' ||
                      (radioButton === 'other' && other.trim().length <= 0)
                    }
                  >
                    Submit
                  </Button>
                </Stack>
              </Box>
            ) : (
              ''
            )}
          </Grid>
        </Box>
      </Drawer>
    </Box>
  )
}
export default AssetDetails
