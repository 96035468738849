import { Box, IconButton, Typography, Grid } from '@mui/material'
import React, { useCallback } from 'react'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useHistory } from 'react-router-dom';
import { setComp } from '../../../Redux/ReduxSlice/FaultReporting/compSlice';
import { useDispatch } from 'react-redux';
import QrCodeIcon from '@mui/icons-material/QrCode';
import backIcon from '../../../Assets/Images/left-arrow.png'
import qr from '../../../Assets/Images/qr.png'
function Nav({ title, route }) {
    const dispatch=useDispatch()
    const history = useHistory()
    const handleClick = useCallback(() => {
        title==='Fault Reporting'?history.push(route):dispatch(setComp('Fault Reporting'))
        
    }, [title])

    const handleQrCodeClick=()=>{
        dispatch(setComp('QrCode'))
    }
    return (
        <Box p={0} pb={0}>
        <Box sx={{ width: "100%", minHeight: "100%" }}>
            <Grid container spacing={0} display='flex' alignItems={'center'} height={70} backgroundColor='#FF6520' p={2}  >
            <Grid item xs={1.2} >
                <IconButton size='small' float='left' onClick={handleClick} >
                    <img src={backIcon}  width={'24px'} />
                </IconButton>

            </Grid>
            <Grid item xs={9.8} display='flex' >
                <Typography fontSize={20} align='left' color={'#FFFFFF'}>{title}</Typography>
            </Grid>
            
            { title==='Fault Reporting'&&<Grid item xs={1} pr={2}  display='flex' justifyContent={'center'}>
                <IconButton onClick={handleQrCodeClick}>
                    {/* <QrCodeIcon style={{color:'#FFFFFF'}}/> */}
                    <img src={qr} width={'24px'} />
                 </IconButton>
            </Grid>}
           
        </Grid>

        </Box>
        </Box>
    )
}

export default Nav