import axios from "axios"
export const UPDATE_AQI_DATA='UPDATE_AQI_DATA'


export const startFetchAqiData=()=>{
    return (dispatch)=>{
        axios.get(`${process.env.REACT_APP_DASHBOARD_URL}common/aqi/carousellatest`,{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((response)=>{
                const result=response.data
                if(result.success&&result.message === "Data Found"){
                    
                    dispatch(updateAqiData(result.data))
                }
                else {
                    console.log({response})
                }
            })
            .catch((err)=>{
                console.log(err)
            })            
    }
}

const updateAqiData=(payload)=>{
    return {
        type:UPDATE_AQI_DATA,
        payload
    }
}