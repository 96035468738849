import './App.css'
import axios from 'axios'
import Router from './Routes/Routes'
import { Box, CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { lightTheme } from './Helpers/ThemeHandler'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import { setUser } from './Redux/actions/MeetingRooms/userActions';
import { setUser } from './Redux/ReduxSlice/Common/userSlice'
import { startFetchMeetings } from './Redux/ReduxSlice/MeetingRooms/meetingsSlice'
import moment from 'moment'
import { startGetAllTickets } from './Redux/ReduxSlice/FaultReporting/TicketsSlice'
// import { startGetUpcomingBookings } from './Redux/ReduxSlice/DeskBooking/bookingAction';
import { startGetUpcomingBookings } from './Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice'
import { startGetUserData } from './Redux/ReduxSlice/Common/userSlice'
import swal from 'sweetalert'
import {
    startFetchAppsConfig,
    startFetchLogo,
    updateAppsConfig,
} from './Redux/ReduxSlice/Common/appsSlice'

function App() {
    const dispatch = useDispatch()
    const [isLoaded, setIsLoaded] = useState(false)
    const location = useLocation()
    const history = useHistory()
    const { tenantCode, email } = useSelector((state) => state?.user?.data)

  useEffect(() => {
    const date = moment().format('YYYY-MM-DD')
    if (location.search?.includes('?redirectcode=')) {
      const code = location.search.split('?redirectcode=')[1]
      const decodedCode = decodeURIComponent(code)
      if (decodedCode) {
        //move code into action
        axios
          .get(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, {
            headers: {
              Authorization: decodedCode,
            },
          })
          .then((response) => {
            if (response.status == 200) {
              localStorage.setItem('token', decodedCode)
              dispatch(setUser(response.data?.user))
              dispatch(startFetchLogo(decodedCode))
              const bid = response?.data?.user?.buildingId
              dispatch(startFetchAppsConfig({ token: decodedCode }))
              // dispatch(startFetchMeetings(date))
              //   dispatch(startFetchMeetings({ date }))

                            //   bid &&
                            //     dispatch(startGetAllTickets({ bid, decodedCode, popUp: false }))

                            //   const body = {
                            //     currentDate: date,
                            //   }
                            //   dispatch(
                            //     startGetUpcomingBookings({ body, decodedCode, popUp: false }),
                            //   )
                            setIsLoaded(true)
                            history.push('/')
                        } else {
                            window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                        }
                    })
                    .catch((err) => {
                        if (err.response?.status === 403) {
                            window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                        } else {
                            console.log(err)
                        }
                    })
            }
        } else if (location.search?.includes('?code=')) {
            const code = localStorage.getItem('token')
            const urlCode = location.search.split('?code=')[1]
            const googleCode = urlCode.split('&scope=')[0]
            const decodedGoogleCode = decodeURIComponent(googleCode)
            axios
                .post(
                    `${process.env.REACT_APP_BASE_URL}mr/v1/google/code`,
                    { code: decodedGoogleCode },
                    {
                        headers: {
                            Authorization: code,
                        },
                    },
                )
                .then((res) => {
                    if (res.status === 201) {
                        setIsLoaded(true)
                        dispatch(startFetchMeetings({ date }))
                        const body = {
                            currentDate: date,
                        }
                        dispatch(startGetUpcomingBookings({ body }))
                        dispatch(startFetchAppsConfig())

                        history.push('/meetingrooms')
                        // window.location.reload()
                    } else {
                        console.log(res)
                    }
                })
                .catch((err) => {
                    if (err.response?.status === 403) {
                        window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                    } else if (err.response?.status === 400) {
                        // alert('Please remove third party access from google and proceed again')
                        swal({
                            title: ' Oops!',
                            text:
                                'Please remove third party access from google and try again!',
                            icon: 'error',
                            button: 'Ok',
                        }).then((willDelete) => {
                            if (willDelete) {
                                history.push('/')
                                window.location.reload()
                            } else {
                                history.push('/')
                                window.location.reload()
                            }
                        })
                    } else {
                        swal({
                            title: ' Oops!',
                            text: 'Something went wrong. Please try again later!',
                            icon: 'error',
                            button: 'Ok',
                        }).then((willDelete) => {
                            if (willDelete) {
                                history.push('/')
                                window.location.reload()
                            } else {
                                history.push('/')
                                window.location.reload()
                            }
                        })
                    }
                })
        } else {
            const code = localStorage.getItem('token')
            //move code into action
            if (code) {
                axios
                    .get(`${process.env.REACT_APP_BASE_URL}um/v1/user/validate`, {
                        headers: {
                            Authorization: code,
                        },
                    })
                    .then((response) => {
                        if (response.status === 200) {
                            dispatch(setUser(response.data?.user))
                            dispatch(startFetchLogo())
                            const bid = response.data?.user?.buildingId
                            // const metaTag = document.querySelector('meta[name="theme-color"]');
                            // metaTag.setAttribute('content','#FFFFFF');
                            //     dispatch(startFetchMeetings(date))
                            //     bid&&dispatch(startGetAllTickets(bid))
                            //     const body = {
                            //         currentDate: date,
                            //     }
                            dispatch(startFetchAppsConfig({ token: code }))

                            //     dispatch(startGetUpcomingBookings(body))
                            setIsLoaded(true)
                        } else {
                            window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
                        }
                    })
                    .catch((err) => {
                        if (err.response?.status === 403) {
                            window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
                        } else {
                            window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
                        }
                    })
            } else {
                window.location.href = process.env.REACT_APP_ACCOUNTSUI_URL
            }
        }
    }, [])

    return (
        <Box>
            <ThemeProvider theme={lightTheme}>
                <CssBaseline />
                {isLoaded && (
                    <Box sx={{ padding: 0, backgroundColor: '#F6F6F6' }}>
                        <Router />
                    </Box>
                )}
            </ThemeProvider>
        </Box>
    )
}

export default App
