import React, { useState } from 'react'
import {
    Toolbar,
    Grid,
    Avatar,
    Typography,
    Box,
    Stack,
    Button,
    Modal,
} from '@mui/material'
import HamburgerIcon from '../../../Assets/MeetingRooms/Images/HamburgerIcon.svg'
import { IconButton } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { styled, useTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import errorMessage from '../../../Assets/MeetingRooms/Images/errorMessage.svg'
import feedback from '../../../Assets/MeetingRooms/Images/feedback.svg'
import meetingRoomIcon from '../../../Assets/MeetingRooms/Images/meetingRoomIcon.svg'
import returningVisitor from '../../../Assets/MeetingRooms/Images/returningVisitor.svg'
import workingAtHome from '../../../Assets/MeetingRooms/Images/workingAtHome.svg'
import AssetManager from '../../../Assets/MeetingRooms/Images/assetManager.png'
import { useHistory } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import logout from '../../../Assets/Images/logout.svg'
import home from '../../../Assets/Images/home.png'
import { useEffect } from 'react'
import { startGetUserInfo } from '../../../Redux/ReduxSlice/Common/userInfoSlice'

const drawerWidth = '70vw'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
)

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}))

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    p: 1.5,
}

const HomeHeader = (props) => {
    const imageLink = useSelector(state => state?.config?.imageLink)
    const userId = useSelector((state) => state.user?.data?.id)
    const userInfo = useSelector(state => state?.userInfo?.data)
    const dispatch = useDispatch()
    const logo = useSelector(state => state?.config?.logo)
    const logoIsLoaded = useSelector(state => state?.config?.logoIsLoaded)
    const apps = useSelector(state => state?.config?.apps)

    const history = useHistory()
    const tenantCode = useSelector((state) => state.user?.data?.tenantCode)
    const userName = useSelector((state) => state.user?.data?.fullName)
    const vcardName = useSelector(state => state?.userInfo?.data?.fullName)
    const email = useSelector((state) => state.user?.data?.email)
    const theme = useTheme()
    const [open, setOpen] = React.useState(false)
    const [logoutOpen, setLogoutOpen] = useState(false)
    const handleCloseLogout = () => setLogoutOpen(false)

    const handleDrawerOpen = () => {
        setOpen(true)
    }

    const handleDrawerClose = () => {
        setOpen(false)
    }

    const handleRedirect = (link) => {
        if (link) {
            history.push(link)
        }
    }

    //   const drawerArr = [
    //     { title: 'Home', logo: home, onclick: () => handleDrawerClose(), enabled: true },
    //     { title: 'Desk Booking', logo: workingAtHome, link: '/deskbooking/home', enabled: deskBookingEnabled },
    //     {
    //       title: 'Room Booking',
    //       logo: meetingRoomIcon,
    //       link: '/meetingrooms/booking', enabled: roomBookingEnabled
    //     },
    //     { title: 'Fault Report', logo: errorMessage, link: '/faultreporting', enabled: faultReportsEnabled },
    //     { title: 'FeedBack', logo: feedback, link: '/feedback', enabled: feedbackEnabled },
    //     { title: 'Occupancy', logo: returningVisitor, link: '/occupancy', enabled: occupancyEnabled },
    //     { title: 'Asset Manager', logo: AssetManager, link: '/assetmanager', enabled: assetManagementEnabled },
    //     { title: 'Logout', logo: logout, onclick: () => handleToggle(), enabled: true },
    //   ]

    const drawerArr = [{ title: 'Home', logo: home, onclick: () => handleDrawerClose(), enabled: true }, ...apps, { title: 'Logout', logo: logout, onclick: () => handleToggle(), enabled: true }]

    const handleProfileRoute = () => {
        history.push('/profile')
    }


    const handleToggle = () => {
        setLogoutOpen(!logoutOpen)
    }

    const handleLogoutButton = () => {
        localStorage.removeItem('token')
        window.location.href = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
    }

    useEffect(() => {
        dispatch(startGetUserInfo(userId))
    }, [])
    return (
        <>
            <AppBar
                disableGutters
                elevation={0}
                boxShadow={0}
                borderColor={'#FF6520'}
                component="nav"
                position="relative"
                sx={{ background: '#FF6520' }}
            >
                <Toolbar>
                    <Grid container>
                        <Grid
                            item
                            xs={1}
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent="flex-start"
                        >
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                sx={{ mr: 2, ...(open && { display: 'none' }) }}
                            >
                                <img src={HamburgerIcon} height="16px" />
                            </IconButton>
                        </Grid>
                        <Grid item xs={9} display={'flex'} alignItems={'center'} pl={2}>
                            {logoIsLoaded ? <img src={logo ? `${logo}` : ''} width="100px" /> : ''}
                            {/* <img src={`${process.env.REACT_APP_BASE_URL}`${companyLogo}} width={'100px'}/> */}
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="flex-end" pr={1}>
                            <Avatar
                                sx={{ backgroundColor: '#F6F6F6', height: 40, width: 40 }}
                                onClick={handleProfileRoute}
                            >
                                {userInfo?.profilePictureUrl ? <img src={`${imageLink}${userInfo.profilePictureUrl}`} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                    :
                                    <Typography color="black">
                                        {userName?.slice(0, 1).toUpperCase()}
                                    </Typography>
                                }

                            </Avatar>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="temporary"
                anchor="left"
                onClose={() => setOpen(false)}
                open={open}
            >
                <DrawerHeader sx={{ backgroundColor: '#fff', minHeight: '14vh' }}>
                    <Grid container>
                        <Grid item xs={4} display="flex" justifyContent={'center'}>
                            <Avatar
                                sx={{
                                    backgroundColor: '#F6F6F6',
                                    height: '8vh',
                                    width: '8vh',
                                    borderColor: '#000',
                                }}
                            >
                                <Typography color="black" fontSize={20}>
                                    {(vcardName || userName) && (vcardName?.slice(0, 1).toUpperCase() || userName?.slice(0, 1).toUpperCase())}
                                </Typography>
                            </Avatar>
                        </Grid>
                        <Grid item container xs={8} display="flex" alignItems={'center'}>
                            <Grid item xs={12} ml={'2vw'}>
                                <Typography color="#212121  " fontSize={'20px'}>
                                    {(vcardName || userName) && (vcardName || userName)}
                                </Typography>
                                <Typography color="#212121" fontSize={'12px'}>
                                    {email && email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <IconButton
                        onClick={handleDrawerClose}
                        sx={{
                            top: ' -2.6371308016877637vh',
                        }}
                    >
                        {theme.direction === 'ltr' ? (
                            <CloseIcon style={{ color: '#000' }} fontSize="large" />
                        ) : (
                            <CloseIcon style={{ color: '#000' }} fontSize="large" />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Box mt={'2.4vh'}>
                        {drawerArr.map((ele) => {
                            return (
                                <Box ml={'8.8vw'} mt={'3vh'}>
                                    <ListItemButton
                                        sx={{ p: 0 }}
                                        disabled={!ele.enabled}
                                        onClick={() =>
                                            ele.onclick ? ele.onclick() : handleRedirect(ele.link)
                                        }
                                    >
                                        <Box paddingRight={'20px'}>
                                            <img src={ele.logo} alt="Logo" width={'25px'} />
                                        </Box>
                                        <Box display={'flex'} alignItems={'flex-start'}>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    fontSize: '16px',
                                                    color: '#000000',
                                                    // mt: '0.9vh',
                                                }}
                                                primary={ele.title}
                                            />
                                        </Box>
                                    </ListItemButton>
                                </Box>
                            )
                        })}
                    </Box>
                </List>

                <Modal
                    open={logoutOpen}
                    onClose={handleCloseLogout}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h6" component="h2">
                                    Are you Sure ?
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={handleLogoutButton}
                                >
                                    Logout
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={handleCloseLogout}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                <Stack sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <Typography fontSize="12px" align="center" color="#212121">
                        Powered by Nhance
                    </Typography>
                    <Typography fontSize="12px" align="center" color="#212121">
                        Version 1.0.03
                    </Typography>
                </Stack>
            </Drawer>
        </>
    )
}

export default HomeHeader
