import React from 'react'
import CardComp from '../../Card/CardComp'
import { useHistory } from 'react-router-dom';

import { IconButton, Typography, Grid, Box } from '@mui/material'
const AppsWidget = (props) => {
    const { title, logo, link,enabled } = props
    const history = useHistory()
    const handleClick = () => {
        if (link) {
            history.push(link)
        }

    }
    return (

        <Grid item xs={4} md={3} >
            <Box display='flex' justifyContent='center'>
                <CardComp height='12' width='12' border='8' backgroundColor={enabled?'#FFFFFF':'#EBEBE4'} >
                    <IconButton onClick={handleClick} disabled={!enabled}>
                        <img src={logo} height='40px' />
                    </IconButton>
                </CardComp>
            </Box>
            <Box mt={1}>
                 <Typography align='center' fontSize='12px' fontFamily={"'roboto','Open sans','Helvetica'"}>{title}</Typography>
            </Box>
        </Grid>

    )
}

export default AppsWidget