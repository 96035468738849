import axios from "axios";
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { errorModal } from "../../../Helpers/ErrorModal";
export const FAULT_IMAGE = 'FAULT_IMAGE'
export const CLEAR_IMAGE = 'CLEAR_IMAGE'
export const SET_IMAGE = 'SET_IMAGE'

const initialState={
    image:'',
    url:'',
    awsUrl:''
}

export const clearFaultImage = () => {
    return {
        type: CLEAR_IMAGE
    }
}

export const startSetImage=createAsyncThunk('tickets/startSetImage',async({formData, handleRedirect, setStageImageBase64, newUrl, handleAwsUrl},dispatch)=>{
    try{
        const response= await axios.post(`${process.env.REACT_APP_FR_UPLOAD_URL}`, formData, {
                        headers: {
                            'content-type': 'multipart/form-data',
                            'Authorization': localStorage.getItem('token')
                        }
                    })
        if(response.status===201){
            const data = {
                            awsUrl: response.data,
                            url: newUrl
                        }
                                            
                                            handleRedirect && handleRedirect(response.data)
                                            setStageImageBase64 && setStageImageBase64(formData.imageString)
                                            handleAwsUrl && handleAwsUrl(response.data)
                return data
        }
    }
    catch(err){
       errorModal()
    }
})


export const faultImageSlice = createSlice({
    name: 'faultImageSlice',
    initialState,
    reducers: {
        faultImage :(state, action) => {                
                state.url=action.payload?.file
                 state.image=action.payload?.newUrl 
                },
         
        
      
    },
    extraReducers(builder) {
        builder
          .addCase(startSetImage.fulfilled, (state, action) => {
            if(action.payload){
                
                state.awsUrl=action.payload?.awsUrl
            }
          })

          
      }

  })
export const {faultImage}=faultImageSlice.actions

export default faultImageSlice.reducer