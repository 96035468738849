import React, { useEffect } from 'react'
import {
  startGetAllAppointments,
  setSelectedAppointments,
  resetVisitorDetails,
  startGetAppointmentsById,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import { Box, Card, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'

const BookedAppointments = (props) => {
  const { bookedAppointments } = useSelector(
    (state) => state.visitData?.allAppointments,
  )

  // const selectedTime = useSelector((state) => state.visitData?.selectedTime);


  const appointmentsIsLoaded = useSelector(
    (state) => state.visitData?.allAppointments?.appointmentsIsLoaded,
  )
  const userId = useSelector((state) => state?.user?.data?.id)

  const handleRedirect = (_id) => {
    // dispatch(setSelectedAppointments({_id}))
    dispatch(startGetAppointmentsById({ _id }))
    dispatch(setVisitorComp('AppointmentDetails'))
  }
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(startGetAllAppointments())
    return () => {
      dispatch(resetVisitorDetails())
    }
  }, [])

  return (
    <Box>
      <Grid item spacing={2} xs={12}>
        {appointmentsIsLoaded ? (
          bookedAppointments.length > 0 ? (
            bookedAppointments.map((appointment) => (
              <Box
                key={appointment._id}
                sx={{
                  display: 'flex',
                  marginBottom: '5%',
                }}
                onClick={() => {
                  handleRedirect(appointment._id)
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100vw',
                    padding: '3%',
                    background: '#ffffff',
                  }}
                >
                  <Typography component="div" variant="h6">
                    Purpose: {appointment?.purpose}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Visitor Name: {appointment?.visitors?.map(visitor => visitor?.visitor?.name).join(', ')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Location: {appointment?.location?.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Start Time:
                  {`${moment(new Date(appointment?.startTime)).format('DD MMM YYYY hh:mm A')}`}
                
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Status: {appointment?.status}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Box
              // height="50vh" 
              // display="flex"
              // alignItems="center"
              // justifyContent="center" 
            >
              <Card
                sx={{
                  width: '100%',
                  // height: '100%',
                  padding: '10%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
               <Typography sx={{fontSize:"15px",textAlign:'center'}}>You don't have booked appointments</Typography> 
              </Card>
            </Box>
          )
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
    </Box>
  )
}
export default BookedAppointments
