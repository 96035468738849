
import styled from '@emotion/styled'
import { Box, Table, TableBody, TableContainer, TableRow,Paper, TableCell, tableCellClasses, Chip } from '@mui/material'
import React from 'react'
import Carousel from 'react-material-ui-carousel'
import { BrokenImageBox } from '../FaultReporting/Styles/AdminTicketDetailsStyles'
import BrokenImage from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import { useSelector } from 'react-redux'

export function AssetModalData(props) {
    const imageLink=useSelector(state=>state?.config?.imageLink)
    const {selectedAsset,ele}=props
    const style={
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        paddingLeft:'20px',
        
        outline:'none',
        borderRadius:'10px'
        }

        const StyledTableCell = styled(TableCell)(({ theme }) => ({
            [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#FFFFFF',
            color: "#000000",
            },
            [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            },
            }));

        const handleStatus=(status)=>{

            const backgroundColor=status === 'Overdue' || status === 'In Progress(Overdue)' || status === 'Scheduled(Overdue)'
            ? '#FF4E5E'
            : status === 'Expired'
            ? '#FF4E5E'
            : status === 'Scheduled'
            ? '#A0E400':'#A0E400'
            return <Chip label={status?status:'NA'} variant="outlined" sx={{border:`solid 1px ${backgroundColor}`,height:20,}} style={{backgroundColor:backgroundColor, color:'#FFFFFF'}}/>
            }
                 
    return (
        <>
            <Box sx={style}>
            { ele.imageLinks.length?
                <Carousel
                autoPlay={true}
                interval={2000}
                duration={1500}
                navButtonsAlwaysInvisible
                stopAutoPlayOnHover={false}
                animation='slide'
                sx={{ width: "100%", height:'15vh' , backgroundColor:'#d3d3d3', display: 'flex', justifyContent: 'center',alignItems:'center' }}
                indicators=''
                >
                {

                ele.imageLinks?.map(el=><Box><Box display='flex' justifyContent={'center'} width='100%'>
                
                
                <img src={`${imageLink}${el}`} style={{objectFit:'cover'}} width={200}/> 
                
                </Box>
                
                </Box>)
                }
                </Carousel>
            :
            
            <Box sx={{ backgroundColor:'#D9D9D9',
            borderRadius:'10px',
            height:'15vh',
            width:'100%',
            }}>
                <BrokenImageBox>
                <img src={BrokenImage} height={'20px'} style={{ objectFit: 'cover' }} />
                </BrokenImageBox>
            </Box>
            }
            <Box sx={{ marginTop:2}}>
            
                <TableContainer component={Paper}>
                    <Table aria-label="customized table">
                    <TableBody>
                    {selectedAsset.assetName&&
                    
                    <TableRow key={selectedAsset.assetName} style={{backgroundColor:'#f5f5f5'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Asset Name'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{selectedAsset.assetName}</StyledTableCell>
                    </TableRow>
                    
                    }

                    {selectedAsset?.serialNumber&&
                    
                    <TableRow key={selectedAsset.serialNumber} style={{backgroundColor:'#ffffff'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Serial No'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{selectedAsset.serialNumber}</StyledTableCell>
                    </TableRow>
                    
                    }

                    
                    
                    { selectedAsset?.model&&<TableRow key={selectedAsset.model} style={{backgroundColor:'#f5f5f5'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Model'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{selectedAsset.model}</StyledTableCell>
                    </TableRow>}

                    {selectedAsset?.description&&<TableRow key={selectedAsset.description} style={{backgroundColor:'#ffffff'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Description'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{selectedAsset.description}</StyledTableCell>
                    </TableRow>}
                    
                    {selectedAsset.nextMaintenanceDate&&<TableRow key={selectedAsset.nextMaintenanceDate} style={{backgroundColor:'#f5f5f5'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Next Maintenance date'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{selectedAsset.nextMaintenanceDate}</StyledTableCell>
                    </TableRow>}

                    { selectedAsset?.maintenanceStatus&&<TableRow key={selectedAsset._id} style={{backgroundColor:'#ffffff'}}>
                    <StyledTableCell component="th" scope="row">
                    {'Maintenance Status'}
                    </StyledTableCell>
                    <StyledTableCell align="right">{handleStatus(selectedAsset.maintenanceStatus)}</StyledTableCell>
                    </TableRow>}

                    </TableBody>
                </Table>
                </TableContainer>
            </Box>
 </Box>
        </>
    )
}
