import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  Button,
  RadioGroup,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Modal,
  IconButton,
  Card,
  Paper,
  Rating,
  Drawer,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  ImageBox,
  imageStyle,
  BackButtonStyle,
  DescriptionBox,
  TypographyStyle,
  AcceptTicketBox,
  AcceptTicketTypographyBox,
  AcceptTicketButtonBox,
  StatusBox,
  GridItemStyle,
  StatusTypography,
  OptionsBox,
  TextFieldStyle,
  snackBarStyle,
  MuiBox,
  inputStyle,
  ModalStyle,
  UserBox,
  TimeTextStyle,
  AreaTextStyle,
  PaddingBox,
  BrokenImageBox,
  FaultImageCard,
  NoTopPaadingBox,
} from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles'
import back from '../../Assets/Images/left-arrow.png'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'
import ConfirmButton from '../../UI/FaultReporting/Button/ConfirmButton'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { setComp } from '../../Redux/ReduxSlice/FaultReporting/compSlice'
import { useDispatch, useSelector } from 'react-redux'
import UserTicketDetails from './UserTicketDetails'
import {
  clearSelectedTicket,
  startAcceptTicket,
  startUpdateTicket,
} from '../../Redux/ReduxSlice/FaultReporting/TicketsSlice'
import { startSetImage } from '../../Redux/ReduxSlice/FaultReporting/FaultImageSlice'
import moment from 'moment'
import StarIcon from '@mui/icons-material/Star'
import cameraIcon from '../../Assets/FaultReporting/Images/camera.svg'
import folderIcon from '../..//Assets/FaultReporting/Images/folder.svg'
import correct from '../../Assets/Images/correct.svg'
import BrokenImage from '../../Assets/FaultReporting/Images/BrokenIcon.png'

import { Global } from '@emotion/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const AdminTicketDetails = (props) => {
  const bid = useSelector((state) => state?.user?.data?.buildingId)
  const imageLink = useSelector((state) => state?.config?.imageLink)
  const [optionsValue, setOptionsValue] = useState('')
  const [otherOptionTitle, setOtherOptionTitle] = useState('')
  const [textFieldValue, setTextFieldValue] = useState('')
  const [snackBar, setSnackBar] = useState(false)
  const [modal, setModal] = useState(false)
  const [source, setSource] = useState('')
  const role = useSelector((state) => state?.user?.data?.role)
  const ticket = useSelector(state => state?.faultReports?.selectedTicket?.ticket)
  const [admin, setAdmin] = useState(
    role == 'admin' || role == 'facility manager' ? true : false,
  )
  const selectedTicket = useSelector(
    (state) => state?.faultReports?.selectedTicket?.ticket,
  )
  const isLoaded = useSelector(
    (state) => state?.faultReports?.selectedTicket?.isLoaded,
  )

  const status = useSelector(
    (state) => state.faultReports?.selectedTicket?.ticket?.status,
  )
  const [disabled, setDisabled] = useState(true)
  const [open, setOpen] = useState(false)
  const [imageModal, setImageModal] = useState(false)
  const [imageBase64, setImageBase64] = useState('')
  const serviceImageUrl = ticket?.serviceImageUrl
  const adminId = useSelector((state) => state.user?.data?.id)
  const [awsUrl, setAwsUrl] = useState('')
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const now = moment().format('DD-MM-YYYY HH:mm:ss')
  const ticketReportedOn = selectedTicket.reportedOn
  const reportedOn = moment(Number(ticketReportedOn)).format(
    'DD-MM-YYYY HH:mm:ss',
  )
  const endTime = moment(`${now}`, 'DD-MM-YYYY HH:mm:ss')
  const startTime = moment(`${reportedOn}`, 'DD-MM-YYYY hh:mm:ss')
  const viewAll = useSelector((state) => state?.redirection?.viewAll)
  const daysDifference = endTime.diff(startTime, 'days')
  const hoursDifference = endTime.diff(startTime, 'hours')
  const minutesDifference = endTime.diff(startTime, 'minutes')
  const secondsDifference = endTime.diff(startTime, 'seconds')
  const history = useHistory()
  const handleAwsUrl = (val) => {
    setAwsUrl(val)
  }
  const handleModal = () => {
    setOpen(true)
  }
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        dispatch(setComp('Fault Reporting'))
        setOpen(false)
      }, 2000)
    }
  }, [open])
  const dispatch = useDispatch()
  const handleSnackBar = () => {
    setSnackBar(true)
  }

  const toggleAccepted = () => {
    handleSnackBar()
  }
  const handleAccepted = () => {
    const data = {
      faultId: selectedTicket.id,
      addressedOn: Number(new Date()),
    }
    dispatch(startAcceptTicket({ ticket: data, bid, toggleAccepted }))
    // setAccepted(true)
    // handleSnackBar()
  }
  const handleOptionsChange = (e) => {
    const val = e.target.value
    setOptionsValue(val)
    if (!(val === 'Other')) {
      setOtherOptionTitle('')
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }
  const handleTextField = (e) => {
    const val = e.target.value
    setTextFieldValue(val)
  }
  const handleOtherOptionTitle = (e) => {
    const val = e.target.value
    const finalVal = val
    setOtherOptionTitle(finalVal)
    if (finalVal.length > 0) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }
  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar(false)
  }

  const handleImageRetake = () => {
    if (optionsValue == 'Other' && otherOptionTitle.length > 0) {
      setDisabled(false)
    } else if (optionsValue !== 'Other' && optionsValue.length > 0) {
      setDisabled(false)
    }
    setSource('')
    setImageBase64('')
    setAwsUrl('')
  }

  const handleBackButtonClick = () => {
    if (viewAll) {
      history.push('/notifications')
      dispatch(setComp('Fault Reporting'))
    } else {
      dispatch(setComp('Fault Reporting'))
    }
  }

  const handleTimeDisplay = () => {
    if (daysDifference > 0) {
      if (daysDifference == 1) {
        return daysDifference + ' ' + 'day' + ' '
      } else {
        return daysDifference + ' ' + 'days' + ' '
      }
    } else if (hoursDifference > 0) {
      if (hoursDifference == 1) {
        return hoursDifference + ' ' + 'hour' + ' '
      } else {
        return hoursDifference + ' ' + 'hours' + ' '
      }
    } else if (minutesDifference > 0) {
      if (minutesDifference == 1) {
        return minutesDifference + ' ' + 'minute' + ' '
      } else {
        return minutesDifference + ' ' + 'minutes' + ' '
      }
    } else {
      return secondsDifference + ' ' + 'seconds' + ' '
    }
  }

  const handleCapture = (target) => {
    setDisabled(true)
    if (target.files) {
      if (target.files.length !== 0) {
        const image = target.files[0]
        let formData = new FormData()
        formData.append('file', image)
        dispatch(startSetImage({formData,handleRedirect:'', setStageImageBase64,newUrl:'', handleAwsUrl}))
        setModal(false)
      }
    }
  }

  const setStageImageBase64 = (val) => {
    setImageBase64(val)
    if (optionsValue == 'Other' && otherOptionTitle.length > 0) {
      setDisabled(false)
    } else if (optionsValue !== 'Other' && optionsValue.length > 0) {
      setDisabled(false)
    }
  }

  const handleSubmit = () => {
    const data = {
      faultId: selectedTicket.id,
      statusCode: optionsValue,
      closedOn: Number(new Date()),
      resolvedOn: Number(new Date()),
      serviceStatus: otherOptionTitle,
      serviceComments: textFieldValue,
      serviceImageUrl: awsUrl,
    }
    dispatch(startUpdateTicket({ ticket: data, bid, handleModal }))
  }

  //   useEffect(() => {
  //     if (Object.keys(selectedTicket).length === 0) {
  //       dispatch(setComp('Fault Reporting'))
  //     }
  //   }, [selectedTicket])

  useEffect(() => {
    return () => {
      dispatch(clearSelectedTicket())
    }
  }, [])

  return (
    <Box>
      {isLoaded ? (
        <>
          <ImageBox>
            {selectedTicket.imageUrl ? (
              <img
                src={`${imageLink}${selectedTicket.imageUrl}`}
                alt={'snap'}
                style={imageStyle}
              ></img>
            ) : (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={'15vh'}
              >
                <Typography>
                  {role === 'employee'
                    ? 'You have not added any image'
                    : adminId === selectedTicket.userId
                    ? 'You have not added any image'
                    : 'The user has not added any image'}
                </Typography>
              </Box>
            )}
            <Box style={BackButtonStyle} onClick={handleBackButtonClick}>
              <img
                src={back}
                height="24px"
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  height: '40px',
                  width: '40px',
                  borderRadius: '50%',
                  padding: '0.5em '
                }}
              />
              <Snackbar
                open={snackBar}
                autoHideDuration={3000}
                message="You accepted the ticket!"
                sx={snackBarStyle}
                onClose={handleSnackBarClose}
              />
            </Box>
          </ImageBox>
          <Global
            styles={{
              '.MuiDrawer-root > .MuiPaper-root': {
                height: `calc(65%)`,
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
              },
            }}
          />
          <Box></Box>
          <Drawer
            anchor="bottom"
            open={true}
            variant="permanent"
            ModalProps={{
              keepMounted: false,
            }}
          >
            <DescriptionBox>
              <Box>
                <Typography
                  fontSize="20px"
                  color="#1F1F1F"
                  fontWeight="400"
                >{`${selectedTicket.title}, ${selectedTicket.issue}`}</Typography>
                <Typography
                  gutterBottom
                  mt={'4px'}
                  color="#4B4B4B"
                  fontWeight="400"
                  pb={1}
                  fontSize="16px"
                >{`${selectedTicket.description}`}</Typography>
                <Stack direction="row">
                  <LocationOnOutlinedIcon fontSize="small" />
                  {/* <Box display='flex' alignItems='center'  height='2vh'>
                            <img src={exportIcon} height='10px' />
                       </Box> */}
                  <Typography sx={AreaTextStyle} pb={2}>
                    {' '}
                    {`${selectedTicket.building} | ${
                      selectedTicket.floor && selectedTicket.floor + ' ' + '|'
                    }  ${selectedTicket.area && selectedTicket.area}`}
                  </Typography>
                </Stack>

                <Grid container>
                  <Grid item xs={8}>
                    <Typography sx={TypographyStyle}>
                      Reported by :{' '}
                      {admin
                        ? adminId == selectedTicket.userId
                          ? 'You'
                          : selectedTicket.raisedBy
                        : 'You'}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} sx={GridItemStyle}>
                    <Typography sx={TimeTextStyle}>
                      {handleTimeDisplay() + 'ago'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </DescriptionBox>
            {(status === 'assigned' || status == 'closed' || !admin) && (
              <Divider />
            )}
            {admin ? (
              <>
                {status === 'assigned' ? (
                  selectedTicket.assignedToId == adminId ? (
                    <>
                      <StatusBox>
                        <Typography sx={StatusTypography}>
                          Service Status
                        </Typography>
                        <OptionsBox>
                          <FormControl>
                            <RadioGroup
                              defaultValue=""
                              name="radio-buttons-group"
                              value={optionsValue}
                              onChange={handleOptionsChange}
                            >
                              <FormControlLabel
                                value="JC"
                                control={<Radio color="warning" />}
                                label="Job Completed"
                              />
                              <FormControlLabel
                                value="NTF"
                                control={<Radio color="warning" />}
                                label="Nothing to fix"
                              />
                              <FormControlLabel
                                value="MTOP"
                                control={<Radio color="warning" />}
                                label="I'm not the right person for this job"
                              />
                              <FormControlLabel
                                value="Other"
                                control={<Radio color="warning" />}
                                label="Other"
                              />
                            </RadioGroup>
                          </FormControl>
                          {optionsValue === 'Other' && (
                            <TextField
                              required
                              label=""
                              placeholder="Enter title"
                              inputProps={{
                                maxLength: 50,
                              }}
                              sx={TextFieldStyle}
                              onChange={handleOtherOptionTitle}
                            />
                          )}
                          <TextField
                            label=""
                            placeholder="Add comments"
                            multiline
                            sx={TextFieldStyle}
                            value={textFieldValue}
                            inputProps={{
                              maxLength: 250,
                            }}
                            onChange={handleTextField}
                            rows={4}
                          />
                          <Typography align="right" fontSize={'12px'}>
                            {250 - Number(textFieldValue.length)} characters
                            left
                          </Typography>
                        </OptionsBox>
                        <OptionsBox>
                          <Stack direction="row" spacing={1}>
                            <MuiBox>
                              <CameraAltOutlinedIcon />
                            </MuiBox>
                            {awsUrl ? (
                              <Stack direction="row" spacing={3}>
                                <MuiBox>
                                  <Typography color="#009900">
                                    Image attached successfully!
                                  </Typography>
                                  <Button onClick={handleImageRetake}>
                                    <CloseOutlinedIcon />
                                  </Button>
                                </MuiBox>
                              </Stack>
                            ) : (
                              <MuiBox onClick={() => setModal(true)}>
                                <Button>Attach an Image</Button>
                              </MuiBox>
                            )}
                          </Stack>
                        </OptionsBox>
                        <ConfirmButton
                          backgroundColor="#DC5D3B"
                          title="Submit"
                          width="100%"
                          onClick={handleSubmit}
                          disabled={disabled}
                        />
                      </StatusBox>
                    </>
                  ) : (
                    <>
                      <Box marginTop="1vh">
                        <Typography align="center">
                          This ticket is being addressed by
                        </Typography>
                        <Typography align="center" fontWeight={700}>
                          {selectedTicket.assignedTo}
                        </Typography>
                      </Box>
                    </>
                  )
                ) : status === 'closed' ? (
                  adminId == selectedTicket.userId ? (
                    <UserTicketDetails ticketResolved={selectedTicket.status} />
                  ) : (
                    <>
                      <Box marginTop="1vh">
                        <Typography align="center">
                          This ticket has been resolved by
                        </Typography>
                        <Typography align="center" fontWeight={700}>
                          {selectedTicket.assignedToId == adminId
                            ? 'You'
                            : selectedTicket.assignedTo}
                        </Typography>
                        <PaddingBox
                          onClick={() => serviceImageUrl && setImageModal(true)}
                        >
                          <FaultImageCard>
                            <BrokenImageBox>
                              <img
                                src={
                                  serviceImageUrl
                                    ? `${imageLink}${serviceImageUrl}`
                                    : BrokenImage
                                }
                                height={serviceImageUrl ? '100%' : '20px'}
                                width={serviceImageUrl && '100%'}
                                style={{ objectFit: 'cover' }}
                              />
                            </BrokenImageBox>
                          </FaultImageCard>
                        </PaddingBox>
                        <NoTopPaadingBox>
                          <Typography align="center" pt={1} p={2}>
                            {selectedTicket.serviceComments}
                          </Typography>
                        </NoTopPaadingBox>
                      </Box>
                      {selectedTicket.rating && (
                        <Box mt={'2vh'}>
                          <Typography
                            variant="h6"
                            fontWeight={'bold'}
                            mt={1}
                            sx={{
                              display: 'flex',
                              alignItems: ',center',
                              justifyContent: 'center',
                            }}
                          >
                            Employee Feedback
                          </Typography>
                        </Box>
                      )}
                      {selectedTicket.reviewed && (
                        <Box display="flex" justifyContent="center" mt={1}>
                          <Card
                            sx={{
                              borderRadius: '10px',
                              width: '90%',
                              marginBottom: '10px',
                              border: '1px solid #e0e0e0',
                            }}
                          >
                            <Box
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              mt={1}
                            >
                              <Box>
                                <Rating
                                  name="size-large"
                                  sx={{ fontSize: '50px' }}
                                  value={selectedTicket.rating}
                                  precision="0.5"
                                  disabled={true}
                                  emptyIcon={
                                    <StarIcon
                                      style={{ opacity: 1 }}
                                      fontSize="inherit"
                                    />
                                  }
                                />
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                >
                                  {selectedTicket.feedback && (
                                    <DescriptionBox>
                                      <Box
                                        display="flex"
                                        justifyContent="center"
                                      >
                                        <Typography fontSize="16px">
                                          {selectedTicket.feedback}
                                        </Typography>
                                      </Box>
                                    </DescriptionBox>
                                  )}
                                </Box>
                              </Box>
                            </Box>
                          </Card>
                        </Box>
                      )}
                    </>
                  )
                ) : selectedTicket.userId == adminId ? (
                  <>
                    <Divider />
                    <AcceptTicketBox>
                      <Box sx={AcceptTicketTypographyBox}>
                        <Typography align="center">
                          You cannot accept this ticket as this was raised by
                          you
                        </Typography>
                      </Box>
                    </AcceptTicketBox>
                  </>
                ) : selectedTicket.buildingId === bid ? (
                  <Box>
                    <AcceptTicketBox>
                      <Box sx={AcceptTicketTypographyBox}>
                        <Typography fontSize="18px">
                          Accept the ticket?
                        </Typography>
                      </Box>
                    </AcceptTicketBox>
                    <Box sx={AcceptTicketButtonBox}>
                      <ConfirmButton
                        backgroundColor="#DC5D3B"
                        title="Accept"
                        width="90%"
                        onClick={handleAccepted}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box>
                    <Divider />
                    <Box mt={2} sx={{ textAlign: 'center' }}>
                      <Typography>
                        You cannot accept this ticket as it is tagged to another
                        building!
                      </Typography>
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <>
                <UserBox>
                  <Box>
                    <UserTicketDetails ticketResolved={selectedTicket.status} />
                  </Box>
                </UserBox>
              </>
            )}

            <Modal open={modal} onClose={() => setModal(false)}>
              <Box sx={ModalStyle}>
                <Grid container>
                  <Grid item xs={6}>
                    <MuiBox>
                      <label htmlFor="icon-button-file">
                        <Stack spacing={1}>
                          <Box display="flex" justifyContent={'center'}>
                            <img src={cameraIcon} width="26px" />
                          </Box>
                          <Typography color="#212121">
                            Click an image
                          </Typography>
                        </Stack>
                      </label>
                      <input
                        accept="image/*"
                        style={inputStyle}
                        id="icon-button-file"
                        type="file"
                        capture="environment"
                        onChange={(e) => handleCapture(e.target)}
                      />
                    </MuiBox>
                  </Grid>
                  <Grid item xs={6}>
                    <MuiBox>
                      <label htmlFor="choose-file" id="choose-file-label">
                        <Stack spacing={1}>
                          <Box display="flex" justifyContent={'center'}>
                            <img src={folderIcon} />
                          </Box>
                          <Typography color="#212121">
                            Upload from Gallery
                          </Typography>
                        </Stack>
                      </label>

                      <input
                        name="uploadDocument"
                        type="file"
                        id="choose-file"
                        accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                        style={inputStyle}
                        onChange={(e) => {
                          handleCapture(e.target)
                        }}
                      />
                    </MuiBox>
                  </Grid>
                </Grid>
              </Box>
            </Modal>
            <Modal open={open}>
              <Paper sx={ModalStyle}>
                <Stack pb={2}>
                  <Box display="flex" justifyContent="center" pb={3}>
                    <img src={correct} height="90px" width="90px" />
                  </Box>
                  <Typography
                    variant="h5"
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    textAlign={'center'}
                    fontWeight="bold"
                    fontSize="24px"
                    pb={2}
                  >
                    Thank you!
                  </Typography>
                  <Typography
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    textAlign={'center'}
                    fontSize="16px"
                  >
                    Your response was submitted!
                  </Typography>
                </Stack>
              </Paper>
            </Modal>
          </Drawer>
        </>
      ) : (
        <>Loading</>
      )}
    </Box>
  )
}

export default AdminTicketDetails
