
import { Grid, Box, CircularProgress } from '@mui/material'
import HomeCard from '../HomeCard/HomeCard'
import AppsWidget from '../../../../UI/MeetingRooms/Widgets/HomePage/AppsWidget'
import {useSelector } from 'react-redux'

const HomePagePanel = (props) => {

    const data=useSelector(state=>state?.aqiData?.data)
    const Apps=useSelector(state=>state?.config?.apps)
    const appsIsLoaded=useSelector(state=>state?.config?.isLoaded)

    
    return (
        <Box /* sx={{ height: '120vh' }} */ sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <Grid container spacing={2} >
                <Grid item xs={12}>
                    <HomeCard />
                </Grid>
                    <Box sx={{ padding: 2, paddingLeft: 4, width: '100%' }}>
                    <Box px={1.5}>
                        {appsIsLoaded?<Grid container item xs={12} spacing={2.5} >
                            {Apps.map((app) => {
                                return (
                                    <AppsWidget title={app.title} logo={app.logo} link={app.link} enabled={app.enabled}/>
                                )
                            })}
                        </Grid>
                            :
                        <Box height={'40vh'} display='flex' justifyContent={'center'} alignItems={'center'}>
                             <CircularProgress color="inherit" />
                         </Box> 
                           }
                    </Box>
                </Box>
            </Grid >
            
        </Box >
    )
}

export default HomePagePanel
