import { styled } from '@mui/system'
import { Avatar, Box, Paper, Typography, Grid, Stack } from '@mui/material';
import FiberManualRecordOutlinedIcon from '@mui/icons-material/FiberManualRecordOutlined';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';


export const MuiBox = styled(Box)({
  borderRadius: 6
});


export const BoxSx = styled(Box)({
  padding: 10, display: 'flex', alignItems: 'center',
})
export const IconSx = styled(Avatar)({
  backgroundColor: '#ff5722', width: 40, height: 40, borderRadius: "13px"
})

export const IconSx1 = styled(FiberManualRecordOutlinedIcon)({
  width: 20, height: 20, color: "white"
})

export const IconSx2 = styled(QrCodeIcon)({
  width: 20, height: 20, color: "white"
})

export const PaperSx = styled(Paper)({
  boxShadow: 0, height: '8.5vh', width:'100%', borderRadius: "8px", display: 'flex', alignItems: 'center', 
  justifyContent:'center'
})

export const TypoSx = styled(Typography)({
  color: "#000000", textAlign: "center", fontSize: "14px"
})

export const BoxSx1 = styled(Box)({
  display: 'flex', justifyContent: 'center'
})

export const Icon3 = styled(ImageOutlinedIcon)({
  width: 60, height: 80, alignItems: 'center'
})

export const Item = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: 'center',
 marginTop:2
}));

export const TypoSx2 = styled(Typography)({
  display: 'flex', alignItems: 'center', fontSize: '1rem', fontWeight: "medium"
})

export const GridSx = styled(Grid)({
  p: 1, display: 'flex', justifyContent: 'center'
})
export const GridSx1 = styled(Grid)({
  display: 'flex', alignItems: 'center'
})
export const GridSx2 = styled(Grid)({
  p: 0.5, spacing: 1, padding:'2vh'
})
export const GridSx3 = styled(Grid)({
  display: 'flex', justifyContent: 'flex-end'
})

export const GridSx4 = styled(Grid)({
  xs: 4, display: 'flex', justifyContent: 'flex-end'
})

export const GridSx5 = styled(Grid)({
  pr: '0.5vh', pl: '1vh'
})

export const GridSx6 = styled(Grid)({
  height: '100%',padding:'1vh', paddingLeft:'2vh'
})
export const TypoSx3 = styled(Typography)({
  variant: 'h6', fontWeight: "400"
})

export const TypoSx7 = styled(Typography)({
  color:'#8bc34a', pl:2
})

export const BoxSx2 = styled(Box)({
  width: '100%', typography: 'body1'
})

export const BoxSx3 = styled(Box)({
  borderBottom: 1, borderColor: 'divider', 
})

export const ItemSx = styled(Item)({
display: 'flex',  alignItems: 'center', padding:'1'
})


export const TypoSx4 = styled(Item)({
  backgroundColor: '#8bc34a', height: '1.5vh', width: '4vh', borderRadius: 8, fontWeight: 'light'
})

export const TypoSx5 = styled(Typography)({
  color: '#4B4B4B',
  fontSize:'12px'
})

export const TypoSx6 = styled(Typography)({
  color: '#212121',fontWeight:'light',fontSize:'12px'
})

export const BoxSx4 = styled(Box)({
  display: 'flex', justifyContent: 'center'
})
  export const StackSx1 = styled(Stack)({
    spacing:1.5, display:'flex',  alignItems:"center",width:"100%"
  })

  
  export const AlignedBox=styled(Box)({
    display:'flex',
    justifyContent:'center',
    alignItems:'center'
  })
  export const inputStyle={
    display: "none"
  }
  
  export const ModalStyle={
  
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      outline:'none',
      borderRadius:'20px'
  
  }