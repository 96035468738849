import axios from 'axios'
import { createAsyncThunk,createSlice } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'

export const SET_BUILDINGS = 'SET_BUILDINGS'
export const RESET_ISLOADED = 'RESET_ISLOADED'
export const RESET_BUILDINGS='RESET_BUILDINGS'

const initialState={
    buildings:[],
    isLoaded:false
}


export const startGetBuildings=createAsyncThunk('frBuildings/startGetBuildings',async()=>{
        try{
            const response=await GET(`fr/v1/buildings`)
            if(response.status===200){
                return response.data
            }
            else {
                errorModal()
            }
        }
        catch(err){
            errorModal()
        }
})



const frBuildingSlice=createSlice({
    name:'frBuildingSlice',
    initialState,
    reducers:{
        resetBuildingIsLoaded:(state,action)=>{
            state.isLoaded=false
        },
        resetBuildings:(state,action)=>{
            state.buildings=[]
            state.isLoaded=false
        }

    },
    extraReducers(builder){
        builder.addCase(startGetBuildings.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.buildings=action.payload
                state.isLoaded=true
            }
        })
    }
})

export const {resetBuildingIsLoaded,resetBuildings}=frBuildingSlice.actions

export default frBuildingSlice.reducer