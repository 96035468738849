

// import { useSelector } from "react-redux"
// import { Redirect, Route } from "react-router-dom"
// const PrivateRoute = ({ component: Component, ...rest }) => {
//     const email=useSelector(state=>state?.user?.data?.email)
//     console.log({email})
//     return (
//         < Route
//             {...rest}
//             render={(props) => {
//                 return localStorage.getItem("token") ? (
//                     <Component {...rest} {...props} />
//                 ) : (
//                     // <Redirect
//                     //     to={
//                     //         { pathname: "/?email=nhance" }
//                     //     }
//                     // />
//                     window.location.href=`${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`
//                 )
//             }
//             }
//         />
//     )
// }

// export default PrivateRoute

import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const tenantCode = useSelector((state) => state?.user?.data?.tenantCode);
    const email = useSelector((state) => state?.user?.data?.email);
    const isAuthenticated = localStorage.getItem("token");

    if (isAuthenticated) {

        return <Route {...rest} render={(props) => <Component {...rest} {...props} />} />;
    } else {
        const redirectUrl = `${process.env.REACT_APP_ACCOUNTSUI_URL}?email=${email}`;
        return <Redirect to={{ pathname: "/", search: `?email=${email}` }} />;
        // or use window.location.href for full page redirect
        // window.location.href = redirectUrl;
    }
};

export default PrivateRoute;
