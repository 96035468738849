import { Box, CircularProgress, Drawer, FormControl, FormControlLabel, FormLabel, Grid, Modal, Radio, RadioGroup, Snackbar, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { BackButtonStyle, imageStyle } from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles';
import back from '../../Assets/Images/left-arrow.png';
import { Global } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { assetSetComp } from '../../Redux/ReduxSlice/AssetManagement/assetCompSlice';
import brokenIcon from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import StatusButton from '../../UI/AssetManager/StatusButton';
import correct from '../../Assets/Images/correct.svg'
import { startCreateTransit, startFetchBuildings, startUpdateTransit } from '../../Redux/ReduxSlice/AssetManagement/assetSlice';


const AssetInfo = () => {
    const imageLink=useSelector(state=>state?.config?.imageLink)
    const [snackBar,setSnackBar]=useState(false)
    const [successModal,setSuccessModal]=useState(false)
    const {data}=useSelector(state=>state?.assetData?.scannedAsset) 
    const buildings=useSelector(state=>state?.assetData?.buildings?.data)
    const buildingsIsLoaded=useSelector(state=>state?.assetData?.buildings?.isLoaded)
    const [buildingModal,setBuildingModal]=useState(false)
    const [selectedBuilding,setSelectedBuilding]=useState('')
    const status=data?.transitRef===null?'Stationary':data?.transitRef?.status
    const image=data?.imageLinks.length>0?data.imageLinks[0]:''
    const userId=useSelector(state=>state.user?.data?.id)
    const transferInitiateId=data?.transitRef===null?'':data?.transitRef?.initiatedBy
    const dispatch=useDispatch()
    const imageBoxStyle = {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "85vh",
        display: "inline-block",
        position: "relative",
        backgroundColor:'#D9D9D9'
      };
      const handleBackClick=()=>{
        dispatch(assetSetComp("AssetHome"))
      }
     const snackBarStyle={
        position: 'absolute',
        top:'-30em',
        left:'7em',
        width:'50vw',
        borderRadius:'20px',
        height:'100%'
    }
    const modalStyle={
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    paddingLeft:'20px',
   
    outline:'none',
    borderRadius:'10px'}
     
      const handleTransitCreateSuccess=()=>{
        setSuccessModal(true)
        handleBuildingModal()
        handleSnackBar()
      }
      const handleSubmit=()=>{
       
        const formData={
            assetId:data?._id,
            initiatedOn:Number(new Date()),
            previousBuilding:data?.buildingId,
            transitToBuilding:selectedBuilding
        }
        dispatch(startCreateTransit({formData,handleTransitCreateSuccess}))
      }
      const Row=({title,value})=>{
        return (
            <Grid container item xs={12} >
                    <Grid item xs={4}>
                        <Typography>{title} </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography>{`:  ${value}`}</Typography>
                    </Grid>
                </Grid>
        )
      }

      const handleSnackBar=()=>{
        setSnackBar(!snackBar)
      }
      const handleBuildingModal=()=>{
            setBuildingModal(!buildingModal)
      }

      const fetchBuildings=()=>{
        dispatch(startFetchBuildings())
        handleBuildingModal()
      }
      const handleAcceptAsset=()=>{
            const formData={
                assetId:data?._id,
                recievedOn:Number(new Date()),
                status:"completed"
            }
            const transitId=data?.transitRef?._id
            dispatch(startUpdateTransit(transitId,formData,handleAcceptSuccess))
      }
      const handleAcceptSuccess=()=>{
        setSuccessModal(true)
      }

      const handleBuildingChange=(e)=>{
        setSelectedBuilding(e.target.value)
      }

      useEffect(()=>{
        if(successModal){
            setTimeout(()=>{
                dispatch(assetSetComp('AssetHome'))
            },3000)
        }
      },[successModal])
  return (
    <>
        <Box style={imageBoxStyle}>
       {image?<img
          src={`${imageLink}${image}`}
          alt={"snap"}
          style={imageStyle}
        ></img>
        :
        <Box display={'flex'} justifyContent='center' width='100%' alignItems='center' height={"35vh"}>
            <img src={brokenIcon} style={{width:'30px'}}/>
        </Box>    
        }
        <Box style={BackButtonStyle} onClick={handleBackClick}>
          <img src={back} height="24px" />
        </Box>
      </Box>
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(65%)`,
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
          },
        }}
      />
       <Drawer
        anchor="bottom"
        open={true}
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box p={2}>
            <Grid container spacing={1.5}>
                <Row title='Asset Name' value={data?.assetName} /> 
                <Row title='Serial No' value={data?.serialNumber} />              
                <Row title='Building Name' value={data?.building}/>
                <Row title='Description' value={data?.description} />               
                <Row title='Model' value={data?.model}/>
                

                
            </Grid>
        </Box>

        <Box sx={styles.buttonContainer}>
            <Box width={'100%'}>
               
                {status==='Stationary'&&<Typography align='center' sx={styles.buttonTitle} >Transfer the asset?</Typography>}
                {status==='in transit'?
                userId===transferInitiateId?
                <>
                <Typography align='center' fontSize={'16px'}>Transfer request has been initiated</Typography>
                <Typography align='center' fontSize={'16px'}>Come back in some time to check the status!</Typography>
                </>
                :
                <StatusButton  backgroundColor="#DC5D3B"
                            title={status==='Stationary'?'Transfer':status==='in transit'?'Accept':''}
                            width="100%"
                            onClick={handleAcceptAsset} />
                        :
                        status==='Stationary'?
                        <StatusButton  backgroundColor="#DC5D3B"
                            title={status==='Stationary'?'Transfer':status==='in transit'?'Accept':''}
                            width="100%"
                            onClick={fetchBuildings} />
                            :
                            <>

                            </>
                        }
        </Box>
        </Box>
      </Drawer>
      <Snackbar
                open={snackBar}
                autoHideDuration={3000}
                message="Asset Transfer initiated!"
                sx={snackBarStyle}
                onClose={handleSnackBar}
              />
        <Modal open={buildingModal} onClose={handleBuildingModal}>
            <Box sx={modalStyle}>
            <Box >
                <Typography>Please select the building the asset is to be transferred</Typography>
                <FormControl>
                    {buildingsIsLoaded?<RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        // defaultValue="PDS1"
                        name="radio-buttons-group"
                        value={selectedBuilding}
                        onChange={handleBuildingChange}
                    >
                        {buildings.map((ele)=>{
                            return (
                                <FormControlLabel value={ele._id} control={<Radio />} label={ele.name} />
                            )
                        })}
                    </RadioGroup>
                    :
                   
                        <Box sx={{width:'80vw', display:'flex', justifyContent:'center',mt:2}}>
                             <CircularProgress />
                        </Box>
                   }
                </FormControl>
                
            </Box>
            <Box sx={{marginTop:3}}>
                 <StatusButton backgroundColor="#DC5D3B" title="Initiate Transfer" onClick={handleSubmit}/>
            </Box>
            </Box>
        </Modal>

        <Modal open={successModal}>
                    <Box sx={modalStyle}>
                      <Stack pb={2} p={2}>
                        <Box display='flex' justifyContent='center' pb={3} >
                          <img src={correct}  width='75px'/>
                        </Box>
                        <Typography
                          variant="h5"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign={"center"}
                          fontWeight="bold"
                          fontSize={'24px'}
                          pb={2}
                        >
                          Thank you!
                        </Typography>
                        <Typography
                          
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign={"center"}
                          fontSize='16px'
                         
                        >
                          {status==='in transit'?'Asset transfer was successfully initiated':'Asset was accepted successfully'}
                        </Typography>                        
                      </Stack>
                    </Box>
                  </Modal>
    </>
  )
}

const styles={
    buttonContainer:{
        display:'flex',height:'50%',alignItems:'center',justifyContent:'center',p:2,pt:0
    },
    buttonTitle:{
       marginBottom:3
    }
}

export default AssetInfo
