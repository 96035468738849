import { Box, Modal, Stack, Typography } from '@mui/material'
import React from 'react'
import correct from '../../Assets/Images/correct.svg'

const SuccessModal = (props) => {
    const {open,message}=props
    const modalStyle={
        position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    paddingLeft:'20px',
   
    outline:'none',
    borderRadius:'10px'}
  return (
    <>
         <Modal open={open}>
                    <Box sx={modalStyle}>
                      <Stack pb={2} p={2}>
                        <Box display='flex' justifyContent='center' pb={3} >
                          <img src={correct}  width='75px'/>
                        </Box>
                        <Typography
                          variant="h5"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign={"center"}
                          fontWeight="bold"
                          fontSize={'24px'}
                          pb={2}
                        >
                          Thank you!
                        </Typography>
                        <Typography
                          
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          textAlign={"center"}
                          fontSize='16px'
                         
                        >
                         {message}
                        </Typography>                        
                      </Stack>
                    </Box>
                  </Modal>
    </>
  )
}

export default SuccessModal
