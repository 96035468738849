import React from 'react'
import AdditionalDetailsPanel from '../../components/MeetingRooms/Container/Panel/AdditionalDetails'
const AdditionalDetails = () => {
    return (

        <AdditionalDetailsPanel />


    )
}

export default AdditionalDetails