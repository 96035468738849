import React from 'react'
import { useState, useEffect } from "react";

import QrReader from 'modern-react-qr-reader'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Modal, Paper, Typography } from '@mui/material';
import Nav from '../../UI/FaultReporting/Widgets/Header';
import { startSubArea } from '../../Redux/ReduxSlice/FaultReporting/AreaSlice';
import { ModalStyle } from '../../UI/FaultReporting/Styles/QrCodeStyles';

const QrCodeReader = () => {
    const bid=useSelector(state=>state?.user?.data?.buildingId) 
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const handleScan = (e) => {
    if (e) {
      const body = {
        scannedString: e
      }
      dispatch(startSubArea({area:body, bid,handleModal}))
    }
  }

  const handleModal = () => {
    setOpen(true)
  }
  useEffect(() => {
    if(navigator.mediaDevices.getUserMedia){
        navigator.mediaDevices.getUserMedia(
        {
          video: true,
        }, 
        function(err){
          alert('The following error occurred when trying to access the camera: ' + err); 
        }
      );
      } else {
        alert('Sorry, browser does not support camera access');
      }
  }, [])


  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }, [open])
  return (
    <Box height='100vh'>
      <Nav title='Scan Area Qr code' route='/' />
      <QrReader
        delay={300}
        facingMode="environment"
        onScan={handleScan}
        style={{ width: '100%' }}
      />

      <Modal open={open}>
        <Paper sx={ModalStyle}>
          <Typography>Unable to scan. Please try again</Typography>
        </Paper>
      </Modal>
    </Box>
  )
}

export default QrCodeReader