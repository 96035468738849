import axios from 'axios'
import { resetBookingTime } from './bookingTimeAction'
import swal from 'sweetalert'
import moment from 'moment'
export const SAVE_BOOKING = 'SAVE_BOOKING'
export const SAVE_TODAYS_BOOKINGS = 'SAVE_TODAYS_BOOKINGS'
export const SAVE_UPCOMING_BOOKINGS = 'SAVE_UPCOMING_BOOKINGS'
export const CANCEL_MEETING = 'CANCEL_MEETING'
export const CHECKIN_MEETING = 'CHECKIN_MEETING'

export const startSaveBooking = (body, handleRedirect) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}db/v1/booking`, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        if (res.status === 201) {
          const result = res.data

          dispatch(saveBooking(result.data))
          handleRedirect(true)
          dispatch(resetBookingTime())
        } else {
          console.log({ res })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const saveBooking = (payload) => {
  return {
    type: SAVE_BOOKING,
    payload,
  }
}

export const startGetTodaysBookings = (body) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}booking/bydate`, body, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then((res) => {
        const result = res.data

        dispatch(saveTodaysBookings(result.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const saveTodaysBookings = (payload) => {
  return {
    type: SAVE_TODAYS_BOOKINGS,
    payload,
  }
}

export const startGetUpcomingBookings = (body, handleLoader) => {
  const date = moment().format('YYYY-MM-DD')
  return (dispatch) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}db/v1/bookings?upcoming=true&date=${body.currentDate}`,
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          const result = res.data

          dispatch(saveUpcomingBookings(result))
          handleLoader && handleLoader(false)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export const saveUpcomingBookings = (payload) => {
  return {
    type: SAVE_UPCOMING_BOOKINGS,
    payload,
  }
}

export const startCancelMeeting = (id, loader) => {
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}db/v1/booking/${id}`,
        { status: false },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          const result = res.data

          dispatch(cancelMeeting(result.id))
          dispatch(resetBookingTime())
          swal('Booking has been cancelled!', {
            icon: 'success',
          })
          loader(false)
        } else {
          console.log({ res })
        }
      })

      .catch((err) => {
        console.log(err)
      })
  }
}

const cancelMeeting = (data) => {
  return {
    type: CANCEL_MEETING,
    payload: data,
  }
}

export const startCheckinMeeting = (id) => {
  return (dispatch) => {
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}db/v1/booking/${id}`,
        { checkedIn: true },
        {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        },
      )
      .then((res) => {
        if (res.status === 200) {
          const result = res.data

          dispatch(checkinMeeting(result))
        } else {
          console.log({ res })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

const checkinMeeting = (data) => {
  return {
    type: CHECKIN_MEETING,
    payload: data,
  }
}
