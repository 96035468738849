import React, { useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';


const style = {
    position: 'absolute',
    width: 100,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 12,
    border:"1px solid red"
};

const Popup = ({ handleDeleteLoader, creator, handleDelete, id, }) => {
    const [open, setOpen] = useState(true)
    const handleOpen = (val) => {
        setOpen(val)
    }
    return (
        <>
            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div>

                        {creator && <DeleteOutlineOutlinedIcon {...bindTrigger(popupState)} sx={{ cursor: "pointer" }} />}

                        {(open && creator) && <Popover

                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            disableRestoreFocus
                        >
                            <Typography px={1.5} py={0.6} onClick={(event) => {
                                handleOpen(false)
                                handleDelete(event, id, handleOpen)
                                handleDeleteLoader(true)
                            }} sx={{ cursor: 'pointer' }}>{creator && 'Delete event'}</Typography>
                        </Popover>}
                    </div>
                )}
            </PopupState>
        </>
    );
}

export default Popup;
