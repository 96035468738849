import { createSlice } from "@reduxjs/toolkit"
const initialState={
    viewAll:false
}


const RedirectSlice=createSlice({
    name:'RedirectSlice',
    initialState,
    reducers:{
        setViewAll:(state,action)=>{
            state.viewAll=true
        },
        resetViewAll:(state,action)=>{
            state.viewAll=false
        }
    }
})

export const {setViewAll,resetViewAll}=RedirectSlice.actions

export default RedirectSlice.reducer