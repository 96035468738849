export const ModalStyle = {
    position: "absolute",
    // border:"1px solid black",
    top: "50%",
    // height: "50%",
    // bottom: -10,
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90vw',
    outline: "0",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 4,
    // p: 1,
    // pt:2,
    // pb:3,
    // height:'30vh'
    // pl: 2,
    //m:1
};