import axios from 'axios'
import { createSlice } from '@reduxjs/toolkit'

export const SET_COMP='SET_COMP'

const initialState={
    title:'Fault Reporting',
}
// export const setComp=(data)=>{
//     return {
//         type:SET_COMP,
//         payload:data
//     }
// }

const frCompSlice=createSlice({
    name:'frCompSlice',
    initialState,
    reducers:{
        setComp:(state,action)=>{
            state.title=action.payload
        }
    }
})

export const {setComp}=frCompSlice.actions

export default frCompSlice.reducer