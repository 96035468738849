import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { MuiBox } from '../../UI/FaultReporting/Styles/MainStyles'
import AssetInfo from './AssetInfo'
import AssetList from './AssetList'
import AssetQrCodeReader from './AssetQrCodeReader'
import AssetTransfer from './AssetTransfer'


const AssetMain=()=>{
    const comp=useSelector(state=>state?.assetComp).title
    return (
        <MuiBox>
            {comp==='AssetHome'?<AssetList />:comp==='assetQrCodeReader'?<AssetQrCodeReader />:comp==='AssetInfo'?<AssetInfo />:comp==='AssetTransfer'?<AssetTransfer />:<></>}
        </MuiBox>
    )
}

export default AssetMain