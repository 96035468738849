import React, { useEffect } from 'react'
import {
  resetVisitorDetails,
  startGetAllAppointments,
  startGetAppointmentsById,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import { Box, Card, Grid, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'

const RequestedAppointments = (props) => {
  const userId = useSelector((state) => state?.user?.data?.id)
  const dispatch = useDispatch()
  const { appointmentRequests } = useSelector(
    (state) => state.visitData?.allAppointments,
  )
  const appointmentsIsLoaded = useSelector(
    (state) => state.visitData?.allAppointments?.appointmentsIsLoaded,
  )
  const handleRedirect = (_id) => {
    // dispatch(setSelectedAppointments({_id}))
    dispatch(startGetAppointmentsById({ _id }))
    dispatch(setVisitorComp('AppointmentDetails'))
  }

  useEffect(() => {
    dispatch(startGetAllAppointments())
    return () => {
      dispatch(resetVisitorDetails())
    }
  }, [])
  return (
    <Box>
      <Grid item spacing={2} xs={12}>
        {appointmentsIsLoaded ? (
          appointmentRequests.length > 0 ? (
            appointmentRequests.map((appointment) => (
              <Box
                key={appointment._id}
                sx={{
                  display: 'flex',
                  marginBottom: '5%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100vw',
                    padding: '3%',
                    background: '#ffffff',
                  }}
                  onClick={() => {
                    handleRedirect(appointment._id)
                  }}
                >
                  <Typography component="div" variant="h6">
                    Purpose: {appointment?.purpose}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Visitor Name:{' '}
                    {appointment?.visitors
                      ?.map((visitor) => visitor?.visitor?.name)
                      .join(', ')}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Location: {appointment?.location?.name}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Start Time:
                    {`${moment(new Date(appointment?.startTime)).format(
                      'DD MMM YYYY hh:mm A',
                    )}`}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Status: {appointment?.status}
                  </Typography>
                </Box>
              </Box>
            ))
          ) : (
            <Box
            // height="50vh"
            // display="flex"
            // alignItems="center"
            // justifyContent="center"
            >
              <Card
                sx={{
                  width: '100%',
                  // height: '100%',
                  padding: '10%',
                  // display: 'flex',
                  // justifyContent: 'cenetr',
                  // alignContent: 'center',
                }}
              >
                <Typography sx={{ fontSize: '15px', textAlign: 'center' }}>
                  You don't have requested appointments
                </Typography>
              </Card>
            </Box>
          )
        ) : (
          <Typography>Loading...</Typography>
        )}
      </Grid>
    </Box>
  )
}
export default RequestedAppointments
