import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import brokenIcon from '../../Assets/FaultReporting/Images/BrokenIcon.png'
import { BrokenImageBox } from '../../UI/FaultReporting/Styles/AdminTicketDetailsStyles'
import { TypoSx } from '../../UI/FaultReporting/Styles/ReportStyles'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import {
  clearAssetsToMove,
  clearSingleAsset,
  removeAssetToTransfer,
  startCreateTransit,
  startFetchBuildings,
} from '../../Redux/ReduxSlice/AssetManagement/assetSlice'
import StatusButton from '../../UI/AssetManager/StatusButton'
import correct from '../../Assets/Images/correct.svg'
import { assetSetComp } from '../../Redux/ReduxSlice/AssetManagement/assetCompSlice'
import SuccessModal from '../../UI/AssetManager/SuccessModal'

const AssetTransfer = () => {
  const dispatch = useDispatch()
  const buildings = useSelector((state) => state?.assetData?.buildings?.data)
  const buildingsIsLoaded = useSelector(
    (state) => state?.assetData?.buildings?.isLoaded,
  )
  const userBuilding = useSelector((state) => state.user?.data?.buildingId)
  const [selectedBuilding, setSelectedBuilding] = useState(
    buildingsIsLoaded ? buildings[0]?._id : '',
  )
  const assetList = useSelector((state) => state?.assetData?.assetsToMove?.data)
  const assetListIsLoaded = useSelector(
    (state) => state?.assetData?.assetsToMove?.isLoaded,
  )
  const [successModal, setSuccessModal] = useState(false)
  // const assetListIsLoaded=false

  const handleBuildingChange = (e) => {
    setSelectedBuilding(e.target.value)
  }
  const handleRemoveAsset = (ele) => {
    // const newArr=assetList.filter(data=>data.id!==ele.id)
    dispatch(removeAssetToTransfer(ele))
  }
  const handleTransitCreateSuccess = () => {
    // dispatch(clearSingleAsset())

    setSuccessModal(true)
  }
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    paddingLeft: '20px',

    outline: 'none',
    borderRadius: '10px',
  }

  const handleSubmit = () => {
    const assetIds = assetList.map((ele) => ele._id)
    const formData = {
      assets: assetIds,
      initiatedOn: Number(new Date()),
      previousBuilding: assetList[0]?.building?._id,
      // previousBuilding:'6412a551267ca61a2ff8b301',
      transitToBuilding: selectedBuilding,
    }
    dispatch(startCreateTransit({ formData, handleTransitCreateSuccess }))
  }
  useEffect(() => {
    dispatch(startFetchBuildings())
  }, [])
  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        dispatch(assetSetComp('AssetHome'))
        dispatch(clearAssetsToMove())
      }, 4000)
    }
  }, [successModal])
  return (
    <Box>
      <Typography sx={{ fontWeight: 500, fontSize: '20px' }}>
        Assets ready to transfer
      </Typography>
      <Box>
        {assetListIsLoaded ? (
          <>
            <Box>
              {assetList?.length > 0 &&
                assetList.map((ele) => {
                  return (
                    <Box
                      paddingY={2}
                      borderTop="solid 1px #D3D3d3"
                      borderBottom={'solid 1px #D3D3d3'}
                    >
                      <Grid item container xs={12} spacing={2}>
                        <Grid item xs={3.5}>
                          <Box
                            sx={{
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: '#D9D9D9',
                                borderRadius: '10px',
                                height: '7vh',
                                width: '90%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              <BrokenImageBox>
                                <img
                                  src={brokenIcon}
                                  height={'10px'}
                                  style={{ objectFit: 'cover' }}
                                />
                              </BrokenImageBox>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item container xs={7}>
                          <Grid item xs={12}>
                            <Typography variant={'h6'}>
                              {ele.assetName}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <TypoSx variant="caption">{`model - ${ele.model} `}</TypoSx>
                            </Box>
                          </Grid>
                        </Grid>
                        <Grid item xs={1.5}>
                          <IconButton onClick={() => handleRemoveAsset(ele)}>
                            <CloseOutlinedIcon fontSize={'medium'} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Box>
                  )
                })}
            </Box>
            <Box>
              {buildingsIsLoaded ? (
                <>
                  <Box>
                    {buildings.length >= 1 ? (
                      <>
                        <Box sx={{ py: 2 }}>
                          <Typography>
                            Select the building the asset is to be transferred
                          </Typography>
                        </Box>
                        <FormControl fullWidth>
                          <InputLabel id="buildingList"></InputLabel>
                          <Select
                            labelId="buildingList"
                            id="buildingList"
                            value={selectedBuilding}
                            label=""
                            onChange={handleBuildingChange}
                          >
                            {buildings.map((ele) => (
                              <MenuItem value={ele._id}>{ele.name}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Box
                          sx={{
                            marginTop: 3,
                            position: 'absolute',
                            bottom: 10,
                            left: 0,
                            width: '100%',
                            px: 2,
                          }}
                        >
                          {assetList.length > 0 && (
                            <StatusButton
                              backgroundColor="#DC5D3B"
                              title="Initiate transfer"
                              width="100%"
                              onClick={handleSubmit}
                            />
                          )}
                        </Box>
                      </>
                    ) : (
                      <Box paddingTop={'12px'}>
                        <Typography sx={{ textAlign: "center" }}>There are no other buildings to transfer the asset</Typography>
                      </Box>
                    )}
                  </Box>
                </>
              ) : (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 3,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </>
        ) : (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              mt: 3,
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
      {successModal && (
        <SuccessModal
          open={successModal}
          message={'Asset Transfer was successfully initiated!'}
        />
      )}
    </Box>
  )
}

export default AssetTransfer
