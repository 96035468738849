import axios from "axios"
export const UPDATE_WASHROOM_STATUS='UPDATE_WASHROOM_STATUS'

export const startFetchWashroomStatus=()=>{
    return (dispatch)=>{
        axios.get(`${process.env.REACT_APP_DASHBOARD_URL}common/washroom/carousellatest`,{
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((response)=>{
                const result=response.data
                if(result.success){
                    dispatch(updateWashroomStatus(result.data))
                }
                else {
                    console.log({result})
                }
            })
            .catch(err=>console.log(err))
    }
}

const updateWashroomStatus=(payload)=>{
    return {
        type:UPDATE_WASHROOM_STATUS,
        payload
    }
}
