import axios from 'axios'
import { setCompFb } from './CompSliceFb'
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'
import { errorModal } from '../../../Helpers/ErrorModal'

export const SET_SUBAREA = 'SET_SUBAREA'

const initialState={
    subArea:{},
    isLoaded:false
}


export const startGetSubArea=createAsyncThunk('fb/startGetSubArea',async({scannedString,buildingId,handleError},thunkAPI)=>{
    try{
        const response=await GET(`fb/v1/qrcode?qrcode=${scannedString}&buildingId=${buildingId}`)
        if(response.status===200){
            thunkAPI.dispatch(setCompFb('Feedback_form'))
            return response.data
        }
        else {
           errorModal()
        }
    }
    catch(err){
        handleError()
    }
})


const fbSubAreaSlice=createSlice({
    name:'fbSubAreaSlice',
    initialState,
    reducers:{

    },
    extraReducers(builder){
        builder.addCase(startGetSubArea.fulfilled,(state,action)=>{
            if(action.payload){
                
                state.subArea=action.payload
                state.isLoaded=true
            }
        })
    }
})

export default fbSubAreaSlice.reducer