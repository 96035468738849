import { createStore, combineReducers, applyMiddleware } from "redux"
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import thunk from "redux-thunk"
import thunkMiddleware from 'redux-thunk';
//MeetingRooms
import eventSlice from "../ReduxSlice/MeetingRooms/eventsSlice"
import meetingsSlice from "../ReduxSlice/MeetingRooms/meetingsSlice"
import resourceSlice from "../ReduxSlice/MeetingRooms/resourcesSlice"
import userSlice from "../ReduxSlice/Common/userSlice"

//DeskBooikng
import buildingsReducer from "../ReduxSlice/deskBooking/bookingLocation"
// import bookingTimeReducer from "../reducers/DeskBooking/bookingTimeSlice"
import bookingTimeReducer from "../ReduxSlice/deskBooking/bookingTimeSlice"

// import floorReducer from "../reducers/DeskBooking/floorReducer"
// import zoneReducer from "../reducers/DeskBooking/zoneReducer"
import zoneReducer from "../ReduxSlice/deskBooking/bookingZoneSlice"
import deskReducer from "../reducers/DeskBooking/deskReducer";
import bookingReducer from "../ReduxSlice/deskBooking/bookingCrudOperationsSlice"

//FaultReporting
import compSlice from "../ReduxSlice/FaultReporting/compSlice"
import FaultImageSlice from "../ReduxSlice/FaultReporting/FaultImageSlice"
// import TicketReducer from "../reducers/FaultReporting/TicketReducer"
import TicketSlice from '../ReduxSlice/FaultReporting/TicketsSlice'
import BuildingSlice from "../ReduxSlice/FaultReporting/BuildingSlice"
import FloorSlice from "../ReduxSlice/FaultReporting/FloorSlice"
import AreaSlice from "../ReduxSlice/FaultReporting/AreaSlice"
import RedirectSlice from "../ReduxSlice/Common/RedirectSlice"
import compSliceFb from "../ReduxSlice/Feedback/CompSliceFb"
import SubAreaSlice from "../ReduxSlice/Feedback/SubAreaSlice"
import RatingsSlice from "../ReduxSlice/Feedback/RatingsSlice"
import userInfoSlice from "../ReduxSlice/Common/userInfoSlice"
import aqiReducer from "../reducers/Common/aqiReducer"
import assetSlice from "../ReduxSlice/AssetManagement/assetSlice"
import washroomReducer from "../reducers/Washroom/washroomReducer"
import AssetCompSlice from "../ReduxSlice/AssetManagement/assetCompSlice"
import appsSlice from "../ReduxSlice/Common/appsSlice"
import VisitorCompSlice from '../ReduxSlice/ManageVisitor/ManageVisitorComp'
import VisitorDetailsSlice from "../ReduxSlice/ManageVisitor/VisitorDetailsSlice";
//Feedback

const storeConfigure = () => {
    const rootReducer = combineReducers({
        //MeetingRooms
        resource: resourceSlice,
        event: eventSlice,
        meetings: meetingsSlice,


        //DeskBooking
        buildings: buildingsReducer,
        bookingTime: bookingTimeReducer,
        // floors: floorSlice,
        zone: zoneReducer,
        desk: deskReducer,
        bookings: bookingReducer,

        //FaultReducer
        faultReportingComp:compSlice,
        faultImage:FaultImageSlice,
        faultReports:TicketSlice,
        frBuildings:BuildingSlice,
        frFloors:FloorSlice,
        frAreas:AreaSlice,


        //Feedback
        feedbackComp:compSliceFb,
        subArea:SubAreaSlice,
        ratings:RatingsSlice,
        
        //AssetManagement

        assetData:assetSlice,
        assetComp:AssetCompSlice,


        //Washroom
        washroomData: washroomReducer,

        //Vistor management
        vistorComp:VisitorCompSlice,
        visitData:VisitorDetailsSlice,

        //common
        user: userSlice,
        redirection:RedirectSlice,
        userInfo:userInfoSlice,
        aqiData:aqiReducer,
        config:appsSlice,

    })
    const store = configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
      });
    return store
}

export default storeConfigure;
