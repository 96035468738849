import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Chip, Grid, Stack, Typography } from '@mui/material'
import CardComp from '../../UI/MeetingRooms/Card/CardComp'
import Header from '../../components/DeskBooking/Container/Header'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const Occupancy = () => {
  const [isSelected, setIsSelected] = useState(false)
  const history=useHistory()
  const appConfig=useSelector(state=>state?.config?.apps)?.find(ele=>ele?.configName==='occupancy')
  const arrayItems = [
    { id: 1, title: 'Temperature', degree: '24.1c' },
    { id: 2, title: 'Humidity', degree: '48%' },
    { id: 2, title: 'Humidity', degree: '48%' },
    { id: 2, title: 'Humidity', degree: '48%' },
  ]

  const handleOnClick = (id) => {
    setIsSelected(id)
  }

  const swipeItems = [
    'Receiption Area',
    'Meeting Room',
    'Meeting Room ',
    'Meeting Room',
    'Meeting Room ',
    'Meeting Room ',
    'Meeting Room',
  ]

  useEffect(()=>{
    if(Object.keys(appConfig).length>0){

        !appConfig?.enabled&&history.push('/')
    }
  },[])
  return (
    <>
            <Header title='Occupancy (Coming Soon)' mb={0}/>
      <Box
        sx={{
          background: 'linear-gradient(180deg, #FF6520 70%, #F6F6F6 30%);',
          padding: 4,
         
          pt: 0.5,
        }}
      >
        <CardComp border="8" minHeight="250px">
          <CardContent sx={{ '&:last-child': { pb: 1.5 } }} width="100%">
            <Grid container item width="100%">
              <Grid
                xs={12}
                style={{
                  marginTop: '20%',
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: '10%',
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CloudQueueOutlinedIcon
                      style={{ color: '#000000' }}
                      fontSize="large"
                    />
                  </Grid>
                  <Typography
                    style={{ textAlign: 'center' }}
                    fontSize="25px"
                    gutterBottom
                    color="#808080"
                  >
                    24.1c
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" fontWeight="bold" gutterBottom>
                    Friday , April 12
                  </Typography>

                  <Typography
                    variant="h5"
                    color="#AAAAAA"
                    style={{ textAlign: 'center' }}
                  >
                    BANGALORE
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardComp>
      </Box>
      <Box style={{ width: '100%' }}>
        <Grid container item>
          <Stack pl={4.5} pr={4.5} width="100%" sx={{ overflowY: 'hidden' }}>
            <div>
              <Grid
                item
                xs={12}
                display="flex"
                direction="row"
                container
                wrap="nowrap"
                align="center"
                justifyContent="space-between"
                sx={{
                  overflow: 'auto',
                  minWidth: '100%',
                  height: '37px',
                  // borderRadius: '20px',
                  overflowY: 'hidden',
                  paddingBottom:"50px"
                }}
              >
                {swipeItems.map((item, id) => {
                  return (
                    <Grid
                      item
                      display="flex"
                      direction="row"
                      mx={1}
                      container
                      align="center"
                      key={id}
                      sx={{ '&:hover': { cursor: 'pointer' } }}
                      
                    >
                      <Box
                        sx={{
                          
                          backgroundColor:
                            isSelected === id ? '#FF6520' : '#ffffff',
                          p: 0.1,
                          borderRadius: '20px',
                          border: isSelected === id ? '1px solid #ffffff' : '1px solid #000000',
                        }}
                        onClick={() => {
                          handleOnClick(id)
                        }}
                      >
                        <Stack m={0} p={0}>
                         <Grid
                            item
                            xs={12}
                            style={{
                              fontSize:"14px",
                              fontWeight:"20px",
                            }}                          >
                            <Chip label={item} style={{color: isSelected === id ? '#FFFFFF' : '#000000'}}/>
                          </Grid>
                        </Stack>
                      </Box>
                    </Grid>
                  )
                })}
              </Grid>
            </div>
          </Stack>

          <Grid item xs={12} pl={4.5} pr={4.5} pt={1}>
            {arrayItems.map((item, index) => {
              const backgroundColor = index % 2 === 0 ? '#FF6520' : '#ffffff'
              const textColor = index % 2 === 0 ? '#fff' : '#32a852'
              const secondaryTextColor = index % 2 === 0 ? '#fff' : '#AAAAAA'

              return (
                <Card
                  style={{
                    height: '150px',
                    width: '100%',
                    backgroundColor,
                    // paddingBottom: '10%',
                    marginBottom: '20px',
                  }}
                >
                  <Typography
                    variant="h3"
                    style={{ color: textColor, padding: '5%' }}
                  >
                    {item.degree}
                  </Typography>
                  <Typography
                    variant="h5"
                    style={{
                      color: secondaryTextColor,
                      padding: '5%',
                      textAlign: 'right',
                    }}
                  >
                    {item.title}
                  </Typography>
                </Card>
              )
            })}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
export default Occupancy