import React from 'react'
import { IconButton, Typography, Grid } from '@mui/material'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import back from '../../../Assets/Images/left-arrow.png'

const Header = (props) => {
  const history = useHistory()
  const location = useLocation()
  const { title, link, mb } = props
  const viewAll = useSelector((state) => state.redirection?.viewAll)
  const dispatch = useDispatch()
  const handleClick = () => {
    if (location.pathname === '/deskbooking/location') {
      history.push('/deskbooking/home')
    } else if (location.pathname === '/deskbooking/home') {
      if (viewAll) {
        history.push('/notifications')
      } else {
        history.push('/')
      }
    } else if (location.pathname === '/deskbooking/upcoming') {
      history.push('/')
    } else {
      history.goBack()
    }
  }

  return (
    <Grid
      container
      spacing={0}
      display="flex"
      alignItems={'center'}
      height={70}
      backgroundColor="#FF6520"
      mb={0}
      p={2}
    >
      <Grid item xs={1.2}>
        <IconButton size="small" float="left" onClick={handleClick}>
          <img src={back} width={'24px'} />
        </IconButton>
      </Grid>
      <Grid item xs={10.8} display="flex">
        <Typography fontSize={20} align="left" color="#FFFFFF">
          {title}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default Header
