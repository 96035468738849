import React from 'react'
import { Typography, Grid, Box, Stack } from '@mui/material'

const CommonTypography = ({ label, value ,bold}) => {
    return (
      <Grid container spacing={1} sx={{height:"100%",width:"100%",marginBottom: 2 }}>
      <Grid item xs={4} fontSize='16px'>
                 {label}:
            </Grid>
             <Grid item xs={8}>
                <Typography fontWeight={bold ? 'bold' : 400} fontSize='16px'>{value}</Typography>
            </Grid>
        </Grid>
    // <Grid container spacing={1} sx={{height:"100%",width:"100%"}}>
    //   <Grid item xs={6}>
    //     <Typography
    //       component="div"
    //       variant="h6"
    //       sx={{ fontWeight: 'bold', color: 'dark' }}
    //     >
    //       {label}:
    //     </Typography>
    //   </Grid>
    //   <Grid item xs={6}>
    //     <Typography
    //       component="div"
    //       variant="h6"
    //       sx={{ fontWeight: 'bold', color: "#808080"}}
    //     >
    //       {value}
    //     </Typography>
    //   </Grid>
    // </Grid>
  )
}

export default CommonTypography
