import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { VMGET, VMPOST, VMPUT } from '../../../Helpers/http'
import moment from 'moment'
import dayjs from 'dayjs'

const initialState = {
  allAppointments: {
    appointments: [],
    appointmentsIsLoaded: false,
    bookedAppointments: [],
    appointmentRequests: [],
  },
  locations: { data: [], locationsIsLoaded: false },
  selectedLocation: { location: {}, building: {} },
  selectedAppointments: { data: {}, isLoaded: false },
  visitDateTime: { startTime: '', endTime: '' },
  visitorSearch: [],
  visitorsInfo: { visitors: [], purpose: '', isLoaded: false },
  selectedVisitors: [],
  selectedPurpose: '',
  selectedDescription: '',
  selectedDate: new Date(),
  selectedTime: moment().format('HH:mm'),
  selectedValue: dayjs(moment().format('YYYY-MM-DDTHH:mm')),
  selectedDuration: 'Select Duration',
  isBookedAppointment: false,
}

export const startGetAllAppointments = createAsyncThunk(
  'visitorManagement/fetchAllAppointments',
  async ({ userId }) => {
    try {
      const response = await VMGET('v1/host/appointments')
      if (response.status === 200) {
        const result = response.data
        return { data: result, userId }
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const startGetAppointmentsById = createAsyncThunk(
  'visitorManagement/startGetAppointmentsById',
  async ({ _id, userId }) => {
    try {
      const response = await VMGET(`v1/host/appointment/${_id}`)
      if (response.status === 200) {
        const result = response.data
        console.log(_id, 'id')
        return result
        // { data: result, userId }
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const startGetAllBuildings = createAsyncThunk(
  'visitorManagement/fetchAllBuildings',
  async () => {
    try {
      const response = await VMGET('v1/host/buildings')
      if (response.status === 200) {
        const result = response.data
        return result
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const getVisitorBySearchTerm = createAsyncThunk(
  'visitorManagement/searchTerm',
  async ({ searchTerm }) => {
    try {
      const response = await VMGET(`v1/host/visitorSearch?name=${searchTerm}`)
      if (response.status === 200) {
        const result = response.data
        return result
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const updateAppointment = createAsyncThunk(
  'visitorManagement/updatedAnAppointment',
  async ({ data, id, handleModal,handleNotesModal,handleSuccess }) => {
    try {
      const response = await VMPUT(`v1/host/appointment/${id}`, data)
      if (response.status === 200) {
        const result = response.data
        handleModal && handleModal()
        handleNotesModal&&handleNotesModal()
        handleSuccess&&handleSuccess(response?.data?.status)
        return result
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const createVisitor = createAsyncThunk(
  'visitorManagement/createVisitor',
  async ({ data, handleAddNewVisitor, toggleToggleAddVisitor }) => {
    try {
      const response = await VMPOST('v1/visitor/create', data)
      console.log(data, 'visitor-added')
      // const response = axios

      //   .post('http://localhost:8020/v1/visitor/create', body)
      //   .then((ele) => {
      //     const response = ele?.data
      //     console.log('visitor-added', response)
      //   })
      //   .catch((err) => {
      //     console.log('visitor-added', err)
      //   })
      //make this 201
      if (response.status === 200) {
        const result = response.data
        handleAddNewVisitor && handleAddNewVisitor(response.data)
        toggleToggleAddVisitor && toggleToggleAddVisitor()
        return result
      } else {
        console.log({ response })
      }
    } catch (err) {
      console.log(err)
      throw err
    }
  },
)

export const startSaveAppointments = createAsyncThunk(
  'visitorManagement/saveAppointments',
  async ({ body, handleModal,handleFail }) => {
    try {
      const response = await VMPOST('v1/host/appointment', body)
      if (response.status === 201) {
        const result = response.data
        handleModal && handleModal()
        return result
      } else {
        handleFail()
        console.log({ response })
      }
    } catch (err) {
        handleFail()
      console.log(err)
      throw err
    }
  },
)

const VisitorDetailsSlice = createSlice({
  name: 'VisitorDetailsSlice',
  initialState,
  reducers: {
    setSelectedAppointments: (state, action) => {
      state.selectedAppointments.data = action.payload
      state.selectedAppointments.isLoaded = true
    },
    setSelectedVisitLocation: (state, action) => {
      state.selectedLocation.building = action.payload
      state.selectedLocation.location = action.payload.location
      // state.selectedLocation.selectedLocationIsLoaded = true;
    },
     setSelectedLocation: (state, action) => {
      state.selectedLocation = action?.payload
    },
    setVisitDateTime: (state, action) => {
      state.visitDateTime.startTime = action.payload.startTime
      state.visitDateTime.endTime = action.payload.endTime
    },
    setVisitorSearch: (state, action) => {
      state.visitorSearch = action?.payload
    },
    setSelectedVisitors: (state, action) => {
      state.selectedVisitors = action?.payload
    },
    setVisitorsInfo: (state, action) => {
      state.visitorsInfo.visitors = action.payload?.visitors
      state.visitorsInfo.purpose = action.payload?.purpose
      state.visitorsInfo.isLoaded = true
    },
    setSelectedPurpose: (state, action) => {
      state.selectedPurpose = action?.payload
    },
    setSelectedDescription: (state, action) => {
      state.selectedDescription = action?.payload
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action?.payload
    },
    setSelectedDuration: (state, action) => {
      state.selectedDuration = action?.payload
    },
    setSelectedTime: (state, action) => {
      state.selectedTime = action?.payload
    },
    setSelectedValue: (state, action) => {
      state.selectedValue = action?.payload
    },
    // resetBuildingLocation: (state, action) => {
    //   state.selectedLocation = { location: {}, building: {} };
    // },
    resetVisitorDetails: (state, action) => {
      // state={...initialState, allAppointments: state.allAppointments, locations: state.locations}
      state.selectedLocation = initialState.selectedLocation
      state.locations = initialState.locations
      state.selectedAppointments = initialState.selectedAppointments
      state.visitDateTime = initialState.visitDateTime
     state.visitorsInfo = initialState.visitorsInfo
      state.selectedVisitors = initialState.selectedVisitors
      state.selectedPurpose = initialState.selectedPurpose
      state.selectedDescription = initialState.selectedDescription
      state.selectedDate = initialState.selectedDate
      state.selectedTime = initialState.selectedTime
      state.selectedValue = initialState.selectedValue
      state.selectedDuration = initialState.selectedDuration
      state.visitorSearch = initialState.visitorSearch
      state.isBookedAppointment = false
    },
  },
  extraReducers(builder) {
    builder
      .addCase(startGetAllAppointments.pending, (state, action) => {
        state.allAppointments.appointmentsIsLoaded = false
      })
      .addCase(startGetAllAppointments.fulfilled, (state, action) => {
        if (action.payload) {
          const appointments = action.payload?.data
          state.allAppointments.appointments = appointments
          state.allAppointments.appointmentsIsLoaded = true
          const userId = action.payload?.userId
          const requestAppointments = appointments.filter(
            (ele) => ele?.host === userId && ele?.initiatedBy === 'visitor',
          )
          const bookedAppointments = appointments.filter(
            (ele) => ele?.host === userId && ele?.initiatedBy === 'host',
          )

          state.allAppointments.appointmentRequests = requestAppointments
          state.allAppointments.bookedAppointments = bookedAppointments
        }
      })
      .addCase(startGetAllBuildings.pending, (state, action) => {
        state.locations.locationsIsLoaded = false
      })
      .addCase(createVisitor.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedVisitors.push(action.payload)
        }
      })
      .addCase(startGetAllBuildings.fulfilled, (state, action) => {
        if (action.payload) {
          state.locations.data = action.payload
          state.selectedLocation.location = action.payload[0].location
          state.locations.locationsIsLoaded = true
        }
      })
      .addCase(getVisitorBySearchTerm.fulfilled, (state, action) => {
        if (action.payload) {
        state.visitorSearch = action.payload
        }
      })
      .addCase(startSaveAppointments.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedAppointments = action.payload
          state.isBookedAppointment = true
        }
      })
      .addCase(startGetAppointmentsById.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedAppointments.data = action.payload
          state.selectedAppointments.isLoaded = true
        }
      })
      .addCase(updateAppointment.fulfilled, (state, action) => {
        if (action.payload) {
          state.selectedAppointments.data = action.payload
        }
      })
  },
})

export const {
  setSelectedVisitLocation,
  setSelectedLocation,
  setVisitDateTime,
  setVisitorsInfo,
  setSelectedAppointments,
  setVisitorSearch,
  resetVisitorDetails,
  setSelectedVisitors,
  setSelectedDate,
  setSelectedTime,
  setSelectedDuration,
  setSelectedValue,
  setSelectedPurpose,
  setSelectedDescription,
  resetBuildingLocation,
} = VisitorDetailsSlice.actions

export default VisitorDetailsSlice.reducer
