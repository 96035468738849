import { Box } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import Report from '../../pages/FaultReporting/Report'
import { MuiBox } from '../../UI/FaultReporting/Styles/MainStyles'
import AdminTicketDetails from './AdminTicketDetails'
import CameraComp from './Camera'
import QrCodeReader from './QrCodeReader'
import AssetDetails from '../FaultReporting/AssetDetails.js'
import QrCodeTicket from './QrCodeTicket'


const Main=()=>{
    const comp=useSelector(state=>state.faultReportingComp).title
    return (
        <MuiBox>
            {comp=='Fault Reporting'?<Report />:comp=='Camera'?<CameraComp />:comp=='QrCode'?<QrCodeReader />:
            comp==='Ticket Details'?<AdminTicketDetails />:comp==='Asset Details'?<AssetDetails />:comp==='QrCodeTicket'?<QrCodeTicket/>:<></>}
        </MuiBox>
    )
}

export default Main