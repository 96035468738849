import React, { useEffect, useState } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import HomeHeader from '../../components/MeetingRooms/HomeHeader/HomeHeader'
import HomePagePanel from '../../components/MeetingRooms/Container/Panel/HomePagePanel';
import newNhance from '../../Assets/Images/NhanceNewLogo.svg'


const Home = (props) => {
    const [randomGreeting, setRandomGreeting] = useState('')
    const style={
        display:'flex',
         alignItems:'center',
          justifyContent:'center'
        
    }
    const style2={
        position:'absolute',
        bottom:10,
        width:'100%',
        display:'flex',
        justifyContent:'center'
    }
    const style3={
        position:'absolute',
        bottom:55,
        width:'100%',
        display:'flex',
        justifyContent:'center'
    }
    const arr = ["There is nothing impossible to they who will try",
    "Spread love everywhere you go", "Belief creates the actual fact",
    "Believe you can and you're halfway there", "Try to be a rainbow in someone's cloud"]
    useEffect(() => {
        const greetingText = arr[Math.floor(Math.random() * arr.length)]
        setRandomGreeting(greetingText)
    }, [])
    return (
        <Box sx={{minHeight:'100vh',position: "relative"}}>
           
                <HomeHeader />
                

                <Box pb={'10vh'}>
                    <HomePagePanel />
                </Box>
            {/* <Box style={style3}>
            <Typography  sx={{ fontFamily: "'sacremento'" ,pt:1}} fontWeight='bold' gutterBottom>
                                {randomGreeting}
                                
            </Typography>
            </Box> */}
            <Box style={style2}>
            
                    <Box mt={1}>
                        <img src={newNhance} width='180px'/>
                    </Box>
            </Box>
                       
          
            
            </Box>   
       
    )
}

export default Home