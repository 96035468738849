import React from 'react'
import { Box, Card } from '@mui/material'
import { styled } from '@mui/system'

export const ImageBox = styled(Card)({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '40vh',
  display: 'inline-block',
  position: 'relative',
  borderRadius: '0px 0px 20px 20px',
})

export const DescriptionBox = styled(Box)({
  padding: '1.6vh',
})

export const AcceptTicketBox = styled(Box)({
  padding: '1.5vh',
  paddingTop: '6vh',
})

export const StatusBox = styled(Box)({
  padding: '16px',
})

export const OptionsBox = styled(Box)({
  padding: '8px',
})

export const UserBox = styled(Box)({
  marginTop: '1vh',
  padding: '8px',
})

export const imageStyle = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '0px 0px 20px 20px',
}
export const GridItemStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
}
export const BackButtonStyle = {
  // position: 'absolute',
  // // bottom:'4em',
  // // right:'4em',
  // top:'2em',
  // height:'18px',
  // borderRadius:'1.5em',
  // color:'white',
  // textTransform:'uppercase',
  // padding:'1em 1.5em',
  // backgroundColor:"#d9d9d9",

  // display:'flex',
  // alignItems:'center',
  position: 'absolute',
  top: '2em',
  height: '15px',
  width: '15px',
  borderRadius: '50%',
  color: 'white',
  textTransform: 'uppercase',
  padding: '1.5em ',
  display: 'flex',
  alignItems: 'center',
}

export const TypographyStyle = {
  fontSize: '14px',
  fontStyle: 'italic',
  fontWeight: '400',
  color: '#201F1E',
}
export const AreaTextStyle = {
  fontSize: '16px',
  color: '#201F1E',
  fontWeight: '400',
}
export const TimeTextStyle = {
  color: '#000000',
  fontSize: '12px',
}
export const AcceptTicketTypographyBox = {
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '2.4vh',
}

export const AcceptTicketButtonBox = {
  padding: '1.5vh',
  display: 'flex',
  justifyContent: 'center',
}

export const StatusTypography = {
  fontSize: '18px',
  fontWeight: 700,
}

export const TextFieldStyle = {
  backgroundColor: '#FFFFFF',
  width: '100%',
  marginBottom: '1vh',
}

export const snackBarStyle = {
  position: 'absolute',
  top: '2em',
  left: '7em',
  width: '50vw',
  borderRadius: '20px',
}

export const MuiBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
export const BrokenImageBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
})

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: '20px',
}

export const inputStyle = {
  display: 'none',
}

export const PaddingBox = styled(Box)({
  padding: '16px',
})
export const FaultImageCard = styled(Card)({
  backgroundColor: '#D9D9D9',
  borderRadius: '10px',
  height: '15vh',
  width: '100%',
})

export const NoTopPaadingBox = styled(Box)({
  padding: '16px',
  paddingTop: '0px',
})
