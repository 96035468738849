import { Button } from '@mui/material'
import React from 'react'

const ConfirmButton = (props) => {
    const { title, backgroundColor, link, action, data, handleRedirect,loader,onclick,disabled,height,width } = props
    
    return (
        <Button sx={{ backgroundColor: backgroundColor, fontWeight:'bold',fontSize:'14px', color: '#F6F6F6', width: width?width:'45%',height:height?height:'60px', textTransform: 'none','&:hover':{backgroundColor:backgroundColor} }}
            onClick={onclick}
            disabled={disabled}>
            {title}
        </Button>
    )
}

export default ConfirmButton