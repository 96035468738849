import React, { useEffect, useState } from 'react'
import {
  Box,
  IconButton,
  Grid,
  Typography,
  Avatar,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Skeleton,
  Backdrop,
  CircularProgress,
  Modal,
} from '@mui/material'
import cameraIcon from '../Assets/FaultReporting/Images/camera.svg'
import folderIcon from '../Assets/FaultReporting/Images/folder.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ConfirmButton from '../UI/FaultReporting/Button/ConfirmButton'
import QRCode from 'qrcode.react'
import {
  startEditUserInfo,
  startGetUserInfo,
  startUploadProfilePic,
} from '../Redux/ReduxSlice/Common/userInfoSlice'
import MuiPhoneNumber from 'material-ui-phone-number'
import back from '../Assets/Images/left-arrow.png'
import {
  AlignedBox,
  inputStyle,
  ModalStyle,
} from '../UI/FaultReporting/Styles/ReportStyles'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import FormControl from '@mui/material/FormControl'
import OutlinedInput from '@mui/material/OutlinedInput'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { PUT } from '../Helpers/http'
import swal from 'sweetalert'
// import {
//   //   fetchPasswordSlice,
//   resetPassword,
//   startFetchPasswordSlice,
//   useResetPasswordMutation,
// } from '../../../Redux/ReduxSlice/Common/passwordSlice'

const AccountProfile = () => {
  const [modal, setModal] = useState(false)
  const imageLink = useSelector((state) => state?.config?.imageLink)
  const userId = useSelector((state) => state.user?.data?.id)

  const userInfo = useSelector((state) => state?.userInfo)
  const userName = useSelector((state) => state.userInfo?.data?.fullName) || ''
  const [loader, setLoader] = useState(false)
  const [passwordOpen, setPasswordOpen] = useState(false)

  const phoneNumber = useSelector((state) => state.userInfo?.data?.mobile) || ''
  const finalPhoneNumber =
    phoneNumber === '+' || !phoneNumber ? '' : phoneNumber
  const profilePicture = userInfo?.data?.profilePictureUrl
  const awsUrl = profilePicture ? `${imageLink}${profilePicture}` : ''
  const [updatedProfilePicture, setUpdatedProfilePicture] = useState(awsUrl)
  const oldProfilePicUrl = userInfo?.data?.profilePictureUrl
    ? userInfo?.data?.profilePictureUrl
    : ''
  const [profilePicUrl, setProfilePicUrl] = useState(oldProfilePicUrl)
  const [profilePicUpdated, setProfilePicUpdated] = useState(false)
  const [numberField, setNumberField] = useState(`${phoneNumber}`)
  const userEmail = useSelector((state) => state.userInfo?.data?.email)

  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const dataIsLoaded = useSelector((state) => state.userInfo?.isLoaded)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setNumberField(phoneNumber == '+' ? '' : `${phoneNumber}`)
    setNameField(userName)
  }, [userName, phoneNumber])

  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  console.log(newPassword, confirmPassword, 'hhhhhhhhhhhhhh')

  const [nameField, setNameField] = useState(userName)

  const [editMode, setEditMode] = useState(false)

  const [phoneEditMode, setPhoneEditMode] = useState(false)
  const [nameEditMode, setNameEditMode] = useState(false)

  const handleEditMode = () => {
    setEditMode(!editMode)
    if (!phoneEditMode) setPhoneEditMode(!phoneEditMode)
    if (!nameEditMode) setNameEditMode(!nameEditMode)
  }
  const handleNumberField = (e) => {
    setNumberField(e)
  }

  const handleNameField = (e) => {
    setNameField(e.target.value)
  }

  const closeEditMode = () => {
    setEditMode(false)
    setNameEditMode(false)
    setPhoneEditMode(false)
    setProfilePicUpdated(false)
    setLoader(false)
  }
  const handleSubmit = () => {
    const data = {
      mobile: numberField,
      fullName: nameField,
      profilePictureUrl: profilePicUrl,
    }
    dispatch(startEditUserInfo({ id: userId, data, closeEditMode }))
    setLoader(true)
  }
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value?.trim())
  }

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value?.trim())
  }
  const onKeyDownConfirmPassword = (e) => {
    if (e.key === 'Enter') {
      submitPassword()
    }
  }
  const handlePasswordModal = () => {
    setPasswordOpen(!passwordOpen)
    // setOpenLogoutPasswrd(false)
  }
  const initiateResetPassword = (password) => {
    PUT('um/v1/user/password', { newPassword })
      .then((res) => {
        if (res.status === 200) {
          setPasswordOpen(false)
          swal('', 'Password Reset Successfully!', 'success')
          setNewPassword('')
          setConfirmPassword('')
        } else {
          console.log(res)
        }
      })    
      .catch((err) => {
        if (err.response?.status === 400) {
          setPasswordError('Password cannot be same as previous password')
        } else {
          setNewPassword('')
          setConfirmPassword('')
          console.log(err)
        }
      })
  }

  useEffect(() => {
    setPasswordError('')
  }, [newPassword, confirmPassword])

  const submitPassword = async () => {
    if (newPassword?.length === 0 || confirmPassword?.length === 0) {
      setPasswordError('Passwords cannot be empty')
    } else if (newPassword?.length < 8 || confirmPassword?.length < 8) {
      setPasswordError('Password must be at least 8 characters')
    } else if (newPassword !== confirmPassword) {
      setPasswordError('Passwords do not match')
    } else if (newPassword === confirmPassword) {
      try {
        initiateResetPassword(newPassword)
      } catch (error) {
        console.log({ error })
      }
    }
  }

  useEffect(() => {
    if (Object.keys(userInfo?.data).length === 0) {
      history.push('/')
    }
  }, [])

  useEffect(() => {
    setUpdatedProfilePicture(
      profilePicture ? `${imageLink}${profilePicture}` : '',
    )
  }, [profilePicture])

  useEffect(() => {
    if (oldProfilePicUrl) {
      setProfilePicUrl(oldProfilePicUrl)
    }
  }, [oldProfilePicUrl])
  const handleBackClick = () => {
    history.push('/')
  }

  const handleImageUpload = (url) => {
    const imageUrl = `${imageLink}${url}`
    setUpdatedProfilePicture(imageUrl)
    setProfilePicUrl(url)
    setProfilePicUpdated(true)
  }

  const handleImageCancel = () => {
    setUpdatedProfilePicture(
      profilePicture ? `${imageLink}${profilePicture}` : '',
    )
    setProfilePicUrl(oldProfilePicUrl)
    setProfilePicUpdated(false)
  }
  const handleCapture = (target) => {
    if (target.files) {
      if (target.files.length !== 0) {
        const file = target.files[0]
        const newUrl = URL.createObjectURL(file)
        let formData = new FormData()
        formData.append('file', file)

        dispatch(startUploadProfilePic(formData, handleImageUpload))
        setModal(false)
      }
    }
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  const handlePasswordReset = () => {}

  function generateVCardData() {
    // fetch(`https://dt.letsnhance.com/static/media/logo.567af26698cbf82d3cf35e69b33b83f7.svg`)
    if (updatedProfilePicture) {
      fetch(
        'https://clv.h-cdn.co/assets/15/22/2048x2048/square-1432664914-strawberry-facts1.jpg',
      )
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            const base64String = reader.result
            const updatedString = base64String.split('base64,')[1]
            //   setBase64Image(base64String);
            const vcardString = `BEGIN:VCARD\nVERSION:3.0\nFN:${userName}\nTEL;TYPE=CELL;EXT=91:${finalPhoneNumber}\nEMAIL:${userEmail}\nPHOTO;ENCODING=BASE64;TYPE=JPEG:${updatedString}\nEND:VCARD`
            const element = document.createElement('a')
            const file = new Blob([vcardString], { type: 'text/vcard' })
            element.href = URL.createObjectURL(file)
            element.download = 'contact.vcf'
            document.body.appendChild(element) // Required for Firefox
            element.click()
            document.body.removeChild(element)
          }
          reader.readAsDataURL(blob)
        })
        .catch((error) => {
          console.log('Error:', error)
        })
    } else {
      const vcardString = `BEGIN:VCARD\nVERSION:3.0\nFN:${userName}\nTEL;TYPE=CELL;EXT=91:${finalPhoneNumber}\nEMAIL:${userEmail}\nEND:VCARD`
      const element = document.createElement('a')
      const file = new Blob([vcardString], { type: 'text/vcard' })
      element.href = URL.createObjectURL(file)
      element.download = 'contact.vcf'
      document.body.appendChild(element) // Required for Firefox
      element.click()
      document.body.removeChild(element)
    }
  }
  const downloadVCard = async () => {
    const vcardData = generateVCardData()
  }

  const vcardData = `BEGIN:VCARD\nVERSION:3.0\nFN:${userName}\nTEL;TYPE=CELL;EXT=91:${finalPhoneNumber}\nEMAIL:${userEmail}\nEND:VCARD`

  return (
    <Box
      p={0}
      backgroundColor="#ffffff"
      sx={{ minHeight: '100vh', position: 'relative' }}
    >
      <Box sx={{ paddingBottom: 10 }}>
        <Grid
          container
          spacing={0}
          display="flex"
          alignItems={'center'}
          height={70}
          backgroundColor="#FF6520"
          p={2}
        >
          <Grid item xs={1.2}>
            <IconButton size="small" float="left" onClick={handleBackClick}>
              <img src={back} width={'24px'} />
            </IconButton>
          </Grid>
          <Grid item xs={10.8} display="flex">
            <Typography fontSize={20} align="left" color="#FFFFFF">
              {'My Profile'}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          pb={2}
          sx={{
            background: 'linear-gradient(180deg, #FF6520 45%, #FFFFFF 20%)',
          }}
        >
          {/* background: "linear-gradient(180deg, #FF6520 45%, #FFFFFF  20%);" */}
          <Grid
            xs={12}
            display="flex"
            justifyContent={'center'}
            alignItems={'center'}
            //   onClick={()=>setModal(true)}
          >
            <Avatar
              sx={{
                backgroundColor: '#D9D9D9',
                height: '38vh',
                width: '38vh',
                p: 0,
              }}
              src={updatedProfilePicture ? updatedProfilePicture : ''}
            >
              <Typography
                color="#AAAAAA"
                sx={{ fontSize: '3.5rem', fontWeight: '700' }}
              >
                {userInfo?.data?.fullName?.slice(0, 1).toUpperCase()}
              </Typography>
            </Avatar>

            {/* <img src={UserIcon} /> */}
          </Grid>
          {editMode && (
            <Box display="flex" justifyContent={'center'} width={'100%'}>
              {profilePicUpdated ? (
                <IconButton onClick={handleImageCancel}>
                  <ClearOutlinedIcon fontSize="medium" />
                </IconButton>
              ) : (
                <IconButton onClick={() => setModal(true)}>
                  <EditOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
            </Box>
          )}
        </Grid>

        <Stack
          display={'flex'}
          alignItems="center"
          spacing={editMode ? 2 : 0}
          pb={editMode ? 0 : 2}
        >
          {dataIsLoaded ? (
            nameEditMode ? (
              <>
                <TextField
                  variant="outlined"
                  value={nameField}
                  name="assetTextBox"
                  autoComplete="off"
                  inputProps={{ maxLength: 30, style: { textAlign: 'center' } }}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        border: `1px solid ${
                          nameField.length === 0 ? 'red' : '#FF6520'
                        }`,
                      },
                    },
                    width: '93vw',
                    fontSize: '16px',
                  }}
                  error={nameField?.length == 0 ? true : false}
                  label={nameField?.length == 0 ? 'Name cannot be empty' : ''}
                  onChange={(e) => handleNameField(e)}
                />
              </>
            ) : (
              <Box mb={1.5}>
                <Typography color="#212121" fontSize={'32px'} fontWeight="700">
                  {nameField || userInfo?.data?.fullName}
                </Typography>
              </Box>
            )
          ) : (
            <Skeleton variant="rectangular" width={210} height={20} />
          )}

          {dataIsLoaded ? (
            editMode ? (
              <>
                <TextField
                  variant="outlined"
                  value={userInfo?.data?.email}
                  name="assetTextBox"
                  autoComplete="off"
                  inputProps={{ maxLength: 30, style: { textAlign: 'center' } }}
                  sx={{
                    '& .MuiOutlinedInput-root.Mui-focused': {
                      '& > fieldset': {
                        border: `1px solid ${
                          nameField.length === 0 ? 'red' : '#FF6520'
                        }`,
                      },
                    },
                    width: '93vw',
                    fontSize: '16px',
                  }}
                  disabled
                />
              </>
            ) : (
              <Typography color="#212121" fontSize={'16px'}>
                {userInfo?.data?.email && userInfo?.data?.email}
              </Typography>
            )
          ) : (
            <Skeleton variant="rectangular" width={210} height={20} />
          )}
          {!phoneEditMode ? (
            <Typography color="#212121" fontSize={'16px'}>
              {numberField && numberField !== '+'
                ? numberField
                : 'Add your phone number!'}
            </Typography>
          ) : (
            <Box sx={{ paddingTop: 0 }}>
              <Box
                sx={{
                  border: 'solid 1px #c4c4c4',
                  borderRadius: 1,
                  padding: 1,
                }}
              >
                <MuiPhoneNumber
                  defaultCountry={'in'}
                  value={numberField}
                  onlyCountries={['in', 'au', 'us', 'sg']}
                  inputProps={{ style: { textAlign: 'center', width: '80vw' } }}
                  onChange={handleNumberField}
                  InputProps={{ disableUnderline: true }}
                  fullWidth
                  disableAreaCodes
                />
              </Box>
            </Box>
          )}
        </Stack>
        <Stack display={'flex'} alignItems="center" spacing={0}>
          <Box
            minHeight="20vh"
            width={'70vw'}
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            mt={1}
          >
            {dataIsLoaded ? (
              <QRCode
                value={vcardData}
                size={160}
                //     imageSettings={{src:"https://picsum.photos/id/67/300/300",
                //                     excavate:true,
                //                     width:60
                // }}
              />
            ) : (
              // <button onClick={downloadVCard}>Download vCard</button>
              <Skeleton variant="rectangular" width={210} height={210} />
            )}
          </Box>
          <Box sx={{ marginTop: 1, paddingBottom: 5 }}>
            <Typography sx={{ fontSize: '14px' }}>
              Use the QR code above to share your Vcard
            </Typography>
          </Box>
        </Stack>
        <Box
          sx={{
            bottom: 10,
            left: 0,
            position: 'absolute',
            width: '100%',
            px: 2,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {/* <ConfirmButton
            backgroundColor={editMode?"#5cdb5c":"#FF6520"}
            width="100%"
            disabled={nameField.length==0}
            title={editMode?"Done":"Edit Profile"}
            onClick={editMode?()=>handleSubmit():handleEditMode}
            /> */}

          <Typography
            style={{ color: '#FF6520', textDecoration: 'underline' }}
            onClick={editMode ? () => handleSubmit() : handleEditMode}
          >
            {editMode ? 'Done' : 'Edit Profile'}
          </Typography>
          <Typography
            style={{
              color: '#FF6520',
              textDecoration: 'underline',
              paddingLeft: '5%',
            }}
            onClick={handlePasswordModal}
          >
            {'forget password'}
          </Typography>
        </Box>
      </Box>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        open={passwordOpen}
        onClose={handlePasswordModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: '10px',
            p: 3,
          }}
        >
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              // sx={{
              //   display: 'flex',
              //   justifyContent: 'center',
              //   alignItems: 'center',
              // }}
            >
              <Typography
                variant="h6"
                component="h2"
                sx={{ fontWeight: '20%' }}
              >
                Reset Password
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                /> */}
              <FormControl sx={{ width: '42ch' }} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">
                    Enter new password
                  </InputLabel> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Enter new password"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label="Password"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              {/* <TextField
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onKeyDown={onKeyDownConfirmPassword}
                /> */}
              <FormControl sx={{ width: '42ch' }} variant="outlined">
                {/* <InputLabel htmlFor="outlined-adornment-password">
                    Enter new password
                  </InputLabel> */}
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Confirm new password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  // label="Password"
                />
              </FormControl>
              {passwordError && (
                <Typography variant="body" sx={{ color: 'red' }}>
                  {passwordError}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                paddingTop: '5%',
                display: 'flex',
                justifyContent: 'flex-end',
                //   alignItems: 'center'
              }}
            >
              <Button
                variant="contained"
                color="warning"
                sx={{ textTransform: 'lowercase' }}
                onClick={submitPassword}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Modal open={modal} onClose={() => setModal(false)}>
        <Box sx={ModalStyle}>
          <Grid container>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="icon-button-file">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={cameraIcon} width="26px" />
                    </Box>
                    <Typography color="#212121">Click an image</Typography>
                  </Stack>
                </label>
                <input
                  accept="image/*"
                  style={inputStyle}
                  id="icon-button-file"
                  type="file"
                  capture="environment"
                  onChange={(e) => handleCapture(e.target)}
                />
              </AlignedBox>
            </Grid>
            <Grid item xs={6}>
              <AlignedBox>
                <label htmlFor="choose-file" id="choose-file-label">
                  <Stack spacing={1}>
                    <Box display="flex" justifyContent={'center'}>
                      <img src={folderIcon} />
                    </Box>
                    <Typography color="#212121">Upload from Gallery</Typography>
                  </Stack>
                </label>

                <input
                  name="uploadDocument"
                  type="file"
                  id="choose-file"
                  accept=".jpg,.jpeg,.pdf,doc,docx,application/msword,.png"
                  style={inputStyle}
                  onChange={(e) => {
                    handleCapture(e.target)
                  }}
                />
              </AlignedBox>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  )
}
export default AccountProfile
