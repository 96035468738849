import React, { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Stack,
  Typography,
  Paper,
  Modal,
} from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import IconButton from '@mui/material/IconButton'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import {
  getAllDesksByZone, resetZoneDesksLoaded,
  setZoneDetails,
} from '../../../Redux/ReduxSlice/deskBooking/bookingZoneSlice'
import Header from './Header'
import moment from 'moment'
import ConfirmButton from '../../../UI/DeskBooking/Button/ConfirmButton'
import faultIcon from '../../../Assets/DeskBooking/Images/faultIcon.png'
const Floor = (props) => {
  const arr = useSelector((state) => state.zone?.zones)
  const inSelectedZone = useSelector((state) => state.zone?.inSelectedZone)
  const selectedZone = useSelector((state) => state.zone?.selectedZone)
  const zoneDesksLoaded = useSelector((state) => state.zone?.zoneDesksLoaded)
  const allBookings = useSelector((state) => state.bookings?.upcomingBookings)
  const [currentSessionBooked, setCurrentSessionBooked] = useState(false)
  const [booked, setBooked] = useState(false)
  const bookingTime = useSelector((state) => state.bookingTime)
  const selectedTime = bookingTime?.selectedTime
  const dispatch = useDispatch()
  const history = useHistory()

  const style2 = {
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    top: '50%',
    outline: '0',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 4,
    p: 2,
    pl: 2,
  }

  useEffect(() => {
    if (inSelectedZone) {
      const body = {
        zoneId: selectedZone?.zone?.id,
        selectedDate: bookingTime?.selectedDate,
        firstHalf: bookingTime?.selectedTime?.firstHalf,
        secondHalf: bookingTime?.selectedTime?.secondHalf,
      }
      dispatch(getAllDesksByZone({ body, redirect }))
    }
  }, [inSelectedZone])
  const redirect = () => {
    history.push('/deskbooking/modal')
  }

  useEffect(() => {
    if (arr?.length === 0) {
      history.push('/deskbooking/location')
    }
  }, [])
  useEffect(() => {
    dispatch(resetZoneDesksLoaded())
  }, [zoneDesksLoaded])

  useEffect(() => {
    if (allBookings?.length > 0) {
      const date = moment().format('YYYY-MM-DD')
      if (
        (selectedTime?.firstHalf == false && selectedTime?.secondHalf === true) ||
        (selectedTime?.firstHalf === true && selectedTime?.secondHalf === true)
      ) {
        const currentBooking = allBookings?.filter((ele) => {
          return (
            (ele?.bookingDate === date && ele?.secondHalf === true) ||
            (ele?.bookingDate === date &&
              ele?.firstHalf &&
              ele?.secondHalf === true)
          )
        })
        if (currentBooking?.length > 0) {
          setCurrentSessionBooked(true)
        }
      } else if (
        (selectedTime?.firstHalf == true && selectedTime?.secondHalf === false) ||
        (selectedTime?.firstHalf === true && selectedTime?.secondHalf === true)
      ) {
        const currentBooking = allBookings.filter((ele) => {
          return (
            (ele?.bookingDate === date && ele?.firstHalf === true) ||
            (ele?.bookingDate === date &&
              ele?.firstHalf &&
              ele?.secondHalf === true)
          )
        })
        if (currentBooking?.length > 0) {
          setCurrentSessionBooked(true)
        }
      }
    }
  }, [allBookings])


  const handleClick = (ele) => {
    const selectedDateBookings = allBookings?.filter((e) => {
      if (e?.bookingDate === bookingTime?.selectedDate) return true
      else return false
    })

    if (selectedDateBookings?.length === 0) {
      dispatch(setZoneDetails(ele))
    } else {
      let firstHalf = false,
        secondHalf = false
      let isBooked = false

      for (let i = 0; i < selectedDateBookings.length; i++) {
        //   selectedDateBookings.forEach((e) => {
        if (firstHalf === false) firstHalf = selectedDateBookings[i]?.firstHalf
        if (secondHalf === false) secondHalf = selectedDateBookings[i]?.secondHalf

        switch (true) {
          case bookingTime.selectedTime?.firstHalf &&
            bookingTime.selectedTime?.secondHalf:
            if ((firstHalf && secondHalf) || firstHalf || secondHalf) {
              setBooked(true)
              isBooked = true
            }
            break
          case bookingTime.selectedTime?.secondHalf:
            if (secondHalf) {
              setBooked(true)
              isBooked = true
            }
            break
          case bookingTime.selectedTime?.firstHalf:
            if (firstHalf) {
              setBooked(true)
              isBooked = true
            }
            break
          default:
            break
        }

      }

      if (!isBooked) {
        dispatch(setZoneDetails(ele))
      }
    }
  }

  const handleBookedAlert = () => {
    setBooked(false)
  }
  return (
    <Box sx={{ paddingBottom: 2 }}>
      <Header title="Select Floor" />
      <Box p={2}>
        <Stack spacing={1}>
          {arr?.map((ele) => {
            return (
              <Paper
                sx={{ boxShadow: 0, borderRadius: '5px' }}
                onClick={() => handleClick(ele)}
              >
                <Grid container item pr={1} py={1} pl={1} pt={2} pb={2}>
                  <Grid xs={11}>
                    <Box>
                      <Typography
                        variant="caption"
                        fontSize={'14px'}
                        px={1}
                        style={{ color: '#AAAAAA' }}
                      >
                        {ele?.building?.name} | {ele?.zone?.name} | {ele.floor?.name}
                      </Typography>
                      <Stack direction={'row'} alignItems={'center'}>
                        <Typography fontSize="40px" px={1}>
                          {' '}
                          {ele.availableDesks}
                        </Typography>
                        <Box display="flex" alignItems={'end'} mt={2}>
                          <Typography fontSize={'16px'} fontWeight={'medium'}>
                            Desk(s) Available
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid
                    xs={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                  >
                    <IconButton >
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Paper>
            )
          })}


        </Stack>
        <Modal open={booked}>
          <Paper sx={style2}>
            <Box pb={2} display="flex" justifyContent={'center'}>
              <img src={faultIcon} width="40px" />
            </Box>
            <Box pb={2}>
              <Typography fontSize="16px" align="center">
                You have already booked a desk for the selected shift
              </Typography>
            </Box>
            <ConfirmButton
              backgroundColor="#FF6520"
              width="100%"
              title="OK"
              height="40px"
              onclick={handleBookedAlert}
            />
          </Paper>
        </Modal>
      </Box>
    </Box>
  )
}

export default Floor
