import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, Container, Paper, Stack, Typography } from '@mui/material';
import ConfirmButton from '../../../../UI/MeetingRooms/Button/ConfirmButton';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import correct from "../../../../Assets/Images/correct.svg"

const BookingConfirmed = (props) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const currentLocation = location?.pathname
    const history = useHistory()
    const meetings = useSelector(state => state?.meetings?.list?.data)
    const selectedEvent = useSelector(state => state?.meetings?.selectedEvent.data)
    const createdMeeting = meetings[meetings.length - 1]
    useEffect(() => {
        if (Object.keys(selectedEvent).length === 0 && meetings.length == 0) {
            history.push("/meetingrooms/booking")
        }

    }, [])


    const style={
        
        width: '100%',
        outline: "0",
        bgcolor: "background.paper",
        borderRadius: 4,
        p: 2,
        pt:5,
        boxShadow:24,
        pb:7
    }
    return (
        
        <Container>
            
            <Stack spacing={3} height={'100vh'} justifyContent='center' alignItems=' center' >
            <Paper sx={style}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} >
                    <Box >
                        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} pb={2}>
                              <img  src={correct} height="80px"/>
                        </Box>
                        <Typography variant='h6' fontSize={'16px'} align={'center'} fontWeight={'bold'}>{currentLocation === '/meetingrooms/update/confirmation' ? `Booking Updated` : `Booking Confirmed`}</Typography>
                    </Box>
                </Box>
                <Box mt={3}>
                    <Typography align={'center'} mb={3} fontSize='16px'>

                        {currentLocation === '/meetingrooms/update/confirmation' ?
                            `Schedule information has been shared with invitees` :
                            `Would you like to add additional information & invite guests to this booking?`}
                    </Typography>
                    <Stack direction='row' justifyContent={'center'} alignItems={'center'} spacing={2} mt={3}>

                        <ConfirmButton title={currentLocation === '/meetingrooms/update/confirmation' ? `Home` : `No`} color='#212121' link='/meetingrooms/booking' />
                        <ConfirmButton title={currentLocation === '/meetingrooms/update/confirmation' ? `View my meetings` : `Yes`} color='#FF6520' link={currentLocation === '/meetingrooms/update/confirmation' ? `/meetingrooms/meetings` : `/meetingrooms/update/attendees`} />
                    </Stack>
                </Box>
                </Paper>
            </Stack>
            
        </Container >
        
    )
}

export default BookingConfirmed