import * as React from "react";
import { useState, useEffect } from "react"
import { Grid, Paper, Box, Autocomplete, TextField, InputAdornment, IconButton } from "@mui/material";
import Modal from "@mui/material/Modal";
// import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { useSelector, useDispatch } from "react-redux";
import { setSelectedDesk, startGetDeskDetails, setDefaultDesk, resetIsLoaded } from "../../Redux/ReduxSlice/DeskBooking/deskAction";
import { startSaveBooking } from '../../Redux/ReduxSlice/deskBooking/bookingCrudOperationsSlice'
import moment from "moment";
import { useHistory } from "react-router-dom";
import Header from "./Container/Header";
import { resetZoneInSelectedZone } from "../../Redux/ReduxSlice/deskBooking/bookingZoneSlice";
import LeafletViewer from "./Container/LeafletViewer";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ConfirmButton from "../../UI/DeskBooking/Button/ConfirmButton";

const style = {
  position: "absolute",
  bottom: -100,
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90%',
  outline: "0",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 2,
  pl: 2,
};
const style2 = {
  position: "absolute",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '90%',
  top: '50%',
  outline: "0",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  p: 2,
  pl: 2,
};

const theme = createTheme({
  palette: {
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    orange: {
      main: "#FF6520",
      contrastText: "#fff",
    },
  },
});


export default function TransitionsModal(props) {
  const [inputValue, setInputValue] = useState("")
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const handleConfirm = () => setConfirm(true)
  const [booked, setBooked] = useState(false)
  const [openOptions, setOpenOptions] = useState(false)
  const [desk, setDesk] = useState("")

  const handleConfirmClose = () => {
    dispatch(setDefaultDesk())
    setConfirm(false)
    setOpen(false)
    setDesk("")
    dispatch(resetIsLoaded())
  }

  const dispatch = useDispatch()


  const history = useHistory()

  const desks = useSelector(state => state.zone?.zoneDesks)
  const bookingTime = useSelector(state => state.bookingTime)
  const isLoaded = useSelector(state => state.desk?.isLoaded);
  const deskData = useSelector(state => state.desk)
  const selectedZone = useSelector(state => state.zone?.selectedZone)
  const selectedLocation = useSelector(state => state.buildings?.selectedLocation)
  const handleInputChange = (value) => {
    setInputValue(value)
  }


  useEffect(() => {
    if (inputValue.length === 0) {
      setOpenOptions(false)
    }
    else if (inputValue.length >= 1) {
      setOpenOptions(true)
    }
    if (desk) {
      setOpenOptions(false)
    }

  }, [inputValue])


  const handleChange = (e, val) => {

    dispatch(setDefaultDesk())
    setDesk(val)
    setOpenOptions(false);

  }


  const handleBooking = () => {
    const newBooking = {

      desk: deskData.data.id,
      zone: selectedZone?.zone?.id,
      firstHalf: bookingTime.selectedTime.firstHalf,
      secondHalf: bookingTime.selectedTime.secondHalf,
      bookingDate: bookingTime.selectedDate,
      building: selectedZone?.building?.id,
      floor: selectedZone?.floor?.id,
      location: selectedLocation?._id
    }
    dispatch(startSaveBooking({ newBooking, handleRedirect }))

  }
  const handleRedirect = (booked) => {
    if (booked) {
      history.push('/deskbooking/confirmed')
    }
    else if (!booked) {
      setOpen(false)
      setConfirm(false)
      setDesk("")
      setBooked(true)
    }

  }

  const handleBookedRedirect = () => {
    history.push('/deskbooking/home')
  }
  const handleRescan = () => {
    dispatch(setDefaultDesk())
    setOpen(false);
    setDesk('')
  }

  useEffect(() => {
    if (desk) {

      const selectedDesk = desks.find(ele => ele.deskCode === desk);

      dispatch(setSelectedDesk(selectedDesk));
      const body = {
        deskId: selectedDesk.id,
        selectedDate: bookingTime.selectedDate,
        firstHalf: bookingTime.selectedTime.firstHalf,
        secondHalf: bookingTime.selectedTime.secondHalf,
      }
      dispatch(startGetDeskDetails(body))
    }
  }, [desk])

  useEffect(() => {

    if (isLoaded && (inputValue.length > 0)) {
      setOpen(true)
    }
  }, [isLoaded])

  useEffect(() => {
    if (desks.length === 0) {
      history.push('/deskbooking/location')
    }
    dispatch(resetZoneInSelectedZone())

  }, [])

  return (
    <Box>
      <Header title="Select Seat" />
      <Box p={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" fontSize={'1rem'} fontWeight={'bold'}>
              Search to select a desk or choose from the map below
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ borderRadius: '20px' }}>
            <Paper>
              <Autocomplete
                id="Search Desk No"
                inputValue={inputValue}
                open={openOptions}
                value={desk}
                onChange={handleChange}
                onInputChange={(e, value) => {
                  handleInputChange(value)
                }}
                onClose={() => {
                  setOpenOptions(false)
                }}
                blurOnSelect={true}
                options={desks.map((option) => option.deskCode)}
                renderInput={(option) => (
                  <TextField
                    {...option}
                    label="Enter desk number"
                    InputProps={{
                      ...option.InputProps,
                      type: 'search',
                      endAdornment: (
                        <InputAdornment>
                          <IconButton sx={{ position: 'absolute' }}>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Paper>
          </Grid>
        </Grid>

        <Box height='10px' pt={1} px={3}>
          <LeafletViewer handleChange={handleChange} />

        </Box>
        <Modal open={confirm} onClose={handleConfirmClose} sx={{ border: "red" }}>
          {/* <Fade in={confirm}> */}
            <Paper sx={style}>
              <Box>
                <Stack spacing={1} textAlign="center">
                  <Grid>
                    <Typography fontSize={'24px'}>Confirm Booking</Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h6">Seat No</Typography>
                    <Typography
                      variant="h5"
                      fontSize={'1.5rem'}

                    >
                      {deskData?.data?.deskCode}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h6">Booking For</Typography>
                    <Typography
                      variant="h5"
                      fontSize={'1.5rem'}

                    >
                      {moment(bookingTime?.selectedDate).format('Do MMM YYYY')}
                    </Typography>
                  </Grid>
                </Stack>
              </Box>
              <Stack direction={'row'} spacing={1} pt={3} justifyContent="space-around">
                <ThemeProvider theme={theme}>
                  <Button
                    color="neutral"
                    variant="contained"
                    sx={{
                      color: '#ececec',
                      borderRadius: '8px',
                      height: '6vh',
                      width: '40vw',
                    }}
                    onClick={handleConfirmClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleBooking}
                    color="orange"
                    variant="contained"
                    sx={{
                      color: '#F6F6F6',
                      borderRadius: '8px',
                      height: '6vh',
                      width: '40vw',
                    }}
                  >
                    Confirm
                  </Button>
                </ThemeProvider>
              </Stack>
            </Paper>
          {/* </Fade> */}
        </Modal>
        <Modal open={open} onClose={handleConfirmClose}>
          <Paper sx={style}>
            <Box >
              <Typography fontSize={'24px'} align='center' >

                Your selection
              </Typography>
              <Box pl={2}>
                <Grid container spacing={1}  >
                  <Grid item xs={5}>
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      Seat No
                    </Typography>
                  </Grid>
                  <Grid item xs={7} >
                    <Typography variant="h6" sx={{ mt: 2 }}>
                      {deskData?.data?.deskCode}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h6">Type</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h6">{deskData.data.type}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h6">Booking Status</Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="h6" color={!deskData?.data?.isBooked ? "green" : "red"}>
                      {!(deskData?.data?.isBooked) ? 'Available' : 'Occupied'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Stack
              direction={'row'}
              spacing={2}
              margin={1}
              pt={2}
              justifyContent="space-between"
            >

              <ThemeProvider theme={theme}>
                {
                  !deskData.data?.isBooked ?
                    <>
                      <Button
                        onClick={handleRescan}
                        color="neutral"
                        variant="contained"
                        sx={{
                          color: '#ececec',
                          borderRadius: '8px',
                          height: '6vh',
                          width: '90vw',
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="orange"
                        variant="contained"
                        sx={{
                          color: '#F6F6F6',
                          borderRadius: '8px',
                          height: '6vh',
                          width: '90vw',
                        }}
                        onClick={handleConfirm}
                      >
                        Book desk
                      </Button>
                    </>
                    :
                    <Button
                      variant="contained"
                      sx={{
                        color: '#F6F6F6',
                        borderRadius: '10px',
                        height: '6vh',
                        width: '90vw',
                        border: "#FFFFFF"
                      }}
                      onClick={handleRescan}>
                      Go Back
                    </Button>}
              </ThemeProvider>

            </Stack>
          </Paper>
        </Modal>
        <Modal open={booked}>
          <Paper sx={style2}>
            <Box pb={2} display='flex' justifyContent={'center'}>
              <InfoOutlinedIcon style={{ color: 'red' }} fontSize='large' />
            </Box>
            <Box pb={2}>
              <Typography fontSize='16px' align='center'>A desk has been already booked for the shift</Typography>
            </Box>
            <ConfirmButton backgroundColor='#FF6520' width='100%' title='OK' height='40px' onclick={handleBookedRedirect} />
          </Paper>
        </Modal>
      </Box>
    </Box>
  );
}