import { Box, Typography, Button } from '@mui/material'
import React from 'react'

import adrianseLogo from '../Assets/Images/adrianse-512x512.png'


const Login = (props) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh', paddingTop: '10%', paddingBottom: '10%' }}        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                style={{ maxWidth: '80vw', width: '100%' }}
            >
                <img src={adrianseLogo} alt="Adrianse logo" height="100px" width="100px" />
                <Box
                    style={{
                        marginTop: '10vh',
                        marginBottom: '1vh'
                    }}
                >
                    <Typography variant="h5" fontWeight="bold"  >
                        Welcome to the Nhance!
                    </Typography>
                </Box>
                <Box style={{
                    marginTop: '100px', 
                 
                }}>
                    <Button
                        variant="contained"
                        color="error"
                        style={{
                            backgroundColor: "#FF6520",
                            height: '6vh',
                            width: '80vw',
                            fontSize: '18px',
                            textTransform: 'none'
                        }}
                    >
                        Login
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Login