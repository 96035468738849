import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { errorModal } from '../../../Helpers/ErrorModal'
import { GET, POST, PUT } from '../../../Helpers/http'
export const UPDATE_ASSET_LIST = 'UPDATE_ASSET_LIST'
export const UPDATE_BUILDINGS = 'UPDATE_BUILDINGS'
export const UPDATE_SCANNED_ASSET = 'UPDATE_SCANNED_ASSET'
export const CREATE_TRANSIT = 'CREATE_TRANSIT'
export const SET_FEATURE = 'SET_FEATURE'
export const ASSETS_TO_TRANSFER = 'ASSETS_TO_TRANSFER'
export const REMOVE_ASSET_TO_TRANSFER = 'REMOVE_ASSET_TO_TRANSFER'
export const UPDATE_OUTGOING_ASSETS = 'UPDATE_OUTGOING_ASSETS'
export const UPDATE_SINGLE_TRANSIT = 'UPDATE_SINGLE_TRANSIT'
export const UPDATE_VERIFIED_ASSET_IN_TRANSIT =
  'UPDATE_VERIFIED_ASSET_IN_TRANSIT'
export const UPDATE_INCOMING_ASSETS = 'UPDATE_INCOMING_ASSETS'
export const CLEAR_SINGLE_ASSET = 'CLEAR_SINGLE_ASSET'
export const CLEAR_ASSET_TO_MOVE = 'CLEAR_ASSET_TO_MOVE'

const initialState = {
  assetData: [],
  isLoaded: false,
  buildings: {
    data: [],
    isLoaded: false,
  },
  scannedAsset: {
    data: {},
    isLoaded: false,
  },
  feature: '',
  assetsToMove: {
    data: [],
    isLoaded: false,
  },
  outgoingAssets: {
    data: [],
    isLoaded: false,
  },
  incomingAssets: {
    data: [],
    isLoaded: true,
  },
  singleTransit: {
    data: {},
    isLoaded: false,
  },
  tabValue: '',
}

export const startFetchAssets = createAsyncThunk(
  'am/startFetchAssets',
  async ({ userId, bid }) => {
    try {
      const response = await GET(
        `am/v1/assets/assigned-to?user=${userId}&bid=${bid}`,
      )
      if (response.status === 200) {
        return response?.data?.Assets
      } else {
        errorModal()
      }
    } catch (err) {
      errorModal()
    }
  },
)

export const asyncFetchAssetInfo = createAsyncThunk(
  'am/asyncFetchAssetInfo',
  async ({ qrcode, bid, handleNavigate }) => {
    try {
      const response = await GET(`am/v1/asset?qr-code=${qrcode}&bid=${bid}`)
      if (response.status === 200) {
        handleNavigate()
        // return response.data
      } else {
        errorModal()
      }
    } catch (error) {
      errorModal()
    }
  },
)

export const startFetchBuildings = createAsyncThunk(
  'am/startFetchBuildings',
  async () => {
    try {
      const response = await GET(`am/v1/buildings`)
      if (response.status === 200) {
        return response.data
      } else {
        errorModal()
      }
    } catch {
      errorModal()
    }
  },
)

export const startCreateTransit = createAsyncThunk(
  'am/startCreateTransit',
  async ({ formData, handleTransitCreateSuccess }) => {
    try {
      const response = await POST(`am/v1/transit`, formData)
      if (response.status === 201) {
        handleTransitCreateSuccess()
        return response.data
      } else {
        errorModal()
      }
    } catch {
      errorModal()
    }
  },
)

export const startUpdateTransit = createAsyncThunk(
  'am/startUpdateTransit',
  async ({ transitId, data, handleSuccess }) => {
    try {
      const response = await PUT(`am/v1/transit/${transitId}`, data)
      if (response.status === 200) {
        const result = response.data
        handleSuccess()
      } else {
        errorModal()
      }
    } catch {
      errorModal()
    }
  },
)

export const fetchAssetStatus = createAsyncThunk(
  'am/fetchAssetStatus',
  async ({ qrcode, toggleLoader, handleError, bid }) => {
    try {
      const response = await GET(`am/v1/asset?qr-code=${qrcode}&bid=${bid}`)
      if (response.status === 200) {
        const result = response.data
        if (!result.inTransit) {
          toggleLoader()
          return result.assetDetails
        } else {
          toggleLoader()
          handleError()
        }
      } else {
        toggleLoader()
      }
    } catch (err) {
      if (err?.response.status === 404) {
        errorModal({errorText:err?.response?.data?.error,toggleLoader})
      } else {
        errorModal({toggleLoader})
        
      }
    }
  },
)

export const startFetchOutgoingTransits = createAsyncThunk(
  'am/startFetchOutgoingTransits',
  async (bid) => {
    try {
      const response = await GET(`am/v1/transits?type=outgoing&bid=${bid}`)
      if (response.status === 200) {
        return response.data
      } else {
        errorModal()
      }
    } catch {
      errorModal()
    }
  },
)

export const startFetchTransit = createAsyncThunk(
  'am/startFetchTransit',
  async ({ transitId, handleNavigate }) => {
    try {
      const response = await GET(`am/v1/transit/${transitId}`)
      if (response.status === 200) {
        handleNavigate()
        return response.data
      } else {
        errorModal()
      }
    } catch {
      errorModal()
    }
  },
)

export const startVerifyAssetInTransit = createAsyncThunk(
  'am/startVerifyAssetInTransit',
  async ({ qrcode, transitId, toggleLoader }) => {
    try {
      const response = await GET(
        `am/v1/asset/in-transit?qr-code=${qrcode}&transitId=${transitId}`,
      )
      if (response.status === 200) {
        const result = response.data
        if (result.assetFound) {
          toggleLoader()
          return result
        } else if (!result.assetFound) {
          alert('The scanned asset in not present in the transit!')
          toggleLoader()
        }
      } else {
        errorModal()
        toggleLoader()
      }
    } catch (err){
        if(err?.response.status === 404){
            errorModal({errorText:err?.response?.data?.error,toggleLoader})
        }else{
            errorModal({toggleLoader})
        }
    }
  },
)

export const startFetchIncomingTransits = createAsyncThunk(
  'am/startFetchIncomingTransits',
  async (bid) => {
    try {
      const response = await GET(`am/v1/transits?type=incoming&bid=${bid}`)
      if (response.status === 200) {
        return response.data
      } else {
        errorModal()
      }
    } catch (error) {
      errorModal()
    }
  },
)

const assetManagementSlice = createSlice({
  name: 'assetManagementSlice',
  initialState,
  reducers: {
    clearSingleAsset: (state, action) => {
      state.singleTransit.data = {}
      state.singleTransit.isLoaded = false
    },
    clearAssetsToMove: (state, action) => {
      state.assetsToMove.data = []
      state.assetsToMove.isLoaded = false
    },
    setFeature: (state, action) => {
      state.feature = action.payload
    },
    removeAssetToTransfer: (state, action) => {
      const assetsToTransfer = state.assetsToMove.data
      const newArr = assetsToTransfer.filter(
        (ele) => ele._id !== action.payload._id,
      )
      state.assetsToMove.data = newArr
      state.assetsToMove.isLoaded = true
    },
  },
  extraReducers(builder) {
    builder.addCase(startFetchAssets.fulfilled, (state, action) => {
      if (action.payload) {
        state.assetData = action.payload
        state.isLoaded = true
      }
    })
    builder.addCase(startFetchIncomingTransits.fulfilled, (state, action) => {
      if (action.payload) {
        state.incomingAssets.data = action.payload
        state.incomingAssets.isLoaded = true
      }
    })
    builder.addCase(startFetchOutgoingTransits.fulfilled, (state, action) => {
      if (action.payload) {
        state.outgoingAssets.data = action.payload
        state.outgoingAssets.isLoaded = true
      }
    })
    builder.addCase(startFetchTransit.fulfilled, (state, action) => {
      if (action.payload) {
        state.singleTransit.data = action.payload
        state.singleTransit.isLoaded = true
      }
    })
    builder.addCase(startFetchBuildings.fulfilled, (state, action) => {
      if (action.payload) {
        state.buildings.data = action.payload
        state.buildings.isLoaded = true
      }
    })
    builder.addCase(asyncFetchAssetInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.scannedAsset.data = action.payload
        state.scannedAsset.isLoaded = true
      }
    })
    builder.addCase(startCreateTransit.fulfilled, (state, action) => {
      if (action.payload) {
        state.scannedAsset.data = {
          ...state.scannedAsset?.data,
          transitRef: action.payload,
        }
      }
    })
    builder.addCase(startVerifyAssetInTransit.fulfilled, (state, action) => {
      if (action.payload) {
        const transit = state.singleTransit?.data
        const newArr = transit?.assets?.map((ele) => {
          if (ele._id === action.payload?._id) {
            return { ...ele, assetVerified: true }
          } else {
            return { ...ele }
          }
        })
        state.singleTransit = {
          ...state?.singleTransit,
          data: { ...state?.singleTransit?.data, assets: newArr },
        }
      }
    })
    builder.addCase(fetchAssetStatus.fulfilled, (state, action) => {
      if (action.payload) {
        const assetsToMove = state.assetsToMove.data
        const arr = assetsToMove.filter((ele) => {
          return ele._id === action.payload._id
        })
        if (arr.length === 0) {
          state.assetsToMove.data = [...state.assetsToMove.data, action.payload]
          state.assetsToMove.isLoaded = true
        } else {
          return state
        }
      }
    })
  },
})

export const {
  clearSingleAsset,
  clearAssetsToMove,
  setFeature,
  removeAssetToTransfer,
} = assetManagementSlice.actions

export default assetManagementSlice.reducer
