import {React , useEffect} from 'react'
import { Box, Container, Stack, Typography, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {useHistory } from 'react-router-dom'
import correct from "../../../Assets/Images/correct.svg"

function Confirmed(props) {
    const history = useHistory()

    useEffect(() => {
        let nextPageTimer = setTimeout(() => {
            history.push('/deskbooking/upcoming')
        },1000);
        return () => {
            clearTimeout(nextPageTimer)
        }
    },[])

    return (
        <>
            <Container>
                <Stack spacing={3} height={'100vh'} justifyContent={'center'} alignItems={'center'}>
                    <Box>
                        <IconButton
                            sx={{ width: 700, height: 700, backgroundColor: '#eeeeee', }}
                        >
                            <Grid >
                                <img src={correct} width='100px'/>
                                <Typography variant='h5' align={'center'} fontWeight={'bold'} color="black" p={4}>Thank you</Typography>
                                <Typography variant='h6'>Seat Booking Confirmed!</Typography>
                            </Grid>
                        </IconButton>
                    </Box>
                </Stack>
            </Container>
        </>
    )
}
export default Confirmed