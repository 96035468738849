import {
  Typography,
  Grid,
  IconButton,
  Box,
  Drawer,
  Stack,
  Divider,
  Rating,
  Modal,
  Button,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import backButton from '../../Assets/Images/left-arrow.png'
import Star from '../../Assets/FeedBack/Star.png'
import { useHistory } from 'react-router-dom'
import { Global } from '@emotion/react'
import StarIcon from '@mui/icons-material/Star'
import FeedBackDescription from './FeedBackDescription'
import { ModalStyle } from '../../UI/FeedBack/NewFeedBackStyles'
import { useDispatch, useSelector } from 'react-redux'
import { startCreateRating } from '../../Redux/ReduxSlice/Feedback/RatingsSlice'
import { setCompFb } from '../../Redux/ReduxSlice/Feedback/CompSliceFb'
import brokenImg from '../../Assets/FeedBack/brokenImg.png'
import locationFeedBack from '../../Assets/FeedBack/locationFeedBack.png'
import correct from '../../Assets/Images/correct.svg'
import FeedbackProgressBar from '../../UI/FeedBack/FeedbackProgressBar'

const NewFeedBack = (props) => {
    const imageLink=useSelector(state=>state?.config?.imageLink)
  const history = useHistory()
  const subArea = useSelector((state) => state.subArea?.subArea)
  const subAreaRating= subArea?.userRating?.rating ? subArea?.userRating?.rating : 0
  const [ratings, setRatings] = useState(0)
  const [description, setDescription] = useState('')
  const [open, setOpen] = useState(false)

  const subAreaIsLoaded = useSelector((state) => state?.subArea?.isLoaded)
  const dispatch = useDispatch()

  const handleBackClick = () => {
    dispatch(setCompFb('Feed Back Home'))
  }

  const handleChange = (e) => {
    if (e?.target?.name === 'description') setDescription(e.target.value)
  }

const ratingDistribution=subAreaIsLoaded?subArea?.overAllRating?.ratingDistribution:{}

  const ratingValues=subAreaIsLoaded?Object.values(ratingDistribution):[]
    const maxRatingCount=subAreaIsLoaded?Math.max(...ratingValues):0
    const progressArr=[
        { label: '5', color: '#a0c15a'},
        { label: '4',color: '#add633'},
        { label: '3',color: '#ffd934'},
        { label: '2',color: '#ffb234'},
        { label: '1',color: '#ff8c5a' },
    ]

    const progressBars = progressArr.map((ele,i)=>{
        return {...ele,progress:((ratingDistribution[ele.label]/maxRatingCount)*100),count:ratingDistribution[ele.label]}
    })

  const handleModalClick = () => {
    const data = {
      rating: ratings,
      ratingDescription:description,
      areaId:subArea?.areaDetails?.area?.id,
      subZoneId:subArea?.areaDetails?.subZone?.id,
      zoneId: subArea?.areaDetails?.zone?.id,
      floorId:subArea?.areaDetails?.floor?.id,
      buildingId:subArea?.areaDetails?.building?.id,
      locationId:subArea?.areaDetails?.location?.id
    }
    dispatch(startCreateRating({feedback:data, handleModal}))
  }

  const handleModal = () => {
    setOpen(true)
  }
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setOpen(false)
        dispatch(setCompFb('Feed Back Home'))
      }, 3000)
    }
  }, [open])

  useEffect(() => {
    if (ratings?.length === 0) {
      history.push('/RatingFeedBack')
    }
    return ()=>{
        setRatings(0)
        setDescription('')
    }
  }, [])

  return (
    <Box>
      <Grid
        container
        spacing={0}
        display="flex"
        alignItems={'center'}
        height={50}
        backgroundColor="#F6F6F6"
        mb={2}
      >
        <Grid item xs={1.2} p={2}>
          <IconButton size="small" float="left" onClick={handleBackClick}>
            <img src={backButton} width='24px' style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
         
                  width: '30px',
                  borderRadius: '50%',
                  padding: '0.5em '
                }}/>
          </IconButton>
        </Grid>
        <Grid item xs={10.8} display="flex" pl={2}>
          <Typography fontSize={20} align="left">
            {'FeedBack'}
          </Typography>
        </Grid>
      </Grid>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(65%)`,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
          },
        }}
      />
      <Box
        display={'flex'}
        justifyContent="center"
        alignItems={'center'}
        height={'20vh'}
      >
        {subArea?.area?.imageLinks.length>0?
        <img src={`${imageLink}${subArea?.area?.imageLinks[0]}`} width='500px'/>: <img src={brokenImg} width="36px" />}
       
      </Box>
      <Drawer
        anchor="bottom"
        open={true}
        variant="permanent"
        ModalProps={{
          keepMounted: false,
        }}
      >
        <Box>
          <Box p={2}>
            <Grid container>
              <Grid item xs={12}>
                 <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: '400',
                  color: '#212121',
                }}
              >
                {subArea?.areaDetails?.area?.name}
              </Typography>

              </Grid>
              <Grid item xs={0.5}
                display={'flex'}
                justifyContent="center"
                alignItems="center"
              >
                 <img src={locationFeedBack} height="16px" />
              </Grid>
              <Grid item xs={11.5}
              >
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: '400',
                    color: '#212121',
                      paddingLeft: '2px',
                  }}
                >
                  {subArea?.areaDetails?.floor?.name} | {subArea?.areaDetails?.building?.name}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box pb={3} pt={0} px={2} >
            <Grid container>
              <Grid
                item
                xs={2}
                display="flex"
                alignItems={'center'}
                justifyContent="flex-start"
              >
                <img src={Star} height="50px" />
              </Grid>
              <Grid item xs={subArea.overAllRating?.averageRating?4:6} display={'flex'} alignItems={'center'}>
                <Stack>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: '700',
                      color: '#212121',
                    }}
                  >
                    {subArea.overAllRating?.averageRating
                      ? subArea.overAllRating?.averageRating
                      : 'No Ratings given yet'}
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#212121',
                    }}
                  >
                    Overall Rating
                  </Typography>
                </Stack>
              </Grid>
             {subArea.overAllRating?.averageRating&&<Grid item xs={subArea?.overAllRating?.averageRating?6:4}>
                 
                  <FeedbackProgressBar progressBars={progressBars}/>
              </Grid>}
            </Grid>
          </Box>
          <Divider />

          <Box p={3}>
            <Stack>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#000000',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {subArea?.reviewedToday?
                  'You rated'
                  :'Please rate your feedback for this area'}
              </Typography>
              <Box display="flex" justifyContent="center" pt={4}>
                <Rating
                  name="size-large"
                  sx={{ fontSize: '50px'  }}
                  value={subArea?.reviewedToday?subAreaRating:ratings}
                  onChange={(event, newValue) => {
                    setRatings(newValue)
                  }}
                  precision="0.5"
                //   disabled={subArea?.reviewedToday}
                  emptyIcon={
                    <StarIcon style={{ opacity: 1 }} fontSize="inherit" />
                  }
                />
              </Box>
            </Stack>
            {subArea?.reviewedToday && (
              <Typography
                paddingTop={'30px'}
                display={'flex'}
                justifyContent="center"
                fontSize={'16px'}
                fontWeight="400"
                color={'#4B4B4B'}
              >
                Come back tomorrow to rate again!
              </Typography>
            )}
          </Box>

          {!subArea?.reviewedToday && (
            <Box pt={3} p={2}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '400',
                  color: '#201F1E',
                  paddingBottom: '5px',
                }}
              >
                Add Comments
              </Typography>
              <FeedBackDescription
                value={description}
                name="description"
                onChange={(e) => handleChange(e)}
              />
            </Box>
          )}
        </Box>
        {!subArea?.reviewedToday && (
          <Box p={2}>
            <Button
              sx={{
                width: '100%',
                height: '6vh',
                borderRadius: '10px',
                backgroundColor: '#FF6520',
              }}
              variant="contained"
              color="warning"
              onClick={handleModalClick}
              disabled={!ratings || description.trim().length <= 0}
            >
              Submit
            </Button>
          </Box>
        )}
      </Drawer>
      <Modal open={open}>
        <Box sx={ModalStyle}>
          <Box
            p={3}
            pt={1}
            display={'flex'}
            justifyContent="center"
            alignItems={'center'}
          >
            <Stack spacing={1}>
              <Stack>
                <Box
                  display={'flex'}
                  justifyContent="center"
                  alignItems={'center'}
                >
                  <img src={correct} height="80px"/>
                </Box>
              </Stack>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '20px',
                    fontWeight: '400',
                    color: '#212121',
                  }}
                >
                  Thank you!
                </Typography>
              </Stack>
              <Stack
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '200',
                    color: '#4B4B4B',
                  }}
                >
                  Your feedback was successfully Submitted
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
export default NewFeedBack