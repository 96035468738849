import React from 'react'
import { Button, Modal, Typography, Box } from '@mui/material'

const CommonModal = ({ open, onClose, children }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
	  width: "95%",
	height:"20%",
    bgcolor: 'background.paper',
    // border: '1px solid #000',/
    boxShadow: 24,
	  p: 1,
	borderRadius:'20px'
  }

  return (
    <Box>
      <Modal
        open={open}
        disableAutoFocus={true}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {children}
          </Typography>
        </Box>
      </Modal>
    </Box>
  )
}
export default CommonModal
