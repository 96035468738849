import moment from "moment"
import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    selectedTime: { firstHalf: false, secondHalf: false },
    selectedDate: moment().format('YYYY-MM-DD')
}

export const bookingTimeSlice = createSlice({
    name: 'bookingTimeSlice',
    initialState,
    reducers: {
        setBookingDate: (state, action) => {
            state.selectedDate = action?.payload
        },
        setBookingTime: (state, action) => {
            state.selectedTime = {
                firstHalf: action?.payload?.firstHalf, 
                secondHalf: action?.payload?.secondHalf
            }
        },
        resetBookingTime: (state, action) => {
            state.selectedDate = moment().format("YYYY-MM-DD")
        }

    }
})

export const { setBookingDate, setBookingTime, resetBookingTime } = bookingTimeSlice.actions

export default bookingTimeSlice.reducer



