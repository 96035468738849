import axios from 'axios';

const setHeaders = () => {
    let headers = {};
    let token = localStorage.getItem('token');
    headers['Content-Type'] = 'application/json';
    if (token) {
        headers['Authorization'] = token;
    }
    return headers;
};
let headers = setHeaders();

export const POST = (endpoint, body) => {
    const headers=setHeaders()
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + endpoint, body, { headers })
            resolve(response)
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

export const PUT = (endpoint, body) => {
    const headers=setHeaders()

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(process.env.REACT_APP_BASE_URL + endpoint, body, { headers })
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

export const GET = (endpoint,decodedToken) => {
    const setGetHeaders = () => {
        let headers = {};
        let token = localStorage.getItem('token');
        headers['Content-Type'] = 'application/json';
        if (token) {
            headers['Authorization'] = token;
        }
        else {
            headers['Authorization']=decodedToken
        }
        return headers;
    };
    let headers = setGetHeaders();
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + endpoint, { headers })
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

export const VMGET = (endpoint,decodedToken) => {
    const setGetHeaders = () => {
        let headers = {};
        let token = localStorage.getItem('token');
        headers['Content-Type'] = 'application/json';
        if (token) {
            headers['Authorization'] = token;
        }
        else {
            headers['Authorization']=decodedToken
        }
        return headers;
    };
    let headers = setGetHeaders();
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.get(process.env.REACT_APP_VM + endpoint, { headers })
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
}


    export const VMPOST = (endpoint, body) => {
    const headers=setHeaders()
    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.post(process.env.REACT_APP_VM + endpoint, body, { headers })
            resolve(response)
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
    };
export const VMPUT = (endpoint, body) => {
    const headers=setHeaders()

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.put(process.env.REACT_APP_VM + endpoint, body, { headers })
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

export const DELETE = (endpoint, data) => {
    const headers=setHeaders()

    return new Promise(async function (resolve, reject) {
        try {
            const response = await axios.delete(process.env.REACT_APP_BASE_URL + endpoint, { headers})
            resolve(response);
        } catch (e) {
            console.log(e);
            reject(e);
        }
    });
};

