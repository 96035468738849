import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Stack,
  Divider,
  Backdrop,
  CircularProgress,
  Modal,
  TextField,
  Paper,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  resetVisitorDetails,
  setSelectedAppointments,
  startGetAllAppointments,
  updateAppointment,
} from "../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice";
import CommonTypography from "../../UI/MeetingRooms/Widgets/VisitorMAnagement/CommonTypography";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useHistory, useLocation } from "react-router-dom";
import { startInvitationResponse } from "../../Redux/ReduxSlice/MeetingRooms/meetingsSlice";
import CardTitle from "../../UI/MeetingRooms/Widgets/MeetingRoom/CardTitle";
import CardComp from "../../UI/MeetingRooms/Card/CardComp";
import ConfirmButton from "../../UI/DeskBooking/Button/ConfirmButton";
import SuccessModal from "../../UI/AssetManager/SuccessModal";
import CommonModal from "../../UI/ManageVistor/CommonModal/CommonModal";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";

const Img = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const AppointmentDetails = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [invitationResponse, setInvitationResponse] = useState("");
  const [invitationNote, setInvitationNote] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const imageLink = useSelector((state) => state?.config?.imageLink);
  const [nonNotifiedVisitors,setNonNotifiedVisitors]=useState([])  
  const visitorData = [
    { label: "Name", value: selectedVisitor?.visitor?.name },
    { label: "Email", value: selectedVisitor?.visitor?.email },
    { label: "Company Name", value: selectedVisitor?.visitor?.companyName },
    { label: "Phone no", value: selectedVisitor?.visitor?.mobile },
  ];

  const selectedAppointments = useSelector(
    (state) => state.visitData?.selectedAppointments?.data
  );
  const isLoaded = useSelector(
    (state) => state.visitData?.selectedAppointments?.isLoaded
  );

  const isInitiatedByVisitor = selectedAppointments?.initiatedBy === "visitor";
  const handleModal = () => {
    setModalOpen((prevState) => !prevState);
  };

  const handleSuccess = (status) => {
    setSuccessMessage(status);
    setSuccessModal(true);
  };

  const handleOpen = (visitor) => {
    setSelectedVisitor(visitor);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedVisitor(null);
    setOpen(false);
  };

  const handleInviteClick = () => {
    handleModal();
    let data = { status: invitationResponse };
    if (invitationResponse === "accepted") {
      data.notes = invitationNote;
    } else {
      data.cancellationReason = invitationNote;
    }
    dispatch(
      updateAppointment({
        data,
        id: selectedAppointments?._id,
        handleModal,
        handleNotesModal: toggleNotesModal,
        handleSuccess,
      })
    );
  };

  const handleInvitationResponse = (status) => {
    setInvitationResponse(status);
    setNotesModal(true);
  };
  const toggleNotesModal = () => {
    setNotesModal((prev) => !prev);
    setInvitationResponse("");
    setInvitationNote("");
  };

  useEffect((userId, _id) => {
    dispatch(startGetAllAppointments({ userId }));
    dispatch(setSelectedAppointments(_id));
    return () => {
      dispatch(resetVisitorDetails());
    };
  }, []);

  useEffect(()=>{
    const filteredNames =selectedAppointments?.visitors?.filter(item =>!(item?.isNotifiedFor))
    .map(item => item?.visitor?.name);

    if(filteredNames?.length>0){setNonNotifiedVisitors(filteredNames)}
  },[selectedAppointments])
  useEffect(() => {
    if (successModal) {
      setTimeout(() => {
        setSuccessModal(false);
      }, 4000);
    }
  }, [successModal]);
  return (
    <Box>
      <Box p={2} height="100vh">
        <Box>
          <Card
            sx={{
              marginBottom: "20px",
              borderRadius: "15px",
              position: "relative",
            }}
          >
            {isLoaded ? (
              <Card
                sx={{
                  borderRadius: "5%",
                  width: "100%",
                  height: "100%",
                  padding: "5%",
                }}
              >
                <CommonTypography
                  label="Purpose"
                  value={selectedAppointments?.purpose}
                />
                {/* <CommonTypography
                  label="Visitor Name"
                  value={selectedAppointments?.visitors?.visitor?.name}
                /> */}
                {/* <CommonTypography
                  label="Visitors"
                  value={selectedAppointments?.visitors
                    ?.map((visitor) => visitor?.visitor?.name)
                    .join(", ")}
                /> */}
                <Grid container spacing={1} pb={2}>
                  <Grid item xs={4}>
                    <Typography variant="h6">Visitors:</Typography>
                  </Grid>
                  {/* <Grid item xs={8}
                    // onClick={handleOpen}
                    sx={{ textDecorationLine: "underline", textDecorationStyle: "solid" }}>
                    <Typography variant="h6"
                        onClick={() => handleOpen(visitor)}
                      key={visitor._id}>{selectedAppointments?.visitors
                    ?.map((visitor) => visitor?.visitor?.name)
                    .join(", ")}</Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={8}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {selectedAppointments?.visitors?.map(
                      (visitor, index, array) => (
                        <React.Fragment key={visitor._id}>
                          <Typography
                            variant="h6"
                            onClick={() => handleOpen(visitor)}
                            sx={{
                              textDecorationLine: "underline",
                              textDecorationStyle: "solid",
                              cursor: "pointer",
                            }}
                          >
                            {visitor?.visitor?.name}
                            {` `}
                          </Typography>
                          {index !== array.length - 1 && <span> ,{` `} </span>}
                        </React.Fragment>
                      )
                    )}
                  </Grid>
                </Grid>

                <CommonTypography
                  label="Location"
                  value={selectedAppointments?.location?.name}
                />
                <CommonTypography
                  label="Building"
                  value={selectedAppointments?.building?.name}
                />
                <CommonTypography
                  label="Start Time"
                  value={`${selectedAppointments?.startTime?moment(
                    new Date(selectedAppointments?.startTime)
                  ).format("DD MMM YYYY hh:mm A"):''}`}
                />
                <CommonTypography
                  label="Status"
                  value={selectedAppointments?.status}
                />
                {(selectedAppointments?.status === "accepted" &&(nonNotifiedVisitors?.length>0))&& (
                  <>
                    {/* <Typography>{`Failed to send notification to visitors ${nonNotifiedVisitors?.join(', ')}`}. </Typography> */}
                    <Typography>
      Failed to send notification to visitor/s <strong style={{fontWeight: 'bold'}}>bindu</strong>, <strong style={{fontWeight: 'bold'}}>Rahul</strong>.
    </Typography>
                  </>
                )}

                {isInitiatedByVisitor && (
                  <>
                    {selectedAppointments?.status === 'awaiting' ? (
                      <>
                    <Divider style={{ marginBottom: 10 }} />
                    <Stack
                      direction="row"
                      spacing={5}
                      display="flex"
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <Box
                        backgroundColor={
                          selectedAppointments?.status === "accepted"
                            ? "#E8EDFF"
                            : "none"
                        }
                        borderRadius="5px"
                      >
                        <Button
                          onClick={() => {
                            handleInvitationResponse("accepted");
                          }}
                        >
                          <DoneOutlinedIcon />
                        </Button>
                      </Box>
                      <Box
                        backgroundColor={
                          selectedAppointments?.status === "cancelled"
                            ? "#E8EDFF"
                            : "none"
                        }
                        borderRadius="5px"
                      >
                        <Button
                          onClick={() => {
                            handleInvitationResponse("cancelled");
                          }}
                        >
                          <CloseOutlinedIcon />
                        </Button>
                      </Box>
                        </Stack>
                        </>
                    ) : null}
                  </>
                )}
              </Card>
            ) : (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </Card>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={modalOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <Modal
        open={notesModal}
        onClose={toggleNotesModal}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <Box width={"100%"} margin={"20px"}>
          <CardComp height={20} padding={4}>
            <Box
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              paddingX={"6px"}
            >
              <Box width={'100%'}>
                <Typography textAlign={'center'}>
                  {invitationResponse === 'accepted'
                    ? 'Add a note that can be seen by the visitor (Optional)'
                    : 'Add your cancellation note (Optional)'}
                </Typography>
                <TextField
                  style={{ marginTop: "10px" }}
                  id="outlined-controlled"
                  fullWidth
                  placeholder="Add note"
                  value={invitationNote}
                  onChange={(event) => {
                    setInvitationNote(event.target.value);
                  }}
                />
                <Box sx={{ marginTop: "10px" }}>
                  <ConfirmButton
                    title="Confirm"
                    backgroundColor="#FF6520"
                    height={"40px"}
                    width={"100%"}
                    onclick={handleInviteClick}
                  />
                </Box>
              </Box>
            </Box>
          </CardComp>
        </Box>
      </Modal>
      <SuccessModal
        open={successModal}
        message={`You have successfully ${
          successMessage === "accepted" ? "accepted" : "declined"
        } the invitation request`}
      />
      {selectedVisitor && (
        <CommonModal open={open} onClose={handleClose}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <CardComp height={15} border={"16"}>
                <Box
                  style={{
                    backgroundImage: `url(${imageLink}${selectedVisitor?.visitor?.picture})`,
                    backgroundSize: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                ></Box>
              </CardComp>
            </Grid>
            <Grid item xs={8} container>
              {visitorData.map((item) => (
                <Grid container spacing={1} key={item.label}>
                  <Grid item xs={4}>
                    <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                      {item.label}:
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography variant="body1">{item.value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CommonModal>
      )}
    </Box>
  );
};
export default AppointmentDetails;
