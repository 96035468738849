import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
  Modal,
} from '@mui/material'
import React, { useState } from 'react'
import isEmail from 'validator/lib/isEmail'
import {
  locationTitleContainer,
  titleText,
} from '../../UI/ManageVistor/Styles/VisitLocationStyle'
import AddGuestInput from '../MeetingRooms/AddGuestInput/AddGuestInput'
import ClearIcon from '@mui/icons-material/Clear'
import GuestDescription from '../MeetingRooms/GuestDescription/GuestDescription'
import BottomButton from '../../UI/ManageVistor/Button/BottomButton'
import { useDispatch, useSelector } from 'react-redux'
import {
  createVisitor,
  getVisitorBySearchTerm,
  resetVisitorDetails,
  setSelectedDescription,
  setSelectedPurpose,
  setSelectedVisitors,
  setVisitorsInfo,
} from '../../Redux/ReduxSlice/ManageVisitor/VisitorDetailsSlice'
import { setVisitorComp } from '../../Redux/ReduxSlice/ManageVisitor/ManageVisitorComp'
import { useEffect } from 'react'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "90%",
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 4,
};

const VisitDetails = () => {
  const [visitors, setVisitors] = useState([])
  const dispatch = useDispatch()
  const visitPurposes = [
    'Interview',
    'Official Business',
    'Meeting',
    'Business Pitch',
    'Delivery',
    'Other',
  ]
  // const [selectedPurpose, setSelectedPurpose] = useState('')
  const [isError, setIsError] = useState(false)
  const [addVisitor, setAddVisitor] = useState(false)
  const [open, setOpen] = useState(false)
  const [showFields, setShowFields] = useState(false)
  // console.log({ addVisitor })
  // const [selectedVisitors, setSelectedVisitors] = useState([])
  const selectedVisitors = useSelector(
    (state) => state?.visitData?.selectedVisitors,
  )
  const selectedPurpose = useSelector(
    (state) => state?.visitData?.selectedPurpose,
  )
  const selectedDescription = useSelector(
    (state) => state?.visitData?.selectedDescription,
  )

  const [visitorInput, setVisitorInput] = useState('')
  const [guestName, setGuestName] = useState('')
  const [guestEmail, setGuestEmail] = useState('')
  // const [description, setDescription] = useState('')
  const { visitorSearch: searchVisitors } = useSelector(
    (state) => state.visitData,
  )
  const handleDelete = (email) => {
    setVisitors(visitors?.filter((e) => e !== email))
  }

  const handleDescriptionChange = (e) => {
    dispatch(setSelectedDescription(e?.target?.value))
  }
  // const handleChange = (event) => {
  //   setIsError(false)
  //   const value = event.target.value
  //   setGuestEmail(event.target.value)
  //   if (value[value?.length - 1] === ',') {
  //     let finalVal
  //     finalVal = value.slice(0, -1)
  //     if (
  //       isEmail(finalVal.trim()) &&
  //       !visitors.includes(finalVal.toLowerCase().trim())
  //     ) {
  //       setVisitors([...visitors, finalVal.toLowerCase().trim()])
  //       setGuestEmail('')
  //     } else {
  //       setIsError(true)
  //     }
  //   }
  // }

  const handlePurposeClick = (val) => {
    dispatch(setSelectedPurpose(val))
  }

  const handleNextClick = () => {
    const data = {
      visitors: selectedVisitors,
      purpose:
        selectedPurpose === 'Other' ? selectedDescription : selectedPurpose,
    }
    dispatch(setVisitorsInfo(data))
    dispatch(setVisitorComp('confirmVisitDetails'))
  }

  const handleInputChange = (e) => {
    // console.log({e})
    const text = e?.target?.value
    setVisitorInput(text)
    // if (text?.length > 1) {
    //   setOpen(true)
    //   dispatch(getVisitorBySearchTerm({ searchTerm: text }))
    // }
    // else {
    //   setOpen(false)
    // }
  }

  const handleChangName = (e) => {
    setGuestName(e.target.value)
  }

  const handleChangeEmail = (e) => {
    setGuestEmail(e.target.value)
  }

  const handleSelectVisitors = (visitors) => {
    setVisitorInput('')
    dispatch(setSelectedVisitors(visitors))
  }
  //  const handleSelectVisitors = (visitors) => {
  //   setVisitorInput('');
  //   dispatch(setSelectedVisitors(visitors));
  // };

  const handleAddText = () => {
    const data = {
      email: guestEmail,
      name: guestName,
    }
    // console.log('body', data)
    dispatch(
      createVisitor({ data, handleAddNewVisitor, toggleToggleAddVisitor }),
    )
    setGuestName('')
    setGuestEmail('')
    setShowFields(false)
  }

  const handleAddVisitor = () => {
    setOpen(false)
    setAddVisitor(true)
    setShowFields((prev) => !prev)
  }

  const toggleToggleAddVisitor = () => {
    setAddVisitor(false)
  }
  const handleAddNewVisitor = (newVisitor) => {
    setSelectedVisitors([...selectedVisitors, newVisitor])
    setVisitorInput('')
  }
  const handleClose = () => {
    setShowFields(false)
  }

  useEffect(() => {
    if (visitorInput?.length < 3) setOpen(false)
    const fetchNames = setTimeout(() => {
      if (visitorInput?.trim() !== '' && visitorInput?.length > 2) {
        dispatch(getVisitorBySearchTerm({ searchTerm: visitorInput }))
        setOpen(true)
      } else {
        setOpen(false)
      }
    }, 1000)

    return () => clearTimeout(fetchNames)
  }, [visitorInput])

  return (
    <Box style={locationTitleContainer}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography style={titleText}>Who are you meeting?</Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            open={open}
            id="search"
            onBlur={() => setOpen(false)}
            placeholder="Search by name"
            options={searchVisitors}
            getOptionLabel={(option) => `${option.name} - ${option.email}`}
            // renderOption={(option) => <p>{ option}</p>}
            inputValue={visitorInput}
            value={selectedVisitors}
            sx={{ backgroundColor: '#ffffff' }}
            // defaultValue={[top100Films[1]]}
            // filterSelectedOptions
            filterOptions={(options, state) => {
              console.log({ options, state })
              if (state.inputValue?.length > 1) {
                return options.filter((item) =>
                  String(item?.name)
                    .toLowerCase()
                    .includes(state.inputValue?.toLowerCase()),
                )
              }
              return options
            }}
            onChange={(e, val) => handleSelectVisitors(val)}
            onInputChange={handleInputChange}
            noOptionsText={
              <Button onClick={handleAddVisitor}>
                No visitors! Click here to add
              </Button>
            }
            renderInput={(params) => (
              <TextField
                // ref={textFieldRef}
                placeholder="Search by name"
                {...params}
                // label="filterSelectedOptions"
              />
            )}
          />

          {addVisitor && (
            <Box sx={{ width: '100%' }}>
              {addVisitor && (
                <>
                  <Modal
                    open={showFields}
                    onClose={handleClose}
                  >
                    <Box sx={style}>
                    <Grid
                      container
                      spacing={3}
                      // sx={{
                      //   display: 'flex',
                      //   justifyContent: 'center',
                      //   alignItems: 'center',
                      //   mt: '10%',
                      // }}
                    >
                      <Grid item xs={12}>
                        <AddGuestInput
                          placeholder="Enter name"
                          height="6vh"
                          name="name"
                          value={guestName}
                          onChange={handleChangName}
                          isError={isError}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <AddGuestInput
                          placeholder="Enter email ID"
                          height="6vh"
                          name="email"
                          onChange={handleChangeEmail}
                          value={guestEmail}
                          isError={isError}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <Button
                          variant="contained"
                          disabled={
                            !guestName ||
                            guestName === '' ||
                            !guestEmail ||
                            guestEmail === ''
                          }
                            sx={{
                            height: '150%',
                            width: '60%',
                            color: '#ffffff',
                            borderRadius: '10px',
                            backgroundColor: '#ee6352',
                            '&:hover': {
                              backgroundColor: '#ff0000',
                            },
                          }}
                          onClick={handleAddText}
                        >
                          Add
                        </Button>
                      </Grid>
                      </Grid>
                      </Box>
                  </Modal>
                </>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={1} mt={'8px'}>
        <Grid item xs={12}>
          <Typography style={titleText}>
            What is the purpose of your invitation?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={'row'}
            spacing={1}
            maxWidth="100vw"
            flexWrap={'wrap'}
          >
            {visitPurposes?.slice(0, 3).map((purpose) => {
              return (
                <Box key={purpose}>
                  <Chip
                    label={`${purpose}`}
                    variant="outlined"
                    onClick={() => handlePurposeClick(purpose)}
                    size="medium"
                    sx={{ height: '40px', border: 'solid 1px #AAAAAA' }}
                    //  border: 'solid 1px #AAAAAA',
                    style={{
                      backgroundColor:
                        selectedPurpose === purpose ? '#FF6520' : '#FFFFFF',
                      color:
                        selectedPurpose === purpose ? '#FFFFFF' : '#000000',
                      fontSize: '16px',
                    }}
                  />
                </Box>
              )
            })}
          </Stack>
          <Stack
            direction={'row'}
            spacing={1}
            mt={1}
            maxWidth="100vw"
            flexWrap={'wrap'}
          >
            {visitPurposes?.slice(3).map((purpose) => {
              return (
                <Box key={purpose}>
                  <Chip
                    label={`${purpose}`}
                    variant="outlined"
                    onClick={() => handlePurposeClick(purpose)}
                    size="medium"
                    sx={{ height: '40px', border: 'solid 1px #AAAAAA' }}
                    style={{
                      backgroundColor:
                        selectedPurpose === purpose ? '#FF6520' : '#FFFFFF',
                      color:
                        selectedPurpose === purpose ? '#FFFFFF' : '#000000',
                      fontSize: '16px',
                    }}
                  />
                </Box>
              )
            })}
          </Stack>
          {selectedPurpose === 'Other' && (
            <Box style={{ marginTop: 10 }}>
              <GuestDescription
                value={selectedDescription}
                placeholder="Enter the purpose"
                name="description"
                width="100%"
                onChange={handleDescriptionChange}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <BottomButton
        onclick={handleNextClick}
        disabled={
          // visitors?.length === 0 ||
          selectedPurpose === 'Other'
            ? selectedDescription?.length === 0
            : selectedPurpose === '' ||
              (selectedVisitors
                ? selectedVisitors.length === 0
                : !guestName ||
                  guestName === '' ||
                  !guestEmail ||
                  guestEmail === '')
        }
      />
    </Box>
  )
}

export default VisitDetails
