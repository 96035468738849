import { createSlice } from '@reduxjs/toolkit'

// export const ASSET_SET_COMP='ASSET_SET_COMP'
const initialState={
    title:'AssetHome',
}
// export const assetSetComp=(data)=>{
//     return {
//         type:ASSET_SET_COMP,
//         payload:data
//     }
// }

const assetCompSlice=createSlice({
    name:'assetCompSlice',
    initialState,
    reducers:{
        assetSetComp:(state,action)=>{
            state.title=action.payload
        }
    }
})

export const {assetSetComp}=assetCompSlice.actions

export default assetCompSlice.reducer