import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GET } from '../../../Helpers/http'

const initialState = {
    locations: { locations: [], locationsIsLoaded: false },
    buildings: { buildings: [], buildingsIsLoaded: false },
    selectedLocation: '',
    selectedBuilding: {},
}

export const fetchLocations = createAsyncThunk(
    'bookings/fetchLocations',
    async () => {
        try {
            const response = await GET('db/v1/locations')
            if (response.status === 200) {
                const result = response?.data
                return result
            }else{
                console.log({response})
            }
        } catch (err) {
            console.log(err)
        }
    },
)

export const fetchBuildings = createAsyncThunk(
    'bookings/fetchBuildings',
    async (id) => {
        try {
            const response = await GET(`db/v1/buildings?location-id=${id}`)
            if (response.status === 200) {
                const result = response?.data
                return result
            }else{
                console.log({response})
            }
        } catch (err) {
            console.log(err)
        }
    },
)

export const bookingAreaSlice = createSlice({
    name: 'bookingAreaSlice',
    initialState,
    reducers: {
        setSelectedLocation: (state, action) => {
            state.selectedLocation = action?.payload
        },
        setSelectedBuilding: (state, action) => {
            state.selectedBuilding = action?.payload
        },

        // clearData:(state,action)=>{
        //     state.locations={locations:[],locationIsLoaded:false},
        //     state.buildings={buildings:[],buildingsIsLoaded:false},
        //     state.selectedLocation='',
        //     state.selectedBuilding={},
        //     state.selectedTime={selectedShift:'',selectedDate:'',firstHalf:false,secondHalf:false}
        // },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLocations.pending, (state, action) => {
                state.locations.locationsIsLoaded = false
            })
            .addCase(fetchBuildings.pending, (state, action) => {
                state.buildings.buildingsIsLoaded = false
            })
            .addCase(fetchLocations.fulfilled, (state, action) => {
                if(action.payload){
                state.locations.locations = action?.payload
                state.locations.locationsIsLoaded = true
                }
            })
            .addCase(fetchBuildings.fulfilled, (state, action) => {
                if(action.payload){
                state.buildings.buildings = action?.payload
                state.buildings.buildingsIsLoaded = true
                }
            })
    },
})

export const {
    setSelectedLocation,
    setSelectedBuilding,

} = bookingAreaSlice.actions

export default bookingAreaSlice.reducer