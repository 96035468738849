import React, { useState, useEffect } from 'react';
import { Box, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useHistory, useLocation } from 'react-router-dom';

const TabsContainer = (props) => {
  const { tabList, tabsPanel } = props
  const [newTabs, setNewTabs] = useState(tabList)
  const [value, setValue] = React.useState('1');
  const history = useHistory()
  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === '1') history.push("/meetingrooms/booking")
    else if (newValue === '2') history.push("/meetingrooms/meetings")
    else if(value==='3') history.push("/meetingrooms/status")
  };
  const location = useLocation()
  useEffect(() => {
    if (location.pathname === "/meetingrooms/meetings") {
      handleChange("event", '2')
    }
    if (location.pathname === "/meetingrooms/booking") {
      handleChange("event", '1')
    }
    if (location.pathname === "/meetingrooms/update") {
      setNewTabs(tabList.slice(0, 1))
    }
  }, [location.pathname])

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} textColor='black' aria-label="lab API tabs example" TabIndicatorProps={{ style: { backgroundColor: '#FF6520', fontWeight: 'bold' } }}>
            {
              newTabs.map((title, index) => {
                return (<Tab key={index} label={title} value={String(index + 1)} />
                )
              })
            }
          </TabList>
        </Box>
        <TabPanel value="1">{tabsPanel[0]()}</TabPanel>
        <TabPanel value="2">{tabsPanel[1]()}</TabPanel>
        {tabsPanel[2]&&<TabPanel value="3">{tabsPanel[2]()}</TabPanel>}

        {/* {tabsPanel()} */}
      </TabContext>
    </Box>
  );
}

export default TabsContainer